// .......... Genereic Table Styles .......... //
const table = {
    colorCodedDataClassifiers: {
        '#33A532': ["Yes", "A", "Grade A", "Generating", "All Values", "All Trafficked", "Approved", "No Action", "Up To Date"],
        '#E4B22A': ["B", "Grade B", "Some Values", "Some Trafficked", "Pending"],
        '#DF1D1D': ["No", "C", "Grade C", "Not Generating", "No Values", "None Trafficked", "Not Submitted", "Turn On", "Out Of Date"]
    }
}

/*
 * Color code table data elements based on data-attr
 */
export const colorCodeTableData = () => {
    let tbodyStyles = {};
    Object.keys(table.colorCodedDataClassifiers).forEach((color) => {
        let colorKey = '';
        table.colorCodedDataClassifiers[color].forEach((classifier) => {
            colorKey += '& [data-attr="' + classifier +'"],';
        });
        colorKey = colorKey.substring(0, colorKey.length - 1);
        tbodyStyles[`${colorKey}`] = {
            color
        }
    });
    return tbodyStyles;
};