/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state
import { displayModal } from '@insticator/insticator-ui';

// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import EditSite from 'apps/sspadstxt/pages/publishermanager/publisherdetail/editsite/editsite';


/* ========== ~~~~~~~~~~ Edit Site: PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = () => ({

});

// map actions
const mapDispatchToProps = dispatch => ({
    displayModal: (isVisible, contentToDisplay, showCloseButton) => dispatch(displayModal(isVisible, contentToDisplay, showCloseButton))
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditSite);
