/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm, replacePXinStringWithEM } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- GEO ENABLING : STYLES --------------- ++++++++++ */
export default theme => ({
    geoenabling: {
        '& .itemselectorlists': {
            display: 'flex',
            justifyContent: 'space-between',
            '& .itemselectorlist': {
                flex: '1',
                marginRight: '8%', //pxToEm(40),
                '&:last-child': {
                    marginRight: '0'
                },
                '& h2': {
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: pxToEm(16),
                    marginBottom: pxToEm(20),
                    '& .icon': {
                        fontSize: '200%',
                        marginRight: pxToEm(5)
                    }
                },
                '& .itemlist': {
                    background: 'rgb(255, 255, 255)',
                    borderRadius: '0',
                    border: replacePXinStringWithEM(`1px solid rgb(239, 239, 239)`),
                    minHeight: pxToEm(360),
                    maxHeight: pxToEm(560),
                    // minWidth: pxToEm(370),
                    padding: replacePXinStringWithEM('15px 20px 20px'),
                    display: 'flex',
                    flexDirection: 'column',
                    '& .searchbar': {
                        minHeight: pxToEm(48),
                        marginBottom: pxToEm(20)
                    },
                    '& .items': {
                        flex: '1',
                        overflow: 'auto',
                        '& .item': {
                            cursor: 'pointer',
                            userSelect: 'none',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            transition: 'all .15s linear 0s',
                            padding: pxToEm(10),
                            '& .name': {
                                flex: '1'
                            },
                            '&:hover': {
                                background: 'rgb(242, 244, 248)'
                            },
                            '&.selected': {
                                color: 'rgb(120, 189, 83)',
                                fontWeight: 'bold',
                                '& .icon': {
                                    color: 'rgb(120, 189, 83)'
                                }
                            }
                        }
                    }
                },
                '&.fulllist': {
                    '& .item': {
                        '& .icon': {
                            color: 'rgb(170, 170, 170)'
                        }
                    }
                },
                '&.selectedlist': {
                    '& .item': {
                        '& .icon': {
                            '&:first-child': {
                                color: 'rgb(120, 189, 83)',
                                marginRight: pxToEm(25)
                            },
                            '&:last-child': {
                                color: 'rgb(105, 107, 110)',
                                transition: 'color .15s linear 0s'
                            }
                        },
                        '&:hover': {
                            '& .icon': {
                                '&:last-child': {
                                    color: '#DF1D1D'
                                }
                            }
                        }
                    }
                }
            }
        }
    }
});
