/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm, replacePXinStringWithEM } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// DASHBOARD : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    answertaxonomy: {
        backgroundColor: 'transparent',
        borderRadius: pxToEm(6),
        border: `${pxToEm(1)} #c0c9dc solid`,
        borderBottom: props => props.depth > 0 ? 'none' : `${pxToEm(1)} #c0c9dc solid`,
        borderLeft: props => props.depth > 0 ? 'none' : `${pxToEm(1)} #c0c9dc solid`,
        '& .taxonomycontent': {
            display: 'flex',
            justifyContent: 'space-between',
            padding: replacePXinStringWithEM('12px 12px 12px 18px'),
            '& .taxonomytext': {
                wordBreak: 'break-word',
                fontSize: `${theme.typography.size.xsmall.fontSize}`,
                lineHeight: 1.3
            },
            '& .clearbuttoncontainer': {
                lineHeight: pxToEm(16),
                width: pxToEm(21),
                paddingLeft: pxToEm(10),
                '& .linkvisible': {
                    display: 'initial'
                }
            },
            '& .linkcontainer': {
                display: 'none',
                '& .icon': {
                    margin: 0,
                    fontSize: pxToEm(16)
                },
            }
        },
        '& .taxonomychildren': {
            width: '100%',
            display: 'flex',
            '& .childrencontainer': {
                // width: `calc(100% - ${pxToEm(17)})`
                paddingRight: pxToEm(17) // doing this to allow for larger sized individual answer containers (for better visibility of taxonomy)
            },
            '& .rightindent': {
                width: pxToEm(17),
            },
        },
        '&.deletedvalue-true':{
            background: theme.color.attention.medium.swatch,
            color: theme.color.attention.medium.contrast,
            borderColor: theme.color.attention.medium.contrast
        }
    }
});