/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import {
    TAXONOMY_FETCHING,
    SET_TAXONOMY_PROCESSING,
    TRIGGER_TAXONOMY_EDITOR,
    SET_TAXONOMY_EDITOR_MODE,
    SELECT_TAXONOMY_CATEGORY,
    DESELECT_TAXONOMY_CATEGORY,
    DESELECT_TAXONOMY_CATEGORIES,
    CREATE_NEW_CATEGORY,
    CANCEL_NEW_CATEGORY,
    SET_TAXONOMY_STATE_PROPERTY,
    DISPLAY_TAXONOMY_EDITOR_MESSAGE,
    CLOSE_TAXONOMY_EDITOR_MESSAGE,
    RUN_TAXONOMY_SEARCH,
    REPORT_CATEGORY_FOUND,
    SET_SCROLL_TO_CATEGORY,
    RESET_TAXONOMY_TREE_STATE
} from 'apps/classification/state/actions/taxonomystatus';



// ^-^ ^-^ ^-^ ^-^ ^-^   TAXONOMY STATUS: DEFAULT STATE   ^-^ ^-^ ^-^ ^-^ ^-^ //

const defaultState = {
    taxonomyDataFetching: false,
    showTaxonomyEditor: false,
    isEditMode: false,
    classifyPermitted: false,
    changesDetected: false,
    searchTerm: '',
    categoriesFoundByPositions: {},
    categoriesFoundByIds: {},
    sortedCategoriesPositions: [],
    processing: false,
    selectedCategories: [],
    activeCategoryId: null,
    newCategoryParentId: null,
    newCategoryName: { name: null, isValid: null },
    editorMessage: { visible: false, messageType: null, uniqueProps: null, showCloseButton: false },
    scrollToCategory: null,
    newlyCreatedCategoryId: null
};


/* ========== ~~~~~~~~~~ TAXONOMY STATUS : REDUCER ~~~~~~~~~~ ========== */
// REDUCER
// used to manipulate application state
// takes an action, checks its type, based on that performs certain functionality, then returns new slice of state
// utilized in "_container" components
const taxonomyStatus = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case SET_TAXONOMY_STATE_PROPERTY:
            newState[action.name] = action.value;
            return newState;
        case TAXONOMY_FETCHING:
            newState.taxonomyDataFetching = action.status;
            return newState;
        case SET_TAXONOMY_PROCESSING:
            newState.processing = action.state;
            return newState;
        case TRIGGER_TAXONOMY_EDITOR:
            newState.showTaxonomyEditor = action.showEditor;
            newState.isEditMode = !action.classifyPermitted || action.editMode;
            newState.classifyPermitted = action.classifyPermitted;
            return newState;
        case SET_TAXONOMY_EDITOR_MODE:
            newState.isEditMode = action.editMode;
            return newState;
        case CREATE_NEW_CATEGORY:
            newState.activeCategoryId = action.activeCategoryId;
            newState.newCategoryParentId = action.parentId;
            newState.newCategoryName = { name: null, isValid: null };
            return newState;
        case CANCEL_NEW_CATEGORY:
            newState.activeCategoryId = null;
            newState.newCategoryParentId = null;
            newState.newCategoryName = { name: null, isValid: null };
            return newState;
        case SELECT_TAXONOMY_CATEGORY:
            newState.selectedCategories.push(action.categoryId);
            return newState;
        case DESELECT_TAXONOMY_CATEGORY:
            let index = newState.selectedCategories.indexOf(action.categoryId);
            if (index !== -1) newState.selectedCategories.splice(index, 1);
            return newState;
        case DESELECT_TAXONOMY_CATEGORIES:
            newState.selectedCategories = [];
            return newState;
        case DISPLAY_TAXONOMY_EDITOR_MESSAGE:
            const { visible, messageType, uniqueProps, showCloseButton} = action;
            newState.editorMessage = { visible, messageType, uniqueProps, showCloseButton};
            return newState;
        case CLOSE_TAXONOMY_EDITOR_MESSAGE:
            newState.editorMessage = defaultState.editorMessage;
            return newState;
        case RUN_TAXONOMY_SEARCH:
            const newSearchTerm = action.searchTerm.toLowerCase();
            if(newState.searchTerm === newSearchTerm) return newState;
            newState.searchTerm = newSearchTerm;
            newState.categoriesFoundByPositions = {};
            newState.categoriesFoundByIds = {};
            newState.sortedCategoriesPositions = [];
            newState.scrollToCategory = null;
            return newState;
        case REPORT_CATEGORY_FOUND:
            newState.categoriesFoundByPositions[action.position] = action.categoryId;
            newState.categoriesFoundByIds[action.categoryId] = action.position;
            newState.sortedCategoriesPositions.push(action.position);
            newState.sortedCategoriesPositions = newState.sortedCategoriesPositions.sort(function(a, b){return a-b});
            return newState;
        case SET_SCROLL_TO_CATEGORY:
            newState.scrollToCategory = action.categoryId;
            return newState;
        case RESET_TAXONOMY_TREE_STATE:
            newState = JSON.parse(JSON.stringify(defaultState));
            // Keep some of the values for consistency.
            newState.taxonomyDataFetching = state.taxonomyDataFetching;
            newState.showTaxonomyEditor = state.showTaxonomyEditor;
            newState.isEditMode = state.isEditMode;
            newState.classifyPermitted = state.classifyPermitted;
            newState.processing = state.processing;
            return newState;
        default:
            return newState;
    }
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default taxonomyStatus;
