// UTILS
import { batchActions } from 'redux-batched-actions';
import { displayErrorMessage, displaySuccessMessage } from '@insticator/insticator-ui';
import { updatePageDetails, changePage } from 'state/actions/app_status';
import { asyncCreateMockUp, asyncGetMockUp, asyncManualMock, asyncGetEmbedsAds,
         asyncSaveProducts, asyncSaveMockUp } from 'apps/mockupgenerator/utils/api/addproducts';

//CONSTANTS
export const CREATING_MOCK_UP = 'CREATING_MOCK_UP';
export const FETCHING_MOCK_UP_DATA = 'FETCHING_MOCK_UP_DATA';
export const RECEIVE_MOCK_UP_DATA = 'RECEIVE_MOCK_UP_DATA';
export const UPDATE_URL = 'UPDATE_URL';
export const INVALIDATE_URL = 'INVALIDATE_URL';
export const FETCHING_EMBEDS_ADS = 'FETCHING_EMBEDS_ADS';
export const RECEIVE_EMBEDS_ADS = 'RECEIVE_EMBEDS_ADS';
export const PRODUCTS_CHANGE_DETECTED = 'PRODUCTS_CHANGE_DETECTED';
export const SAVING_MOCK_UP = 'SAVING_MOCK_UP';
export const SET_PRODUCT_INSERTED = 'SET_PRODUCT_INSERTED';
export const ADD_PRODUCTS_UNMOUNT = 'ADD_PRODUCTS_UNMOUNT';
export const DISCARD_PRODUCTS_CHANGE = 'DISCARD_PRODUCTS_CHANGE';
export const REMOVE_PRODUCTS = 'REMOVE_PRODUCTS';

//ACTIONS

const receiveMockUpData = (mockupData, create) => ({
    type: RECEIVE_MOCK_UP_DATA,
    mockupData,
    create
});

export const setProductInserted = ({ id, inserted, location })  => ({
    type: SET_PRODUCT_INSERTED,
    id,
    inserted,
    location
});

export const removeProducts = products => ({
    type: REMOVE_PRODUCTS,
    products
});

const fetchingMockups = (status) => ({
    type: FETCHING_MOCK_UP_DATA,
    status
});
const creatingMockup = (status) => ({
    type: CREATING_MOCK_UP,
    status
});

export const invalidateURL = (status) => ({
    type: INVALIDATE_URL,
    status
});

export const updateURL = url => ({
    type: UPDATE_URL,
    url
});

const receiveEmbedsAds = (embedsAds) => ({
    type: RECEIVE_EMBEDS_ADS,
    embedsAds
});

const fetchingEmbedsAds = status => ({
    type: FETCHING_EMBEDS_ADS,
    status
});

export const setProductsChangeDetected = state => ({
    type: PRODUCTS_CHANGE_DETECTED,
    state
});

export const discardProductsChange = () => ({
   type: DISCARD_PRODUCTS_CHANGE
});

const savingMockup = (status) => ({
    type: SAVING_MOCK_UP,
    status
});

export const addProductsUnmount = () => ({
    type: ADD_PRODUCTS_UNMOUNT
});

//BATCHED ACTIONS
const createMockupFail = () => batchActions([creatingMockup(false), displayErrorMessage('There was an issue creating your mock up.')]);
const createMockupSuccess = (mockupData) => batchActions([creatingMockup(false), receiveMockUpData(mockupData)]);

const getMockupFail = () => batchActions([fetchingMockups(false), displayErrorMessage('There was an issue with your mock up.')]);

const getEmbedsAdsFail = () => batchActions([fetchingEmbedsAds(false), displayErrorMessage('There was an issue fetching your integrations.')]);
const getEmbedsAdsSuccess = (embedsAds) => batchActions([fetchingEmbedsAds(false), receiveEmbedsAds(embedsAds)]);

const saveMockupFail = () => batchActions([savingMockup('fail'), displayErrorMessage('There was an issue saving your mock up.')]);
const saveMockupSuccess = () => batchActions([savingMockup('success'), displaySuccessMessage('Your mock up was saved successfully!')]);
//THUNKS

export const createMockup = (pageURL, siteUUID, pageCode) => dispatch => {
    dispatch(creatingMockup(true));
    // "requestStatus": "SUCCESS" or "DUPLICATE" or "NO_CONTENT",
    asyncCreateMockUp(pageURL, siteUUID)
        .then(response => {
            dispatch(createMockupSuccess(response.data));
            if(response.data.requestStatus === 'NO_CONTENT'){
                dispatch(updatePageDetails('apps/mockupgenerator/manualmockup'));
                dispatch(changePage('apps/mockupgenerator/manualmockup'));
                dispatch(displayErrorMessage('We were unable to fetch the source code for this site. You can still grab it manually.'))
            }else if(response.data.requestStatus === 'DUPLICATE'){
                dispatch(displayErrorMessage('You have already created a mock up for this page!'))
            } else {
                dispatch(updatePageDetails('apps/mockupgenerator/addproducts'));
                dispatch(changePage('apps/mockupgenerator/addproducts'));
            }
        })
        .catch(err => console.log(err) || dispatch(createMockupFail()))
};

export const getMockup = (pageUUID) => dispatch => {
    dispatch(fetchingMockups(true));
    asyncGetMockUp(pageUUID)
        .then(response => {
            let mockupData = response.data;
            mockupData.requestStatus = 'SUCCESS';
            dispatch(createMockupSuccess(mockupData));
            dispatch(updatePageDetails('apps/mockupgenerator/addproducts'));
            dispatch(changePage('apps/mockupgenerator/addproducts'));
        })
        .catch(err => console.log(err) || dispatch(getMockupFail()))
};

export const manualMock = (pageURL, siteUUID, pageCode) => dispatch => {
    dispatch(fetchingMockups(true));
    // "requestStatus": "SUCCESS" or "DUPLICATE" or "NO_CONTENT",
    asyncManualMock(pageURL, siteUUID, pageCode)
        .then(response => {
            dispatch(createMockupSuccess(response.data));
            dispatch(updatePageDetails('apps/mockupgenerator/addproducts'));
            dispatch(changePage('apps/mockupgenerator/addproducts'));
        })
        .catch(err => console.log(err) || dispatch(createMockupFail()))
};

export const getEmbedsAds = (pageUUID, source) => dispatch => {
    dispatch(fetchingEmbedsAds(true));
    asyncGetEmbedsAds(pageUUID, source)
        .then(response => dispatch(getEmbedsAdsSuccess(response.data)))
        .catch(err => console.log(err) ||dispatch(getEmbedsAdsFail()))
};

export const saveMockup = (pageUUID, products, pageCode) => dispatch => {
    dispatch(savingMockup('processing'));
    asyncSaveProducts(pageUUID, products)
        .then(response => {
            asyncSaveMockUp(pageUUID, pageCode)
                .then(response => dispatch(saveMockupSuccess()))
                .catch(err => console.log(err) || dispatch(saveMockupFail()))        })
        .catch(err => console.log(err) || dispatch(saveMockupFail()))
};
