/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { Component } from 'react';
//material
import { Icon } from '@insticator/insticator-ui';
//styles
import injectSheet from 'react-jss';
import styles from 'apps/sspadstxt/pages/publishermanager/publisherdetail/editsite/preferredimplementation/type/custom/editclear/editclear_styles';




/* ++++++++++ --------------- PUBLISHER DETAIL --------------- ++++++++++ */

const EditClear = props => {

    return (
        <div className={`editclear ${props.classes.editclear} isEditMode-${props.isEditMode}`}>
            <div className={'editbutton'} onClick={()=>props.edit(!props.isEditMode)}>
                <Icon name={'pen'} />
            </div>
            {!!props.delete && <div className={'deletebutton'} onClick={props.delete}>
                <Icon name={'trash'} />
            </div>}
        </div>
    );
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(EditClear);
