/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { CLEAR_ALL_STATES, SET_AUTH_TOKEN_DATA,
    RECEIVE_ADMIN_PANEL_CREDENTIALS, FETCHING_USER_CREDENTIALS,
    FETCHING_USER_ERROR, RECEIVE_ADMINS} from 'state/actions/auth_status';



/* ========== ~~~~~~~~~~  AUTH STATUS : REDUCER ~~~~~~~~~~ ========== */


//----default State ----//

const defaultState = {
    adminInformation: {
        adminEmail: "",
        adminName: "",
        adminUUID: "",
        permissions: []
    },
    isCASuperAdmin: false,
    fellowAdmins: {},
    fetchingAdminInformation: false,
    adminInfoReceived: false,
    adminInfoFailed: false
};
//CLASSIFICATION UI PERMISSION VALUES "newAdminPanel.csAdmin, newAdminPanel.csSuperAdmin"

// REDUCER
// used to manipulate application state
// takes an action, checks its type, based on that performs certain functionality, then returns new slice of state
// utilized in "_container" components

const authStatus = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case CLEAR_ALL_STATES:
            return defaultState;
        case RECEIVE_ADMIN_PANEL_CREDENTIALS:
            newState.isCASuperAdmin = !!action.data.permissions ? action.data.permissions.includes('newAdminPanel.csSuperAdmin') : false;
            newState.adminInformation = action.data;
            newState.adminInfoReceived = true;
            return newState;
        case FETCHING_USER_CREDENTIALS:
            newState.fetchingAdminInformation = action.status;
            return newState;
        case FETCHING_USER_ERROR:
            newState.adminInfoFailed = true;
            return newState;
        case RECEIVE_ADMINS:
            newState.fellowAdmins = action.admins;
            return newState;
        default:
            return state;
    }
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default authStatus;
