/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm, pxToEmWithBaseline, replacePXinStringWithEM } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// TAXONOMY CATEGORY : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    taxonomycategory: {
        // transition: 'background .2s linear 0s',
        marginLeft: pxToEm(30),
        '&.level-0': {
            marginLeft: 0,
        },
        '& .category-line': {
            display: 'flex',
            alignItems: 'center',
            height: pxToEmWithBaseline({ value: 26, fontSizeBaseline: 12 }),

            '& p span': {
                color: '#C0C9DC'
            },
            '&:not(:hover)': {
                '& .taxonomycategorycontrols': {
                    display: 'none'
                }

            },
            '&:hover': {
                background: theme.color.supporting.light.swatch,
                cursor: 'pointer',
            },
        }
    }
});



