import React from 'react';
import { Button, CTAs } from "@insticator/insticator-ui";
//utils
import { downloadTxtFile } from 'utils/downloadFiles';

//styles
import styles from 'apps/sspadstxt/pages/publishermanager/publisherdetail/editsite/preferredimplementation/type/typecontrols/typecontrols_styles';
import injectSheet from "react-jss";


const TypeControls = props => {


    const renderButtons = isEditMode => {
        if(isEditMode){
            return editingButtons
        } else {
            return currentFileButtons
        }

    };
    //MICHELE REQUESTED TO REMOVE COPYING FROM ADSTXT // ONLY COMMENTED OUT SO WE CAN REVERT IF NEEDED

    // const handleCopyCode = (e) => {
    //     window.getSelection().removeAllRanges();// to deselect
    //     let range = document.createRange();
    //     range.selectNode(document.getElementsByClassName('valuehighlighter')[0]);
    //     window.getSelection().addRange(range);
    //     document.execCommand("copy");
    //     props.sendMessage('ads.txt values have been copied to clipboard!');
    // };
    const formatArrayToStringFile = (arr) => {
        let str = ``;
        arr.map(val => str = str + `${val}\n`);
        return str;
    };
    const currentFileButtons = (
        <CTAs name={'currentfile'}>
            {/*<Button text={'Copy Values'} variant={'primary'} type={'action'} action={handleCopyCode} />*/}
            <Button text={'Download File'} variant={'system'} type={'action'} action={() => downloadTxtFile('adstxt_file', formatArrayToStringFile(props.values))} />
        </CTAs>
    );

    const editingButtons = (
        <CTAs name={`newfile discard-${!!props.discard}`}>
            <Button text={'Save as preferred'} variant={'primary'} type={'action'} action={props.save} />
            {!!props.discard && <Button text={'Discard Changes'} variant={'system'} type={'action'} action={props.discard} name={'discard'} disabled={!props.customDetailsUpdated}/>}
        </CTAs>
    );

    return  <div className={`typecontrols ${props.classes.typecontrols}`}>
                {renderButtons(props.isEditMode)}
            </div>

};

/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(TypeControls);
