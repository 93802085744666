/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm, pxToEmWithBaseline } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// TAXONOMY EDITOR HEADER : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    taxonomyeditorheader: {
        '& .name-editorheader': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems:'flex-start',
            '& .name-editortitle': {
                '& .title': {
                    width: pxToEm(204),
                    height: pxToEm(38),
                    verticalAlign: 'center',
                    '& h5': {
                        fontSize: pxToEm(20),
                        marginBottom: 0
                    }
                }
            },
            '& .name-editorstatecontrols': {
                '& .controls': {
                    display: 'flex',
                    '& .control': {
                        fontSize: pxToEm(14),
                        height: pxToEmWithBaseline({ value: 38, fontSizeBaseline: 14 }),
                        minWidth: pxToEm(95),
                    }
                }

            }
        }
    }
});
