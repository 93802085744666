import axios from 'axios';

import store from 'state/store/store';
import {signOut} from 'state/actions/auth_status';
import {changePage, updatePageDetails} from 'state/actions/app_status';
import { environment } from "@insticator/insticator-ui"; // iui-utils

const handleErrorResponse = (response) => {
    switch(response.status) {
        case 401:
            handleError401();
            return;
        case 403:
            handleError403();
            return;
        default:
            return;
    }
};

// if the user is not authenticated, they are automatically signed out and returned to sign in screen.
// If the user is already on signin page, stop calling the dispatch in loop.
const handleError401 = () => {
    if(!store.getState().authStatus.adminInfoReceived && (environment !== 'LOCALHOST')) routeToMarketingSite();
};
const routeToMarketingSite = () => window.location.href = (environment === 'PRODUCTION' ? "http://insticator.com" : "http://hunchme.com");

// Returned to 403 error page if user is not permitted to perform certain actions.
const handleError403 = () => {
    store.dispatch(updatePageDetails('error/403/'));
    store.dispatch(changePage('error/403/true'));
};

// The response interceptors listen to all the responses coming in from the server.
// Here we check the error status and take appropriate action.
axios.interceptors.response.use(function (response) { return response; }, function (error) {
    if(!!error.response) {
        handleErrorResponse(error.response);
    }
    return Promise.reject(error);
});
