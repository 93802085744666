/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state
import {  sendMessage } from '@insticator/insticator-ui';
// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import TypeControls from 'apps/sspadstxt/pages/publishermanager/publisherdetail/editsite/preferredimplementation/type/typecontrols/typecontrols';


/* ========== ~~~~~~~~~~ TYPE CONTROLS: CHILD ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ authStatus, sessionStatus, sspAdsTxtState }) => ({
    customDetailsUpdated: sspAdsTxtState.publisherManagerStatus.customDetailsUpdated
});

// map actions
const mapDispatchToProps = dispatch => ({
    sendMessage: message => dispatch(sendMessage(message))
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TypeControls);
