/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state
import { getSites, getSiteDetails } from 'apps/sspadstxt/state/actions/publishermanagerstatus';
// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import CurrentImplementation from 'apps/sspadstxt/pages/publishermanager/publisherdetail/editsite/currentimplementation/currentimplementation';


/* ========== ~~~~~~~~~~ PREFERRED IMPLEMENTATION: CHILD ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ authStatus, sessionStatus, sspAdsTxtState }) => ({
    fileType: sspAdsTxtState.publisherManagerStatus.currentImplementation.fileType,
    fileName: sspAdsTxtState.publisherManagerStatus.currentImplementation.fileName,
    status: sspAdsTxtState.publisherManagerStatus.currentImplementation.status,
    sspTestingWith: sspAdsTxtState.publisherManagerStatus.newCustomFileDetails.sspTestingWith

});

// map actions
const mapDispatchToProps = dispatch => ({

});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CurrentImplementation);
