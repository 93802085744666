/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { Component } from 'react';
import TagManager from 'react-gtm-module';
import { Switch, Route, Redirect } from 'react-router';
import { ConnectedRouter } from 'react-router-redux';
// pages
import Dashboard from 'pages/dashboard/dashboard_container';
// material
import { Error, Modal, MessageHolder, LoadState, OnInActivityMessage } from '@insticator/insticator-ui'; // iui-material
// utils
import { extendClassSession } from 'utils/api/auth';
import { url } from 'utils/urls';
import { 
    history,
    pageurl, device, environment,
    language, languages, cookieEnabled, userAgent, visitorGeo, isMobile,
    clearAutoLogoutTimer, clearCheckInactivityTimer, setAutoLogoutTimer, setCheckInactivityTimer 
} from '@insticator/insticator-ui'; // iui-utils
// styles
import injectSheet from 'react-jss';
import styles from 'app_styles';



/* ========== ~~~~~~~~~~ APP ~~~~~~~~~~ ========== */
class App extends Component {
    constructor(props) {
        super(props);
        this.state = { phase: 'loading' };
        this.tokenUUID = !!this.authRef && !!this.props.queryParams['token_uuid'] ? this.props.queryParams['token_uuid'] : null;
        this.handleSessionExtensionCallback = this.handleSessionExtensionCallback.bind(this);
        this.handleAutoLogout = this.handleAutoLogout.bind(this);
    }
    componentDidMount() {
        this.props.isUserLoggedIn();
        this.props.asyncFetchUsers();
        // // Called every time user reloads the application (this causes session extension in BE). This will extend the session timeout check for 23 hours and 30 mins.
        if (!!this.props.adminInfoReceived) this.setSessionExtensionTimeout(3300000);
    }
    componentDidUpdate(prevProps) {
        if (history.location.pathname.replace('/', '') !== this.props.currentPage) {
            this.props.updatePageDetails(history.location.pathname.replace('/', ''))
        }
        if(this.props.adminInfoFailed !== prevProps.adminInfoFailed){
            if(!this.isLocalHost()) this.handleNoCredentials()
        }
        // call the function on signin
        if (!!this.props.adminInfoReceived && prevProps.adminInfoReceived !== this.props.adminInfoReceived) this.setSessionExtensionTimeout(3300000);
    }
    isLocalHost = () => (environment === 'LOCALHOST');
    setSessionExtensionTimeout(timeout) {
        clearCheckInactivityTimer();
        setCheckInactivityTimer(this.handleSessionExtensionCallback, timeout);
    }
    handleNoCredentials = () => {
        if(this.props.adminInfoFailed) this.routeToMarketingSite();
    };
    routeToMarketingSite = () => window.location.href = (environment === 'PRODUCTION' ? "http://insticator.com" : "http://hunchme.com");

    // Gets called when user is signed in and the session is about to expire.
    handleSessionExtensionCallback() {
        // get current timestamp
        const currentTime = new Date().getTime();
        // gets the timestamp for last action triggered by user.
        const lastActionDispatchedAt = this.props.lastActionDispatchedAt;
        // calculates time difference between current time and the last time user triggered some action.
        const minutesTolastActionDispatchedAt = Math.ceil(((currentTime - lastActionDispatchedAt) / 1000) / 60);

        // if last time user was active is 15 minutes or more than 15 minutes
        if (minutesTolastActionDispatchedAt <= 15) {
            // auto logout in 30 minutes if no response from user
            setAutoLogoutTimer(this.handleAutoLogout, 900000);
            // show the modal to confirm if user is still there for 30 minutes
            this.props.displayModal(true, <OnInActivityMessage primaryAction={() => { extendClassSession().then(() => this.handleExtendSessionSuccess()).catch(() => this.handleExtendSessionFailure()); }} />, false);
        } else {
            // if the user is active/was recently active on the browser, auto extend the session by 24 hours more. Reset the session extension timeout.
            extendClassSession().then(() => this.handleExtendSessionSuccess()).catch(() => this.handleExtendSessionFailure());

        }
    }

    handleAutoLogout() {
        this.routeToMarketingSite();
    }

    handleExtendSessionSuccess() {
        clearAutoLogoutTimer();
        this.setSessionExtensionTimeout(3300000);
        this.props.displayModal(false);
    };

    handleExtendSessionFailure() {
        this.props.displayErrorMessage('Sorry! we were unable to extend your session.');
        this.props.displayModal(false);
    }


    //all auth based routing was removed from this method. This method can me modified accordingly to add this functionality back.
    navigateOnPageLoad() {
        return <Redirect to='/apps' />;
    }

    render() {
        if ((this.props.fetchingAdminInformation || !this.props.adminInfoReceived) && !this.isLocalHost()) {
            return (<LoadState
                        graphicName={`insticator-icon`}
                        graphicText={`Browser with Search Icon`}
                        bodycopy={`Insticator is loading your data.`}
                    />)
        }
        return (
            <ConnectedRouter history={history}>
                <div id={`app`} className={`${this.props.classes.app} ${this.props.currentApp}`}>
                    <Switch>
                        <Route exact path='/' render={() => this.navigateOnPageLoad()} />
                        <PrivateRoute authed path='/apps' component={Dashboard} /> {/* !!! Nested routes to sections are in the Dashboard component !!! */}
                        <PrivateRoute authed path='/error' component={Error} />
                        <Redirect to='/error/404' />
                    </Switch>
                    {/*<PrivateRoute authed={props.isDataReceived} exact path='/getstarted' component={GetStartedPage} />*/}
                    <Modal /> {/* activated via redux action/state and actual content is passed through there as well */}
                    <MessageHolder /> {/* activated via redux action/state and actual content is passed through there as well */}
                </div>
            </ConnectedRouter>
        );
    }
}

/*DISABLING AUTH FUNCTIONALITY
<Route path='/auth' render={() => (this.props.isSignedIn ? (<Redirect to='/dashboard/home' />) : (<Auth {...this.props} /> ))} />
*/

const PrivateRoute = ({ component: Component, authed, ...rest }) => {
    return (
        <Route
            {...rest}
            render={(props) => authed === true
                ? <Component {...props} />
                : <Redirect to={{ pathname: '/auth/signin', state: { from: props.location } }} />}
        />
    )
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(App);
