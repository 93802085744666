/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React from 'react';
// material
//styles
import injectSheet from 'react-jss';
import styles from 'apps/sspadstxt/pages/adstxt/masteradstxt/ssplist/ssplist_styles';

/* ++++++++++ --------------- COMPONENT ADS.TXT TIMELINE --------------- ++++++++++ */

const SSPList = props => {

    return (
        <div className={`ssplist ${props.classes.ssplist} ${props.type}-file`}>
            <div className={'ssplabel'} >{'Active SSPs'}</div>
            <ul className={'list'}>
                {props.list.map(ssp => <li>{ssp}</li>)}
            </ul>
        </div>
    )
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(SSPList);
