/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { Component } from 'react';
//material
import {
    TextAreaField,
    TextField,
    SelectableList,
    Button,
    PopupMessage,
    Loader,
    ValueHighlighter,
    MultiSelectDropDown,
    CheckboxField
} from '@insticator/insticator-ui';
import EditClear from 'apps/sspadstxt/pages/publishermanager/publisherdetail/editsite/preferredimplementation/type/custom/editclear/editclear';
import TypeControls from 'apps/sspadstxt/pages/publishermanager/publisherdetail/editsite/preferredimplementation/type/typecontrols/typecontrols_container';
//styles
import injectSheet from 'react-jss';
import styles from 'apps/sspadstxt/pages/publishermanager/publisherdetail/editsite/preferredimplementation/type/custom/custom_styles';




/* ++++++++++ --------------- PUBLISHER DETAIL --------------- ++++++++++ */

class Custom extends Component{
    constructor(props){
        super(props)
        this.state = {
            enableAutoInsert: this.props.storedEnableAutoInsert
        }
    }

    componentDidMount() {
        if (!Object.keys(this.props.sspList).length) this.props.getSSPList();
        this.handleAutoInsertSelect(this.state.enableAutoInsert);
    }

    componentWillUnmount() {
        this.props.handleEdit(false)
    }

    renderSSPList = () => {
        let items = [];
        Object.keys(this.props.sspList).forEach(ssp => items = [...items, ...Object.keys(this.props.sspList[ssp].adsTxtInventories).map(type => ({value: Number(type), name:`${this.props.sspList[ssp].sspName} ${this.props.sspList[ssp].adsTxtInventories[type].name}`}))])
        return items
    };

    handleCustomMasterFileSelectInsticator = (masterTypeSelected) => {
        this.props.handleMasterSSPValues(masterTypeSelected, this.props.customMasterFileType, this.props.storedMasterFiles[masterTypeSelected], this.props.storedMasterFiles[this.props.customMasterFileType], 'INSTICATOR' )
    };

    handleCustomMasterFileSelectOKO = (masterTypeSelected) => {
        this.props.handleMasterSSPValues(masterTypeSelected, this.props.customMasterFileTypeOKO, this.props.storedMasterFilesOKO[masterTypeSelected], this.props.storedMasterFilesOKO[this.props.customMasterFileTypeOKO], 'OKO' )
    };

    handleCustomMasterFileSelectS2S = (masterTypeSelected) => {
        this.props.handleMasterSSPValues(masterTypeSelected, this.props.customMasterFileTypeS2S, this.props.storedMasterFilesS2S[masterTypeSelected], this.props.storedMasterFilesS2S[this.props.customMasterFileTypeS2S], 'S2S' )
    };

    handleSSPInventorySelect = (selectedItems) => {
        if (selectedItems.length < this.props.adInventoryIds.length) {
            const items = this.props.adInventoryIds.filter(x => !selectedItems.includes(x));
            items.forEach(item => {
                this.props.removeSSPValues(item, this.props.storedSSPValues[item]);
            });
        } else {
            const items = selectedItems.filter(x => !this.props.adInventoryIds.includes(x));
            items.forEach(item => {
                this.props.addSSPValues(item, this.props.storedSSPValues[item]);
            });
        }
    };

    formatCustomDetails = () => ({
    preferredFileType : "CUSTOM",
    preferredMasterFileType : null,
    customFileDetails : {
        customFileName : this.props.fileName.value,
        customFileAdInventoryIds : this.props.adInventoryIds,
        customFileMasterValueType : this.props.customMasterFileType,
        customFileMasterValueTypeOKO : this.props.customMasterFileTypeOKO,
        customFileMasterValueTypeS2S : this.props.customMasterFileTypeS2S,
        customFileAdsTxtValues : this.props.adsTxtValues
        },
    enableAutoInsert: this.state.enableAutoInsert
    });

    saveCustomDetails = () => {
        this.props.invalidateCustomFile();
        if(this.props.fileName.valid){
            this.props.updateSiteDetails(this.props.siteAdstxtID, this.props.selectedSite, this.formatCustomDetails());
        } else {
            this.props.sendErrorMessage('Please enter a valid file name!')
        }
    };

    delete = () => {
        this.props.delete(this.props.siteAdstxtID, this.props.selectedSite.seatType);
        this.props.displayModal(false);
    };

    deleteAction = () => this.props.customFileSaved ? ()=> this.props.displayModal(true, this.deleteModal(), true) : false;

    deleteModal = () => (
        <PopupMessage
            buttonPrimary={{text:'Delete', variant: 'delete', action: this.delete}}
            buttonSecondary={{text:'Cancel', variant: 'primary', action:()=>this.props.displayModal(false)}}
            title={'Are you sure you want to delete this custom file?'} />
    );

    renderAdsTxtLoader = () => {
        if (this.props.loading.updatingMasterValues || this.props.loading.updatingSSPValues) {
            return <Loader/>
        }
    };

    handleLoad = () => {
        let loading = false;
        Object.keys(this.props.loading).forEach(type => {
            if(!!this.props.loading[type]){
                loading = this.props.loading[type]
            }
        });
        return loading;
    };

    renderCustomValues = (newValues) => {
        const adsTxtValues = newValues || [...this.props.adsTxtValues]
        if(adsTxtValues) {
            const insticatorAdsTxtIndicatorValue = this.props.selectedOverrideAccountId ? `insticator.com, ${this.props.selectedOverrideAccountId}, DIRECT, b3511ffcafb23a32` : `insticator.com, ${this.props.selectedAccountUUID}, DIRECT, b3511ffcafb23a32`
            const okoAdsTxtIndicatorValue = this.props.selectedOverrideAccountId ? `oko.uk, ${this.props.selectedOverrideAccountId}, DIRECT, b3511ffcafb23a32` : `oko.uk, ${this.props.selectedAccountUUID}, DIRECT, b3511ffcafb23a32`
            const insticatorLine = adsTxtValues.find(value => value.startsWith('insticator.com'))
            const okoLine = adsTxtValues.find(value => value.startsWith('oko.uk'))
            if (insticatorLine) {
                if (insticatorLine !== insticatorAdsTxtIndicatorValue) {
                    const index = adsTxtValues.indexOf(insticatorLine)
                    adsTxtValues[index] = insticatorAdsTxtIndicatorValue
                }
            } else {
                 adsTxtValues.push(insticatorAdsTxtIndicatorValue)
            }
            if (okoLine) {
                if (okoLine !== okoAdsTxtIndicatorValue) {
                    const index = adsTxtValues.indexOf(okoLine)
                    adsTxtValues[index] = okoAdsTxtIndicatorValue
                }
            } else {
                adsTxtValues.push(okoAdsTxtIndicatorValue)
            }

            return adsTxtValues
        }
    };

    customValuesString = () => {
        if(this.props.adsTxtValues){
            return this.renderCustomValues().join("\n");
        }
    };

    handleDiscardChanges = () => {
        this.props.discardPreferredChanges()
    };

    missingValues = () => {
        return this.renderCustomValues().filter(value => !this.props.adsTxtFileRows.includes(value));
    }

    isEdit = () => this.props.isEditMode || this.props.storedFileType !== 'CUSTOM' ||
        this.state.enableAutoInsert !== this.props.storedEnableAutoInsert

    handleAutoInsertSelect = enableAutoInsert => {
        this.setState({ enableAutoInsert: enableAutoInsert });

        let newValues = [...this.props.adsTxtValues]
        const newEntries = [
            'contact=revops@insticator.com',
            `managerdomain=${this.props.selectedSite.seatType === 'INSTICATOR' ? 'insticator.com' : 'oko.uk'}`,
            `ownerdomain=${this.props.selectedSite.name}`,
        ];
        newEntries.forEach(entry => {
            const index = newValues.indexOf(entry);
            if (index > -1) {
                newValues.splice(index, 1);
            }
        })
        if (enableAutoInsert) {
            newEntries.forEach(entry => {
                newValues.unshift(entry);
            });
        }
        this.props.updateCustomAdsTxtValues(newValues)
        this.renderCustomValues(newValues)
    }

    render(){
        return (
            <div className={`custom ${this.props.classes.custom} loading-${this.handleLoad()} edit-${this.props.isEditMode}`}>
                <div className={'checkbox-row'}>
                    <CheckboxField
                        type={'checkbox'}
                        label={'Enable auto-insert'}
                        checked={this.state.enableAutoInsert}
                        handleChange={e => this.handleAutoInsertSelect(!this.state.enableAutoInsert)}/>
                </div>
                <div className={'name-edit'}>
                    <TextField
                        label={'File Name'}
                        type={'text'}
                        value={this.props.fileName.value}
                        valid={this.props.fileName.valid}
                        required
                        disabled={!this.props.isEditMode}
                        handleChange={e => this.props.updateCustomFileName(e.target.value)}/>
                    <EditClear edit={this.props.handleEdit} isEditMode={this.props.isEditMode} delete={this.deleteAction()}/>
                </div>
                <div className={'configurevalues'}>
                    {!this.props.isEditMode &&
                    <ValueHighlighter
                        label={<div>Ads.txt values {this.renderAdsTxtLoader()}</div>}
                        values={this.renderCustomValues()}
                        valuesToHighlight={this.missingValues()}
                        direction={'column'}
                        showHighlightedFirst={true}
                        minHeight={'340px'}
                    />
                    }
                    {this.props.isEditMode &&
                    <div className={`filevalues-custom`}>
                    <TextAreaField
                        label={<div>Ads.txt values {this.renderAdsTxtLoader()}</div>}
                        value={this.customValuesString()}
                        type={'textarea'}
                        valid={'customfields'}
                        required
                        cols={'10'}
                        disabled={!this.props.isEditMode}
                        handleChange={e => {
                                let values = e.target.value.split("\n");
                                this.props.updateCustomAdsTxtValues(values);
                        }}/>
                    </div>
                    }
                    <div className={'lists'}>
                        <div className={'list-label'}>
                            <span className={'label adstxtvalues'}>Add Master Values</span>
                            <SelectableList
                                name={'adstxt-values'}
                                type={'radio'}
                                items={[
                                    {"value": "DISPLAY_SHORT", "text": "Insticator - Display short file"},
                                    {"value": "DISPLAY_LONG", "text": "Insticator - Display long file"},
                                    {"value": "VIDEO_SHORT", "text": "Insticator - Video short file"},
                                    {"value": "VIDEO_LONG", "text": "Insticator - Video long file"},
                                    {"value": "COMBINED_SHORT", "text": "Insticator - Combined short file"},
                                    {"value": "COMBINED_LONG", "text": "Insticator - Combined long file"},
                                    {"value": "NONE", "text": "None"}
                                ]}
                                selected={this.props.customMasterFileType}
                                handleChange={this.handleCustomMasterFileSelectInsticator} />
                            <SelectableList
                                name={'adstxt-values-oko'}
                                type={'radio'}
                                items={[
                                    {"value": "OKO_DISPLAY_SHORT", "text": "OKO - Display short file"},
                                    {"value": "OKO_DISPLAY_LONG", "text": "OKO - Display long file"},
                                    {"value": "OKO_VIDEO_SHORT", "text": "OKO - Video short file"},
                                    {"value": "OKO_VIDEO_LONG", "text": "OKO - Video long file"},
                                    {"value": "OKO_COMBINED_SHORT", "text": "OKO - Combined short file"},
                                    {"value": "OKO_COMBINED_LONG", "text": "OKO - Combined long file"},
                                    {"value": "OKO_NONE", "text": "None"}
                                ]}
                                selected={this.props.customMasterFileTypeOKO}
                                handleChange={this.handleCustomMasterFileSelectOKO} />
                            <SelectableList
                                name={'adstxt-values-s2s'}
                                type={'radio'}
                                items={[
                                    {"value": "S2S_DISPLAY_SHORT", "text": "S2S - Display short file"},
                                    {"value": "S2S_DISPLAY_LONG", "text": "S2S - Display long file"},
                                    {"value": "S2S_NONE", "text": "None"}
                                ]}
                                selected={this.props.customMasterFileTypeS2S}
                                handleChange={this.handleCustomMasterFileSelectS2S} />
                            {this.props.isEditMode && <Button text={'Clear Selection'} variant={'delete'} type={'action'} action={this.props.clearSelection}/>}
                        </div>
                        {!!Object.keys(this.props.sspList).length &&
                        <div className={'list-label'}>
                            <span className={'label'}>Add SSP Values</span>
                            <MultiSelectDropDown
                                name={'ssp-values'}
                                title={'SSP Values'}
                                icon={`link`}
                                onSelect={this.handleSSPInventorySelect}
                                items={this.renderSSPList()}
                                button={false}
                                selectedItems={this.props.adInventoryIds} />

                        </div>}
                    </div>
                </div>
                <TypeControls values={[`insticator.com, ${this.props.selectedAccountUUID}, DIRECT`, ...this.props.adsTxtValues]} save={this.saveCustomDetails} discard={this.handleDiscardChanges} isEditMode={this.isEdit()} />
            </div>
        );
    }
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(Custom);
