/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React from 'react';
// material
import TimelineCard from 'apps/sspadstxt/material/adstxttimeline/timelinecard/timelinecard';
//styles
import injectSheet from 'react-jss';
import styles from 'apps/sspadstxt/material/adstxttimeline/adstxttimeline_styles';



/* ++++++++++ --------------- COMPONENT ADS.TXT TIMELINE --------------- ++++++++++ */
const AdsTxtTimeline = props => {
    const renderCards = () => {
        let currentVersion = null;
        let otherVersions = [];
        const isCurrentVersion = (version) => Number(props.currentVersion) === Number(version.version);
        const createCard = (version) => <TimelineCard
            currentVersion={Number(props.currentVersion) === Number(version.version)}
            version={version.version}
            updated={version.updatedOn}
            onSelect={props.onSelect}
            selectedVersion={Number(props.selectedVersion) === Number(version.version)} 
        />;
        props.data.forEach(version => {
            if (isCurrentVersion(version)) {
                currentVersion = createCard(version);
            } else {
                otherVersions.push(createCard(version));
            }
        });
        const finalCardsMarkup = () => (
            <div className='timelinecardcontainer'>
                <div className='timelineline'></div>
                <div className='timelinecardcurrent'>
                    {currentVersion}
                </div>
                <div className='timelinecardwrapper'>
                    {otherVersions}
                </div>
            </div>
        );
        return finalCardsMarkup();
    };

    return (
        <div className={`adstxttimeline ${props.classes.adstxttimeline}`}>
            {renderCards()}
        </div>
    )
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(AdsTxtTimeline);
