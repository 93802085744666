// libraries
import React from "react";
//material
import { Icon } from '@insticator/insticator-ui'; // iui-material
//styles
import injectSheet from 'react-jss';
import styles from 'apps/classification/pages/questionmanager/questionstatusindicator/questionstatusindicator_styles';



const QuestionStatusIndicator = props => props.status ?
    <div className={`${props.classes.questionstatusindicator} questionstatusindicator`}><Icon name={'check'}/> Complete</div>
    :
    <div className={`${props.classes.questionstatusindicator} questionstatusindicator`}><Icon name={'ban'}/> Incomplete</div>;



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(QuestionStatusIndicator);