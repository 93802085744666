import React from 'react';
//styles
import styles from 'apps/sspadstxt/pages/sspmanager/sspdetail/sspinfo/statusindicator/statusindicator_styles';
import injectSheet from "react-jss";





const StatusIndicator = props => {
    return (
        <div className={`statusindicator ${props.classes.statusindicator} status-${props.status}`} >
        {!!props.status ? props.status.toLowerCase() : 'inactive'}
        </div>
    )
};

/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(StatusIndicator);