/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state
import { getSSPApprovalData, getSSPApprovalStats, exportSSPApprovals } from 'apps/sspadstxt/state/actions/sspapprovalsstatus';
import { getSSPDetails } from 'apps/sspadstxt/state/actions/sspmanagerstatus';
import { getSSPList, selectSiteFromTable, selectPublisherFromTable } from 'apps/sspadstxt/state/actions/publishermanagerstatus';
// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import SSPApprovals from 'apps/sspadstxt/pages/sspapprovals/sspapprovals';


/* ========== ~~~~~~~~~~ SSP APPROVALS: PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ sspAdsTxtState }) => ({
    filters: sspAdsTxtState.sspApprovalsStatus.filterSettings,
    tableData: sspAdsTxtState.sspApprovalsStatus.sspTableData,
    fetchingTableData: sspAdsTxtState.sspApprovalsStatus.fetchingSSPTableData,
    currentPage: sspAdsTxtState.sspApprovalsStatus.currentPage,
    totalRows: sspAdsTxtState.sspApprovalsStatus.totalRows,
    totalPages: sspAdsTxtState.sspApprovalsStatus.totalPages,
    fetchingStats: sspAdsTxtState.sspApprovalsStatus.fetchingApprovalStats,
    statsReceived: Object.keys(sspAdsTxtState.sspApprovalsStatus.approvalStats).length,
    stats: sspAdsTxtState.sspApprovalsStatus.approvalStats,
    sspList: sspAdsTxtState.publisherManagerStatus.sspList,
    exporting: sspAdsTxtState.sspApprovalsStatus.exporting
});

// map actions
const mapDispatchToProps = dispatch => ({
    getSSPApprovalTableData: filterData => dispatch(getSSPApprovalData(filterData)),
    getSSPApprovalStats: () => dispatch(getSSPApprovalStats()),
    getSSPList: () => dispatch(getSSPList()),
    getSSPDetails: sspUUID => dispatch(getSSPDetails(sspUUID)),
    selectPublisherFromTable: publisher => dispatch(selectPublisherFromTable(publisher)),
    selectSiteFromTable: (site, publisher) => dispatch(selectSiteFromTable(site, publisher)),
    exportSSPApprovals: () => dispatch(exportSSPApprovals())
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SSPApprovals);
