/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React from 'react';
// material
import FileValues from 'apps/sspadstxt/material/adstxtcolumn/filevalues/filevalues_container';
// insticator material
import { Icon } from '@insticator/insticator-ui';
//styles
import injectSheet from 'react-jss';
import styles from 'apps/sspadstxt/material/adstxtcolumn/adstxtcolumn_styles';

/* ++++++++++ --------------- COMPONENT ADS.TXT TIMELINE --------------- ++++++++++ */

const AdsTxtColumn = props => {

    const renderLabelIcons = () => props.icons.map(icon => <Icon name={icon}/> );

    return (
        <div className={`adstxtcolumn ${props.classes.adstxtcolumn} editing-${props.editing}`}>
            <div className={'label'}>{renderLabelIcons()}{props.label}</div>
            <FileValues type={'Short'} fileType={props.fileType} file={props.shortFile} onChange={props.updateShortFile}/>
            <FileValues type={'Long'} fileType={props.fileType} file={props.longFile} onChange={props.updateLongFile}/>
        </div>
    )
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(AdsTxtColumn);
