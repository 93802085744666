/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { Component } from 'react';
// material
import {
    Content,
    Link,
    debounce,
    FilterMenu,
    ListTable,
    numberWithCommas,
    NumberedPagination,
    Page,
    Button,
    SimplePagination,
    DateRangePickerDropDown,
    SearchBar, Icon
} from "@insticator/insticator-ui-classic";
//utils
import { lastScan } from 'apps/sspadstxt/pages/publishermanager/lastscan';
import pageUtils from 'apps/sspadstxt/pages/utils'
//styles
import injectSheet from 'react-jss';
import styles from 'apps/sspadstxt/pages/publishermanager/publisherslist/publisherslist_styles';


/* ++++++++++ --------------- PUBLISHERS LIST --------------- ++++++++++ */

class PublishersList extends Component {
    constructor(props){
        super(props);

        this.state = {
            searchTerm :'',
            searchType: {name: 'Site', value: 'SITE'},
            tableData: [],
            sortedColumn: null,
            dateRange: {
                from: null,
                to: null
            }
        };
        this.rowsPerPage = 50;
        this.activeFilters = {
            grades: [],
            accountManagerUUIDs: [],
            generatingRevenue: [],
            hasAdsTxt: [],
            implementedFileType: [],
            preferredFileType: [],
            fileVersions: [],
            implementationStatus: []
        };
    }

    componentDidMount() {
        this.props.getAdminsList();
        const accountQueryParam = new URLSearchParams(this.props.location.search).get('accountName');
        this.state.searchTerm =  accountQueryParam || '';
        this.state.searchType =  accountQueryParam? {name: 'Publisher', value: 'PUBLISHER'} : {name: 'Site', value: 'SITE'};
        this.props.getPublishers(this.transpileFilterData(this.activeFilters, this.state.searchTerm, this.props.currentPage, this.state.searchType.value))
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.fetchingPublishers !== prevProps.fetchingPublishers && !this.props.fetchingPublishers){
            this.setState({sortedColumn: null, tableData: this.transpileTableData()})
        }
    }

    transpileTableData = () => {
        return Object.keys(this.props.publishers).map((row) => ({
            publisher:!this.props.publishers[row].accountName || this.props.publishers[row].accountName === 'N/A' ? 'N/A' : <Link element={'link'} text={this.props.publishers[row].accountName} type={'action'} action={()=>this.handlePubSelect(this.props.publishers[row])} />,
            grades: this.props.publishers[row].grade.split('_')[1] ? this.props.publishers[row].grade.split('_')[1] : 'N/A' ,
            accountManagerUUIDs: this.props.publishers[row].accountManagerName,
            site: <Link element={'link'} text={this.props.publishers[row].siteURL} type={'action'} action={()=>this.handleSiteSelect(this.props.publishers[row])} />,
            generatingRevenue: this.props.publishers[row].generatingRevenue ? 'Generating' : 'Not Generating',
            hasAdsTxt: this.props.publishers[row].hasAdsTxt ? 'Yes' : 'No',
            implementedFileType: this.props.publishers[row].implementedFile,
            preferredFileType: this.props.publishers[row].preferredFile,
            implementationStatus: this.formatImplementedStatus(this.props.publishers[row].implementationStatus),
            adsTxtUpdatedDate: this.props.publishers[row].adsTxtUpdatedDate
        }))
    };

    formatImplementedFileType = fileType => {
        if(!!fileType && fileType !== 'N/A'){
            return pageUtils.formatCellString(fileType.toLocaleLowerCase())
        }
        return 'N/A'
    };

    formatImplementedStatus = status => {
        if(!!status && status !== 'N/A'){
            return pageUtils.formatCellString(status);
        }
        return 'N/A'
    };

    handlePubSelect = pubData => {
        let seatType = pubData.okosite ? 'OKO' : 'INSTICATOR';
        let site = {value: pubData.siteUUID, name: pubData.siteURL, seatType: seatType};
        this.props.selectPublisherFromTable(site, pubData);
    };

    handleSiteSelect = pubData => {
        let seatType = pubData.okosite ? 'OKO' : 'INSTICATOR';
        let site = {value: pubData.siteUUID, name: pubData.siteURL, seatType: seatType};
        this.props.selectSiteFromTable(site, pubData);
    };

    getFilters = () => {
        const filters = {
            grades: {
                title: `Grade`,
                icon: false,
                initialState: this.activeFilters.grades,
                items: [
                    { value: 'GRADE_A', text: 'A' },
                    { value: 'GRADE_B', text: 'B' },
                    { value: 'GRADE_C', text: 'C' }
                ]
            },
            accountManagerUUIDs: {
                title: `Account Manager`,
                icon: false,
                initialState: this.activeFilters.accountManagerUUIDs,
                items: [

                ]
            },
            generatingRevenue: {
                title: `Revenue`,
                icon: false,
                initialState: this.activeFilters.generatingRevenue,
                items: [
                    { value: true, text: 'Generating' },
                    { value: false, text: 'Not Generating'}
                ]
            },
            hasAdsTxt: {
                title: `Ads.txt File`,
                icon: false,
                initialState: this.activeFilters.hasAdsTxt,
                items: [
                    { value: true, text: 'Yes' },
                    { value: false, text: 'No'}
                ]
            },
            implementedFileType: {
                title: `Implemented File Type`,
                icon: false,
                initialState: this.activeFilters.implementedFileType,
                items: [
                    { value: 'CUSTOM', text: 'Custom' },
                    { value: 'MASTER', text: 'Master' }
                ]
            },
            preferredFileType: {
                title: `Preferred File Type`,
                icon: false,
                initialState: this.activeFilters.implementedFileType,
                items: [
                    { value: 'CUSTOM', text: 'Custom' },
                    { value: 'MASTER', text: 'Master' }
                ]
            },
            implementationStatus: {
                title: `Status`,
                icon: false,
                initialState: this.activeFilters.implementationStatus,
                items: [
                    { value: 'UP_TO_DATE', text: 'Up to date' },
                    { value: 'OUT_OF_DATE', text: 'Outdated' }
                ]
            }
        };

        if(this.props.adminListReceived) filters.accountManagerUUIDs.items = this.getAdminsFilter();

        return filters;
    };

    getAdminsFilter = () => Object.keys(this.props.adminsList).map(admin => ({value: admin, text: this.props.adminsList[admin]}));

    transpileFilterData = (filters, searchTerm, page, searchType) => ({
        grades: !!filters.grades.length ? filters.grades : null,
        accountManagerUUIDs: !!filters.accountManagerUUIDs.length ? filters.accountManagerUUIDs : null,
        generatingRevenue: !filters.generatingRevenue.length || filters.generatingRevenue.length === 2 ? null : filters.generatingRevenue[0],
        hasAdsTxt: !filters.hasAdsTxt.length || filters.hasAdsTxt.length === 2 ? null : filters.hasAdsTxt[0],
        implementedFileType: !filters.implementedFileType.length || filters.implementedFileType.length === 2 ? null : filters.implementedFileType[0],//,
        preferredFileType: !filters.preferredFileType.length || filters.preferredFileType.length === 2 ? null : filters.preferredFileType[0],//,
        implementationStatus: !filters.implementationStatus.length || filters.implementationStatus.length === 2 ? null : filters.implementationStatus[0], // filters.implementationStatus,
        adsTxtUpdatedStartDate: this.state.dateRange.from,
        adsTxtUpdatedEndDate: this.state.dateRange.to,
        namePattern: !!searchTerm ? searchTerm : null,
        keywordSearchType: searchType,
        currentPage: page
    });

    applyFilters = (filters) => {
        this.updateActiveFilters(filters);
        this.props.getPublishers(this.transpileFilterData(filters, this.state.searchTerm, 1, this.state.searchType.value));
    };

    updateActiveFilters = filters => {
        Object.keys(filters).forEach(filterName => this.activeFilters[filterName] = filters[filterName])
    };

    updateDateRange = dateRange => this.setState({dateRange});

    customRangeClick = dateRange => this.setState({dateRange}, ()=> this.applyFilters(this.activeFilters));

    searchPublishers = searchTerm => {
        this.setState({searchTerm});
        this.props.getPublishers(this.transpileFilterData(this.activeFilters, searchTerm, 1, this.state.searchType.value));
    };

    handlePrevious = () => {
        const page = this.props.currentPage - 1;
        this.props.getPublishers(this.transpileFilterData(this.activeFilters, this.state.searchTerm, page, this.state.searchType.value));
    };

    handleNext = () => {
        const page = this.props.currentPage + 1;
        this.props.getPublishers(this.transpileFilterData(this.activeFilters, this.state.searchTerm, page, this.state.searchType.value));
    };

    handlePage = (page) => {
        this.props.getPublishers(this.transpileFilterData(this.activeFilters, this.state.searchTerm, page, this.state.searchType.value));
    };

    renderTableHeaders = () => ({
        publisher: 'Publisher',
        grades: 'Grade',
        accountManagerUUIDs: 'Account Manager',
        site: 'Site',
        generatingRevenue: 'Revenue',
        hasAdsTxt: 'Ads.txt File',
        implementedFileType: 'Implemented File Type',
        preferredFileType: 'Preferred File Type',
        implementationStatus: 'Status',
        adsTxtUpdatedDate: 'Last Changed'
    });

    sortByColumnHead = (columnHead) => {
        this.setState({
            sortedColumn: columnHead === this.state.sortedColumn ? null : columnHead ,
            tableData: this.state.tableData.sort((a,b) => this.sortTextHelper(a[columnHead],b[columnHead],this.state.sortedColumn !== columnHead)) })
    };

    sortTextHelper = (rowA, rowB, ascending) => {
        rowA = typeof rowA === 'number' ? rowA : typeof rowA === 'object' ? rowA.props.text.toLowerCase() : rowA.toLowerCase();
        rowB = typeof rowB === 'number' ? rowB : typeof rowB === 'object' ? rowB.props.text.toLowerCase() : rowB.toLowerCase();
        return ascending ? (rowB > rowA ? -1 : rowA > rowB ? 1 : 0) : (rowA > rowB ? -1 : rowB > rowA ? 1 : 0);
    };

    generateSortableColumnFunctionality = () => {
        return Object.keys(this.renderTableHeaders());
    };

    handleSearchType = type => this.setState({searchType: type});

    handlePageNumAmount = (totalPages) => totalPages < 5 ? totalPages : 5;

    render() {
        return (
            <Page klass={`publisherslist ${this.props.classes.publisherslist}`}>
                <Content>
                    <div className={'searchfilterscan'}>
                        <div className={'paginationexport'} >
                            <div className={'paginationholder'}>
                                {this.props.totalRows > this.rowsPerPage &&
                                <SimplePagination
                                    handleNext={this.handleNext}
                                    handlePrevious={this.handlePrevious}
                                    itemsPerPage={this.rowsPerPage}
                                    currentPage={this.props.currentPage}
                                    currentIndex={this.props.currentPage * this.rowsPerPage}
                                    totalItems={this.props.totalRows}
                                    paginationstyle={'simplepagination'}
                                    totalItemsDisplay={`${numberWithCommas(this.props.totalRows)} Publishers`}
                                />}
                            </div>
                            <Button
                                type={'action'}
                                text={'Export All'}
                                variant={'primary'}
                                action={this.props.downloadPublisherAdsTxt}
                                operation={'download'}
                                processing={this.props.exporting} />
                        </div>
                        <div className={'searchscan'}>
                            <div className={'searchfilter'}>
                                <DateRangePickerDropDown
                                    handleApply={()=>this.applyFilters(this.activeFilters)}
                                    updateRange={this.updateDateRange}
                                    range={this.state.dateRange}
                                    disabledDates={[{after: new Date()}]}
                                    alignedRight={true}
                                    customDateRanges={[{"ctaText":"Anytime","range":null,"indicator":"Anytime"},{"ctaText":"Today","range":"TODAY","indicator":"Today"}, {"ctaText":"Yesterday","range":"YESTERDAY","indicator":"Yesterday"},{"ctaText":"Last 7 Days","range":7},{"ctaText":"Last 30 Days","range":30},{"ctaText":"Last 90 Days","range":90}]}
                                    customRangeClick={this.customRangeClick}
                                    indicatorTitle={'Date modified'} />
                                <FilterMenu
                                    name={`filtermenu`}
                                    onApply={this.applyFilters}
                                    filters={this.getFilters()}
                                />
                                <SearchBar
                                    value={this.state.searchTerm || ""}
                                    placeholder={'Search'}
                                    onChange={debounce(this.searchPublishers, 500)}
                                    selectCategory={this.handleSearchType}
                                    currentCategory={this.state.searchType}
                                    categories={[{name: 'Publisher', value: 'PUBLISHER'}, {name: 'Site', value: 'SITE'}]}
                                />
                            </div>
                            <div className={'lastscanexport'} >
                                <div className={'lastscan'} >
                                    <Icon name={'history'} />
                                    <span> {`Ads.txt files scanned ${lastScan()}hr ago`}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <ListTable
                        name={`sspapprovals`}
                        size={`full`}
                        onSort={this.sortByColumnHead}
                        columnHeads={this.renderTableHeaders()}
                        sortableHeads={this.generateSortableColumnFunctionality()}
                        showTitle={false}
                        exportData={false}
                        moreDetails={false}
                        data={this.state.tableData}
                        loading={this.props.fetchingPublishers}
                        addDataAttribute={true}
                    />
                </Content>
                {this.props.totalRows > this.rowsPerPage &&
                <NumberedPagination
                    itemsPerPage={this.rowsPerPage}
                    currentIndex={this.props.currentPage * this.rowsPerPage}
                    totalItems={this.props.totalRows}
                    paginationstyle={'numberedpagination'}
                    totalItemsDisplay={`${numberWithCommas(this.props.totalRows)} total publishers`}
                    currentPage={this.props.currentPage}
                    pageNumAmount={this.handlePageNumAmount(this.props.totalPages)}
                    handlePage={this.handlePage}
                    totalPages={this.props.totalPages}
                />}
            </Page>
        );
    }
}



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(PublishersList);
