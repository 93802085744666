/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { log, parseQueryString, getCookie } from '@insticator/insticator-ui'; // iui-utils
import { SET_SESSION_DATA, SET_USER_IP, SET_AUTH_DATA, SET_SESSION_STARTTIME, SET_SESSION_EXTENSION_MODAL } from 'state/actions/session_status';
import { CLEAR_ALL_STATES } from 'state/actions/auth_status';
// utils
import { transpileAuthData } from 'utils/transpilers/authdata';
import {SET_SESSION_EXTENSION_TIMEOUT, SHOW_SESSION_EXTENSION_MODAL} from "../actions/session_status";


/* ========== ~~~~~~~~~~ SESSION STATUS : REDUCER ~~~~~~~~~~ ========== */

// ^-^ ^-^ ^-^ ^-^ ^-^   DEFAULT STATE   ^-^ ^-^ ^-^ ^-^ ^-^ //

// READ COOKIE TO DETERMINE IF USER IS SIGNED IN

const getDefaultState = () => ({
    accountUUID: getCookie('account_uuid'), // READ COOKIE TO DETERMINE WHETHER USER IS SIGNED IN
    userUUID: getCookie('user_uuid'),
    isSignedIn: !!getCookie('account_uuid') && !!getCookie('user_uuid'),
    permissions: [],
    permissionsReceived: false,
    userEmail: null,
    userFirstName: null,
    userLastName: null,
    userPhoneNumber: null,
    language: null,
    languages: null,
    cookieEnabled: null,
    userAgent: null,
    pageurl: null,
    device: null,
    ip: null,
    lastActionDispatchedAt: new Date().getTime(),
    queryParams: parseQueryString(document.location.search)
});


// REDUCER
// used to manipulate application state
// takes an action, checks its type, based on that performs certain functionality, then returns new slice of state
// utilized in "_container" components

const sessionStatus = (state = getDefaultState(), action) => {
    let newState = JSON.parse(JSON.stringify(state));
    newState.lastActionDispatchedAt = new Date().getTime(); // this gets updated every time any reducer action is called.
    switch (action.type) {
        case SET_SESSION_DATA:
            log("@@@ SETTING SESSION STATUS @@@@", action.sessionData);
            newState = Object.assign({}, newState, action.sessionData);
            return newState;
        case SET_AUTH_DATA:
            newState = Object.assign({}, newState, transpileAuthData(action.response.data));
            log("SESSION STATUS AUTH DATA:", 'ACTION RESPONSE DATA:', action.response.data, 'PREVIOUS STATE:', state, 'NEW STATE:', newState);
            return newState;
        case SET_USER_IP:
            log("@@@ SETTING USER IP @@@@", action.ip);
            newState = Object.assign({}, newState, { ip: action.ip });
            return newState;
        case CLEAR_ALL_STATES:
            return getDefaultState();
        default:
            return newState;
    }
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default sessionStatus;