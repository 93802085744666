/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { replacePXinStringWithEM } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// DASHBOARD : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    dashboard: {
        '& .wrapper': {
            display: 'flex',
            flexWrap: 'nowrap',
            '& .taxonomyeditor': {
                overflow: 'hidden',
                flex: '0',
                transition: 'all .2s ease-out 0s',
                '&.visible-true': {
                    flex: replacePXinStringWithEM('0 0 470px')
                }
            },
            '& #main': {
                width: '100%', // needed for when the contents of the table don't stretch far enough width wise
                transition: 'all .3s ease-in-out 0s',
                '& .dimmer': {
                    opacity: '0',
                    position: 'fixed',
                    zIndex: '-1',
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    '&.visible-true': {
                        zIndex: '15',
                        animationName: 'fadeIn',
                        animationDelay: '0s',
                        animationDuration: '.4s',
                        animationFillMode: 'forwards',
                    }
                }
            }
        }
    }
});
