// IMPORT
import * as taxonomyAPI from 'apps/classification/utils/api/taxonomy';

// UTILS
import { batchActions } from 'redux-batched-actions';
import { displayErrorMessage, displaySuccessMessage } from '@insticator/insticator-ui';

// CONSTANTS
export const SET_TAXONOMY_STATE_PROPERTY = 'SET_TAXONOMY_STATE_PROPERTY';
export const TAXONOMY_RECEIVED = 'TAXONOMY_RECEIVED';
export const TAXONOMY_FETCHING = 'TAXONOMY_FETCHING';
export const SET_TAXONOMY_PROCESSING = 'SET_TAXONOMY_PROCESSING';
export const TRIGGER_TAXONOMY_EDITOR = 'TRIGGER_TAXONOMY_EDITOR';
export const SET_TAXONOMY_EDITOR_MODE = 'SET_TAXONOMY_EDITOR_MODE';
export const SELECT_TAXONOMY_CATEGORY = 'SELECT_TAXONOMY_CATEGORY';
export const DESELECT_TAXONOMY_CATEGORY = 'DESELECT_TAXONOMY_CATEGORY';
export const DESELECT_TAXONOMY_CATEGORIES = 'DESELECT_TAXONOMY_CATEGORIES';
export const CREATE_NEW_CATEGORY = 'CREATE_NEW_CATEGORY';
export const CANCEL_NEW_CATEGORY = 'CANCEL_NEW_CATEGORY';
export const DISPLAY_TAXONOMY_EDITOR_MESSAGE = 'DISPLAY_TAXONOMY_EDITOR_MESSAGE';
export const CLOSE_TAXONOMY_EDITOR_MESSAGE = 'CLOSE_TAXONOMY_EDITOR_MESSAGE';
export const RUN_TAXONOMY_SEARCH = 'RUN_TAXONOMY_SEARCH';
export const REPORT_CATEGORY_FOUND = 'REPORT_CATEGORY_FOUND';
export const SET_SCROLL_TO_CATEGORY = 'SET_SCROLL_TO_CATEGORY';
export const RESET_TAXONOMY_TREE_STATE = 'RESET_TAXONOMY_TREE_STATE';
// ACTIONS

export const setTaxonomyStateProperty = ({ name, value }) => ({
    type: SET_TAXONOMY_STATE_PROPERTY,
    name,
    value
});

const taxonomyReceived = taxonomy => ({
    type: TAXONOMY_RECEIVED,
    taxonomy
});

const setTaxonomyFetchStatus = status => ({
    type: TAXONOMY_FETCHING,
    status
});

export const createNewCategory = ({ activeCategoryId, parentId }) => ({
    type: CREATE_NEW_CATEGORY,
    activeCategoryId,
    parentId
});

export const cancelNewCategory = () => ({
    type: CANCEL_NEW_CATEGORY,
});

const setTaxonomyProcessing = status => ({
    type: SET_TAXONOMY_PROCESSING,
    status
});

export const triggerTaxonomyEditor = ({ showEditor, editMode, classifyPermitted }) => ({
    type: TRIGGER_TAXONOMY_EDITOR,
    showEditor,
    editMode,
    classifyPermitted
});

export const setTaxonomyEditorMode = ({ editMode }) => ({
    type: SET_TAXONOMY_EDITOR_MODE,
    editMode
});

export const selectTaxonomyCategory = categoryId => ({
    type: SELECT_TAXONOMY_CATEGORY,
    categoryId
});

export const deselectTaxonomyCategory = categoryId => ({
    type: DESELECT_TAXONOMY_CATEGORY,
    categoryId
});

export const deselectTaxonomyCategories = () => ({
    type: DESELECT_TAXONOMY_CATEGORIES
});

export const displayTaxonomyEditorMessage = ({ visible, messageType, uniqueProps, showCloseButton }) => ({
    type: DISPLAY_TAXONOMY_EDITOR_MESSAGE,
    visible,
    messageType,
    uniqueProps,
    showCloseButton
});

export const closeTaxonomyEditorMessage = () => ({
    type: CLOSE_TAXONOMY_EDITOR_MESSAGE
});

export const runTaxonomySearch = searchTerm => ({
    type: RUN_TAXONOMY_SEARCH,
    searchTerm
});

export const reportCategoryFound = ({ categoryId, position }) => ({
    type: REPORT_CATEGORY_FOUND,
    categoryId,
    position
});

export const setScrollToCategory = categoryId => ({
    type: SET_SCROLL_TO_CATEGORY,
    categoryId
});

export const resetTaxonomyTreeState = () => ({
    type: RESET_TAXONOMY_TREE_STATE
});

//BATCHED ACTIONS
const taxonomyFetchSuccess = taxonomy => batchActions([
    taxonomyReceived(taxonomy),
    setTaxonomyFetchStatus(false)
]);

// newCategoryId  newlyCreatedCategoryId
const createCategorySuccess = response => batchActions([
    cancelNewCategory(),
    taxonomyReceived(response.data.tree),
    setTaxonomyStateProperty({ name: 'newlyCreatedCategoryId', value: response.data.newCategoryId })
]);

const createTaxonomyFail = () => batchActions([
    createTaxonomyFail('There was an issue creating the category')
]);

const taxonomyFetchFail = error => batchActions([
    setTaxonomyFetchStatus(false),
    displayErrorMessage('There was an issue fetching taxonomy')
]);



//THUNKS

//we can use getClassificationQuestions thunk for "Apply Filter" button
export const getTaxonomy = data => dispatch => {
    dispatch(setTaxonomyFetchStatus(true));
    return taxonomyAPI.fetchTaxonomy()
        .then(response => dispatch(taxonomyFetchSuccess(response.data.tree)))
        .catch(error => dispatch(taxonomyFetchFail(error)))
};

export const createTaxonomyCategory = category => dispatch => {
    return taxonomyAPI.createCategory(category)
        .then(response => dispatch(createCategorySuccess(response)),
            error => {
                if(error.response.status === 400) dispatch(displayErrorMessage(error.response.data));
            }
        )
        .catch(() => createTaxonomyFail())
};

