/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { useState } from 'react';
// material
import { BubbleNav } from '@insticator/insticator-ui'; // iui-material



/* ========== ~~~~~~~~~~ CLASSIFICATION NAV CONTROLS ~~~~~~~~~~ ========== */
const TaxonomyEditorNavButton = (props) => {
    console.log('props.showTaxonomyEditor', props.showTaxonomyEditor)
    // Declare a new state variable, which we'll call "taxonomyIconClicked"
    // See React Hooks for reference https://reactjs.org/docs/hooks-intro.html
    // const [taxonomyIconClicked, setTaxonomyIconClicked] = useState(false);
    const handleTaxonomyIconClick = () => {
        // setTaxonomyIconClicked(true);
        if(!props.classifyPermitted) props.triggerTaxonomyEditor({ showEditor: !props.showTaxonomyEditor, editMode: true, classifyPermitted: false });
    };
    return (
        <BubbleNav
          name={`topnav`}
          items={[
            {
              name: 'taxonomyEditor',
              text: 'Taxonomy Editor',
              icon: 'sitemap',
              type: 'action',
              action: handleTaxonomyIconClick,
              allowMultiClick: true,
              isActive: props.showTaxonomyEditor,
              disabled: props.classifyPermitted
            }
          ]}
        />
    );
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default (TaxonomyEditorNavButton);
