/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, {useEffect, useState} from 'react';
// material
import {CheckBox, ValueHighlighter} from '@insticator/insticator-ui'; // iui-material
import TypeControls from 'apps/sspadstxt/pages/publishermanager/publisherdetail/editsite/preferredimplementation/type/typecontrols/typecontrols_container';
//utils
import addclientuuid from 'apps/sspadstxt/utils/addclientuuid.js';
//styles
import injectSheet from 'react-jss';
import styles from 'apps/sspadstxt/pages/publishermanager/publisherdetail/editsite/preferredimplementation/type/master/master_styles';




/* ++++++++++ --------------- PUBLISHER DETAIL --------------- ++++++++++ */

const Master = props => {

    const [enableAutoInsert, setEnableAutoInsert] = useState(props.storedEnableAutoInsert);
    const [currentMasterValues, setCurrentMasterValues] = useState([])

    useEffect(() => {
        renderMasterValues(enableAutoInsert);
    }, [props.masterAdsTxtValues, enableAutoInsert]);

    const formatDetails = () => ({
        preferredFileType : "MASTER",
        preferredMasterFileType : props.masterFileType,
        enableAutoInsert: enableAutoInsert
    });

    const handleAutoInsertSelect = enabled => setEnableAutoInsert(enabled)

    const saveAsPreferred = () => props.updateSiteDetails(props.siteAdstxtID, props.selectedSite, formatDetails());
    const isEdit = () => {
        return (props.storedMasterFileType !== props.masterFileType &&
            props.selectedSite.seatType === props.seatType) ||
            enableAutoInsert !== props.storedEnableAutoInsert
    }
    const renderMasterValues = (enableAutoInsert) => {
        if(props.masterAdsTxtValues && props.masterAdsTxtValues.length > 0){
            let masterValues = JSON.parse(JSON.stringify(props.masterAdsTxtValues));
            if (props.selectedAccountOverrideId) {
                masterValues[0] = addclientuuid(props.masterAdsTxtValues[0], props.selectedAccountOverrideId);
                masterValues[1] = addclientuuid(props.masterAdsTxtValues[1], props.selectedAccountOverrideId);
            } else if (props.selectedSite.seatType === 'OKO' || props.selectedSite.seatType === 'S2S') {
                masterValues[0] = addclientuuid(props.masterAdsTxtValues[0], props.selectedAccountUUID);
                masterValues[1] = addclientuuid(props.masterAdsTxtValues[1], props.selectedAccountUUID);
            } else {
                masterValues[0] = addclientuuid(props.masterAdsTxtValues[0], props.selectedAccountUUID);
                masterValues[1] = addclientuuid(props.masterAdsTxtValues[1], props.selectedAccountUUID);
            }

            if (enableAutoInsert) {
                const newEntries = [
                    'contact=revops@insticator.com',
                    `managerdomain=${props.selectedSite.seatType === 'INSTICATOR' ? 'insticator.com' : 'oko.uk'}`,
                    `ownerdomain=${props.selectedSite.name}`,
                ];
                newEntries.forEach(entry => {
                    if (!masterValues.includes(entry)) {
                        masterValues.unshift(entry);
                    }
                });
            }

            setCurrentMasterValues(masterValues)
        }
        return [];
    };
    const missingValues = () => {
        return currentMasterValues.filter(value => !props.adsTxtFileRows.includes(value));
    }
    return (
        <div className={`master ${props.classes.master}`}>
            <div className={'checkbox-row'}>
                <CheckBox
                    handleClick={(val, on) => handleAutoInsertSelect(on)}
                    title={"Enable auto-insert"}
                    on={enableAutoInsert}/>
            </div>
            <ValueHighlighter
                values={currentMasterValues}
                valuesToHighlight={missingValues()}
                direction={'column'}
                showHighlightedFirst={true}
                minHeight={'183px'}
            />
            <TypeControls save={saveAsPreferred} values={currentMasterValues} isEditMode={isEdit()}/>
        </div>
    );
};


/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(Master);
