/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { Component } from 'react';
import moment from 'moment';
//material
import { Page, Content, ListTable, SimplePagination, NumberedPagination, SingleSelectDropDown, Link, months, Title } from '@insticator/insticator-ui';
import { url }  from 'utils/urls';
import ApproveReject from 'material/approverejectcolumn/approverejectcolumn';
// styles
import injectSheet from 'react-jss';
import styles from 'apps/approvals/pages/approvalpage/approvalpage_styles';
import { ProductTypes, RequestStatuses } from "../../utils/api/approvalpage";
import { Icon } from '@insticator/insticator-ui'; // iui-material


/* ========== ~~~~~~~~~~ DASHBOARD : CHILD ~~~~~~~~~~ ========== */
// PRESENTATIONAL COMPONENT
// receives app state & actions as props from parent "_container" component
// this child component handles local state and uses props passed from parent to read/write application state when necessary
class ApprovalPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            requestStatus: {value: 'ALL', name: 'All'},
            productType: {value: 'ALL', name: 'All'},
            approvalType: this.props.currentRoute === 'apps/approvals/commentunitapprovals' ? 'commenting' : 'ratings',
            sortOrder: 1,
            sortField: 'accountName',
        }
        this.rowsPerPage = 50;
    }

    componentDidMount() {
        this.props.fetchApprovalRequestData({approvalType: this.state.approvalType.value, requestStatus: this.state.requestStatus.value, pageNumber: 1});
    };

    componentWillUnmount() {
        this.props.clearApprovalsState()
    }

    updateApprovalRequestStatus = (UUID, requestStatus) => this.props.updateApprovalRequestStatus(UUID, requestStatus);
    populateDropdownRequestStatus = () => [{value: 'ALL', name: 'All'}, ...RequestStatuses];
    populateDropdownProductTypes = () => [{value: 'ALL', name: 'All'}, ...ProductTypes];

    handleDropdownSelect = (selected, type) => {
        if(selected.value === this.state[type].value){
            return null;
        }
        this.setState({[type] : selected});
        this.props.fetchApprovalRequestData({
            ...{
                pageNumber: 1,
                requestStatus: this.state.requestStatus.value,
                productType: this.state.productType.value
            },
            ...{
                [type]: selected.value
            }
        })
    }

    handlePageSelect = (pageNumber) => this.props.fetchApprovalRequestData({
        requestStatus: this.state.requestStatus.value,
        productType: this.state.productType.value,
        pageNumber
    });

    handlePageNumAmount = (totalPages) => totalPages < 5 ? totalPages : 5;

    renderPaginationBottom = () => {
        if(this.props.totalRequests > this.rowsPerPage){
            return (
                <NumberedPagination
                    handlePage={this.handlePageSelect}
                    itemsPerPage={this.rowsPerPage}
                    totalItems={this.props.totalRequests}
                    currentPage={this.props.currentPage}
                    totalPages={Math.ceil(this.props.totalRequests / this.rowsPerPage)}
                    pageNumAmount={this.handlePageNumAmount(this.props.totalPages)}
                    currentIndex={this.props.currentPage * this.rowsPerPage}/>
                )}
    }

    generatePublisherLink = accountUUID => `/admin/manageclient?clientUUID=${accountUUID}&source=ppa`;

    getRequestStatusTitle = status => {
        const curStatus = RequestStatuses.find(s => s.value === status);
        return curStatus ? curStatus.name : null;
    }

    getProductTypeTitle = type => {
        const curType = ProductTypes.find(s => s.value === type);
        return curType ? curType.name : null;
    }

    transpileApprovalRequestData = (data, approve, reject) => {
        let transpiledData = [];
        Object.keys(data).forEach((row, idx) => {
            const { accountUUID, accountName, userRequesting, dateRequested, requestStatus, dateApproved, approvedDate, requestedDate, userEmail, productType } = data[row];
            const name = accountName === 'null' ? 'N/A' : accountName;
            let rowData = {
                accountName: accountUUID ? <Link text={name} type={'external'} action={`${url.embed.url}${this.generatePublisherLink(accountUUID)}`} /> : name,
                productType: this.getProductTypeTitle(productType),
                userRequesting: userRequesting || userEmail,
                dateRequested: dateRequested || requestedDate,
                requestStatus: this.getRequestStatusTitle(requestStatus),
                " ": <ApproveReject status={requestStatus} approve={()=>this.updateApprovalRequestStatus(row, 'APPROVED')} reject={()=>this.updateApprovalRequestStatus(row, 'REJECTED')} loading={this.props.updatingRequestStatus[row]} />,
                dateApproved: dateApproved || approvedDate
            }
            transpiledData.push(rowData)
        })
        return transpiledData;
    };

    handleSort = sortField => {
        const sortOrder = sortField === this.state.sortField ? -1 * this.state.sortOrder : this.state.sortOrder;
        this.setState({ sortOrder, sortField });
    }
    
    renderRequestTableColumnHeads = () => ({
        accountName: <span style={{cursor: 'pointer'}} onClick={() => this.handleSort('accountName')}><Icon
          name="sort"/>Account name</span>,
        productType: <span style={{cursor: 'pointer'}} onClick={() => this.handleSort('productType')}><Icon
          name="sort"/>Product type</span>,
        userRequesting: <span style={{cursor: 'pointer'}} onClick={() => this.handleSort('userRequesting')}><Icon
          name="sort"/>User requesting</span>,
        dateRequested: <span style={{cursor: 'pointer'}} onClick={() => this.handleSort('dateRequested')}><Icon
          name="sort"/>Date requested</span>,
        requestStatus: <span style={{cursor: 'pointer'}} onClick={() => this.handleSort('requestStatus')}><Icon
          name="sort"/>Request status</span>,
        " ": " ",
        dateApproved: <span style={{cursor: 'pointer'}} onClick={() => this.handleSort('dateApproved')}><Icon
          name="sort"/>Date approved</span>,
    })

    render() {
        const { sortOrder, sortField } = this.state;
        const data = this.transpileApprovalRequestData(this.props.tableData)
            .sort(( a, b ) => {
                switch (true) {
                    case sortField === 'accountName':
                        if (a[sortField].props.text > b[sortField].props.text) return sortOrder;
                        if (a[sortField].props.text < b[sortField].props.text) return -1 * sortOrder;
                    case ['productType', 'userRequesting', 'requestStatus'].includes(sortField):
                        if (a[sortField] > b[sortField]) return sortOrder;
                        if (a[sortField] < b[sortField]) return -1 * sortOrder;
                    case ['dateRequested', 'dateApproved'].includes(sortField):
                        const dateAMoment = moment(a[sortField]);
                        const dateBMoment = moment(b[sortField]);
                        if (dateAMoment.isBefore(dateBMoment)) return sortOrder;
                        if (dateAMoment.isAfter(dateBMoment)) return -1 * sortOrder;
                }

                return 0;
            });

        return (
            <Page klass={`approvalpage ${this.props.classes.approvalpage}`} >
                <Content>
                    <Title>Product Access Requests</Title>

                    <div className={'dropdownwithlabel'}>
                        <span>Product Types:</span>
                        <SingleSelectDropDown
                          searchable={false}
                          name={'All ProductTypes'}
                          icon={'exclamation-circle'}
                          onSelect={(select) => this.handleDropdownSelect(select, 'productType')}
                          items={this.populateDropdownProductTypes()}
                          selected={this.state.productType}/>
                        &nbsp;
                        &nbsp;
                        <span>Status:</span>
                        <SingleSelectDropDown searchable={false}
                          name={'All Status'}
                          icon={'exclamation-circle'}
                          onSelect={(select) => this.handleDropdownSelect(select, 'requestStatus')}
                          items={this.populateDropdownRequestStatus()}
                          selected={this.state.requestStatus}/>
                    </div>
                    <ListTable
                      data={data}
                      columnHeads={this.renderRequestTableColumnHeads()}
                      loading={this.props.fetchingTableData}
                      size={`full`}
                      paginationLocation={false}
                      rowsPerPage={false}
                    />
                    {this.renderPaginationBottom()}
                </Content>
            </Page>
        );
    }
}

/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// will be utilized in parent component after being infused with app state & actions
export default injectSheet(styles)(ApprovalPage);
