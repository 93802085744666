/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state
import { selectTaxonomyCategory, deselectTaxonomyCategory } from 'apps/classification/state/actions/taxonomystatus';

// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import TaxonomyCategoryName from 'apps/classification/material/taxonomyeditor/taxonomytree/category/taxonomycategoryname/taxonomycategoryname';


/* ========== ~~~~~~~~~~ TAXONOMY CATEGORY NAME: PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ authStatus, classificationState }, { categoryId }) => ({
    // taxonomy: classificationState.taxonomyTree.taxonomy,
    // categoryData: classificationState.taxonomyTree.taxonomy[categoryId],
    name: classificationState.taxonomyTree.taxonomy[categoryId]['name'],
    isIAB: classificationState.taxonomyTree.taxonomy[categoryId]['isIAB'],
    // isSelected: classificationState.taxonomyTree.taxonomy[categoryId]['isSelected'],
    isEditMode: classificationState.taxonomyStatus.isEditMode && authStatus.isCASuperAdmin,
    isSelected: classificationState.taxonomyStatus.selectedCategories.includes(categoryId),
    searchTerm: !!classificationState.taxonomyStatus.searchTerm && classificationState.taxonomyTree.taxonomy[categoryId]['name'].includes(classificationState.taxonomyStatus.searchTerm) ? classificationState.taxonomyStatus.searchTerm : null,
    scrollInvoked: classificationState.taxonomyStatus.scrollToCategory === categoryId,
    isNewCategory: classificationState.taxonomyStatus.newlyCreatedCategoryId === categoryId
});

// map actions
const mapDispatchToProps = dispatch => ({
    selectTaxonomyCategory: categoryId => dispatch(selectTaxonomyCategory(categoryId)),
    deselectTaxonomyCategory: categoryId => dispatch(deselectTaxonomyCategory(categoryId)),
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaxonomyCategoryName);
