/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { useState, useEffect } from 'react';
// styles
import injectSheet from 'react-jss';
import styles from 'apps/classification/material/taxonomyeditor/taxonomytree/category/newtaxonomycategory/newtaxonomycategory_styles';



/* ========== ~~~~~~~~~~ NEW TAXONOMY CATEGORY : CHILD ~~~~~~~~~~ ========== */
// PRESENTATIONAL COMPONENT
// receives app state & actions as props from parent "_container" component
// this child component handles local state and uses props passed from parent to read/write application state when necessary

const NewTaxonomyCategory = (props) => {
    // Declare a new state variable, which we'll call "newCategoryName"
    // See React Hooks for reference https://reactjs.org/docs/hooks-intro.html
    const [newCategoryName, setNewCategoryName] = useState(null);
    const [isNameValid, setIsNameValid] = useState(null);
    const [focusTriggered, setFocusTriggered] = useState(false);

    let inputRef = React.createRef();

    // automatically focus on input once component is rendered
    useEffect(() => { if(!focusTriggered) focusOnInput() });

    const focusOnInput = () => {
        inputRef.current.focus();
        setFocusTriggered(true);
    };

    const handleChange = event => {
        const name = event.target.value;
        const isValid = checkNameValidity(name);
        setNewCategoryName(event.target.value);
        if(isValid !== isNameValid) setIsNameValid(isValid);
        props.setTaxonomyStateProperty({ name: 'newCategoryName', value: { name, isValid } })
    };

    const checkNameValidity = name => /^(?!\s)[A-Za-z0-9'\.\-\s\,]{1,255}$/.test(name);

    const handleCancelButton = () => props.cancelNewCategory();

    const handleKeyDown = event => {
        if(event.key === 'Enter') { createCategory(); }
    };

    const handleSaveButton = () => createCategory();

    const createCategory = () => {
        if (isNameValid) props.createTaxonomyCategory({name: newCategoryName.toLowerCase(), parentId: props.newCategoryParentId})
    };

    return (
        <div className={`newtaxonomycategory ${props.classes.newtaxonomycategory} child-${props.isChild}`}>
            <input type="text"
                   ref={inputRef}
                   className={`taxonomynameinput valid-${isNameValid}`}
                   value={newCategoryName}
                   onChange={handleChange}
                   onKeyDown={handleKeyDown}
            />
            <span className={'newcategorybutton savebutton'} onClick={handleSaveButton}>save</span>
            <span className={'newcategorybutton'} onClick={handleCancelButton}>cancel</span>
        </div>
    );
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// will be utilized in parent component after being infused with app state & actions
export default injectSheet(styles)(NewTaxonomyCategory);
