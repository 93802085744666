/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT : SSP BIDDER NAME : STYLES --------------- ++++++++++ */
export default theme => ({
    sspbiddername: {
        
    }
});

