/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm, pxToEmWithBaseline, replacePXinStringWithEM, convertHexToRGB } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// TAXONOMY CATEGORY : STYLES
export default theme => ({
    taxonomycategoryname: {
        // transition: 'background .2s linear 0s',
        display: 'flex',
        alignItems: 'center',
        height: pxToEmWithBaseline({ value: 26, fontSizeBaseline: 14 }),
        whiteSpace: 'pre',
        '& .iab': {
            color: '#C0C9DC'
        },
        '& .fullcategoryname': {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            '& .categorynamepart': {
                height: '90%',
                display: 'flex',
                alignItems: 'center',
                '&.highlighted-true': {
                    color: theme.color.primary.medium.swatch,
                    transition: 'color 0.5s ease-out',
                    '&.scrollInvoked-true': {
                        backgroundColor: 'rgba(20, 148, 244, 0.09)',
                        border: '1px solid rgb(20, 148, 244)',
                        borderRadius: '1px',
                        boxSizing: 'border-box',
                        transition: 'background-color 0.15s ease-out'
                    }
                }
            }
        },
        '&:not(:hover)': {
            '& .taxonomycategorycontrols': {
                display: 'none'
            }

        },
        '&:hover': {
            background: theme.color.supporting.light.swatch,
            cursor: 'pointer'
        },
        '&.editmode-false': {
            cursor: 'pointer'
        },
        '&.editmode-true': {
            cursor: 'default',
            // '&.new-true': {
            //
            // }
        },
        '&.selected-true': {
            backgroundColor: '#fff8d9',
            transition: 'background-color 0.15s ease-out'
        }
    }
});



