/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { Component } from 'react';
//material
import { Link } from '@insticator/insticator-ui'; // iui-material
// styles
import injectSheet from 'react-jss';
import styles from 'apps/classification/pages/editor/answercontainer/answertaxonomy/answertaxonomy_styles';



/* ========== ~~~~~~~~~~ ANSWERTAXONOMY : CHILD ~~~~~~~~~~ ========== */
// PRESENTATIONAL COMPONENT
// receives app state & actions as props from parent "_container" component
// this child component handles local state and uses props passed from parent to read/write application state when necessary
class AnswerTaxonomy extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selected: false
        }
    }
    //sets the selected state to true in order to trigger selected-specific styling (see addSelectedClass)
    handleMouseHover = (e) => {
        e.type === 'mouseenter' ? this.setState({ selected: true }) : this.setState({ selected: false })
    }
    handleClearClick = () => {
        //uncomment when taxonomy tree structure/actions are completed
        this.props.removeTaxonomyEvent();
    }
    renderDeleteButton = () => (
        <div className={`clearbuttoncontainer control`}>
            <div className={`linkcontainer ${this.addSelectedClass('linkvisible')}`} >
                <Link type={`custom`} handleClick={this.handleClearClick} icon={`times`} />
            </div>
        </div >
    )
    //returns the given classname if selected state is true (used for adding flagged class and selected class for styling)
    addSelectedClass = (className) => (this.state.selected ? className : null)
    render() {
        return (
            <div className={`answertaxonomy ${this.props.classes.answertaxonomy} ${this.addSelectedClass('selected')} deletedvalue-${this.props.valueDeleted}`}>
                <div className={'taxonomycontent'} onMouseEnter={this.handleMouseHover} onMouseLeave={this.handleMouseHover}>
                    <div className={'taxonomytext'}>
                        {this.props.text}
                    </div>
                    {this.renderDeleteButton()}
                </div>
                <div className={'taxonomychildren'}>
                    <div className={'childrencontainer'}>
                        {this.props.children}
                    </div>
                    <div className={'rightindent'} onMouseEnter={this.handleMouseHover} onMouseLeave={this.handleMouseHover} />
                </div>
            </div>
        );
    }
};


/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// will be utilized in parent component after being infused with app state & actions
export default injectSheet(styles)(AnswerTaxonomy);
