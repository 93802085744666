/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state
import { setSessionData, getUserIP } from 'state/actions/session_status.js';
import { isUserLoggedIn, asyncFetchUsers } from 'state/actions/auth_status';
import { setAuthTokenData } from 'state/actions/auth_status';
import { displaySuccessMessage, displayErrorMessage } from '@insticator/insticator-ui';
import { updatePageDetails, changePage } from 'state/actions/app_status'
// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import App from './app';
import {displayModal} from '@insticator/insticator-ui';
import {signOut} from 'state/actions/auth_status';
// import { batchActions } from "redux-batched-actions/lib/index";



/* ========== ~~~~~~~~~~ APP : PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ sessionStatus, authStatus, appStatus, userStatus }) => ({
    sessionStatus,
    appStatus,
    currentPage: appStatus.currentPage,
    currentApp: appStatus.currentApp,
    userLoggedIn: userStatus.loggedIn,
    accountUUID: sessionStatus.accountUUID,
    userUUID: sessionStatus.userUUID,
    isSignedIn: sessionStatus.isSignedIn,
    permissions: sessionStatus.permissions,
    queryParams: sessionStatus.queryParams,
    lastActionDispatchedAt: sessionStatus.lastActionDispatchedAt,
    sessionStartTime: sessionStatus.sessionStartTime,
    adminInformation: authStatus.adminInformation,
    adminInfoReceived: authStatus.adminInfoReceived,
    fetchingAdminInformation: authStatus.fetchingAdminInformation,
    adminInfoFailed: authStatus.adminInfoFailed
});
// map actions
const mapDispatchToProps = dispatch => ({
    setSessionData: sessionData => dispatch(setSessionData(sessionData)),
    displayModal: (isVisible, contentToDisplay, showCloseButton) => dispatch(displayModal(isVisible, contentToDisplay, showCloseButton)),
    getUserIP: () => dispatch(getUserIP()),
    setAuthTokenData: data => dispatch(setAuthTokenData(data)),
    displayErrorMessage: errorMessage => dispatch(displayErrorMessage(errorMessage)),
    displaySuccessMessage: successMessage => dispatch(displaySuccessMessage(successMessage)),
    updatePageDetails: location => dispatch(updatePageDetails(location)),
    signOut: () => dispatch(signOut()),
    isUserLoggedIn: () => dispatch(isUserLoggedIn()),
    asyncFetchUsers: () => dispatch(asyncFetchUsers())
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
