/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React from 'react';
// material
import { TextField, Tip, Icon } from '@insticator/insticator-ui';
//styles
import injectSheet from 'react-jss';
import styles from 'apps/sspadstxt/pages/sspmanager/sspdetail/sspapprovalprocess/columndata/columndata_styles';

/* ++++++++++ --------------- COMPONENT COLUMN DATA FIELD --------------- ++++++++++ */

const ColumnData = props => {

    const renderPlusButton = (index) => {
       return (
           <div className={`plus ${index + 1 === props.value.length ? 'active' : 'inactive'}`} onClick={() => props.addColumn(props.fieldName)}>
                <Icon name={'plus'}/>
           </div>
        )
    };

    const renderMinusButton = (index) => {
        return (
            <div className={`minus ${props.value.length > 1 ? 'active' : 'inactive'}`} onClick={() => props.deleteColumn(props.fieldName, index)}>
                <Icon name={'minus'}/>
            </div>
        )
    };

    const renderMultiField = () => (
        props.value.map((val, index) => {
            return  <div className={'multifield'} key={index} >
                        {renderMinusButton(index)}
                        <TextField type={'text'} value={val.value} placeholder={props.placeholder} handleChange={e => props.handleChange(props.fieldName, index, e.target.value)}/>
                        {renderPlusButton(index)}
                    </div>
        })
    );

    const renderField = () => {
        if(props.type === 'single'){
            return <div className={'singlefield'}><div className={'spacer'} /><TextField type={'text'} value={props.value} placeholder={props.placeholder} handleChange={e => props.handleChange(props.fieldName, e.target.value)}/><div className={'spacer'} /></div>
        }else if(props.type === 'multi'){
            return renderMultiField()
        }
    };

    return (
        <div className={`columndata ${props.classes.columndata}`}>
            <span className={'labeltext'} >{props.labelText} <Tip content={props.infoText}/></span>
            <div className={'values'}>
                {renderField()}
            </div>
        </div>
    )
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(ColumnData);
