/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React from 'react';
//material
import { Icon, SelectableList, Button } from '@insticator/insticator-ui'; // iui-material
//styles
import injectSheet from 'react-jss';
import styles from 'apps/classification/pages/editor/answercontrols/answercontrols_styles';


/* ========== ~~~~~~~~~~ ANSWERCONTROLS : CHILD ~~~~~~~~~~ ========== */
// PRESENTATIONAL COMPONENT
// receives app state & actions as props from parent "_container" component
// this child component handles local state and uses props passed from parent to read/write application state when necessary
const AnswerControls = (props) => {
    // console.log(props)

    const allAnswersSelected = () => (props.selectedAnswers === props.totalAnswers);

    const handleSelect = () => {allAnswersSelected() ? props.unselectAllAnswers() : props.selectAllAnswers()}

    const selectAllCheckbox = () => (
        <SelectableList klass={'answercontrol selectallcheckbox'} type='checkbox' selected={allAnswersSelected() ? 'SELECT_ALL' : null}
            items={[{ value: 'SELECT_ALL', text: 'Select All' }]} handleChange={handleSelect} />
    )

    const clearSelectedButton = () => (
        <Button
            type={`action`}
            action={props.clearSelected}
            variant={`normal`}
            name={`clear-selected`}
            text={`Clear Selected`}
            klass={'answercontrol'}
            icon={`times`}
            disabled={props.selectedAnswers === 0}
        />
    )

    const flagSelectedButton = () => (
        <Button
            type={`action`}
            action={props.flagSelected}
            variant={`delete`}
            name={`flag-selected`}
            text={`Flag Selected`}
            klass={'answercontrol'}
            icon={`flag`}
            disabled={props.selectedAnswers === 0 || props.activeQuestion.classified}
        />
    );

    const classificationCompleteButton = () => (
        <Button
            type={`action`}
            action={()=>props.toggleClassificationStatus(!props.classified)}
            variant={`action`}
            name={`classification-complete`}
            text={`Classification Complete`}
            klass={'answercontrol'}
            disabled={props.activeQuestion.answersFlagged}
            icon={props.classified ? 'check' : 'noactualicon'}
        />
    );
    return (
        <div className={`answercontrols ${props.classes.answercontrols}`}>
            {selectAllCheckbox()}
            {clearSelectedButton()}
            {flagSelectedButton()}
            {classificationCompleteButton()}
        </div>
    );
};


/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// will be utilized in parent component after being infused with app state & actions
export default injectSheet(styles)(AnswerControls);
