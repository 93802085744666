/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { combineReducers } from 'redux';
import geoStatus from 'apps/geoblocking/state/reducers/geostatus';




/* ========== ~~~~~~~~~~ REPORTS STATE : ROOT REDUCER ~~~~~~~~~~ ========== */
// COMBINE REDUCERS
// used to comhine all various reducers together into an easily accesible object
// utilized in store.js
const geoblockingState = combineReducers({
    geoStatus
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default geoblockingState;
