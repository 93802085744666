/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { Component } from 'react';
// material
import { Page, Subhead, Content, Copy, Title, BodyCopy, Divider } from '@insticator/insticator-ui'; // material
import AppCard from 'pages/dashboard/appmenu/appcard/appcard_container';
// styles
import injectSheet from 'react-jss';
import styles from 'pages/dashboard/appmenu/appmenu_styles';



/* ========== ~~~~~~~~~~ APPMENU ~~~~~~~~~~ ========== */
class AppMenu extends Component {
    componentDidMount() {
        this.props.updateCurrentApp('adminpanel');
    }
    // showGeoblocking = () => ( this.props.adminInformation.permissions.includes('newAdminPanel.geoBlocking'));
    showApproval = () => (this.props.adminInformation.permissions.includes('newAdminPanel.manageProducts'));
    showSSPManager = () => (this.props.adminInformation.permissions.includes('newAdminPanel.sspManager'));
    showMockUp = () => (this.props.adminInformation.permissions.includes('newAdminPanel.mockupGenerator'));

    render() {
        return (
            <Page klass={this.props.classes.appmenu}>
                <Subhead>
                    <h1>Insticator Admin Panel</h1>
                </Subhead>
                <Content>
                    <div className={`dashboard-card-content`}>
                        <div className={'card-container'}>
                            {/*<AppCard route={'apps/classification/manager'} name={'Classification Tool'} description={'Categorize Quiz & Poll Questions, answered by our users via the Embed, into sellable market segments.'} posterImage={'thumb-app-classification.png'} />*/}
                            {/*{this.showGeoblocking() && <AppCard route={'apps/geoblocking/library'} name={'Geo Blocking'} description={'Block specific geo locations for our clientele`s websites.'} posterImage={'thumb-app-geoblocking.png'} />}*/}
                            {this.showSSPManager() && <AppCard route={'apps/sspadstxt/sspmanager/sspslist'} name={'SSP Manager'} description={'Create and manage SSP details. Update Ads.txt files for Publishers.'} posterImage={'thumb-app-sspmanager.png'} />}
                            {this.showApproval() && <AppCard route={'apps/approvals'} name={'Product Access Requests'} description={'List of publisher users requesting access to products they aren\'t currently using.'} posterImage={'thumb-app-commentingapproval.png'} />}
                            {this.showMockUp() && <AppCard route={'apps/mockupgenerator/getstarted'} name={'Mockup Generator'} description={'Create sample mockups of our products (Quiz/Poll/Story Unit and Commenting Unit) in publisher sites to aid in gaining new client business.'} posterImage={'thumb-app-mockupgenerator.png'} />}
                        </div>
                    </div>
                </Content>
            </Page>
        );
    }
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(AppMenu);
