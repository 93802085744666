import axios from 'axios';
//urls
import { url }  from 'utils/urls';

const withCredentials = true;

export const RequestStatuses = [{value: 'APPROVED', name: 'Approved'}, {value: 'REJECTED', name: 'Rejected'}, {value: 'PENDING', name: 'Approval Pending'}];

export const ProductTypes = [
    {value: 'COMMENTING', name: 'Commenting'},
    {value: 'COMMENTING_V2', name: 'Commenting 2.0'},
    {value: 'RATINGS', name: 'Ratings'},
    {value: 'ARTICLE_SENTIMENT', name: 'Article Sentiment'},
    {value: 'ARTICLE_SENTIMENT_V2', name: 'Article Sentiment 2.0'},
    {value: 'TRENDING_NOW', name: 'Trending Now'},
    {value: 'TRENDING_NOW_V2', name: 'Trending Now 2.0'}
];

export const asyncGetCommentRequestData = ({ requestStatus, productType, pageNumber, approvalType }) => {
    const requestStatusParam = RequestStatuses.map(i => i.value).includes(requestStatus) ? `requestStatus=${requestStatus}&` : '';
    const productTypeParam = ProductTypes.map(i => i.value).includes(productType) ? `productType=${productType}&` : '';
    const pageNumberParam = `pageNumber=${pageNumber}`;
    const urlRes = `${url.ppa.url}/v1/product-access-requests?${requestStatusParam}${productTypeParam}${pageNumberParam}`;
    return axios.get(urlRes, {withCredentials});
};

export const asyncUpdateApprovalRequestStatus = (uuid, requestStatus) => {
    const urlRes = `${url.ppa.url}/v1/product-access-requests/${uuid}?newRequestStatus=${requestStatus}`;
    return axios.put(urlRes, {}, {withCredentials});
}
