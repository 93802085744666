/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import { connect } from 'react-redux';
// actions
import { displayModal } from "@insticator/insticator-ui";
import { updatePageDetails, changePage } from 'state/actions/app_status';
import { updateCurrentSite, activateMockDetails, getPageExistingMock, deleteMockUp } from 'apps/mockupgenerator/state/actions/sitestatus';
// component
import MockupDetails from './mockupdetails';



/* ========== ~~~~~~~~~~ MOCKUP DETAILS : CONTAINER ~~~~~~~~~~ ========== */
// map state
const mapStateToProps = ({ mockupGeneratorState }) => ({
    currentSite: mockupGeneratorState.siteStatus.currentSite,
    deletingMockUp: mockupGeneratorState.siteStatus.deletingMockUp
});

// map actions
const mapDispatchToProps = dispatch => ({
    updateCurrentSite: (site) => dispatch(updateCurrentSite(site)),
    activateMockDetails: (pageUUID) => dispatch(activateMockDetails(pageUUID)),
    getPageExistingMock: (pageUUID, source) => dispatch(getPageExistingMock(pageUUID, source)),
    displayModal: (isVisible, contentToDisplay, showCloseButton) => dispatch(displayModal(isVisible, contentToDisplay, showCloseButton)),
    deleteMockUp: (pageUUID) => dispatch(deleteMockUp(pageUUID)),
    moveToPage: (location) => {
        dispatch(updatePageDetails(location));
        dispatch(changePage(location))
    }
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MockupDetails);
