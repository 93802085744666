/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { Component } from 'react';
// material
import { ToolTip, Link } from '@insticator/insticator-ui' // material
// utils
import { url } from 'utils/urls';
// styles
import injectSheet from 'react-jss';
import styles from 'pages/dashboard/appmenu/appcard/appcard_styles';



/* ========== ~~~~~~~~~~ APP CARD ~~~~~~~~~~ ========== */
class AppCard extends Component {
    constructor(props) {
        super(props);
    }
    handleOverlayClick = () => {
        this.props.moveToPage(this.props.route);
    }
    render() {
        const name = this.props.name;
        const description = this.props.description;
        const posterImage = this.props.posterImage ? `${url.image}/${this.props.posterImage}` : `${url.image}/logo-insticator-icon.svg`;
        const hasImage = !!this.props.posterImage;
        return (
            <div className={`appcard ${this.props.classes.appcard} hasImage-${hasImage}`}>
                <div className={`cover disabled-false`} style={{ backgroundImage: `url(${posterImage})` }} onClick={this.handleOverlayClick} >
                        <div className={`overlay`} />
                        <Link type={`internal`} action={this.props.route} />
                </div>
                <div className={`details`}>
                    <ToolTip position={`top`} action={`mouseenter`} content={name}>
                        <div className={`name`} >{name}</div>
                    </ToolTip>
                    <ToolTip position={`top`} action={`mouseenter`} content={description}>
                        <div className={`desc`} >{description}</div>
                    </ToolTip>
                </div>
            </div>
        );
    }
}



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(AppCard);
