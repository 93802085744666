import { pxToEm } from '@insticator/insticator-ui';

export default theme => ({
    typecontrols: {
        '& .ctas': {
            marginTop: pxToEm(5),
            justifyContent: 'flex-start',
            '&.name-currentfile, &.discard-true': {
                '& .cta': {
                    width: pxToEm(150),
                    marginRight: pxToEm(10),
                    '&:last-child': {
                        marginRight: 0
                    }
                }
            }
        }
    }
});