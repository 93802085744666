/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React from 'react';
//material
import { TextField, CheckboxField, Group, Tip } from '@insticator/insticator-ui';
import StatusIndicator from 'apps/sspadstxt/pages/sspmanager/sspdetail/sspinfo/statusindicator/statusindicator';
import SeatIndicator from 'apps/sspadstxt/pages/sspmanager/sspdetail/sspinfo/seatindicator/seatindicator';
import SSPSeat from 'apps/sspadstxt/pages/sspmanager/sspdetail/sspseat/sspseat_container';
import SSPStatus from 'apps/sspadstxt/pages/sspmanager/sspdetail/sspstatus/sspstatus_container';
import SSPBidderName from 'apps/sspadstxt/pages/sspmanager/sspdetail/sspbiddername/sspbiddername_container';
//styles
import styles from 'apps/sspadstxt/pages/sspmanager/sspdetail/sspinfo/sspinfo_styles';
import injectSheet from "react-jss";



/* ++++++++++ --------------- SSP INFO --------------- ++++++++++ */
const SSPInfo = props => {
    return (<Group klass={`sspinfo ${props.classes.sspinfo}`} name={'info'}>
                <div className={'nameindicator'} >
                    <TextField
                        type={'text'}
                        label={'SSP name'}
                        name={'name'}
                        placeholder={'SSP Name'}
                        value={props.name.value}
                        valid={props.name.valid}
                        handleChange={(e) => props.updateName(e.target.value)}
                    />
                    <SeatIndicator seatType={props.storedSeatType}/>
                    <StatusIndicator status={props.storedStatus}/>
                </div>
                <div className={'infogroup'}>
                    <div className={'infoarea area-contact'}>
                        <div className={'contact'} >
                            <div className={'labeltitle'} > SSP Contact Info</div>
                            <TextField
                                type={'text'}
                                label={'Account rep name'}
                                placeholder={'Full Name'}
                                value={props.accountReps[0].name.value}
                                handleChange={e => props.updateAccountRep('name', e.target.value)} />
                            <TextField
                                type={'text'}
                                label={'Account rep email address'}
                                value={props.accountReps[0].email.value}
                                placeholder={'rep@ssp.com'}
                                handleChange={e => props.updateAccountRep('email', e.target.value)} />
                        </div>
                    </div>
                    <div className={'infoarea area-seat'}>
                        <SSPSeat/>
                    </div>
                    <div className={'infoarea area-status'}>
                        <SSPStatus/>
                    </div>
                    <div className={'infoarea area-biddername'}>
                        <SSPBidderName />
                    </div>
                </div>
                <div className={'infogroup'}>
                    <div className={'infoarea'}>
                        <div className={'inventory'} >
                            <div className={'labeltitle'} >Ad Inventory Type</div>
                            <div className={'inventoryfields'} >
                                <CheckboxField type={'checkbox'} label={'Display ads'} checked={props.adInventoryType.value === 'DISPLAY'} handleChange={e => props.updateInventoryType('DISPLAY')}/>
                                <CheckboxField type={'checkbox'} label={'Video ads'} checked={props.adInventoryType.value === 'VIDEO'} handleChange={e => props.updateInventoryType('VIDEO')} />
                                <CheckboxField type={'checkbox'} label={'Display & Video'} checked={props.adInventoryType.value === 'DISPLAY_AND_VIDEO'} handleChange={e => props.updateInventoryType('DISPLAY_AND_VIDEO')} />
                            </div>
                        </div>
                    </div>
                    <div className={'infoarea'}>
                        <div className={'inventory s2s-integration'} >
                            <div className={'labeltitle'} >S2S Integration</div>
                            <div className={'inventoryfields'} >
                                <CheckboxField type={'checkbox'} label={<span>This SSP has S2S integration<Tip space={'left'} icon={'question-circle'} content={<p>Any changes made may take up to 24 hours to be reflected</p>} /></span>} checked={props.hasS2SIntegration.value} handleChange={e => props.updateHasS2SIntegration(!props.hasS2SIntegration.value)}/>
                                <CheckboxField type={'checkbox'} label={<span>Validate ads.txt<Tip space={'left'} icon={'question-circle'} content={<p>Validate domain ads.txt against short file during domain approval automation</p>} /></span>} checked={props.validateAdsTxt.value} handleChange={e => props.updateValidateAdsTxt(!props.validateAdsTxt.value)}/>
                            </div>
                        </div>
                    </div>
                    <div className={'infoarea'}>
                        <div className={'inventory requires-approval'} >
                            <div className={'labeltitle'} >SSP Approval Requirements</div>
                            <div className={'inventoryfields'} >
                                <CheckboxField type={'checkbox'} label={<span>This SSP requires approval <Tip space={'left'} icon={'question-circle'} content={<p>Any changes made may take up to 24 hours to be reflected</p>} /></span>} checked={props.approvalRequired.value} handleChange={e => props.updateApprovalRequired(!props.approvalRequired.value)}/>
                            </div>
                        </div>
                    </div>
                </div>
            </Group>)

};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(SSPInfo);
