/* ========== ~~~~~~~~~~ APP STATUS : ACTIONS ~~~~~~~~~~ ========== */
// ACTION
// utilized by reducers as a call to action to perform some kind of update to application state
// utilized in "_container" components and reducers


/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { push } from 'react-router-redux';
// import { batchActions } from 'redux-batched-actions';


/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export const UPDATE_CURRENT_APP = 'UPDATE_CURRENT_APP';
export const UPDATE_PAGE_DETAILS = 'UPDATE_PAGE_DETAILS';
export const SET_EDIT_MODE = 'SET_EDIT_MODE';

// update store with new page values based on some cta to navigate
// this gets batched with changePage and is called directly before that action
export const updatePageDetails = (location) => ({
    type: UPDATE_PAGE_DETAILS,
    location
});
export const updateCurrentApp = (name) => ({
    type: UPDATE_CURRENT_APP,
    name
});
// this will actually navigate to a new page (really just renders a new component) 
// location will be passed from the cta that wants to navigate ... it will match up to a route in app.js which will transition to that new component
// behind the scenes this will be synced with the history object we created so there is standard browser functionality in place
// this gets batched with updatePage and is called directly after that action
// NO reducer for this, instead we're just using a module to "push" the new location

// const getPageUrl = location => `https://www.${url.baseurl}/${location.indexOf('/') === 0 ? location.substring(0) : location}`;
// const tagManagerArgs = location => ({
//     gtmId: environment === 'PRODUCTION' ? 'GTM-P972HDC' : 'GTM-T77J246',
//     events: {
//         pageURL: console.log('location:', location, 'pageURL:', getPageUrl(location)) || getPageUrl(location)
//     }
// });

export const changePage = (location) => {
    // if (environment !== 'LOCALHOST') TagManager.initialize(tagManagerArgs(location));
    return push(`/${location === undefined ? '' : location}`)
};

export const setEditMode = editState => ({
    type: SET_EDIT_MODE,
    editState
});
