/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { combineReducers } from 'redux';
import questionStatus from 'apps/classification/state/reducers/questionstatus';
import taxonomyStatus from 'apps/classification/state/reducers/taxonomystatus';
import taxonomyTree from 'apps/classification/state/reducers/taxonomytree';




/* ========== ~~~~~~~~~~ REPORTS STATE : ROOT REDUCER ~~~~~~~~~~ ========== */
// COMBINE REDUCERS
// used to comhine all various reducers together into an easily accesible object
// utilized in store.js
const classificationState = combineReducers({
    questionStatus,
    taxonomyStatus,
    taxonomyTree
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default classificationState;
