/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm, disable } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// SSP DETAIL : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    sspdetail: {
        display: 'flex',
        flexDirection: 'column',
        '& #content': {
          paddingTop: pxToEm(50)
        },
        // '& .field': {
        //     '&:not(.type-checkbox)': {
        //         '& .formelement': {
        //             width: '100%'
        //         },
        //         maxWidth: pxToEm(369),
        //         width: '100%',
        //         flex: 1
        //     }
        // },
        '& .labeltitle': {
            fontSize: pxToEm(20),
            fontWeight: 'normal',
            height: 'auto',
            // maxWidth: pxToEm(369),
            // width: '100%',
            marginTop: pxToEm(50),
            marginBottom: pxToEm(20),
            paddingBottom: pxToEm(5),
            borderBottom: '1px solid rgb(192, 201, 220)',
            '&.full': {
                maxWidth: '100%'
            }
        },
        '& .name-save': {
            marginTop: pxToEm(40)
        },
        '&.saving-true': {
            ...disable
        }
    }
});
