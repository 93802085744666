/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { sharedURLs, environment } from "@insticator/insticator-ui"; // iui-utils

/* --------------- UTILITIES : URLs --------------- */

// set host URL based on dynamic environment variable
let commenting;

switch (environment) {
    case 'PRODUCTION':
        commenting = 'https://soapps.net/live';
        break;
    case 'LOCALHOST':
        commenting = 'https://soapps.net/dev';
        break;
    case 'REVIEW':
    // fall through
    case 'BETA':
    // fall through
    case 'STAGING':
    // fall through
    default:
        commenting = 'https://soapps.net/test';
        break;
}

/* --------------- UTILITIES : URLs --------------- */

// store URLs for export
let url = {
    ...sharedURLs,

    // Extend urls list
    // Admin Panel specific URLs
    image: `${sharedURLs.images}/adminpanel`,
    site: {
        test: /^http(s)?:\/\/(www\.)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)+\.*[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
        message: 'Format should be http://www.sample.com'
    },
    commenting: {
        base: commenting,
        integrations: `${commenting}/integrations`
    }
};

/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export { url };
