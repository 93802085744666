/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state
import { getMasterAdsTxtValues, updatePreferredMasterType, getSiteDetails, getSiteSSPDetails } from 'apps/sspadstxt/state/actions/publishermanagerstatus';
// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import PreferredImplementation from 'apps/sspadstxt/pages/publishermanager/publisherdetail/editsite/preferredimplementation/preferredimplementation';


/* ========== ~~~~~~~~~~ PREFERRED IMPLEMENTATION: CHILD ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ authStatus, sessionStatus, sspAdsTxtState }) => ({
    masterFileType: sspAdsTxtState.publisherManagerStatus.preferredImplementation.masterFileType,
    storedMasterFiles: sspAdsTxtState.publisherManagerStatus.storedMasterFiles,
    storedMasterFilesOKO: sspAdsTxtState.publisherManagerStatus.storedMasterFilesOKO,
    storedMasterFilesS2S: sspAdsTxtState.publisherManagerStatus.storedMasterFilesS2S,
    loading: sspAdsTxtState.publisherManagerStatus.loading,
    seatType: sspAdsTxtState.publisherManagerStatus.selectedSite.seatType || 'INSTICATOR',
    selectedSite: sspAdsTxtState.publisherManagerStatus.selectedSite,
});

// map actions
const mapDispatchToProps = dispatch => ({
    getMasterAdsTxtValues: (fileType, includeInsticatorAdsTxtValue, seatType) => dispatch(getMasterAdsTxtValues(fileType, includeInsticatorAdsTxtValue, seatType)),
    updatePreferredMasterType: fileType => dispatch(updatePreferredMasterType(fileType)),
    getSiteDetails: site => dispatch(getSiteDetails(site)),
    getSiteSSPDetails: site => dispatch(getSiteSSPDetails(site)),
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PreferredImplementation);
