/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm, pxToEmWithBaseline, replacePXinStringWithEM, mediaQuery } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// CLASSIFY CTAs : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    classifyctas: {
        '& .ctas': {
            display: 'flex',
            justifyContent: 'space-around',
            fontSize: pxToEm(14),
            '& .button': {
                height: pxToEmWithBaseline({ value: 48, fontSizeBaseline: 14 }),
                '&:first-child': {
                    maxWidth: pxToEmWithBaseline({ value: 120, fontSizeBaseline: 14 }),
                },
                '&:last-child': {
                    maxWidth: pxToEmWithBaseline({ value: 213, fontSizeBaseline: 14 }),
                }
            }
        }
    },
    [mediaQuery.thinnest]: {
        classifyctas: {
            flexDirection: 'column',
            alignItems: 'center',
            '& .ctas': {
                '& .button':{
                    width: '100%'
                }
            }
        }
    }
});
