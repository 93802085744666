/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import { connect } from 'react-redux';
import { withRouter } from 'react-router'
// component
import Dashboard from 'pages/dashboard/dashboard';
import {isUserLoggedIn} from "../../state/actions/auth_status";
import userStatus from "../../state/reducers/user_status";



/* ========== ~~~~~~~~~~ DASHBOARD : CONTAINER ~~~~~~~~~~ ========== */
// map state
const mapStateToProps = ({ appStatus, authStatus }) => ({
    appStatus,
    fetchingAdminInformation: authStatus.fetchingAdminInformation,
    adminInfoFailed: authStatus.adminInfoFailed,
    adminInfoReceived: authStatus.adminInfoReceived,
    adminInformation: authStatus.adminInformation,
    userLoggedIn: userStatus.loggedIn,
});
// map actions
const mapDispatchToProps = dispatch => ({
    isUserLoggedIn: () => dispatch(isUserLoggedIn()),
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard));
