/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import {pxToEm, replacePXinStringWithEM} from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// COLUMN DATA : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    columndata: {
        display: 'flex',
        marginTop: pxToEm(20),
        justifyContent: 'space-between',
        // maxWidth: pxToEm(340),
        '& .labeltext': {
            flex: '1',
            height: pxToEm(48),
            display: 'flex',
            alignItems: 'center',
            marginRight: pxToEm(5),
            '& .tip': {
                marginLeft: pxToEm(5)
            }
        },
        '& .values': {
            flex: '1',
            display: 'flex',
            flexDirection: 'column',
            '& .multifield': {
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                marginBottom: pxToEm(10),
                '&:last-child': {
                    marginBottom: '0'
                }
            },
            '& .singlefield': {
                display: 'flex',
                alignItems: 'center'
            },
            '& .plus, .minus, .spacer': {
                height: pxToEm(38),
                width: pxToEm(38),
                borderRadius: pxToEm(3),
                color: theme.color.supporting.dark.contrast,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                '&.inactive': {
                    opacity: '0',
                    pointerEvents: 'none'
                }
            },
            '& .plus':{
                // position: 'absolute',
                // right: pxToEm(-48),
                background: theme.color.supporting.dark.swatch,
                cursor: 'pointer'
                // bottom: 0
            },
            '& .minus':{
                // position: 'absolute',
                // left: pxToEm(-48),
                backgroundColor: theme.color.attention.dark.swatch,
                cursor: 'pointer'
            },
            '& .field':{
                flex: '1',
                // maxWidth: pxToEm(96),
                fontSize: pxToEm(14),
                margin: replacePXinStringWithEM('0px 5px'),
                '& .formelement': {
                    height: pxToEm(48)
                }
            }
        }
    }
});
