/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
import { displayTaxonomyEditorMessage, closeTaxonomyEditorMessage } from 'apps/classification/state/actions/taxonomystatus';
import { deleteTaxonomyCategory } from 'apps/classification/state/actions/taxonomytree';
// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import TaxonomyEditorModal from 'apps/classification/material/taxonomyeditor/taxonomyeditormodal/taxonomyeditormodal';



/* ========== ~~~~~~~~~~ MODAL : PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ classificationState }) => ({
    visible: classificationState.taxonomyStatus.editorMessage.visible,
    messageType: classificationState.taxonomyStatus.editorMessage.messageType,
    uniqueProps: classificationState.taxonomyStatus.editorMessage.uniqueProps,
    showCloseButton: classificationState.taxonomyStatus.editorMessage.showCloseButton
});

// map actions
const mapDispatchToProps = dispatch => ({
    displayTaxonomyEditorMessage: message => dispatch(displayTaxonomyEditorMessage(message)),
    closeTaxonomyEditorMessage: () => dispatch(closeTaxonomyEditorMessage()),
    deleteTaxonomyCategory: categoryId => dispatch(deleteTaxonomyCategory(categoryId))
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaxonomyEditorModal);
