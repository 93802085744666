/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm, replacePXinStringWithEM } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// DASHBOARD : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    answercontrols: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        alignItems: 'center',
        marginBottom: pxToEm(25),
        '& .answercontrol': {
            marginRight: pxToEm(15),
            marginBottom: pxToEm(15),
            fontSize: theme.typography.size.xsmall.fontSize,
            '& i': { fontSize: '100%' },
            '&.name-classification-complete': {
                width: pxToEm(257),
                '& .icon': {
                    background: 'rgb(102, 172, 26)',
                    borderRadius: pxToEm(4),
                    border: replacePXinStringWithEM(`2px solid rgb(102, 172, 26)`),
                    height: pxToEm(24),
                    width: pxToEm(24),
                    marginRight: pxToEm(10),
                    '& i': {
                        fontSize: '80%',
                        marginRight: '0'
                    }
                }
            },
            '&.name-flag-selected, &.name-clear-selected': {
                width: pxToEm(170)
            },
            '&.selectallcheckbox': {
                '& .option-text': {
                    opacity: 1
                }
            }
        }
    },
});