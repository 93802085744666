/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { theme, pxToEm, replacePXinStringWithEM, disable } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- LIST ITEM : STYLES --------------- ++++++++++ */
export default theme => ({
    listitem: {
        borderBottom: replacePXinStringWithEM(`1px solid ${theme.color.grey.medium.swatch}`),
        padding: replacePXinStringWithEM('10px 10px'),
        display: 'flex',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        alignItems: 'center',
        transition: 'background .15s ease-out 0s',
        '&:last-child': {
            borderBottom: '0'
        },
        '&:hover': {
            background: '#fafafa'
        },
        '& .itemcontents': {
            display: 'flex',
            justifyContent: 'flex-start',
            flex: '1',
            '& .iconography': {
                cursor: 'default',
                display: 'flex',
                color: theme.color.primary.medium.swatch,
                opacity: '1',
                width: pxToEm(25)
            },
            '& .itemdetails': {
                // flex: '1',
                // width: '100%',
                // display: 'block',
                // overflow: 'hidden',
                // whiteSpace: 'nowrap',
                // textOverflow: 'ellipsis',
                '& .itemdetail': {
                    marginRight: pxToEm(15),
                    '&.device, &.size': {
                        color: '#aaa',
                        fontSize: pxToEm(12)
                    }
                }
            }
        },
        '& .itemctas': {
            display: 'flex',
            justifyContent: 'flex-end',
            '& .cta': {
                marginLeft: pxToEm(15),
                '& .text': {
                    fontSize: pxToEm(15)
                },
                '& .icon': {
                    fontSize: '75%'
                },
                '&.name-removeproduct': { display: 'none' },
                '&.name-insertproduct': { display: 'block' }
            }
        },
        '&.inserted-true': {
            '& .itemcontents': {
                '& .iconography': {
                    // opacity: '0'
                },
                '& .itemdetails': {
                    // ...disable
                }
            },
            '& .itemctas': {
                '& .cta': {
                    '&.name-removeproduct': { display: 'block' },
                    '&.name-insertproduct': { display: 'none' }
                }
            }
        },
        '&.inserted-false': {
            '& .itemcontents': {
                '& .iconography': {
                    ...disable
                },
                '& .itemdetails': {
                    ...disable
                }
            }
        },
        '&.inspectorEnabled-true': {
            '&.isActive-false': {
                ...disable
            }
        },
        '&.isActive-true': {
            background: '#fafafa'
        },
        '&.highlight, &.isHighlighted-true': {
            background: 'rgba(20,148,244,.1)'
        }
    }
});
