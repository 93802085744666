/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { Component } from 'react';
import DomInspector from 'dom-inspector';
// material
import { Page, Content, TextAndGraphicLayout, CTAs, Button, TextField, LoadState, Label } from '@insticator/insticator-ui'; // iui-material
//styles
import injectSheet from 'react-jss';
import styles from 'apps/mockupgenerator/pages/createmockup/createmockup_styles';



/* ++++++++++ --------------- CREATE MOCKUP --------------- ++++++++++ */
class CreateMockupPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			scrapping: false
		};
	}
	handleCreate = () => this.props.createMockup(this.props.pageUrl.value, this.props.currentSite.value);
// (pageURL, siteUUID, pageCode)
	handleChange = (e) => {
		this.props.updateURL(e.target.value);
	};
	render() {
		return (
			<Page klass={`createmockup ${this.props.classes.createmockup}`}>
				{this.state.scrapping ? <LoadState graphicName={'error-404'} bodycopy={<span>We're grabbing that site's code and generating a sample.</span>} /> :
			    <Content>
			    	<TextAndGraphicLayout
		        		headline={'Create a Mockup'}
		        		title={this.props.currentSite && this.props.currentSite.name}
		        		bodycopy={
		        			<div>
			        			<p>Enter the URL of the page you'd like to embed our products in.</p>
			        			<ul>
			        				<li>We'll create the page here for you ...</li>
			        				<li>Then insert your configured products where you see fit.</li>
			        			</ul>
			        		</div>
		        		}
		        		additionalMessageContent={
		        			<div>
		        				<Label>Enter page URL</Label>
			        			<CTAs>
				                	<TextField
										type={'url'}
										name={'pageurl'}
										value={this.props.pageUrl.value}
										valid={this.props.pageUrl.valid}
										operation={'load'}
										processing={this.props.fetchingMockUp}
										placeholder={'ex: https://www.autoblog.com/2020/06/12/porsche-911-turbo-s-vs-taycan-turbo/'}
										handleChange={this.handleChange} />
				                	<Button type={`action`} action={this.handleCreate} variant={`primary`} name={`create`} text={`Create Mockup`} />
				                </CTAs>
				            </div>
			            }
			            illustration={'product-content-incontent-autoblog'}
			        />
			    </Content>}
			</Page>
		)
	}
}



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(CreateMockupPage);
