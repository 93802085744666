/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state
import { setTaxonomyStateProperty, runTaxonomySearch, setScrollToCategory } from 'apps/classification/state/actions/taxonomystatus';


// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import TaxonomyEditorSearch from 'apps/classification/material/taxonomyeditor/taxonomyeditorsearch/taxonomyeditorsearch';




/* ========== ~~~~~~~~~~ TAXONOMY EDITOR SEARCH: PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ classificationState }, { categoryId }) => {
    const categoriesFoundByPositions = classificationState.taxonomyStatus.categoriesFoundByPositions;
    const categoriesFoundByIds = classificationState.taxonomyStatus.categoriesFoundByIds;
    const sortedCategoriesPositions = classificationState.taxonomyStatus.sortedCategoriesPositions;
    const foundCategoriesIds = Object.keys(categoriesFoundByIds);
    const numCategoriesFound = Object.keys(categoriesFoundByIds).length;
    const searchTerm = classificationState.taxonomyStatus.searchTerm;
    const searchActive = searchTerm.length > 0;
    const nothingFound =  searchActive && numCategoriesFound === 0;
    const scrollToCategory = classificationState.taxonomyStatus.scrollToCategory;
    const currentPositionIndex = sortedCategoriesPositions.indexOf(categoriesFoundByIds[scrollToCategory]);

    return {
        searchTerm,
        nothingFound,
        scrollToCategory,
        numCategoriesFound,
        foundCategoriesIds,
        currentPositionIndex,
        categoriesFoundByIds,
        categoriesFoundByPositions,
        sortedCategoriesPositions
    }
};

// map actions
const mapDispatchToProps = dispatch => ({
    setTaxonomyStateProperty: propertyData => dispatch(setTaxonomyStateProperty(propertyData)),
    runTaxonomySearch: searchTerm => dispatch(runTaxonomySearch(searchTerm)),
    setScrollToCategory: categoryId => dispatch(setScrollToCategory(categoryId))
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaxonomyEditorSearch);
