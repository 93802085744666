import axios from 'axios';
import { url } from 'utils/urls'

const withCredentials = true;
const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

export const asyncGetSSPs = filters => axios.post(`${url.spm.url}ssps/list`, filters, { withCredentials });

export const asyncGetSSPDetails = sspID => axios.get(`${url.spm.url}ssps/${sspID}`, { withCredentials });

export const asyncSaveNewSSP = details => axios.post(`${url.spm.url}ssps`, details, { withCredentials });

export const asyncUpdateSSPDetails = details => axios.put(`${url.spm.url}ssps/${details.id}`, details, { withCredentials });
