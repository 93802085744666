/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { hasLocalStorage } from "@insticator/insticator-ui";
import { FETCHING_MOCK_UP_DATA, RECEIVE_MOCK_UP_DATA,
    UPDATE_URL, SAVING_MOCK_UP, FETCHING_EMBEDS_ADS,
    RECEIVE_EMBEDS_ADS, CREATING_MOCK_UP, PRODUCTS_CHANGE_DETECTED,
    SET_PRODUCT_INSERTED, ADD_PRODUCTS_UNMOUNT, INVALIDATE_URL, DISCARD_PRODUCTS_CHANGE,
    REMOVE_PRODUCTS
} from 'apps/mockupgenerator/state/actions/addproducts';


// ^-^ ^-^ ^-^ ^-^ ^-^   ADD PRODUCTS: DEFAULT STATE   ^-^ ^-^ ^-^ ^-^ ^-^ //
//helper functions

// const formProductData = data => {
//     const products = {};
//     Object.entries(data).forEach(([id, product]) =>
//         products[id] = { id, ...product, location: decodeURIComponent(product.location) });
//     return products;
// };

const formProductData = data => {
    const products = {};
    Object.entries(data).forEach(([id, product]) => {
        const isMobileAd = 'device' in product && product.device === 'mobile';
        if(!isMobileAd) products[id] = {id, ...product, location: decodeURIComponent(product.location)}
    });
    return products;
};

const defaultState = {
    fetchingMockUp: false,
    requestStatus: null,
    pageUUID: !!hasLocalStorage('pageData') ? JSON.parse(hasLocalStorage('pageData')).pageUUID : '',
    pageUrl: {
        value: '',
        valid: null
    },
    mockupUrl: '',
    mockupCode: '',
    embedsAdsReceived: false,
    fetchingEmbedsAds: false,
    savingMockup: false,
    creatingMockup: false,
    productsChangeDetected: false,
    updatedProducts: { ads: {}, embeds: {} },
    originalProductsState: { ads: {}, embeds: {} },
    ads: {},
    embeds: {}
};


/* ========== ~~~~~~~~~~ SITE STATUS : REDUCER ~~~~~~~~~~ ========== */
const addProductsStatus = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case FETCHING_MOCK_UP_DATA:
            newState.fetchingMockUp = action.status;
            return newState;
        case UPDATE_URL:
            newState.pageUrl.value = action.url;
            return newState;
        case INVALIDATE_URL:
            newState.pageUrl.valid = action.status;
            return newState;
        case CREATING_MOCK_UP:
            newState.creatingMockup = action.status;
            return newState;
        case RECEIVE_MOCK_UP_DATA:
            newState.requestStatus = action.mockupData.requestStatus;
            newState.pageUUID = action.mockupData.pageUUID ? action.mockupData.pageUUID : newState.pageUUID;
            newState.pageUrl.value = action.mockupData.pageUrl ? action.mockupData.pageUrl : newState.pageURL;
            newState.pageUrl.valid = action.mockupData.pageUrl ? true : null;
            newState.mockupUrl = action.mockupData.mockupUrl ? action.mockupData.mockupUrl : newState.mockupUrl;
            newState.mockupCode = decodeURIComponent(action.mockupData.mockupCode);
            return newState;
        case FETCHING_EMBEDS_ADS:
            newState.fetchingEmbedsAds = action.status;
            return newState;
        case RECEIVE_EMBEDS_ADS:
            newState.ads = formProductData(action.embedsAds.adUnits);
            newState.embeds = formProductData(action.embedsAds.products);
            newState.originalProductsState = {
                ads: JSON.parse(JSON.stringify(newState.ads)),
                embeds: JSON.parse(JSON.stringify(newState.embeds))
            };
            return newState;
        case PRODUCTS_CHANGE_DETECTED:
            newState.productsChangeDetected = action.state;
            return newState;
        case DISCARD_PRODUCTS_CHANGE:
            newState.ads = JSON.parse(JSON.stringify(newState.originalProductsState.ads));
            newState.embeds = JSON.parse(JSON.stringify(newState.originalProductsState.embeds));
            newState.updatedProducts = { ads: {}, embeds: {} };
            newState.productsChangeDetected = false;
            return newState;
        case SAVING_MOCK_UP:
            if(action.status === 'success') {
                newState.updatedProducts = { ads: {}, embeds: {} };
                newState.productsChangeDetected = false;
                newState.originalProductsState = {
                    ads: JSON.parse(JSON.stringify(newState.ads)),
                    embeds: JSON.parse(JSON.stringify(newState.embeds))
                };
            }
            newState.savingMockup = action.status === 'processing';
            return newState;
        case SET_PRODUCT_INSERTED:
            const type = action.id in newState.embeds ? 'embeds' : 'ads';

            newState[type][action.id].inserted = action.inserted;
            newState[type][action.id].location = action.location;

            const originalInsertion = newState.originalProductsState[type][action.id].inserted === action.inserted;
            const originalLocation = newState.originalProductsState[type][action.id].location === action.location;
            const productOnUpdatedList = action.id in newState.updatedProducts[type];

            // if the product updated, store it's original state
            if (!originalInsertion || !originalLocation) {
                newState.updatedProducts[type][action.id] = newState.originalProductsState[type][action.id];
            }

            // if the user manually moves the product to it's original place, remove the product from the list of updated products
            if (originalInsertion && originalLocation && productOnUpdatedList) {
                delete newState.updatedProducts[type][action.id];
            }

            newState.productsChangeDetected = Object.keys(newState.updatedProducts.embeds).length > 0 ||
                Object.keys(newState.updatedProducts.ads).length > 0;

            return newState;

        case REMOVE_PRODUCTS:
            action.products.forEach(id => {
                const type = id in newState.embeds ? 'embeds' : 'ads';
                newState[type][id].inserted = false;
                newState[type][id].location = null;

                const originalInsertion = newState.originalProductsState[type][id].inserted === false;
                const originalLocation = newState.originalProductsState[type][id].location === null;
                const productOnUpdatedList = id in newState.updatedProducts[type];

                // if the product updated, store it's original state
                if (!originalInsertion || !originalLocation) {
                    newState.updatedProducts[type][id] = newState.originalProductsState[type][id];
                }
                // if the user manually moves the product to it's original place, remove the product from the list of updated products
                if (originalInsertion && originalLocation && productOnUpdatedList) {
                    delete newState.updatedProducts[type][id];
                }
            });
            newState.productsChangeDetected = Object.keys(newState.updatedProducts.embeds).length > 0 ||
                Object.keys(newState.updatedProducts.ads).length > 0;

            return newState;

        case ADD_PRODUCTS_UNMOUNT:
            newState = defaultState;
            return newState;
        default:
            return state;
    }
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default addProductsStatus;
