/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import {
    QUESTIONS_RECEIVED,
    QUESTIONS_FETCHING,
    SELECT_SINGLE_QUESTION,
    SELECT_ALL_QUESTIONS,
    UNSELECT_ALL_QUESTIONS,
    CLEAR_FILTER,
    SELECT_FILTER,
    UPDATE_SEARCH_TERM,
    UPDATE_SEARCH_TARGET,
    UPDATE_QUESTION_CLASSIFICATION_STATUS,
    QUESTIONS_PENDING_OR_LIVE,
    SET_ACTIVE_QUESTION,
    UPDATE_ACTIVE_QUESTION_INDEX,
    DOWNLOADING_CSV,
    SETTING_LIVE_ON_DMP,
    SELECT_SINGLE_ANSWER,
    SELECT_ALL_ANSWERS,
    UNSELECT_ALL_ANSWERS,
    ACTIVE_QUESTION_CHANGE_DETECTED,
    ACTIVE_QUESTION_IS_SAVED,
    SET_NEW_TAXONOMY,
    REMOVE_TAXONOMY,
    REMOVE_ALL_QUESTION_TAXONOMIES,
    CLEAR_SELECTED_ANSWERS_TAXONOMY,
    FLAG_ANSWER,
    FLAG_SELECTED_ANSWER,
    UPDATE_QUESTION_NOTE,
    DISCARD_QUESTION_CHANGES,
    SAVING_QUESTION,
    UPDATE_ACTIVE_QUESTION,
    REMOVE_QUESTIONS_FROM_QUESTIONS_STATE,
    TRIGGER_REFETCH
} from 'apps/classification/state/actions/questionstatus';

// ^-^ ^-^ ^-^ ^-^ ^-^   QUESTION MANAGER STATUS: TRANSPILERS/HELPER FUNCTIONS   ^-^ ^-^ ^-^ ^-^ ^-^ //

const transpileActiveQuestion = (questionID, question) => ({
    questionID: questionID,
    question: !!question ? question.question : null,
    notes: !!question ? (question.notes ? question.notes : '') : '',
    classified: !!question ? question.classified : null,
    questionType: !!question ? question.questionType : 'quiz',
    answers: !!question ? question.answers.map(answer => ({
        correct: answer.correct,
        text: answer.text,
        answerID: answer.answerID,
        flagged: answer.flagged,
        classification: answer.classification.map(classification => classification.categoryID)
    })) : [],
    answersFlagged:!!question ? answersFlagged(question.answers) : false
});

const answersFlagged = answers => !!answers.filter(answer => answer.flagged).length;
// ^-^ ^-^ ^-^ ^-^ ^-^   QUESTION MANAGER STATUS: DEFAULT STATE   ^-^ ^-^ ^-^ ^-^ ^-^ //

const defaultState = {
    questionsReceived: false,
    questions: {},
    storedSelectedQuestions: {},
    pagesSelectedAll: [],
    questionDataFetching: false,
    storedQuestionData: null,
    selectedQuestions: [],
    isLiveOnDMP: false,
    triggerRefetch: false,
    searchTerm: "",
    searchTarget: {name: 'Questions', value: 'QUESTION'},
    filters: {
        questionType: [], // QUIZ, POLL, SURVEY
        classificationStatus: [], // INCOMPLETE, COMPLETE
        flagged: [], // FLAGGED or NOT_FLAGGED
        notes: [], // WITH_NOTES or WITHOUT_NOTES
        adminUUIDs: [], // LIST OF ADMIN UUIDS
    },
    totalPages: 0,
    totalQuestions: 0,
    currentPage: 1,
    questionsPerPage: 50,
    settingLiveOnDMP: false,
    exportingQuestionsCSV: false,
    activeQuestionIndex: 0,
    activeQuestion: transpileActiveQuestion(null, null),
    savingActiveQuestion: false,
    activeQuestionChangeDetected: false,
    activeQuestionIsSaved: false,
    activeAnswers: []
};

/* ========== ~~~~~~~~~~ QUESTION MANAGER STATUS : REDUCER ~~~~~~~~~~ ========== */
// REDUCER
// used to manipulate application state
// takes an action, checks its type, based on that performs certain functionality, then returns new slice of state
// utilized in "_container" components
const questionStatus = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case QUESTIONS_RECEIVED:
            newState.questionsReceived = true;
            newState.currentPage = action.questions.currentPage;
            newState.totalPages = action.questions.totalPages;
            newState.totalQuestions = action.questions.totalQuestions;
            newState.questions = action.questions;
            //when navigating back to questions after editing then back to editor changes in storedSelectedQuestions need to be updated
            Object.keys(newState.storedSelectedQuestions).forEach(questionID => newState.questions[questionID] ? newState.storedSelectedQuestions[questionID] = newState.questions[questionID] : null);
            delete newState.questions.currentPage;
            delete newState.questions.totalPages;
            delete newState.questions.totalQuestions;
            newState.triggerRefetch = false;
            return newState;
        case QUESTIONS_FETCHING:
            if(action.status) newState.questions = defaultState.questions;
            newState.questionDataFetching = action.status;
            return newState;
        case SELECT_SINGLE_QUESTION:
            const questionIndex = newState.selectedQuestions.indexOf(action.questionID);
            !!newState.storedSelectedQuestions[action.questionID] ? delete newState.storedSelectedQuestions[action.questionID] : newState.storedSelectedQuestions[action.questionID] = newState.questions[action.questionID];
            if(questionIndex < 0){
                newState.selectedQuestions.push(action.questionID)
            } else {
                newState.selectedQuestions.splice(questionIndex, 1);
                newState.pagesSelectedAll.splice(newState.pagesSelectedAll.indexOf(newState.currentPage, 1))
            }
            return newState;
        case TRIGGER_REFETCH:
            newState.triggerRefetch = true;
            return newState;
        case SELECT_ALL_QUESTIONS:
            let selectedQuestions = newState.selectedQuestions;
            let storedSelectedQuestions = newState.storedSelectedQuestions;
           Object.keys(newState.questions).forEach(questionIndex => {
               if(!selectedQuestions.includes(parseInt(questionIndex))) selectedQuestions.push(parseInt(questionIndex));
                storedSelectedQuestions[questionIndex] = newState.questions[questionIndex];
            });
            newState.pagesSelectedAll.push(newState.currentPage);
            newState.storedSelectedQuestions = storedSelectedQuestions;
            newState.selectedQuestions = selectedQuestions;
            return newState;
        case UNSELECT_ALL_QUESTIONS:
            Object.keys(newState.questions).forEach(questionIndex => {
                let indexToRemove = newState.selectedQuestions.indexOf(parseInt(questionIndex))
                if(indexToRemove >= 0)newState.selectedQuestions.splice(indexToRemove,1);
                delete newState.storedSelectedQuestions[questionIndex]
            });
            newState.pagesSelectedAll.splice(newState.pagesSelectedAll.indexOf(newState.currentPage), 1);
            return newState;
        case SELECT_SINGLE_ANSWER:
            const answerIndex = newState.activeAnswers.indexOf(action.index);
            answerIndex < 0 ? newState.activeAnswers.push(action.index) : newState.activeAnswers.splice(answerIndex, 1);
            return newState;
        case UNSELECT_ALL_ANSWERS:
            newState.activeAnswers = [...defaultState.activeAnswers];
            return newState;
        case SELECT_ALL_ANSWERS:
            newState.activeAnswers = [...defaultState.activeAnswers];
            newState.activeQuestion.answers.forEach((answer,index) => newState.activeAnswers.push(index));
            return newState;
        case CLEAR_FILTER:
            newState.filters = defaultState.filters;
            return newState;
        case SELECT_FILTER:
            const filterIndex = newState.filters[action.filter].indexOf(action.filterValue);
            filterIndex < 0 ? newState.filters[action.filter].push(action.filterValue) : newState.filters[action.filter].splice(filterIndex, 1);
            return newState;
        case UPDATE_SEARCH_TERM:
            newState.searchTerm = action.searchTerm;
            return newState;
        case UPDATE_SEARCH_TARGET:
            newState.searchTarget = action.searchTarget;
            return newState;
        case UPDATE_QUESTION_CLASSIFICATION_STATUS:
            newState.activeQuestion.classified = action.classificationStatus;
            return newState;
        case QUESTIONS_PENDING_OR_LIVE:
            newState.isLiveOnDMP = action.status;
            return newState;
        case SET_ACTIVE_QUESTION:
            newState.activeQuestion = transpileActiveQuestion(newState.selectedQuestions[action.index], newState.storedSelectedQuestions[newState.selectedQuestions[action.index]]) ;
            newState.activeAnswers = [...defaultState.activeAnswers];
            return newState;
        case UPDATE_ACTIVE_QUESTION_INDEX:
            newState.activeQuestionIndex = action.index;
            return newState;
        case SET_NEW_TAXONOMY:
            action.deepestChildren.forEach(categoryID => {
                newState.activeAnswers.forEach(answerIndex => {
                    const taxonomyIndex = newState.activeQuestion.answers[answerIndex].classification.indexOf(categoryID);
                    if(taxonomyIndex < 0) newState.activeQuestion.answers[answerIndex].classification.push(categoryID);
                })
            });
            return newState;
        case REMOVE_TAXONOMY:
            const indexToBeUpdated = newState.activeQuestion.answers[action.answerIndex].classification.indexOf(action.deepestChildID);
            if(action.newDeepestChildID){
                newState.activeQuestion.answers[action.answerIndex].classification[indexToBeUpdated] = action.newDeepestChildID;
            } else {
                newState.activeQuestion.answers[action.answerIndex].classification.splice(indexToBeUpdated, 1)
            }
            newState.activeQuestion.answers[action.answerIndex].classification = [...new Set(newState.activeQuestion.answers[action.answerIndex].classification)];
            return newState;
        case REMOVE_ALL_QUESTION_TAXONOMIES:
            newState.activeQuestion.answers[action.answerIndex].classification = [];
            return newState;
        case CLEAR_SELECTED_ANSWERS_TAXONOMY:
            newState.activeAnswers.forEach(answer => newState.activeQuestion.answers[answer].classification = []);
            return newState;
        case ACTIVE_QUESTION_CHANGE_DETECTED:
            newState.activeQuestionChangeDetected = action.status;
            return newState;
        case ACTIVE_QUESTION_IS_SAVED:
            newState.activeQuestionIsSaved = action.status;
            return newState;
        case FLAG_ANSWER:
            newState.activeQuestion.answers[action.answerIndex].flagged = !newState.activeQuestion.answers[action.answerIndex].flagged;
            newState.activeQuestion.answersFlagged = answersFlagged(newState.activeQuestion.answers);
            return newState;
        case FLAG_SELECTED_ANSWER:
            newState.activeAnswers.forEach(answer =>  newState.activeQuestion.answers[answer].flagged = true);
            newState.activeQuestion.answersFlagged = answersFlagged(newState.activeQuestion.answers);
            return newState;
        case UPDATE_QUESTION_NOTE:
            newState.activeQuestion.notes = action.notes;
            return newState;
        case DISCARD_QUESTION_CHANGES:
            newState.activeQuestion = transpileActiveQuestion(newState.selectedQuestions[newState.activeQuestionIndex], newState.questions[newState.selectedQuestions[newState.activeQuestionIndex]]);
            newState.activeAnswers = [...defaultState.activeAnswers];
            return newState;
        case DOWNLOADING_CSV:
            newState.exportingQuestionsCSV = action.status;
            return newState;
        case SETTING_LIVE_ON_DMP:
            newState.settingLiveOnDMP = action.status;
            return newState;
        case REMOVE_QUESTIONS_FROM_QUESTIONS_STATE:
            action.questions.forEach(questionID =>  delete newState.questions[questionID]);
            newState.storedSelectedQuestions = defaultState.storedSelectedQuestions;
            newState.selectedQuestions = defaultState.selectedQuestions;
            newState.pagesSelectedAll = defaultState.pagesSelectedAll;
            return newState;
        case SAVING_QUESTION:
            newState.savingActiveQuestion = action.status;
            return newState;
        case UPDATE_ACTIVE_QUESTION:
            //this is kind of insane but i will try to get the BE to return me the appropriate data next sprint
            let activeQuestionCopy = JSON.parse(JSON.stringify(newState.activeQuestion));
            activeQuestionCopy.answers = newState.activeQuestion.answers.map(answer => ({...answer, classification: answer.classification.map(classification => ({categoryID: classification}))}));
            newState.storedSelectedQuestions[action.questionData.questionID] = activeQuestionCopy;
            return newState;
        default:
            return newState;
    }
};




/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default questionStatus;
