/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { Component } from 'react';
// material
import { SearchBar, Icon } from '@insticator/insticator-ui'; // iui-material
// styles
import injectSheet from 'react-jss';
import styles from 'apps/classification/material/taxonomyeditor/taxonomyeditorsearch/taxonomyeditorsearch_styles';



/* ========== ~~~~~~~~~~ TAXONOMY EDITOR SEARCH : CHILD ~~~~~~~~~~ ========== */
// PRESENTATIONAL COMPONENT
// receives app state & actions as props from parent "_container" component
// this child component handles local state and uses props passed from parent to read/write application state when necessary
class TaxonomyEditorSearch extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected: null,
            searchValue: ''
        };
        this.handleOnChange = this.handleOnChange.bind(this);
    }

    componentDidUpdate(prevProps) {
        if(this.props.searchTerm === '' && prevProps.searchTerm !== this.props.searchTerm) this.setState({searchValue: ''})
        if(!this.props.nothingFound && prevProps.nothingFound) this.handleNext()
    }

    handleNext = () => this.handleScroll(1);

    handlePrevious = () => this.handleScroll(-1);

    handleScroll = (direction) => {
        let currentPosition = null;
        let currentPositionIndex = null;
        if(!!this.props.scrollToCategory && this.props.foundCategoriesIds.includes(this.props.scrollToCategory)) {
            currentPosition = this.props.categoriesFoundByIds[this.props.scrollToCategory];
            currentPositionIndex = this.props.sortedCategoriesPositions.indexOf(currentPosition);
        }
        const targetPosition = this.props.sortedCategoriesPositions[this.getTargetPositionIndex(direction, currentPositionIndex)];
        this.scrollToCategoryByPosition(targetPosition);
    };

    scrollToCategoryByPosition = (targetPosition) => {
        const targetCategoryId = this.props.categoriesFoundByPositions[targetPosition];
        this.props.setScrollToCategory(targetCategoryId);
    };

    getTargetPositionIndex = (direction, currentPositionIndex) => {
        if(currentPositionIndex === null) return 0;
        const adjustedPosition = (currentPositionIndex + direction) % this.props.numCategoriesFound;
        return adjustedPosition >= 0 ? adjustedPosition : this.props.numCategoriesFound + adjustedPosition;
    };

    handleOnChange(searchTerm) {
        this.setState({ searchValue: searchTerm });
        this.debouncedSearch.apply(this, arguments)
    };

    debouncedSearch = this.debounce(this.props.runTaxonomySearch, 750, false);

    debounce(func, wait, immediate) {
        let timeout;
        return function () {
            let context = this;
            let args = arguments;
            let callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(function () {
                timeout = null;
                if(!immediate) {
                    func.apply(context, args)
                }
            }, wait);
            if(callNow) func.apply(context, args)
        }
    };

    clearSearch = () => {
        this.setState({searchValue: ''});
        this.props.runTaxonomySearch('');
        this.props.setScrollToCategory(null);
    };

    handleKeyDown = event => {
        if (event.key === 'Enter' && !this.props.nothingFound && this.props.searchTerm !== '') { this.handleNext(); }
    };

    render() {
        return (
            <div className={`taxonomyeditorsearch ${this.props.classes.taxonomyeditorsearch}`}>
                <div className={'taxonomysearchbar'}>
                    <SearchBar value={this.state.searchValue} placeholder={'Search'} onChange={this.handleOnChange} onKeyDown={this.handleKeyDown}/>
                    <div className={'taxonomycategoriescount'}>
                        <div className={`taxonomycategoriescountcontent`}>
                            { this.props.numCategoriesFound > 0 ? <p>{`${this.props.currentPositionIndex + 1} of ${this.props.numCategoriesFound} found`}</p> : null }
                            { this.props.nothingFound ? <p>{`nothing found`}</p> : null }
                            { this.props.numCategoriesFound > 0 || this.props.nothingFound ? <div className={'clearsearchicon'} onClick={this.clearSearch}><Icon name={'times-circle'} /></div> : null }
                        </div>
                    </div>
                </div>
                <span className={`arrows`}>
	            <span className={`arrow previous disabled-${this.props.numCategoriesFound < 1}`} onClick={this.handlePrevious}><Icon name={`angle-left`} /></span>
	            <span className={`arrow next disabled-${this.props.numCategoriesFound < 1}`} onClick={this.handleNext}><Icon name={`angle-right`} /></span>
            </span>
            </div>
        );
    };
}



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// will be utilized in parent component after being infused with app state & actions
export default injectSheet(styles)(TaxonomyEditorSearch);
