import React from 'react';
//material
import { Group } from "@insticator/insticator-ui";
import AdsTxtColumn from 'apps/sspadstxt/material/adstxtcolumn/adstxtcolumn';

//styles
import styles from 'apps/sspadstxt/pages/sspmanager/sspdetail/sspadstxt/sspadstxt_styles';
import injectSheet from "react-jss";





const SSPAdsTxt = props => {
    const removeDuplicateValues = values => {
        let files = ``;
        let valuesArray = values.split('\n');
        valuesArray.filter((a, b) => valuesArray.indexOf(a) === b).forEach(value => files = files.concat(`${value}`, `\n`));
        return files;
    };
    const updateAdsTxtFiles = (event, adType, fileLength) => props.updateAdsTxtValues(event.target.value, adType, fileLength);
    const renderCombinedShortFile = () => removeDuplicateValues(props.displayAdsTxtValues.short.concat(`\n`,`${props.videoAdsTxtValues.short}`));
    const renderCombinedLongFile = () => removeDuplicateValues(props.displayAdsTxtValues.long.concat(`\n`,`${props.videoAdsTxtValues.long}`));

    return (<Group klass={`sspadstxt ${props.classes.sspadstxt}`} name={'adstxt'} >
                <div className={'full labeltitle'} >Ads.txt</div>
                <Group name={'adstxtcolumns'}>
                    <AdsTxtColumn fileType={'display'} icons={['image']} label={'Display Ads.txt'} updateShortFile={e=>updateAdsTxtFiles(e, 'display', 'short')} shortFile={props.displayAdsTxtValues.short} longFile={props.displayAdsTxtValues.long} updateLongFile={e=>updateAdsTxtFiles(e, 'display', 'long')} editing={true}/>
                    <AdsTxtColumn fileType={'video'} icons={['film']} label={'Video Ads.txt'} updateShortFile={e=>updateAdsTxtFiles(e, 'video', 'short')} shortFile={props.videoAdsTxtValues.short} longFile={props.videoAdsTxtValues.long} updateLongFile={e=>updateAdsTxtFiles(e, 'video', 'long')} editing={true}/>
                    <AdsTxtColumn fileType={'combined'} icons={['image', 'film']} label={'Combined Ads.txt'} shortFile={renderCombinedShortFile()} longFile={renderCombinedLongFile()} editing={true} />
                </Group>
            </Group>)

};

/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(SSPAdsTxt);