/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state
import { updateS2SAdsTxtValues } from 'apps/sspadstxt/state/actions/sspmanagerstatus';
// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import S2SAdsTxt from 'apps/sspadstxt/pages/sspmanager/sspdetail/s2sadstxt/s2sadstxt';


/* ========== ~~~~~~~~~~ SSP ADSTXT: CHILD ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ sspAdsTxtState }) => {
    const { s2SAdsTxtValues } = sspAdsTxtState.sspManagerStatus.newSSPDetails;
    const { isEditMode, savingS2SDetails } = sspAdsTxtState.sspManagerStatus;
    return ({
        s2SAdsTxtValues,
        isEditMode,
        savingS2SDetails
    });
};

// map actions
const mapDispatchToProps = dispatch => ({
    updateS2SAdsTxtValues: (values, adType, fileLength) => dispatch(updateS2SAdsTxtValues(values, adType, fileLength))
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(S2SAdsTxt);
