/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm, centerVertically, pxToEmWithBaseline, replacePXinStringWithEM } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// TAXONOMY EDITOR SEARCH : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    taxonomyeditorsearch: {
        marginTop: pxToEm(34),
        height: pxToEmWithBaseline({ value: 48, fontSizeBaseline: 14 }),
        display: 'flex',
        alignItems: 'center',
        '& .taxonomysearchbar': {
            display: 'flex',
            flex: '1',
            alignItems: 'center',
            position: 'relative',
            height: '100%',
            background: theme.color.supporting.light.swatch,
            transition: theme.animation.general('all'),
            '&:hover': {
                filter: 'brightness(97%)'
            },
            '& .searchbar': {
                flex: '1',
                '& .search': {
                    background: 'none',
                    fontSize: pxToEm(14),
                    '& .field': {
                        color: theme.color.supporting.dark.swatch,
                    }
                }
            },
            '& .taxonomycategoriescount': {
                fontSize: pxToEmWithBaseline({ value: 10, fontSizeBaseline: 16 }),
                marginLeft: pxToEmWithBaseline({ value: 5, fontSizeBaseline: 16 }),
                '& .taxonomycategoriescountcontent': {
                    display: 'flex',
                    alignItems: 'center',
                    '& .clearsearchicon': {
                        display: 'block',
                        fontSize: pxToEmWithBaseline({ value: 14, fontSizeBaseline: 10 }),
                        marginRight: pxToEmWithBaseline({ value: 10, fontSizeBaseline: 14 }),
                        marginLeft: pxToEmWithBaseline({ value: 5, fontSizeBaseline: 14 }),
                        '&:hover': {
                            cursor: 'pointer'
                        }
                    },
                    '& p': {
                        display: 'block',
                    }
                }
            },
        },
        '& .arrows': {
            height: '100%',
            '& .arrow': {
                display: 'inline-flex',
                justifyContent: 'center',
                alignContent: 'center',
                alignItems: 'center',
                fontSize: pxToEm(14),
                color: theme.color.supporting.dark.swatch,
                height: '100%',
                width: pxToEmWithBaseline({ value: 35, fontSizeBaseline: 14 }),
                border: replacePXinStringWithEM('#ECF0F7 1px solid'),
                borderRadius: pxToEmWithBaseline({ value: 4, fontSizeBaseline: 14 }),
                cursor: 'pointer',
                transition: 'all .15s linear 0s',
                '&:hover': {
                    filter: 'brightness(85%)'
                },
                '&.disabled': {
                    '&-true': {
                        // background: convertHexToRGB(theme.color.supporting.dark.swatch, 2),
                        color: theme.color.grey.medium.swatch,
                        cursor: 'default',
                        pointerEvents: 'none'
                    }
                }
            }
        }
    }
});
