/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { Component } from 'react';
// material
import AdsTxtTimeline from 'apps/sspadstxt/material/adstxttimeline/adstxttimeline';
import AdsTxtColumn from 'apps/sspadstxt/material/adstxtcolumn/adstxtcolumn';
import SSPList from 'apps/sspadstxt/pages/adstxt/masteradstxt/ssplist/ssplist';
// insticator material iui
import {
    Page,
    Content,
    Group,
    Title,
    TextAreaField,
    Button,
    LoadState,
    EmptyState,
    Tabs,
    Tab
} from '@insticator/insticator-ui'; // iui-material
//pages (different sections of classification editor page)
//styles
import injectSheet from 'react-jss';
import styles from 'apps/sspadstxt/pages/adstxt/masteradstxt/masteradstxt_styles';



/* ++++++++++ --------------- MASTER ADS.TXT --------------- ++++++++++ */
class MasterAdsTxt extends Component {

    componentDidMount() {
        this.props.getMasterAdsTxtFiles();
    }

    transpileTimeLineData = (data) => {
        return Object.keys(data).reverse().map(version => ({
            version,
            updatedOn: data[version].updatedOn,
            currentVersion: data[version].currentVersion
        }))
    };

    transpileAdsTxtFiles = (arr, type) => {
        let files = ``;
        arr.filter((a, b) => arr.indexOf(a) === b).forEach(value => files = files.concat(`${value}`, `\n`));
        return files
    };

    saveNote = () => {
        this.props.saveMasterFileNote(this.props.selectedVersion, this.props.selectedVersionFiles.versionNotes)
    };

    okoSaveNote = () => {
        this.props.okoSaveMasterFileNote(this.props.okoSelectedVersion, this.props.okoSelectedVersionFiles.versionNotes)
    };

    s2SSaveNote = () => {
        this.props.s2SSaveMasterFileNote(this.props.s2SSelectedVersion, this.props.s2SSelectedVersionFiles.versionNotes)
    };

    renderNote = () => this.props.selectedVersionFiles.versionNotes ? this.props.selectedVersionFiles.versionNotes : '';

    okoRenderNote = () => this.props.okoSelectedVersionFiles.versionNotes ? this.props.okoSelectedVersionFiles.versionNotes : '';

    s2SRenderNote = () => this.props.s2SSelectedVersionFiles.versionNotes ? this.props.s2SSelectedVersionFiles.versionNotes : '';

    renderS2SContent = () => {
        if (this.props.s2SSelectedVersionFiles) {
            return <Group name={'content'}>
                <Group name={'adstxtcolumns'}>
                    <AdsTxtColumn fileType={'display'} icons={['image']} label={'Display Ads.txt'} shortFile={this.transpileAdsTxtFiles(this.props.s2SSelectedVersionFiles.displayAdsTxt.shortValues, 'display')} longFile={this.transpileAdsTxtFiles(this.props.s2SSelectedVersionFiles.displayAdsTxt.longValues, 'display')}/>
                </Group>
                <Group name={'sspandnotes'}>
                    <SSPList list={this.props.s2SMasterFiles[this.props.s2SSelectedVersion].activeSsps}/>
                    <div className={'notes'}>
                        <TextAreaField
                            value={this.s2SRenderNote()}
                            handleChange={e => this.props.s2SUpdateFileNote(e.target.value)}
                            label={'Version Notes'}
                            type={'textarea'}
                            disabled={this.props.s2SSavingNote}
                        />
                        <Button
                            text={'Save'}
                            variant={'system'}
                            type={'action'}
                            action={this.s2SSaveNote}
                            operation={'save'}
                            processing={this.props.s2SSavingNote}
                        />
                    </div>
                </Group>
            </Group>;
        } else {
            return <span></span>;
        }
    }

    renderS2STab = () => {
        if (this.props.s2SCurrentVersion) {
            return <Group name={'timelineandcontent'}>
                <Group name={'timeline'}>
                    <AdsTxtTimeline
                        currentVersion={this.props.s2SCurrentVersion}
                        selectedVersion={this.props.s2SSelectedVersion}
                        data={this.transpileTimeLineData(this.props.s2SMasterFiles)}
                        onSelect={this.props.s2SSelectVersion}
                    />
                </Group>
                {this.renderS2SContent()}
            </Group>;
        } else {
            return <span>No data</span>;
        }
    }

    renderOkoContent = () => {
        if (this.props.okoSelectedVersionFiles) {
            return <Group name={'content'}>
                <Group name={'adstxtcolumns'}>
                    <AdsTxtColumn fileType={'display'} icons={['image']} label={'Display Ads.txt'} shortFile={this.transpileAdsTxtFiles(this.props.okoSelectedVersionFiles.displayAdsTxt.shortValues, 'display')} longFile={this.transpileAdsTxtFiles(this.props.okoSelectedVersionFiles.displayAdsTxt.longValues, 'display')}/>
                    <AdsTxtColumn fileType={'video'} icons={['film']} label={'Video Ads.txt'} shortFile={this.transpileAdsTxtFiles(this.props.okoSelectedVersionFiles.videoAdsTxt.shortValues, 'video')} longFile={this.transpileAdsTxtFiles(this.props.okoSelectedVersionFiles.videoAdsTxt.longValues, 'video')}/>
                    <AdsTxtColumn fileType={'combined'} icons={['image', 'film']} label={'Combined Ads.txt'} shortFile={this.transpileAdsTxtFiles(this.props.okoSelectedVersionFiles.combinedAdsTxt.shortValues, 'combined')} longFile={this.transpileAdsTxtFiles(this.props.okoSelectedVersionFiles.combinedAdsTxt.longValues, 'combined')}/>
                </Group>
                <Group name={'sspandnotes'}>
                    <SSPList list={this.props.okoMasterFiles[this.props.okoSelectedVersion].activeSsps}/>
                    <div className={'notes'}>
                        <TextAreaField
                            value={this.okoRenderNote()}
                            handleChange={e => this.props.okoUpdateFileNote(e.target.value)}
                            label={'Version Notes'}
                            type={'textarea'}
                            disabled={this.props.okoSavingNote}
                        />
                        <Button
                            text={'Save'}
                            variant={'system'}
                            type={'action'}
                            action={this.okoSaveNote}
                            operation={'save'}
                            processing={this.props.okoSavingNote}
                        />
                    </div>
                </Group>
            </Group>;
        } else {
            return <span></span>;
        }
    }

    renderOkoTab = () => {
        if (this.props.okoCurrentVersion) {
            return <Group name={'timelineandcontent'}>
                <Group name={'timeline'}>
                    <AdsTxtTimeline
                        currentVersion={this.props.okoCurrentVersion}
                        selectedVersion={this.props.okoSelectedVersion}
                        data={this.transpileTimeLineData(this.props.okoMasterFiles)}
                        onSelect={this.props.okoSelectVersion}
                    />
                </Group>
                {this.renderOkoContent()}
            </Group>;
        } else {
            return <span>No data</span>;
        }
    }

    render() {
        if(this.props.fetchingMasterFiles) return <LoadState />;
        if(!this.props.masterFilesReceived) return <EmptyState />;
        return (
            <Page klass={`masteradstxt ${this.props.classes.masteradstxt}`} >
                <Content>
                    <Title>
                        <h1>Master Ads.txt Files</h1>
                        <span>Edit SSP pages to update <br/> the master ads.txt files</span>
                    </Title>
                    <Tabs id={`seat-type`}>
                        <Tab id={`insticator`} title={`INSTICATOR`}>
                            <Group name={'timelineandcontent'}>
                                <Group name={'timeline'}>
                                    <AdsTxtTimeline
                                        currentVersion={this.props.currentVersion}
                                        selectedVersion={this.props.selectedVersion}
                                        data={this.transpileTimeLineData(this.props.masterFiles)}
                                        onSelect={this.props.selectVersion}
                                    />
                                </Group>
                                <Group name={'content'}>
                                    <Group name={'adstxtcolumns'}>
                                        <AdsTxtColumn fileType={'display'} icons={['image']} label={'Display Ads.txt'} shortFile={this.transpileAdsTxtFiles(this.props.selectedVersionFiles.displayAdsTxt.shortValues, 'display')} longFile={this.transpileAdsTxtFiles(this.props.selectedVersionFiles.displayAdsTxt.longValues, 'display')}/>
                                        <AdsTxtColumn fileType={'video'} icons={['film']} label={'Video Ads.txt'} shortFile={this.transpileAdsTxtFiles(this.props.selectedVersionFiles.videoAdsTxt.shortValues, 'video')} longFile={this.transpileAdsTxtFiles(this.props.selectedVersionFiles.videoAdsTxt.longValues, 'video')}/>
                                        <AdsTxtColumn fileType={'combined'} icons={['image', 'film']} label={'Combined Ads.txt'} shortFile={this.transpileAdsTxtFiles(this.props.selectedVersionFiles.combinedAdsTxt.shortValues, 'combined')} longFile={this.transpileAdsTxtFiles(this.props.selectedVersionFiles.combinedAdsTxt.longValues, 'combined')}/>
                                    </Group>
                                    <Group name={'sspandnotes'}>
                                        <SSPList list={this.props.masterFiles[this.props.selectedVersion].activeSsps}/>
                                        <div className={'notes'}>
                                            <TextAreaField
                                                value={this.renderNote()}
                                                handleChange={e => this.props.updateFileNote(e.target.value)}
                                                label={'Version Notes'}
                                                type={'textarea'}
                                                disabled={this.props.savingNote}
                                            />
                                            <Button
                                                text={'Save'}
                                                variant={'system'}
                                                type={'action'}
                                                action={this.saveNote}
                                                operation={'save'}
                                                processing={this.props.savingNote}
                                            />
                                        </div>
                                    </Group>
                                </Group>
                            </Group>
                        </Tab>
                        <Tab id={`oko`} title={`OKO`}>
                            {this.renderOkoTab()}
                        </Tab>
                        <Tab id={`s2s`} title={`S2S`}>
                            {this.renderS2STab()}
                        </Tab>
                    </Tabs>
                </Content>
            </Page>
        )
    }
}



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(MasterAdsTxt);
