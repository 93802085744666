/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state

// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import QuestionDetails from 'apps/classification/pages/editor/questiondetails/questiondetails';


/* ========== ~~~~~~~~~~ QUESTIONDETAILS: PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({classificationState}) => {
    const questionStatus = classificationState.questionStatus
    return {
        questionIndex: questionStatus.activeQuestionIndex,
        questionType: questionStatus.activeQuestion.questionType,
        question: questionStatus.activeQuestion.question,
        classified: questionStatus.activeQuestion.classified,
        totalQuestions: questionStatus.selectedQuestions.length
    }
};


/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    null
)(QuestionDetails);