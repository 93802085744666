// imports
import { batchActions } from 'redux-batched-actions';
import { displayErrorMessage, displaySuccessMessage } from '@insticator/insticator-ui';
import { asyncGetMasterAdsTxtFiles, asyncUpdateMasterFileNote } from 'apps/sspadstxt/utils/api/masteradstxt';

// constants
export const RECEIVE_MASTER_ADSTXT_FILES = 'RECEIVE_MASTER_ADSTXT_FILES ';
export const FETCHING_MASTER_ADSTXT_FILES = 'FETCHING_MASTER_ADSTXT_FILES';
export const UPDATE_MASTER_FILE_NOTE = 'UPDATE_MASTER_FILE_NOTE';
export const SAVING_MASTER_FILE_NOTE = 'SAVING_MASTER_FILE_NOTE';
export const SELECT_VERSION = 'SELECT_VERSION';
export const OKO_UPDATE_MASTER_FILE_NOTE = 'OKO_UPDATE_MASTER_FILE_NOTE';
export const OKO_SAVING_MASTER_FILE_NOTE = 'OKO_SAVING_MASTER_FILE_NOTE';
export const OKO_SELECT_VERSION = 'OKO_SELECT_VERSION';
export const S2S_UPDATE_MASTER_FILE_NOTE = 'S2S_UPDATE_MASTER_FILE_NOTE';
export const S2S_SAVING_MASTER_FILE_NOTE = 'S2S_SAVING_MASTER_FILE_NOTE';
export const S2S_SELECT_VERSION = 'S2S_SELECT_VERSION';

// actions
const receiveMasterFiles = data => ({
    type: RECEIVE_MASTER_ADSTXT_FILES,
    data
});

const fetchingMAsterAdsTxtFiles = status => ({
   type: FETCHING_MASTER_ADSTXT_FILES,
   status
});

//used in component
export const updateFileNote = note => dispatch => dispatch({
    type: UPDATE_MASTER_FILE_NOTE,
    note
});

export const okoUpdateFileNote = note => dispatch => dispatch({
    type: OKO_UPDATE_MASTER_FILE_NOTE,
    note
});

export const s2SUpdateFileNote = note => dispatch => dispatch({
    type: S2S_UPDATE_MASTER_FILE_NOTE,
    note
});

const savingMasterFileNote = status => ({
   type: SAVING_MASTER_FILE_NOTE,
   status
});

const okoSavingMasterFileNote = status => ({
    type: OKO_SAVING_MASTER_FILE_NOTE,
    status
});

const s2SSavingMasterFileNote = status => ({
    type: S2S_SAVING_MASTER_FILE_NOTE,
    status
});
//used in component
export const selectVersion = version => dispatch => dispatch({
    type: SELECT_VERSION,
    version
});

export const okoSelectVersion = version => dispatch => dispatch({
    type: OKO_SELECT_VERSION,
    version
});

export const s2SSelectVersion = version => dispatch => dispatch({
    type: S2S_SELECT_VERSION,
    version
});

// batched actions
const fetchingMasterFilesFail = () => batchActions([fetchingMAsterAdsTxtFiles(false), displayErrorMessage('There was an issue fetching your Master Ads.txt files.')]);

const fetchingMasterFilesSuccess = data => batchActions([fetchingMAsterAdsTxtFiles(false), receiveMasterFiles(data)]);

const savingMasterFileNoteFail = () => batchActions([savingMasterFileNote(false), displayErrorMessage('There was an issue saving your file note.')]);

const savingMasterFileNoteSuccess = () => batchActions([savingMasterFileNote(false), displaySuccessMessage('We have successfully saved your note.')]);

const okoSavingMasterFileNoteFail = () => batchActions([okoSavingMasterFileNote(false), displayErrorMessage('There was an issue saving your file note.')]);

const okoSavingMasterFileNoteSuccess = () => batchActions([okoSavingMasterFileNote(false), displaySuccessMessage('We have successfully saved your note.')]);

const s2SSavingMasterFileNoteFail = () => batchActions([s2SSavingMasterFileNote(false), displayErrorMessage('There was an issue saving your file note.')]);

const s2SSavingMasterFileNoteSuccess = () => batchActions([s2SSavingMasterFileNote(false), displaySuccessMessage('We have successfully saved your note.')]);

// thunks
export const getMasterAdsTxtFiles = () => dispatch => {
    dispatch(fetchingMAsterAdsTxtFiles(true));
    asyncGetMasterAdsTxtFiles()
        .then(response => dispatch(fetchingMasterFilesSuccess(response.data)))
        .catch(err => console.log(err) || dispatch(fetchingMasterFilesFail()))
};

export const saveMasterFileNote = (versionID, note) => dispatch => {
    dispatch(savingMasterFileNote(true));
    asyncUpdateMasterFileNote(versionID, note, "INSTICATOR")
        .then(response => dispatch(savingMasterFileNoteSuccess()))
        .catch(err => console.log(err) || dispatch(savingMasterFileNoteFail()))
};

export const okoSaveMasterFileNote = (versionID, note) => dispatch => {
    dispatch(okoSavingMasterFileNote(true));
    asyncUpdateMasterFileNote(versionID, note, "OKO")
        .then(response => dispatch(okoSavingMasterFileNoteSuccess()))
        .catch(err => console.log(err) || dispatch(okoSavingMasterFileNoteFail()))
};

export const s2SSaveMasterFileNote = (versionID, note) => dispatch => {
    dispatch(s2SSavingMasterFileNote(true));
    asyncUpdateMasterFileNote(versionID, note, "S2S")
        .then(response => dispatch(s2SSavingMasterFileNoteSuccess()))
        .catch(err => console.log(err) || dispatch(s2SSavingMasterFileNoteFail()))
};
