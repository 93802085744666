/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import {pxToEm} from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// SSPS LIST: STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    sspslist: {
        '& .searchaddssp': {
            display: 'flex',
            justifyContent: 'space-between',
            '& .searchfilter': {
                display: 'flex',
                alignItems: 'flex-end',
                marginBottom: pxToEm(34),
                marginRight: pxToEm(20),
                flex: 1,
                '& .filtermenu': {
                    display: 'flex',
                },
                '& .searchbar': {
                    width: '100%',
                    maxWidth: pxToEm(655),
                    flex: 1,
                    marginLeft: pxToEm(10)
                },
                '& .paginationsearchfilter': {
                    width: '100%'
                },
            }
        },
        '& .table': {
            fontWeight: 'normal',
            '& .cta': {
                color: theme.color.primary.medium.swatch
            }
        },
        '& .numberofssps': {
            fontSize: pxToEm(24),
            fontWeight: 'bold',
            marginBottom: pxToEm(20)
        }
    }
});
