/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { theme, pxToEm, pxToRem, replacePXinStringWithEM, disable } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- ADD PRODUCTS : STYLES --------------- ++++++++++ */
const toolbarControlOnState = { filter: 'brightness(93%)', cursor: 'default', pointerEvents: 'none' };
export default theme => ({
    addproducts: {
        // add effect to the page when we're inpsecting
        '&.inspectorEnabled-true': {
            boxShadow: replacePXinStringWithEM('inset 0px 0px 59px 0px rgba(20,148,244,0.75)'),
            transition: 'box-shadow .1s ease-out 0s'
        },

        // ----- MOCKUP ----- //
        '& #mockup': {
            position: 'relative',
            zIndex: '1',
            width: '100%',
            height: '100%',
            '& iframe': {
                border: '0',
                width: '100%',
                height: '100%'
            }
        },

        // ----- TOOLBAR ----- //
        '& #toolbar': {
            // start toolbar in bottom right corner (so as to not overlap important visual of mockup on load)
            position: 'fixed',
            zIndex: '2',
            width: pxToEm(500),
            height: pxToEm(300), // inline style in component that gets updated via user control
            transition: 'all .15s linear 0s',

            // toolbar positions
            '&.position': {
                '&-top-left': {
                    top: pxToEm(80), left: '0', bottom: 'auto', right: 'auto',
                    '& .mover.top-left': { ...toolbarControlOnState }
                },
                '&-top-right': {
                    top: pxToEm(80), left: 'auto', right: '0', bottom: 'auto',
                    '& .mover.top-right': { ...toolbarControlOnState }
                },
                '&-bottom-left': {
                    top: 'auto', left: '0', bottom: '0', right: 'auto',
                    '& .mover.bottom-left': { ...toolbarControlOnState }
                },
                '&-bottom-right': {
                    top: 'auto', left: 'auto', bottom: '0', right: '0',
                    '& .mover.bottom-right': { ...toolbarControlOnState }
                }
            },

            // toolbar sizes
            '&.size': {
                '&-xsmall': { width: pxToEm(415), height: pxToEm(250), '& .sizer.decrease, & .sizer.xsmall': { ...toolbarControlOnState } },
                '&-small': { width: pxToEm(425), height: pxToEm(375), '& .sizer.small': { ...toolbarControlOnState } },
                '&-medium': { width: pxToEm(450), height: pxToEm(500), '& .sizer.medium': { ...toolbarControlOnState } },
                '&-large': { width: pxToEm(550), height: pxToEm(625), '& .sizer.large': { ...toolbarControlOnState } },
                '&-xlarge': { width: pxToEm(650), height: pxToEm(740), '& .sizer.increase, & .sizer.xlarge': { ...toolbarControlOnState } }
            },

            // toolbar contents
            '& #mockuptoolbar': {
                background: "#fff",
                border: replacePXinStringWithEM(`3px solid ${theme.color.primary.medium.swatch}`),
                borderRadius: pxToEm(10),
                boxShadow: theme.boxShadow.highlightCard,
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                height: '100%',

                // title 
                '& .title': {
                    background: theme.color.primary.medium.swatch,
                    padding: replacePXinStringWithEM('10px 10px 10px'),
                    // height: pxToEm(66),
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    '& h2': {
                        color: '#fff',
                        fontSize: pxToEm(18),
                        marginBottom: '0'
                    },
                    '& .ctas': {
                        '& .cta': {
                            marginRight: pxToEm(7),
                            fontSize: pxToEm(12),
                            width: pxToEm(150),
                            '&:last-child': {
                                marginRight: '0'
                            }
                        }
                    }
                },

                // toolbar controls - movers and sizers
                '& #controlbar': {
                    background: '#80acce',
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderBottom: `1px solid #badffb`,
                    '& .toolbarcontrol': {
                        background: theme.color.primary.light.swatch,
                        borderRight: `1px solid #badffb`,
                        borderLeft: `1px solid #badffb`,
                        color: theme.color.primary.medium.swatch,
                        cursor: 'pointer',
                        width: pxToEm(30),
                        height: pxToEm(30),
                        // borderRadius: '100%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        transition: 'all .15s linear 0s',
                        '&:hover': { filter: 'brightness(93%)' },
                        '& .icon': { fontSize: '75%' }
                    },
                    '& .sizers': {
                        display: 'flex',
                        '& .incrementers': {
                            display: 'flex' //'none'
                        },
                        '& .directsize': {
                            display: 'none', //'flex'
                            '& .toolbarcontrol': {
                                width: pxToEm(55),
                                '& span': {
                                    fontSize: pxToEm(10)
                                }
                            }
                        }
                    },
                    '& .movers': {
                        display: 'flex',
                        '& .movers-group': {
                            display: 'flex'
                        },
                        '& .mover': {
                            '&.top-left': { '& .icon': { transform: 'rotate(-45deg)' } },
                            '&.top-right': { '& .icon': { transform: 'rotate(45deg)' } },
                            '&.bottom-left': { '& .icon': { transform: 'rotate(-145deg)' } },
                            '&.bottom-right': { '& .icon': { transform: 'rotate(145deg)' } },
                        }
                    }
                },

                // controls
                '& .controls': {
                    flex: '1',
                    overflow: 'auto',
                    padding: pxToEm(20),
                    position: 'relative',
                    '& .copyfieldName-mockupurl': {
                        marginRight: pxToEm(3)
                    },
                    '& .itemlistnodata':{
                      marginTop: pxToEm(10)
                    },
                    '&.disabled-true': {
                        extend: disable
                    }
                }
            }
        }
    }
});
