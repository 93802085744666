// UTILS
import { batchActions } from 'redux-batched-actions';
import { displayErrorMessage, displaySuccessMessage } from '@insticator/insticator-ui';
import { asyncGetCommentRequestData, asyncUpdateApprovalRequestStatus } from 'apps/approvals/utils/api/approvalpage'

//CONSTANTS
export const RECEIVE_APPROVAL_REQUESTS = 'RECEIVE_APPROVAL_REQUESTS';
export const FETCHING_APPROVAL_REQUESTS = 'FETCHING_APPROVAL_REQUESTS';
export const UPDATE_REQUEST_STATUS = 'UPDATE_REQUEST_STATUS';
export const UPDATING_REQUEST_STATUS = 'UPDATING_REQUEST_STATUS';

export const CLEAR_APPROVALS = 'CLEAR_APPROVALS';

//BATCHED ACTIONS
const fetchApprovalRequestDataFail = () => batchActions([displayErrorMessage('There was an issue fetching comment approval requests'), fetchingApprovalRequestData(false)]);
const fetchApprovalRequestDataSuccess = data => batchActions([receiveApprovalRequestData(data), fetchingApprovalRequestData(false)]);
const updateRequestStatusFail = UUID => batchActions([updatingRequestStatus(UUID), displayErrorMessage('There was an issue updating the request status for this publisher')]);
const updateRequestStatusSuccess = (data, UUID) => batchActions([updateRequestStatus(data, UUID), updatingRequestStatus(UUID), displaySuccessMessage('The request status for this publisher was successfully updated')]);

//ACTIONS
const fetchingApprovalRequestData = status => ({
    type: FETCHING_APPROVAL_REQUESTS,
    status
});

const receiveApprovalRequestData = data => ({
    type: RECEIVE_APPROVAL_REQUESTS,
    currentPage: data.currentPage,
    totalPages: data.totalPages,
    totalRequests: data.totalRequests,
    tableData: data.data
});

const updateRequestStatus = (data, UUID) => ({
    type: UPDATE_REQUEST_STATUS,
    rowData: data,
    UUID
});

const updatingRequestStatus = (UUID, requestStatus) => ({
    type: UPDATING_REQUEST_STATUS,
    UUID,
    requestStatus
});

export const clearApprovalsState = () => ({
    type: CLEAR_APPROVALS
})

//THUNKS
export const fetchApprovalRequestData = ({ requestStatus, productType, pageNumber, approvalType }) => dispatch => {
    dispatch(fetchingApprovalRequestData(true));
    asyncGetCommentRequestData({ requestStatus, productType, pageNumber, approvalType })
        .then(response => dispatch(fetchApprovalRequestDataSuccess(response.data)))
        .catch(err => console.log(err) || dispatch(fetchApprovalRequestDataFail()))
};

export const updateApprovalRequestStatus = (UUID, requestStatus) => dispatch => {
    dispatch(updatingRequestStatus(UUID, requestStatus));
    asyncUpdateApprovalRequestStatus(UUID, requestStatus)
        .then(response => dispatch(updateRequestStatusSuccess(response.data, UUID)))
        .catch(err => console.log(err) || dispatch(updateRequestStatusFail(UUID)))
};


