/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { Component } from 'react';
// material
import { Page, Content, Fade } from '@insticator/insticator-ui'; // iui-material
//pages (different sections of classification editor page)
import QuestionDetails from 'apps/classification/pages/editor/questiondetails/questiondetails_container';
import QuestionAnswers from 'apps/classification/pages/editor/questionanswers/questionanswers_container';
import AnswerControls from 'apps/classification/pages/editor/answercontrols/answercontrols_container';
import PageControls from 'apps/classification/pages/editor/pagecontrols/pagecontrols_container';
//styles
import injectSheet from 'react-jss';
import styles from 'apps/classification/pages/editor/editor_styles';

//REDUX-DEPENDENT FUNCTIONALITY HAS BEEN COMMENTED OUT IN IMPORTED SUB-SECTIONS OF EDITOR PAGE UNTIL COMPLETE
//PLACEHOLDER VALUES EXIST IN PLACE FOR UI TESTING PURPOSES



/* ++++++++++ --------------- EDITOR --------------- ++++++++++ */
class Editor extends Component {
    componentDidMount() {
        if(!this.props.showTaxonomyEditor || !this.props.classifyPermitted) this.props.triggerTaxonomyEditor({ showEditor: true, editMode: false, classifyPermitted: true });
    }
    componentWillUnmount() {
        this.props.triggerTaxonomyEditor({ showEditor: false, editMode: false, classifyPermitted: false });
    }
    render() {
        return <Page klass={`editor ${this.props.classes.editor}`}>
            <Content>
                <div className={'editorcontent'}>
                    <QuestionDetails/>
                    <QuestionAnswers/>
                </div>
                <div className={'controlbar'}>
                    <Fade marginTop={'0'} />
                    <AnswerControls/>
                    <PageControls/>
                </div>
            </Content>
        </Page>
    }

}



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(Editor);
