/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// SSP STATUS INDICATOR : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    statusindicator: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: pxToEm(24),
        width: pxToEm(100),
        borderRadius: pxToEm(4),
        textTracks: 'capitalize',
        color: theme.color.primary.medium.contrast,
        background: theme.color.neutral.dark.swatch,
        '&.status-ACTIVE': {
            background: theme.color.secondary.medium.swatch
        },
        '&.status-TESTING': {
            background: theme.color.accent.medium.swatch
        }
    }
});
