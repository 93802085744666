/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state
import { dispatchWithChangeDetection, nextActiveQuestion, updateQuestionNote, saveQuestion, discardQuestionChanges } from 'apps/classification/state/actions/questionstatus';
// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import PageControls from 'apps/classification/pages/editor/pagecontrols/pagecontrols';


/* ========== ~~~~~~~~~~ PAGECONTROLS: PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({classificationState, authStatus}) => {
    const questionStatus = classificationState.questionStatus;
    return {
        totalQuestions: questionStatus.selectedQuestions.length,
        classified: questionStatus.activeQuestion.classified,
        notes: questionStatus.activeQuestion.notes,
        savingQuestion: questionStatus.savingQuestion,
        questionIndex: questionStatus.activeQuestionIndex,
        questionChangeDetected: questionStatus.activeQuestionChangeDetected,
        questionIsSaved: questionStatus.activeQuestionIsSaved,
        activeQuestion: questionStatus.activeQuestion,
        adminUUID: authStatus.adminInformation.adminUUID,
        taxonomyTree: classificationState.taxonomyTree.taxonomy
    }
};

// map actions
const mapDispatchToProps = dispatch => ({
    nextActiveQuestion: (newQuestionIndex) => dispatch(nextActiveQuestion(newQuestionIndex)),
    updateQuestionNote: (notes) => dispatchWithChangeDetection(dispatch, updateQuestionNote(notes)),
    saveQuestion: (questionIndex, questionData) => dispatch(saveQuestion(questionIndex, questionData)),
    discardChanges: () => dispatchWithChangeDetection(dispatch, discardQuestionChanges(), false)
});


/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PageControls);
