/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state
import { getSites, getSiteDetails, getSiteSSPDetails, unmountPublisherDetails } from 'apps/sspadstxt/state/actions/publishermanagerstatus';
// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import PublisherDetail from 'apps/sspadstxt/pages/publishermanager/publisherdetail/publisherdetail';
import publisherManagerStatus from "../../../state/reducers/publishermanagerstatus";


/* ========== ~~~~~~~~~~ PUBLISHER DETAIL: PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ authStatus, sessionStatus, sspAdsTxtState }) => ({
    accountUUID: sessionStatus.accountUUID,
    sites: sspAdsTxtState.publisherManagerStatus.sites,
    selectedSite: sspAdsTxtState.publisherManagerStatus.selectedSite,
    loading: sspAdsTxtState.publisherManagerStatus.loading,
    adUUID: sspAdsTxtState.publisherManagerStatus.adUUID,
    migratedAdvertisement: sspAdsTxtState.publisherManagerStatus.migratedAdvertisement,
    siteDetailsReceived: sspAdsTxtState.publisherManagerStatus.siteDetailsReceived,
    selectedSiteSSPDetails: sspAdsTxtState.publisherManagerStatus.selectedSiteSSPDetails,
    selectedPubDetails: sspAdsTxtState.publisherManagerStatus.selectedPubDetails,
    currentImplementation: sspAdsTxtState.publisherManagerStatus.currentImplementation,
    preferredImplementation: sspAdsTxtState.publisherManagerStatus.preferredImplementation,
    seatType: sspAdsTxtState.publisherManagerStatus.seatType,
});

// map actions
const mapDispatchToProps = dispatch => ({
    getSites: accountUUID => dispatch(getSites(accountUUID)),
    getSiteDetails: site => dispatch(getSiteDetails(site)),
    getSiteSSPDetails: site => dispatch(getSiteSSPDetails(site)),
    unmountPublisherDetails: () => dispatch(unmountPublisherDetails())
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PublisherDetail);
