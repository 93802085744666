/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state
import { clearDetailState, saveNewSSPDetails, updateSSPDetails, invalidateSSPDetails } from 'apps/sspadstxt/state/actions/sspmanagerstatus';
import { displayModal, sendErrorMessage } from '@insticator/insticator-ui';
// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import SSPDetail from 'apps/sspadstxt/pages/sspmanager/sspdetail/sspdetail';


/* ========== ~~~~~~~~~~ SSP DETAIL: PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ sspAdsTxtState }) => ({
    sspStoredDetails: sspAdsTxtState.sspManagerStatus.sspStoredDetails,
    newSSPDetails: sspAdsTxtState.sspManagerStatus.newSSPDetails,
    savingSSPDetails: sspAdsTxtState.sspManagerStatus.savingSSPDetails,
    isEditMode: sspAdsTxtState.sspManagerStatus.isEditMode
});

// map actions
const mapDispatchToProps = dispatch => ({
    saveNewSSPDetails: data => dispatch(saveNewSSPDetails(data)),
    updateSSPDetails: data => dispatch(updateSSPDetails(data)),
    clearDetailState: () => dispatch(clearDetailState()),
    displayModal: (isVisible, contentToDisplay, showCloseButton) => dispatch(displayModal(isVisible, contentToDisplay, showCloseButton)),
    sendErrorMessage: message => dispatch(sendErrorMessage(message)),
    invalidateSSPDetails: () => dispatch(invalidateSSPDetails())
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SSPDetail);
