/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import { connect } from 'react-redux';
// actions
import { updatePageDetails, changePage } from 'state/actions/app_status';
import { sendErrorMessage } from '@insticator/insticator-ui';
import { manualMock, updateURL, invalidateURL } from 'apps/mockupgenerator/state/actions/addproducts';
// component
import ManualMockup from 'apps/mockupgenerator/pages/manualmockup/manualmockup';



/* ========== ~~~~~~~~~~ MANUAL MOCKUP : CONTAINER ~~~~~~~~~~ ========== */
// map state
const mapStateToProps = ({ appStatus, mockupGeneratorState }) => ({
    currentPage: 'manualmockup',
    currentSite: mockupGeneratorState.siteStatus.currentSite,
    fetchingMockUps: mockupGeneratorState.siteStatus.fetchingMockUps,
    pageUrl: mockupGeneratorState.addProductsStatus.pageUrl,
    fetchingMockUp: mockupGeneratorState.addProductsStatus.fetchingMockUp,
});

// map actions
const mapDispatchToProps = dispatch => ({
    manualMock: (pageURL, siteUUID, pageCode) => dispatch(manualMock(pageURL, siteUUID, pageCode)),
    updateURL: (url) => dispatch(updateURL(url)),
    invalidateURL: (status) => dispatch(invalidateURL(status)),
    sendErrorMessage: (message) => dispatch(sendErrorMessage(message)),
    moveToPage: (location) => {
        dispatch(updatePageDetails(location));
        dispatch(changePage(location))
    }
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManualMockup);
