/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import { connect } from 'react-redux';
// actions
import { updatePageDetails, changePage } from 'state/actions/app_status';
import { sendErrorMessage, displayModal } from '@insticator/insticator-ui';
import { getRedirectURL, deleteMockUp } from 'apps/mockupgenerator/state/actions/sitestatus';
import { getEmbedsAds, saveMockup, setProductsChangeDetected,
    discardProductsChange, setProductInserted, addProductsUnmount,
    removeProducts
} from 'apps/mockupgenerator/state/actions/addproducts';
// component
import AddProductsPage from 'apps/mockupgenerator/pages/addproducts/addproducts';




/* ========== ~~~~~~~~~~ ADD PRODUCTS : CONTAINER ~~~~~~~~~~ ========== */
// map state
const mapStateToProps = ({ appStatus, mockupGeneratorState }) => ({
    currentPage: 'createmockup',
    currentSite: mockupGeneratorState.siteStatus.currentSite,
    enteredPageURL: mockupGeneratorState.siteStatus.enteredPageURL,
    addProductsStatus: mockupGeneratorState.addProductsStatus,
    siteMockUps: mockupGeneratorState.siteStatus.siteMockUps,
    fetchingRedirect: mockupGeneratorState.siteStatus.fetchingRedirect,
    mockupCode: mockupGeneratorState.addProductsStatus.mockupCode,
    savingMockup: mockupGeneratorState.addProductsStatus.savingMockup,
    ads: mockupGeneratorState.addProductsStatus.ads,
    embeds: mockupGeneratorState.addProductsStatus.embeds,
    fetchingEmbedsAds: mockupGeneratorState.addProductsStatus.fetchingEmbedsAds,
    productsChangeDetected: mockupGeneratorState.addProductsStatus.productsChangeDetected,
    updatedProducts: mockupGeneratorState.addProductsStatus.updatedProducts,
    originalProductsState: mockupGeneratorState.addProductsStatus.originalProductsState,
    pageUUID: mockupGeneratorState.addProductsStatus.pageUUID,
    pageUrl: mockupGeneratorState.addProductsStatus.pageUrl.value,
    mockupUrl: mockupGeneratorState.addProductsStatus.mockupUrl,
    deletingMockUp: mockupGeneratorState.siteStatus.deletingMockUp
});

// map actions
const mapDispatchToProps = dispatch => ({
    getEmbedsAds: (pageUUID, source) => dispatch(getEmbedsAds(pageUUID, source)),
	sendErrorMessage: message => dispatch(sendErrorMessage(message)),
    getRedirectURL: (siteUUID, source) => dispatch(getRedirectURL(siteUUID, source)),
    setProductsChangeDetected: (state) => dispatch(setProductsChangeDetected(state)),
    discardProductsChange: () => dispatch(discardProductsChange()),
    saveMockup: (pageUUID, products, pageCode) => dispatch(saveMockup(pageUUID, products, pageCode)),
    setProductInserted: state => dispatch(setProductInserted(state)),
    removeProducts: products => dispatch(removeProducts(products)),
    addProductsUnmount: () => dispatch(addProductsUnmount()),
    displayModal: (isVisible, contentToDisplay, showCloseButton) => dispatch(displayModal(isVisible, contentToDisplay, showCloseButton)),
    deleteMockUp: (pageUUID) => dispatch(deleteMockUp(pageUUID, 'ADD_PRODUCTS')),
    moveToPage: (location) => {
        dispatch(updatePageDetails(location));
        dispatch(changePage(location))
    }
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddProductsPage);
