/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state
import { reportCategoryFound } from 'apps/classification/state/actions/taxonomystatus';

// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import TaxonomyCategory from 'apps/classification/material/taxonomyeditor/taxonomytree/category/taxonomycategory';




/* ========== ~~~~~~~~~~ TAXONOMY CATEGORY: PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ classificationState }, { categoryId }) => ({
    taxonomy: classificationState.taxonomyTree.taxonomy,
    categoryData: classificationState.taxonomyTree.taxonomy[categoryId],
    parentId: classificationState.taxonomyTree.taxonomy[categoryId]['parentId'],
    isActivated: classificationState.taxonomyStatus.activeCategoryId === categoryId,
    searchTerm: !!classificationState.taxonomyStatus.searchTerm && classificationState.taxonomyTree.taxonomy[categoryId]['name'].includes(classificationState.taxonomyStatus.searchTerm) ? classificationState.taxonomyStatus.searchTerm : null,
    invokeScroll: classificationState.taxonomyStatus.scrollToCategory === categoryId,
    isNewCategory: classificationState.taxonomyStatus.newlyCreatedCategoryId === categoryId
});

// map actions
const mapDispatchToProps = dispatch => ({
    reportCategoryFound: categoryId => dispatch(reportCategoryFound(categoryId))
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaxonomyCategory);
