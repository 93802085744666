/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// PUBLISHER DETAIL : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    editclear: {
        display: 'flex',
        '& .editbutton, .deletebutton': {
            marginRight: pxToEm(10),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: theme.color.neutral.light.swatch,
            height: pxToEm(36),
            width: pxToEm(36),
            borderRadius: pxToEm(50),
            cursor: 'pointer',
            transition: 'all .2s ease-in 0s'
        },
        '&.isEditMode-true': {
            '& .editbutton': {
                color: theme.color.neutral.light.swatch,
                background: theme.color.supporting.dark.swatch
            }
        }
    }
});