// IMPORT
import * as taxonomyAPI from 'apps/classification/utils/api/taxonomy';

// UTILS
import { batchActions } from 'redux-batched-actions';
import { displayErrorMessage, displaySuccessMessage } from '@insticator/insticator-ui';
import { closeTaxonomyEditorMessage } from 'apps/classification/state/actions/taxonomystatus';

// CONSTANTS
export const REMOVE_TAXONOMY_CATEGORY = 'REMOVE_TAXONOMY_CATEGORY';

// ACTIONS
const removeTaxonomyCategory = categoryId => ({
    type: REMOVE_TAXONOMY_CATEGORY,
    categoryId
});


//BATCHED ACTIONS
const taxonomyDeleteSuccess = categoryId => batchActions([
    removeTaxonomyCategory(categoryId),
    closeTaxonomyEditorMessage()
]);

const taxonomyDeleteFailure = () => batchActions([
    closeTaxonomyEditorMessage(),
    displayErrorMessage('There was an issue deleting the category')
]);

//THUNKS

//we can use getClassificationQuestions thunk for "Apply Filter" button

export const deleteTaxonomyCategory = categoryId => dispatch => {
    return taxonomyAPI.deleteCategory(categoryId)
        .then(() => dispatch(taxonomyDeleteSuccess(categoryId)))
        .catch(() => dispatch(taxonomyDeleteFailure()))
};
