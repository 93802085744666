/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import * as useripAPIUtil from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export const SET_SESSION_DATA = 'SET_SESSION_DATA';
export const SET_USER_IP = 'SET_USER_IP';
export const RECEIVE_ERRORS = 'RECEIVE_ERRORS';
export const SET_AUTH_DATA = 'SET_AUTH_DATA';

// ACTIONS USED BY THUNKS TO COMMUNICATE WITH A REDUCER
const setUserIP = ip => ({
	type: SET_USER_IP,
	ip
});
const receiveErrors = errors => ({
    type: RECEIVE_ERRORS,
    errors
});

// ACTIONS THAT CAN BE IMPORTED AND USED IN COMPONENTS
export const setSessionData = sessionData => ({
    type: SET_SESSION_DATA,
    sessionData
});

export const setAuthData = response => ({
    type: SET_AUTH_DATA,
    response
});

// THUNKS
export const getUserIP = () => dispatch => (
    useripAPIUtil.getUserIP()
        .then(response => dispatch(setUserIP(JSON.parse(response).ip)),
            err => dispatch(receiveErrors(err)))
);