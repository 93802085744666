/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state
import { createNewCategory, cancelNewCategory, createTaxonomyCategory, setTaxonomyStateProperty} from 'apps/classification/state/actions/taxonomystatus';


// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import NewTaxonomyCategory from 'apps/classification/material/taxonomyeditor/taxonomytree/category/newtaxonomycategory/newtaxonomycategory';
import React from "react";




/* ========== ~~~~~~~~~~ NEW TAXONOMY CATEGORY : PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ classificationState }, { categoryId }) => ({
    // taxonomy: classificationState.taxonomyTree.taxonomy,
    // categoryData: classificationState.taxonomyTree.taxonomy[categoryId],
    // name: classificationState.taxonomyTree.taxonomy[categoryId]['name'],
    // isIAB: classificationState.taxonomyTree.taxonomy[categoryId]['isIAB'],
    // isSelected: classificationState.taxonomyTree.taxonomy[categoryId]['isSelected'],
    // isEditMode: classificationState.taxonomyStatus.isEditMode,
    // isSelected: classificationState.taxonomyStatus.selectedCategories.includes(categoryId)
    parentId: classificationState.taxonomyTree.taxonomy[categoryId]['parentId'],
    isActivated: classificationState.taxonomyStatus.activeCategoryId === categoryId,
    newCategoryParentId: classificationState.taxonomyStatus.newCategoryParentId,
    isChild: classificationState.taxonomyStatus.newCategoryParentId === categoryId
});

// map actions
const mapDispatchToProps = dispatch => ({
    cancelNewCategory: () => dispatch(cancelNewCategory()),
    createTaxonomyCategory: category => dispatch(createTaxonomyCategory(category)),
    setTaxonomyStateProperty: propertyData => dispatch(setTaxonomyStateProperty(propertyData))
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NewTaxonomyCategory);
