/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm, pxToRem, replacePXinStringWithEM, mediaQuery } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- GET STARTED : STYLES --------------- ++++++++++ */
export default theme => ({
    getstarted: {
        '& .name-message': {
            position: 'relative',
            zIndex: '2',
            '& .siteselection': {
                marginTop: pxToEm(40),
                '& .ctas': {
                    justifyContent: 'flex-start',
                    '& .dropdown': { flex: '1', marginRight: pxToRem(7), zIndex: '10' },
                    '& .field': { flex: '1', marginRight: pxToRem(7) },
                    '& .button': { 
                        width: pxToEm(140), 
                        marginRight: pxToRem(7)
                    }
                }
            }
        }
    }
});