/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React from 'react';
// material
import { Button } from '@insticator/insticator-ui'; // iui-material
//styles
import injectSheet from 'react-jss';
import styles from './controlsection_styles';



/* ++++++++++ --------------- CONTROL SECTION --------------- ++++++++++ */
const ControlSection = (props) => {
	const insertedProducts = () => props.products && props.products.filter(product => product.inserted);
	return (
		<div className={`controlsection ${props.classes.controlsection}`} id={`list-${props.id}`}>
			{props.headline &&
				<div className={'headline'}>
					<h3>{props.headline}</h3>
					{props.buttonAction && <Button type={`action`} action={props.buttonAction} operation={'load'} disabled={props.buttonDisabled} removeLoaderIcon={true} processing={props.buttonProcessing} variant={`secondary`} name={'manage'} text={`Manage`} icon={'wrench'} />}
				</div>
			}
	        <div className='contents'>
	        	{props.contents}
	        </div>
	        {props.products &&
		        <div className={'amount'}>
					<div className={'number total'}>Available: {props.products && props.products.length}</div>
					<div className={'number inserted'}>Inserted: {props.products && insertedProducts().length}</div>
				</div>
			}
		</div>
	)
}



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(ControlSection);
