/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React from 'react';
// material
import {Title, TextAreaField} from '@insticator/insticator-ui';
//styles
import injectSheet from 'react-jss';
import styles from 'apps/sspadstxt/material/pubsspmodal/pubsspmodal_styles';



/* ++++++++++ --------------- COMPONENT Pub Detail SSP Modal--------------- ++++++++++ */
const PubSSPModal = props => {
    const formatArrayInToString = arr => {
        let files = ``;
        arr.forEach(value => files = files.concat(`${value}`, `\n`));
        return files
    };
    return (
        <div className={`pubsspmodal ${props.classes.pubsspmodal}`}>
            <Title>{props.title}</Title>
            <TextAreaField readOnly={true} label={props.list1.title} value={formatArrayInToString(props.list1.list)} />
            <TextAreaField readOnly={true} label={props.list2.title} value={formatArrayInToString(props.list2.list)} />
        </div>
    )
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(PubSSPModal);
