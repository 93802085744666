/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import {
    //list
    RECEIVE_SSPS,
    SET_FETCHING_SSPS,
    UPDATE_SSP_MANAGER_FILTER,
    //detail
    RECEIVE_SSP_DETAILS,
    FETCHING_SSP_DETAILS,
    SAVING_SSP_DETAILS,
    UPDATE_EDIT_MODE,
    SET_SAVED_SSP_DETAILS,
    //updatedetails
    UPDATE_NAME,
    UPDATE_ACCOUNT_REP,
    UPDATE_INVENTORY_TYPE,
    UPDATE_APPROVAL_REQUIRED,
    UPDATE_DESCRIPTION,
    UPDATE_NOTE,
    UPDATE_TURNAROUND_TIME,
    UPDATE_APPROVAL_GOOGLE_SHEET,
    UPDATE_REVIEW_TAB,
    UPDATE_SUBMISSION_TAB,
    UPDATE_SINGLE_COLUMN,
    UPDATE_MULTI_COLUMN,
    DELETE_COLUMN,
    ADD_COLUMN,
    UPDATE_ADSTXT_VALUES,
    UPDATE_SSP_STATUS,
    UPDATE_SSP_SEAT_TYPE,
    UPDATE_WHITE_LISTED_GEOS,
    UPDATE_CHECK_AD_IMPLEMENTATION,
    UPDATE_BIDDER_NAME, REMOVE_BIDDER_NAME, ADD_BIDDER_NAME,
    CLEAR_DETAIL_STATE,
    INVALIDATE_SSP_DETAILS
} from 'apps/sspadstxt/state/actions/sspmanagerstatus';
// ^-^ ^-^ ^-^ ^-^ ^-^   SSP MANAGER STATUS: DEFAULT STATE   ^-^ ^-^ ^-^ ^-^ ^-^ //

//helper functions
import { validation } from '@insticator/insticator-ui';
import {
    HAS_S2S_INTEGRATION,
    VALIDATE_ADS_TXT,
    UPDATE_S2S_ADSTXT_VALUES
} from "../actions/sspmanagerstatus";

const transpileAdsTxtFiles = (arr) => {
    let files = ``;
    if(!!arr && arr.length){
        arr.forEach((value, index) => files = index === arr.length - 1 ? files.concat(`${value}`) : files.concat(`${value}`, '\n'));
    }
    return files
};

const formatMultipleValueFields = values => values && values.length ? values.map(val => ({value: val , valid: true})) : [{value: '' , valid: null}];

const transpileSSP = (data = {}) => ({
    name: { value: !!data.name ? data.name : '', valid: !!data.name ? true : null },
    id: { value: !!data.id ? data.id : null, valid: !!data.id ? true : null },
    accountReps: [
        {
            name: { value: !!data.accountReps && !!data.accountReps.length ? data.accountReps[0].name : '', valid: !!data.accountReps && !!data.accountReps.length && !!data.accountReps[0].name ? true : null },
            email: { value: !!data.accountReps && !!data.accountReps.length ? data.accountReps[0].email : '', valid: !!data.accountReps && !!data.accountReps.length &&  !!data.accountReps[0].email ? true : null }
        }], //only send one at a time
    bidderNames: { value: !!data.bidderNames ? data.bidderNames : [], valid: true }, // or empty array
    enabledGeos: { value: !!data.enabledGeos ? data.enabledGeos : [], valid: true }, // or empty array
    checkAdsTxtImplementation: { value: data.checkAdsTxtImplementation !== undefined ? data.checkAdsTxtImplementation : true, valid: true }, // true when it is unchecked or false;
    seatType: { value: !!data.seatType ? data.seatType : 'INSTICATOR', valid: !!data.seatType ? true : null },
    adInventoryType: { value: !!data.adInventoryType ? data.adInventoryType : 'DISPLAY', valid: !!data.adInventoryType ? true : true },
    approvalRequired: { value: data.approvalRequired || false, valid: true },
    hasS2SIntegration: { value: data.hasS2SIntegration || false, valid: true },
    validateAdsTxt: { value: !!data.validateAdsTxt || false, valid: true },
    approvalProcessDescription: { value: !!data.approvalProcessDescription ? data.approvalProcessDescription : '', valid: !!data.approvalProcessDescription ? true : null },
    approvalTurnaroundTime: { value: !!data.approvalTurnaroundTime ? data.approvalTurnaroundTime : 0, valid: !!data.approvalTurnaroundTime ? true : null },
    approvalSheetDetails: {
        link: { value: !!data.approvalSheetDetails  && !!data.approvalSheetDetails.link ? data.approvalSheetDetails.link : '', valid: true },
        submissionTab: { value: !!data.approvalSheetDetails  && !!data.approvalSheetDetails.submissionTab ? data.approvalSheetDetails.submissionTab : '', valid: !!data.approvalSheetDetails  && !!data.approvalSheetDetails.submissionTab ? true : null },
        domainSubmittedColumn: { value: !!data.approvalSheetDetails  && !!data.approvalSheetDetails.domainSubmittedColumn ? data.approvalSheetDetails.domainSubmittedColumn : '', valid: !!data.approvalSheetDetails  && !!data.approvalSheetDetails.domainSubmittedColumn ? true : null },
        dateSubmittedColumn: { value: !!data.approvalSheetDetails  && !!data.approvalSheetDetails.dateSubmittedColumn ? data.approvalSheetDetails.dateSubmittedColumn : '', valid: !!data.approvalSheetDetails  && !!data.approvalSheetDetails.dateSubmittedColumn ? true : null },
        reviewTab: { value: !!data.approvalSheetDetails  && !!data.approvalSheetDetails.reviewTab ? data.approvalSheetDetails.reviewTab : '', valid: !!data.approvalSheetDetails  && !!data.approvalSheetDetails.reviewTab ? true : null },
        domainReviewedColumn: { value: !!data.approvalSheetDetails  && !!data.approvalSheetDetails.domainReviewedColumn ? data.approvalSheetDetails.domainReviewedColumn : '', valid: !!data.approvalSheetDetails  && !!data.approvalSheetDetails.domainReviewedColumn ? true : null },
        dateReviewedColumn: { value: !!data.approvalSheetDetails  && !!data.approvalSheetDetails.dateReviewedColumn ? data.approvalSheetDetails.dateReviewedColumn : '', valid: !!data.approvalSheetDetails  && !!data.approvalSheetDetails.dateReviewedColumn ? true : null },
        approvalStatusColumn: { value: !!data.approvalSheetDetails  && !!data.approvalSheetDetails.approvalStatusColumn ? data.approvalSheetDetails.approvalStatusColumn : '', valid: !!data.approvalSheetDetails  && !!data.approvalSheetDetails.approvalStatusColumn ? true : null },
        reviewNoteColumn: { value: !!data.approvalSheetDetails  && !!data.approvalSheetDetails.reviewNoteColumn ? data.approvalSheetDetails.reviewNoteColumn : '', valid: !!data.approvalSheetDetails  && !!data.approvalSheetDetails.reviewNoteColumn ? true : null },
        rejectionTerms: !!data.approvalSheetDetails && !!data.approvalSheetDetails.rejectionTerms ? formatMultipleValueFields(data.approvalSheetDetails.rejectionTerms) : formatMultipleValueFields(),
        approvalTerms: !!data.approvalSheetDetails && !!data.approvalSheetDetails.approvalTerms ? formatMultipleValueFields(data.approvalSheetDetails.approvalTerms) : formatMultipleValueFields(),
        blacklistedTerms: !!data.approvalSheetDetails && !!data.approvalSheetDetails.blacklistedTerms ? formatMultipleValueFields(data.approvalSheetDetails.blacklistedTerms) : formatMultipleValueFields(),
        pausedTerms: !!data.approvalSheetDetails && !!data.approvalSheetDetails.pausedTerms ? formatMultipleValueFields(data.approvalSheetDetails.pausedTerms) : formatMultipleValueFields()
    },
    displayAdsTxtValues: {
        short: !!data.displayAdsTxtValues && !!data.displayAdsTxtValues.shortValues ? transpileAdsTxtFiles(data.displayAdsTxtValues.shortValues) : '',
        long: !!data.displayAdsTxtValues && !!data.displayAdsTxtValues.longValues ? transpileAdsTxtFiles(data.displayAdsTxtValues.longValues) : '',
    },
    videoAdsTxtValues: {
        short: !!data.videoAdsTxtValues && !!data.videoAdsTxtValues.shortValues ? transpileAdsTxtFiles(data.videoAdsTxtValues.shortValues) : '',
        long: !!data.videoAdsTxtValues && !!data.videoAdsTxtValues.longValues ? transpileAdsTxtFiles(data.videoAdsTxtValues.longValues) : '',
    },
    s2SAdsTxtValues: {
        short: !!data.s2SAdsTxtValues && !!data.s2SAdsTxtValues.shortValues ? transpileAdsTxtFiles(data.s2SAdsTxtValues.shortValues) : '',
        long: !!data.s2SAdsTxtValues && !!data.s2SAdsTxtValues.longValues ? transpileAdsTxtFiles(data.s2SAdsTxtValues.longValues) : '',
    },
    status: { value: !!data.status ? data.status : 'INACTIVE', valid: !!data.status ? true : null },
    note: { value: !!data.note ? data.note : '', valid: true }
});

const invalidateSSP = details => {
    details.name.valid = details.name.value.length >= 1 && details.name.value.length < 100;
    details.approvalSheetDetails.link.valid = !!validation['domain'].test.test(details.approvalSheetDetails.link.value) || !details.approvalSheetDetails.link.value;
    return details;
};

const defaultState = {
    //list
    sspManagerFilter: {
        inventoryTypes: [],     // ['DISPLAY', 'VIDEO'], // Any values from the list
        statuses: [],           // ['INACTIVE', 'TESTING', 'ACTIVE'], // Any values from the list
        notes: [],              // ['HAS_NOTE', 'NO_NOTE'], // Any values from the list
        namePattern: '',        // Find SSPs which names match the given pattern
        currentPage: 1          // Number of the page to retrieve data for
    },
    fetchingSSPs: false,
    ssps: {},
    currentPage : 1,
    totalRows : 0,
    totalPages : 0,
    //detail
    fetchingSSPDetails: null,
    sspStoredDetails: {},
    newSSPDetails: transpileSSP(),
    savingSSPDetails: false,
    isEditMode: false
};

/* ========== ~~~~~~~~~~ SSP MANAGER STATUS : REDUCER ~~~~~~~~~~ ========== */
// REDUCER
// used to manipulate application state
// takes an action, checks its type, based on that performs certain functionality, then returns new slice of state
// utilized in "_container" components
const sspManagerStatus = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        //list
        case RECEIVE_SSPS:
            newState.ssps = action.ssps;
            newState.currentPage = action.paginationData.currentPage;
            newState.totalRows = action.paginationData.totalRows;
            newState.totalPages = action.paginationData.totalPages;
            return newState;

        case SET_FETCHING_SSPS:
            newState.fetchingSSPs = action.status;
            return newState;

        case UPDATE_SSP_MANAGER_FILTER:
            newState.filterSettings[action.filterName] = action.filterData;
            return newState;
        //detail
        case RECEIVE_SSP_DETAILS:
            newState.sspStoredDetails = action.data;
            newState.newSSPDetails = transpileSSP(action.data);
            return newState;
        case FETCHING_SSP_DETAILS:
            newState.fetchingSSPDetails = action.ssp;
            return newState;
        case SAVING_SSP_DETAILS:
            newState.savingSSPDetails = action.status;
            return newState;
        case UPDATE_EDIT_MODE:
            newState.isEditMode = action.status;
            return newState;
        case SET_SAVED_SSP_DETAILS:
            newState.sspStoredDetails = action.data;
            newState.newSSPDetails = transpileSSP(action.data);
            return newState;
        //update details
        case UPDATE_NAME:
            newState.newSSPDetails.name.value = action.name;
            newState.newSSPDetails.name.valid = (action.name.length >= 1 && action.name.length < 100) ? true : null;
            return newState;
        case UPDATE_ACCOUNT_REP:
            newState.newSSPDetails.accountReps[0][action.fieldName].value = action.text;
            return newState;
        case UPDATE_INVENTORY_TYPE:
            newState.newSSPDetails.adInventoryType.value = action.inventoryType;
            return newState;
        case UPDATE_APPROVAL_REQUIRED:
            newState.newSSPDetails.approvalRequired.value = action.status;
            return newState;
        case HAS_S2S_INTEGRATION:
            newState.newSSPDetails.hasS2SIntegration.value = action.hasS2SIntegration;
            return newState;
        case VALIDATE_ADS_TXT:
            newState.newSSPDetails.validateAdsTxt.value = action.validateAdsTxt;
            return newState;
        case UPDATE_DESCRIPTION:
            newState.newSSPDetails.approvalProcessDescription.value = action.description;
            return newState;
        case UPDATE_NOTE:
            newState.newSSPDetails.note.value = action.note;
            return newState;
        case UPDATE_TURNAROUND_TIME:
            newState.newSSPDetails.approvalTurnaroundTime.value = action.time;
            return newState;
        case UPDATE_APPROVAL_GOOGLE_SHEET:
            newState.newSSPDetails.approvalSheetDetails.link.value = action.link;
            newState.newSSPDetails.approvalSheetDetails.link.valid = !!validation['domain'].test.test(action.link) || !action.link ? true : null;
            return newState;
        case UPDATE_REVIEW_TAB:
            newState.newSSPDetails.approvalSheetDetails.reviewTab.value = action.text;
            return newState;
        case UPDATE_SUBMISSION_TAB:
            newState.newSSPDetails.approvalSheetDetails.submissionTab.value = action.text;
            return newState;
        case UPDATE_SINGLE_COLUMN:
            newState.newSSPDetails.approvalSheetDetails[action.fieldName].value = action.value;
            return newState;
        case UPDATE_MULTI_COLUMN:
            newState.newSSPDetails.approvalSheetDetails[action.fieldName][action.index].value = action.value;
            return newState;
        case DELETE_COLUMN:
            newState.newSSPDetails.approvalSheetDetails[action.fieldName].splice([action.index], 1);
            return newState;
        case ADD_COLUMN:
            newState.newSSPDetails.approvalSheetDetails[action.fieldName].push({ value: '', valid: null });
            return newState;
        case UPDATE_ADSTXT_VALUES:
            newState.newSSPDetails[`${action.adType}AdsTxtValues`][action.fileLength] = action.values;
            return newState;
        case UPDATE_S2S_ADSTXT_VALUES:
            newState.newSSPDetails[`s2SAdsTxtValues`][action.fileLength] = action.values;
            return newState;
        case UPDATE_SSP_SEAT_TYPE:
            newState.newSSPDetails.seatType.value = action.seatType;
            return newState;
        case UPDATE_SSP_STATUS:
            newState.newSSPDetails.status.value = action.status;
            return newState;
        case UPDATE_WHITE_LISTED_GEOS:
            const geoSelectedIndex = newState.newSSPDetails.enabledGeos.value.indexOf(action.geoCode);
            geoSelectedIndex >= 0 ? newState.newSSPDetails.enabledGeos.value.splice(geoSelectedIndex, 1) : newState.newSSPDetails.enabledGeos.value.push(action.geoCode);
            return newState;
        case UPDATE_CHECK_AD_IMPLEMENTATION:
            newState.newSSPDetails.checkAdsTxtImplementation.value = !newState.newSSPDetails.checkAdsTxtImplementation.value;
            return newState;
        //    UPDATE_BIDDER_NAME, REMOVE_BIDDER_NAME, ADD_BIDDER_NAME
        case UPDATE_BIDDER_NAME:
            newState.newSSPDetails.bidderNames.value[action.index] = action.name;
            return newState;
        case REMOVE_BIDDER_NAME:
            const filteredNames = newState.newSSPDetails.bidderNames.value.filter(bidderName => bidderName !== newState.newSSPDetails.bidderNames.value[action.index]);
            newState.newSSPDetails.bidderNames.value = [...filteredNames];
                return newState;
        case ADD_BIDDER_NAME:
            newState.newSSPDetails.bidderNames.value.push('');
                return newState;
        case CLEAR_DETAIL_STATE:
            newState = defaultState;
            return newState;
        case INVALIDATE_SSP_DETAILS:
            newState.newSSPDetails = invalidateSSP(newState.newSSPDetails);
            return newState;
        default:
            return newState;
    }
};




/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default sspManagerStatus;
