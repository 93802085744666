/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, {useEffect,useState} from 'react';
import {Route, Switch} from 'react-router';
// structure
import { Division, Body, LoadState, EmptyState } from '@insticator/insticator-ui'; // material
import Header from 'material/structure/header/header_container';
//pages
import AppMenu from 'pages/dashboard/appmenu/appmenu_container';
// apps (each import represents a sub-application of the admin panel)
import Classification from 'apps/classification/pages/dashboard/dashboard_container';
import Approvals from 'apps/approvals/pages/dashboard/dashboard_container';
import SSPAdsTxt from 'apps/sspadstxt/pages/dashboard/dashboard_container';
import MockupGenerator from 'apps/mockupgenerator/pages/dashboard/dashboard_container';
// import GeoBlocking from 'apps/geoblocking/pages/dashboard/dashboard_container';
//utils
import { PrivateRoute } from '@insticator/insticator-ui'; // iui-utils
// styles
import injectSheet from 'react-jss';
import styles from 'pages/dashboard/dashboard_styles';


/* ========== ~~~~~~~~~~ DASHBOARD ~~~~~~~~~~ ========== */
const Dashboard = (props) => {
    const permitCommentingApprovalPage = props.adminInformation.permissions.includes('newAdminPanel.manageProducts');
    // const permitGeoblocking = props.adminInformation.permissions.includes('newAdminPanel.geoBlocking');
    const permitSSPManager = props.adminInformation.permissions.includes('newAdminPanel.sspManager');
    const permitMockUp = props.adminInformation.permissions.includes('newAdminPanel.mockupGenerator');
    if(props.fetchingAdminInformation || !props.adminInfoReceived) return <LoadState />;
    if(props.adminInfoFailed) return <EmptyState />;

    useEffect(() => {
        if(!props.adminInformation.permissions.length) {
            props.isUserLoggedIn();
        }
    },[])

    return (
        <Division name={`dashboard`} klass={props.classes.dashboard}>
            <Header />
            <Body>
                <Switch>
                    {/*Routes to subsections*/}
                    {/*pages not specific to any sub-application*/}
                    <Route exact path='/apps' component={AppMenu} />
                    {/*sub-application*/}
                    <PrivateRoute permitted={permitCommentingApprovalPage} path='/apps/approvals' component={Approvals} />
                    {/*<PrivateRoute permitted path='/apps/classification' component={Classification} />*/}
                    <PrivateRoute permitted={permitSSPManager} path='/apps/sspadstxt' component={SSPAdsTxt} />
                    <PrivateRoute permitted={true} path='/apps/mockupgenerator' component={MockupGenerator} />
                    {/*<PrivateRoute permitted={permitGeoblocking} path='/apps/geoblocking' component={GeoBlocking} />*/}
                </Switch>
            </Body>
        </Division>
    )
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(Dashboard);
