/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import {
	UPDATE_USER_STATUS,
} from 'state/actions/user_status';
import { CLEAR_ALL_STATES } from 'state/actions/auth_status';
// utils
// import { log } from '@insticator/insticator-ui'; // iui-insticator



/* ========== ~~~~~~~~~~  USER STATUS : REDUCER ~~~~~~~~~~ ========== */

// ^-^ ^-^ ^-^ ^-^ ^-^   DEFAULT STATE   ^-^ ^-^ ^-^ ^-^ ^-^ //

const defaultState = { loggedIn: false };


// REDUCER
// used to manipulate application state
// takes an action, checks its type, based on that performs certain functionality, then returns new slice of state
// utilized in "_container" components
const userStatus = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
    	case UPDATE_USER_STATUS:
            newState.loggedIn = action.value;
            return newState;
        case CLEAR_ALL_STATES:
            return defaultState;
        default:
            return state;
    }
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default userStatus;