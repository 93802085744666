/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state
import { setTaxonomyEditorMode, deselectTaxonomyCategories } from 'apps/classification/state/actions/taxonomystatus';

// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import TaxonomyEditorHeader from 'apps/classification/material/taxonomyeditor/taxonomyeditorheader/taxonomyeditorheader';




/* ========== ~~~~~~~~~~ TAXONOMY EDITOR HEADER: PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ authStatus, classificationState }) => ({
    isEditMode: classificationState.taxonomyStatus.isEditMode && authStatus.isCASuperAdmin,
    classifyPermitted: classificationState.taxonomyStatus.classifyPermitted,
    isCASuperAdmin: authStatus.isCASuperAdmin,
    categoriesSelected: classificationState.taxonomyStatus.selectedCategories.length > 0,
});

// map actions
const mapDispatchToProps = dispatch => ({
    setTaxonomyEditorMode: editMode => dispatch(setTaxonomyEditorMode(editMode)),
    deselectTaxonomyCategories: () => dispatch(deselectTaxonomyCategories())
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaxonomyEditorHeader);
