/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { useState } from 'react';
// material
import { Group, Icon, SearchBar } from "@insticator/insticator-ui";
// utils
import { sortArrayOfObjectsAlphabetically } from "@insticator/insticator-ui";
import { countries } from 'utils/api/countries';
// styles
import styles from 'apps/sspadstxt/pages/sspmanager/sspdetail/geoenabling/geoenabling_styles';
import injectSheet from "react-jss";



/* ++++++++++ --------------- GEO ENABLING --------------- ++++++++++ */
const GeoEnabling = props => {
    // item state
    const [items, updateItems] = useState(countries);
    const [filteredItems, updateFilteredItems] = useState(sortArrayOfObjectsAlphabetically(items, 'name'));

    // select item functions
    const handleSelectedItem = (e) => {
        const parentNode = e.target.classList.contains('item') ? e.target : e.target.parentNode.closest('.item');
        const selectedItemName = parentNode.querySelector('.name').innerHTML;
        const getIndex = () => items.findIndex((item => item.name === selectedItemName));
        const geo = items[getIndex()];
        props.updateWhiteListedGeos(geo.code);
    };

    // render item markup functions
    const renderAllItems = (selected) => filteredItems.map(item => 
        <div className={`item ${!!props.enabledGeos.value.includes(item.code) && 'selected'}`} onClick={handleSelectedItem}>
            <span className={'name'}>{item.name}</span>
            <Icon name={'check-circle'} animation={'none'} />
        </div>
    );
    const renderSelectedItems = () => items.map(item => !props.enabledGeos.value.includes(item.code) ? null :
        <div className={'item'} onClick={handleSelectedItem}>
            <Icon name={'check-circle'} animation={'none'} />
            <span className={'name'}>{item.name}</span>
            <Icon name={'times-circle'} animation={'none'} />
        </div>
    );

    // handles search
    const handleSearch = (searchName) => {
        const foundArrayItems = items.filter(item => item.name.toLowerCase().includes(searchName.toLowerCase()));
        updateFilteredItems(foundArrayItems);
    };
    // render
    return (
        <Group klass={`geoenabling ${props.classes.geoenabling}`} name={'geoenabling'} >
            <div className={'full labeltitle'}>Geo Enabling</div>
            <div className={'itemselectorlists'}>
                <div className={'itemselectorlist fulllist'}>
                    <h2><Icon name={'globe-americas'} animation={'none'} /> Full Geos List</h2>
                    <div className={'itemlist'}>
                        <SearchBar type={'search'} placeholder={'Type to search country'} onChange={handleSearch} />
                        <div className={'items'}>
                            {renderAllItems()}
                        </div>
                    </div>
                </div>
                <div className={'itemselectorlist selectedlist'}>
                    <h2><Icon name={'thumbs-up'} animation={'none'} /> Enabled Geos</h2>
                    <div className={'itemlist'}>
                        <div className={'items'}>
                            {renderSelectedItems()}
                        </div>
                    </div>
                </div>
            </div>
        </Group>
    );
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(GeoEnabling);