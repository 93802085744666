/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state

// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import QuestionAnswers from 'apps/classification/pages/editor/questionanswers/questionanswers';


/* ========== ~~~~~~~~~~ QUESTIONANSWERS: PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({classificationState}) => {
    const questionStatus = classificationState.questionStatus
    return {
        answers: questionStatus.activeQuestion.answers,
        selectedAnswers: questionStatus.activeAnswers,
        activeQuestionIndex: questionStatus.activeQuestionIndex,
        selectedQuestions: questionStatus.selectedQuestions,
        //questionData: questionStatus.questions[questionStatus.selectedQuestions[questionStatus.activeQuestionIndex]],
        activeQuestion: questionStatus.activeQuestion
    }
};


/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    null
)(QuestionAnswers);