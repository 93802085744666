/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React from 'react';
//material
import AnswerContainer from 'apps/classification/pages/editor/answercontainer/answercontainer_container';
//styles
import injectSheet from 'react-jss';
import styles from 'apps/classification/pages/editor/questionanswers/questionanswers_styles';



/* ========== ~~~~~~~~~~ QUESTIONANSWERS : CHILD ~~~~~~~~~~ ========== */
// PRESENTATIONAL COMPONENT
// receives app state & actions as props from parent "_container" component
// this child component handles local state and uses props passed from parent to read/write application state when necessary
const QuestionAnswers = (props) => {
    const renderAnswerContainer = () => (
        props.answers.map((answerObject, index) =>
        (<AnswerContainer answerIndex={index} {...answerObject} selected={props.selectedAnswers.includes(index)}/>))
    );
    return (
        <div className={`questionanswers ${props.classes.questionanswers}`}>
             {props.activeQuestion && renderAnswerContainer()}
        </div>
    );
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// will be utilized in parent component after being infused with app state & actions
export default injectSheet(styles)(QuestionAnswers);