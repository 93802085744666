/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state
import {
    updateDescription, updateNote, updateTurnaroundTime,
    updateGoogleApprovalSheetLink, updateReviewTab,
    updateSubmissionTab, updateSingleColumn,
    updateMultiColumn, deleteColumn, updateCheckAdImplementation,
    addColumn } from 'apps/sspadstxt/state/actions/sspmanagerstatus';
// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import SSPApprovalProcess from 'apps/sspadstxt/pages/sspmanager/sspdetail/sspapprovalprocess/sspapprovalprocess';


/* ========== ~~~~~~~~~~ SSP APPROVAL PROCESS: CHILD ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ sspAdsTxtState }) => {

    const { approvalProcessDescription, approvalTurnaroundTime, checkAdsTxtImplementation, note } = sspAdsTxtState.sspManagerStatus.newSSPDetails;
    const {
        link, dateSubmittedColumn,
        dateReviewedColumn, approvalStatusColumn,
        rejectionTerms, approvalTerms,
        reviewNoteColumn, submissionTab,
        reviewTab, domainReviewedColumn,
        domainSubmittedColumn,
        blacklistedTerms, pausedTerms } = sspAdsTxtState.sspManagerStatus.newSSPDetails.approvalSheetDetails;
    const { isEditMode, savingSSPDetails } = sspAdsTxtState.sspManagerStatus;


    return({
        approvalProcessDescription,
        approvalTurnaroundTime,
        note,
        link,
        dateSubmittedColumn,
        dateReviewedColumn,
        approvalStatusColumn,
        rejectionTerms,
        approvalTerms,
        blacklistedTerms,
        pausedTerms,
        reviewNoteColumn,
        submissionTab,
        reviewTab,
        domainReviewedColumn,
        domainSubmittedColumn,
        checkAdsTxtImplementation,
        isEditMode,
        savingSSPDetails,
    });
};

// map actions
const mapDispatchToProps = dispatch => ({
    updateDescription: description => dispatch(updateDescription(description)),
    updateNote: note => dispatch(updateNote(note)),
    updateTurnaroundTime: time => dispatch(updateTurnaroundTime(time)),
    updateGoogleApprovalSheetLink: link => dispatch(updateGoogleApprovalSheetLink(link)),
    updateSubmissionTab: text => dispatch(updateSubmissionTab(text)),
    updateReviewTab: text => dispatch(updateReviewTab(text)),
    updateSingleColumn: (fieldName, value) => dispatch(updateSingleColumn(fieldName, value)),
    updateMultiColumn: (fieldName, index, value) => dispatch(updateMultiColumn(fieldName, index, value)),
    deleteColumn: (fieldName, index) => dispatch(deleteColumn(fieldName, index)),
    updateCheckAdImplementation: () => dispatch(updateCheckAdImplementation()),
    addColumn: fieldName => dispatch(addColumn(fieldName))
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SSPApprovalProcess);
