/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import { connect } from 'react-redux';
// actions
import { sendErrorMessage } from '@insticator/insticator-ui';
import { updatePageDetails, changePage } from 'state/actions/app_status';
import { updateCurrentSite, updateEnteredPageURL, createSite } from 'apps/mockupgenerator/state/actions/sitestatus';
// component
import GetStartedPage from 'apps/mockupgenerator/pages/getstarted/getstarted';



/* ========== ~~~~~~~~~~ GET STARTED : CONTAINER ~~~~~~~~~~ ========== */
// map state
const mapStateToProps = ({ mockupGeneratorState }) => ({
    currentSite: mockupGeneratorState.siteStatus.currentSite,
    siteList: mockupGeneratorState.siteStatus.siteList,
    enteredPageURL: mockupGeneratorState.siteStatus.enteredPageURL,
    creatingSite: mockupGeneratorState.siteStatus.creatingSite
});

// map actions
const mapDispatchToProps = dispatch => ({
    updateCurrentSite: (site) => dispatch(updateCurrentSite(site)),
    updateEnteredPageURL: (url) => dispatch(updateEnteredPageURL(url)),
    createSite: (siteURL, source) => dispatch(createSite(siteURL, source)),
    sendErrorMessage: (message) => dispatch(sendErrorMessage(message)),
    moveToPage: (location) => {
        dispatch(updatePageDetails(location));
        dispatch(changePage(location))
    }
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GetStartedPage);
