//IMPORTS
//ASYNC
import * as geostatusAPI from 'apps/geoblocking/utils/api/geoblockinglist';
//UTILS
import { batchActions } from 'redux-batched-actions';
import { displaySuccessMessage, displayErrorMessage } from '@insticator/insticator-ui';
import {changePage, updatePageDetails} from 'state/actions/app_status';

//CONSTANTS
export const GEOBLOCKLIST_RECEIVED = 'GEOBLOCKLIST_RECEIVED';
export const GEOBLOCKLIST_FETCHING = 'GEOBLOCKLIST_FETCHING';
export const GEOBLOCKLOGS_RECEIVED = 'GEOBLOCKLOGS_RECEIVED';
export const GEOBLOCKLOGS_FETCHING = 'GEOBLOCKLOGS_FETCHING';
export const ACTIVESSPLIST_RECEIVED = 'ACTIVESSPLIST_RECEIVED';
export const ACTIVESSPLIST_FETCHING = 'ACTIVESSPLIST_FETCHING';

//ACTIONS
const geoblocklistReceived = geoblocklist => ({
    type: GEOBLOCKLIST_RECEIVED,
    geoblocklist
});

const geoblocklogsReceived = geoblocklogs => ({
    type: GEOBLOCKLOGS_RECEIVED,
    geoblocklogs
});

const geoblocklistFetchStatus = status => ({
    type: GEOBLOCKLIST_FETCHING,
    status
});

const geoblocklogsFetchStatus = status => ({
    type: GEOBLOCKLOGS_FETCHING,
    status
});

const activessplistFetchStatus = status => ({
    type: ACTIVESSPLIST_FETCHING,
    status
});

const activessplistReceived = activessplist => ({
    type: ACTIVESSPLIST_RECEIVED,
    activessplist
});

const geoblocklistFetchFail = () => batchActions([
    geoblocklistFetchStatus(false),
    displayErrorMessage('There was an issue fetching your geoblock list')
]);

const activessplistFetchFail = () => batchActions([
    activessplistFetchStatus(false),
    displayErrorMessage('There was an issue fetching your active ssp list')
]);

const geoblocklogsFetchFail = () => batchActions([
    geoblocklogsFetchStatus(false),
    displayErrorMessage('There was an issue fetching your geoblock change logs')
]);

const geoblocklistUpdateSuccess = (geoblocklist) => batchActions([
    geoblocklistReceived(geoblocklist),
    displaySuccessMessage('Geoblocklist was updated successfully')
]);

const geoblocklistUpdateFail = () => batchActions([
    displayErrorMessage('There was an issue updating your geoblock list')
]);

//BATCHED ACTIONS
const geoblocklistFetchSuccess = geoblocklist => batchActions([
    geoblocklistReceived(geoblocklist),
    geoblocklistFetchStatus(false)
]);

//BATCHED ACTIONS
const activessplistFetchSuccess = activessplist => batchActions([
    activessplistReceived(activessplist),
    activessplistFetchStatus(false)
]);

const geoblocklogsFetchSuccess = geoblocklogs => batchActions([
    geoblocklogsReceived(geoblocklogs),
    geoblocklogsFetchStatus(false)
]);

//THUNKS
export const getGeoblockingList = () => dispatch => {
    dispatch(geoblocklistFetchStatus(true));
    geostatusAPI.fetchGeoBlockingList()
        .then(response => dispatch(geoblocklistFetchSuccess(response.data)))
        .catch(err => dispatch(geoblocklistFetchFail()))
};

export const getGeoblockingLogs = () => dispatch => {
    dispatch(geoblocklogsFetchStatus(true));
    geostatusAPI.fetchGeoBlockingLogs()
        .then(response => dispatch(geoblocklogsFetchSuccess(response.data)))
        .catch(err => dispatch(geoblocklogsFetchFail()))
};

export const getAllActiveSspList = () => dispatch => {
    dispatch(activessplistFetchStatus( true));
    geostatusAPI.fetchAllActiveSSPs()
        .then(response => dispatch(activessplistFetchSuccess(response.data)))
        .catch(err => dispatch(activessplistFetchFail()))
};

export const updateGeoBlockingList = (geoblocklist) => dispatch => {
    //not batched to prevent error with changePage
    geostatusAPI.updateGeoBlockingList(geoblocklist)
        .then(response => dispatch(geoblocklistUpdateSuccess(response.data)))
        .catch(err => dispatch(geoblocklistUpdateFail()))
};

export const editList = () => dispatch => {
    //not batched to prevent error with changePage
    dispatch(changePage('apps/geoblocking/editor'));
    dispatch(updatePageDetails('apps/geoblocking/editor'));
};
