import {pxToEm} from '@insticator/insticator-ui'; // iui-utils


export default theme => ({
    questionstatusindicator:{
            display: 'flex',
            '& .icon':{
                paddingRight: pxToEm(8)
            },
            '& .name-check': {
                color: theme.color.secondary.dark.swatch
            }
        }
})