/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
import { updatePageDetails, changePage } from 'state/actions/app_status';
import { triggerTaxonomyEditor } from 'apps/classification/state/actions/taxonomystatus';
// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import TaxonomyEditorNavButton from 'apps/classification/material/taxonomyeditor/navbutton/navbutton';



/* ========== ~~~~~~~~~~ CLASSIFICATION NAV CONTROLS : HEADER : PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ classificationState }) => ({
    showTaxonomyEditor: classificationState.taxonomyStatus.showTaxonomyEditor,
    isEditMode: classificationState.taxonomyStatus.isEditMode,
    classifyPermitted: classificationState.taxonomyStatus.classifyPermitted,
    changesDetected: classificationState.taxonomyStatus.changesDetected
});
// map actions
const mapDispatchToProps = dispatch => ({
    moveToPage: (location) => {
        dispatch(updatePageDetails(location));
        dispatch(changePage(location))
    },
    triggerTaxonomyEditor: params => dispatch(triggerTaxonomyEditor(params))
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaxonomyEditorNavButton);
