/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { useState } from 'react';
// material
import { Accordion, Link, Loader, Icon, Title, BodyCopy, CTAs, Button, ListTable, PopupMessage } from '@insticator/insticator-ui'; // iui-material
// data
import { embedData } from '../../../data/data_embeds';
import { adsData } from '../../../data/data_ads';
//styles
import injectSheet from 'react-jss';
import styles from './mockupdetails_styles';



/* ++++++++++ --------------- SITE DETAIL --------------- ++++++++++ */
const MockupDetails = (props) => {
	const noDataMessage = (productType) => {
		return (<div className={'nodatamessage'}>No {productType} included in this mockup yet. <Link name={'addproduct'} type={'action'} variant={'primary'} text={`Place ${productType}`} icon={'arrow-circle-right'} operation={'link'} behavior={'none'} action={handleOpenMockup} iconRight={true} color={'primary'} /></div>);
	};
	const renderProducts = () => {
		const products = props.siteMockUpDetails.products;
		if (Object.keys(products).length === 0) { return noDataMessage('Products'); }
		return Object.keys(products).map(product => {
			return <div className={'embed'}>
				<Link name={'embedlink'}
					  type={'external'}
					  variant={'primary'}
					  text={products[product].name}
					  icon={'edit'}
					  operation={'link'}
					  behavior={'none'}
					  action={products[product].redirectUrl}
					  iconRight={false}
					  color={'primary'} />
			</div>
		});
	};
	const renderAdsData = data => {
		return Object.keys(data).map(ad => ({
			id: ad,
			name: data[ad].name,
			type: data[ad].type, // [display, video]
			device: data[ad].device, // [desktop, mobile]
			unit: data[ad].unit, // [standalone, commenting]
			size: data[ad].size
		}))
	};
	const renderAds = () => {
		const products = props.siteMockUpDetails.adUnits;
		if (Object.keys(products).length === 0) { return noDataMessage('Ads'); }
		return <ListTable name={'ads'} type={'standard'} variant={'slim'} size={'full'} paginationLocation={'bottom'} data={renderAdsData(props.siteMockUpDetails.adUnits)} columnHeads={{"id":"ID","name":"Name","type":"Type","unit":"Unit","size":"Size"}} exportData={''} exportAll={''} rowsPerPage={100} fixedColWidth={0} loading={false} moreDetails={false} />
	};
	const handleOpenMockup = () => props.openMockup(props.page.pageUUID);
	const handleDeleteMockup = () => {
		props.displayModal(true, <PopupMessage
			type={'delete'}
			title={'Are you sure you want to delete this mock up?'}
			buttonPrimary={{ text: 'Yes, delete this mock up', variant: 'action', action: deleteMockUpAction }}
			buttonSecondary={{ text: 'Cancel', variant: 'system', action: () => props.displayModal(false) }}
		/>);
	};
	const deleteMockUpAction = () => {
		props.deleteMockUp(props.page.pageUUID);
		props.displayModal(false);
	};
	const handleToggle = () => {
		if(!props.dataReceived){
			props.getPageExistingMock(props.page.pageUUID, 'mockupdetails');
			return props.activateMockDetails(props.page.pageUUID);
		}
		return props.activateMockDetails(props.page.pageUUID);
	};
	return (
		<div className={`mockupdetails ${props.classes.mockupdetails} deleting-${props.deletingMockUp}`}>
			<Accordion
				title={props.page.name}
				icon={'newspaper'}
				handleLink={handleOpenMockup}
				static={false}
				active={props.active}
				handleToggle={handleToggle}
				nowrap={true}
				contents={
					props.loading ? <Loader/> :
					<div className={'mockupcontents'}>
						<div className={'mockupcontent embeds'}>
							<h4>Products</h4>
							<div className={'mockupdetails'}>
								{renderProducts()}
							</div>
						</div>
						<div className={'mockupcontent ads'}>
							<h4>Ads</h4>
							<div className={'mockupdetails'}>
								{renderAds()}
							</div>
						</div>
						<div className={'view'}>
							<Button name={'deletemockup'} type={'action'} variant={'delete'} text={'Delete Mockup'} operation={'delete'} processing={props.deletingMockUp} behavior={'none'} action={handleDeleteMockup} color={'delete'} />
							<Button name={'viewmockup'} type={'action'} variant={'primary'} text={'View Mockup'} operation={'link'} disabled={props.deletingMockUp} behavior={'none'} action={handleOpenMockup} color={'primary'} />
						</div>
					</div>
				}
			/>
		</div>
	);
};




/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(MockupDetails);
