import axios from 'axios';
import { url } from 'utils/urls'

const withCredentials = true;
const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

const testDataSSPAPPROVALS = {
    "approvals" : {
        "id": {
            "sspId" : 34,
            "sspName" : "OpenX",
            "sspStatus" : "Active", // enum: "INACTIVE", "ACTIVE", "TESTING"
            "accountUUID" : "account_uuid",
            "accountName" : "bSnack Media",
            "siteUUID" : "site uuid",
            "siteURL" : "https://hostpurs.co.uk",
            "adType" : "VIDEO", // enum: "DISPLAY", "VIDEO"
            "approvalProgress" : "PENDING", // enum: "PENDING", "DELAYED", "COMPLETE"
            "approvalStatus" : "N/A" , // enum: "APPROVED", "REJECTED", "N/A"
            "sspNote" : "any String"
        },
        "id2": {
            "sspId" : 34,
            "sspName" : "fOpenX",
            "sspStatus" : "Active", // enum: "INACTIVE", "ACTIVE", "TESTING"
            "accountUUID" : "account_uuid",
            "accountName" : "aSnack Media",
            "siteUUID" : "site uuid",
            "siteURL" : "https://hostpurs.co.uk",
            "adType" : "VIDEO", // enum: "DISPLAY", "VIDEO"
            "approvalProgress" : "PENDING", // enum: "PENDING", "DELAYED", "COMPLETE"
            "approvalStatus" : "N/A" , // enum: "APPROVED", "REJECTED", "N/A"
            "sspNote" : "any String"
        },
        "id3": {
            "sspId" : 34,
            "sspName" : "feefOpenX",
            "sspStatus" : "Active", // enum: "INACTIVE", "ACTIVE", "TESTING"
            "accountUUID" : "account_uuid",
            "accountName" : "rSnack Media",
            "siteUUID" : "site uuid",
            "siteURL" : "https://hostpurs.co.uk",
            "adType" : "VIDEO", // enum: "DISPLAY", "VIDEO"
            "approvalProgress" : "PENDING", // enum: "PENDING", "DELAYED", "COMPLETE"
            "approvalStatus" : "N/A" , // enum: "APPROVED", "REJECTED", "N/A"
            "sspNote" : "any String"
        },
        "id4": {
            "sspId" : 34,
            "sspName" : "eOpenX",
            "sspStatus" : "Active", // enum: "INACTIVE", "ACTIVE", "TESTING"
            "accountUUID" : "account_uuid",
            "accountName" : "eSnack Media",
            "siteUUID" : "site uuid",
            "siteURL" : "https://hostpurs.co.uk",
            "adType" : "VIDEO", // enum: "DISPLAY", "VIDEO"
            "approvalProgress" : "PENDING", // enum: "PENDING", "DELAYED", "COMPLETE"
            "approvalStatus" : "N/A" , // enum: "APPROVED", "REJECTED", "N/A"
            "sspNote" : "any String"
        },
        "id5": {
            "sspId" : 34,
            "sspName" : "dOpenX",
            "sspStatus" : "Active", // enum: "INACTIVE", "ACTIVE", "TESTING"
            "accountUUID" : "account_uuid",
            "accountName" : "fcSnack Media",
            "siteUUID" : "site uuid",
            "siteURL" : "https://hostpurs.co.uk",
            "adType" : "VIDEO", // enum: "DISPLAY", "VIDEO"
            "approvalProgress" : "PENDING", // enum: "PENDING", "DELAYED", "COMPLETE"
            "approvalStatus" : "N/A" , // enum: "APPROVED", "REJECTED", "N/A"
            "sspNote" : "any String"
        },
        "id6": {
            "sspId" : 34,
            "sspName" : "COpenX",
            "sspStatus" : "Active", // enum: "INACTIVE", "ACTIVE", "TESTING"
            "accountUUID" : "account_uuid",
            "accountName" : "popSnack Media",
            "siteUUID" : "site uuid",
            "siteURL" : "https://hostpurs.co.uk",
            "adType" : "VIDEO", // enum: "DISPLAY", "VIDEO"
            "approvalProgress" : "PENDING", // enum: "PENDING", "DELAYED", "COMPLETE"
            "approvalStatus" : "N/A" , // enum: "APPROVED", "REJECTED", "N/A"
            "sspNote" : "any String"
        },
        "id7": {
            "sspId" : 34,
            "sspName" : "bOpenX",
            "sspStatus" : "Active", // enum: "INACTIVE", "ACTIVE", "TESTING"
            "accountUUID" : "account_uuid",
            "accountName" : "eSnack Media",
            "siteUUID" : "site uuid",
            "siteURL" : "https://hostpurs.co.uk",
            "adType" : "VIDEO", // enum: "DISPLAY", "VIDEO"
            "approvalProgress" : "PENDING", // enum: "PENDING", "DELAYED", "COMPLETE"
            "approvalStatus" : "N/A" , // enum: "APPROVED", "REJECTED", "N/A"
            "sspNote" : "any String"
        },
        "id8": {
            "sspId" : 34,
            "sspName" : "aOpenX",
            "sspStatus" : "Active", // enum: "INACTIVE", "ACTIVE", "TESTING"
            "accountUUID" : "account_uuid",
            "accountName" : "rSnack Media",
            "siteUUID" : "site uuid",
            "siteURL" : "https://hostpurs.co.uk",
            "adType" : "VIDEO", // enum: "DISPLAY", "VIDEO"
            "approvalProgress" : "PENDING", // enum: "PENDING", "DELAYED", "COMPLETE"
            "approvalStatus" : "N/A" , // enum: "APPROVED", "REJECTED", "N/A"
            "sspNote" : "any String"
        },
    },
    "currentPage" : 3,
    "totalRows": 50000,
    "totalPages": 1000
};

const testDataSSPStats = {
    "pending" : 35,
    "complete" : 45,
    "delayed" : 20
};

export const asyncGetSSPApprovalData = filters => axios.post(`${url.spm.url}publishers/approvals`, filters, { withCredentials });

export const asyncGetSSPApprovalStats = () => axios.get(`${url.spm.url}publishers/approvals/stats`, { withCredentials });

export const exportSSPApprovalStats = () => axios.get(`${url.spm.url}publishers/approvals/export/all`, { withCredentials });