/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import React, { Component } from 'react';
// material
import Category from 'apps/classification/material/taxonomyeditor/taxonomytree/category/taxonomycategory_container';
import TaxonomyCategoryName from 'apps/classification/material/taxonomyeditor/taxonomytree/category/taxonomycategoryname/taxonomycategoryname_container';
import NewTaxonomyCategory from 'apps/classification/material/taxonomyeditor/taxonomytree/category/newtaxonomycategory/newtaxonomycategory_container';
// styles
import injectSheet from 'react-jss';
import styles from 'apps/classification/material/taxonomyeditor/taxonomytree/category/taxonomycategory_styles';



/* ========== ~~~~~~~~~~ DASHBOARD : CHILD ~~~~~~~~~~ ========== */
// PRESENTATIONAL COMPONENT
// receives app state & actions as props from parent "_container" component
// this child component handles local state and uses props passed from parent to read/write application state when necessary
class TaxonomyCategory extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
    }

    componentDidUpdate(prevProps) {
        if(!prevProps.invokeScroll && this.props.invokeScroll) { this.scrollToMyRef() }
        if(prevProps.searchTerm !== this.props.searchTerm && !!this.props.searchTerm) {
            this.props.reportCategoryFound({ position: this.myRef.offsetTop, categoryId: this.props.categoryId })
        }
    }

    componentDidMount() {
        if(this.props.isNewCategory) { this.scrollToMyRef() }
    }

    shouldComponentUpdate(nextProps) {
        return JSON.stringify(nextProps.categoryData) !== JSON.stringify(this.props.categoryData) ||
            nextProps.isActivated !== this.props.isActivated || nextProps.invokeScroll !== this.props.invokeScroll ||
            nextProps.searchTerm !== this.props.searchTerm
    }

    scrollToMyRef = () => {
        this.props.treeElementRef.scrollTo({
            top: this.myRef.offsetTop,
            behavior: 'auto'
        });
    };

    renderKids = () => {
        if(this.props.categoryData.children.length === 0) return null;
        return this.props.categoryData.children.map(categoryId => <Category key={`${categoryId}+${this.props.level + 1}`} categoryId={categoryId} level={this.props.level + 1} treeElementRef={this.props.treeElementRef}/>)
    };

    render() {
        return (
            <div className={`taxonomycategory taxonomycategory-${this.props.categoryId} ${this.props.classes.taxonomycategory} level-${this.props.level}`} ref={ ref => this.myRef = ref}>
                <TaxonomyCategoryName categoryId={this.props.categoryId} />
                {this.props.isActivated ? <NewTaxonomyCategory categoryId={this.props.categoryId} /> : null}
                {this.renderKids()}
            </div>
        );
    }
}



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// will be utilized in parent component after being infused with app state & actions
export default injectSheet(styles)(TaxonomyCategory);
