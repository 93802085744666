/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
import { withRouter } from 'react-router'
// actions and reducers used to manipulate application state
import { updateCurrentApp } from 'state/actions/app_status';
// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import SSPAdxTxtDashboard from 'apps/sspadstxt/pages/dashboard/dashboard';



/* ========== ~~~~~~~~~~ SSP / ADS.TXT DASHBOARD: PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ appStatus }) => ({
    currentPage: appStatus.currentPage
});

// map actions
const mapDispatchToProps = dispatch => ({
	updateCurrentApp: name => dispatch(updateCurrentApp(name))
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(SSPAdxTxtDashboard));
