/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { Component } from 'react';
import { Redirect, Switch } from 'react-router';
// material
import {
    Page, LoadState, PrivateRoute,
    parseQueryString, hasLocalStorage, clearCookies
} from '@insticator/insticator-ui';
// pages
import GetStartedPage from 'apps/mockupgenerator/pages/getstarted/getstarted_container';
import ManageSitePage from 'apps/mockupgenerator/pages/managesite/managesite_container';
import CreateMockupPage from 'apps/mockupgenerator/pages/createmockup/createmockup_container';
import ManualMockupPage from 'apps/mockupgenerator/pages/manualmockup/manualmockup_container';
import AddProductsPage from 'apps/mockupgenerator/pages/addproducts/addproducts_container';
// styles
import injectSheet from 'react-jss';
import styles from 'apps/mockupgenerator/pages/dashboard/dashboard_styles';



/* ========== ~~~~~~~~~~ MOCKUP GENERATOR DASHBOARD ~~~~~~~~~~ ========== */
class MockupGeneratorDashboard extends Component {
    constructor(props) {
        super(props);
        this.queryParams = parseQueryString(document.location.search);
        this.state = {
            fromPubPortal: this.queryParams.source === 'PUBLISHER_PORTAL'
        }
    }

    componentDidMount() {
        this.props.updateCurrentApp('mockupgenerator');
        //if we are routing directly to add products page we will check for local storage
        // and use this data to populate add products page
        //if no data is present we route to get started
        if(this.props.currentPage === 'apps/mockupgenerator/addproducts' && !!hasLocalStorage('pageData')){
            let storedData = JSON.parse(hasLocalStorage('pageData'));
            this.props.updateCurrentSite(storedData.currentSite);
            this.props.getSiteMockUps(storedData.currentSite.value);
            this.props.getMockUp(storedData.pageUUID);
            this.clearPublisherPortalCookies()
            // console.log(storedData)
        } else if (this.props.currentPage !== '/apps/mockupgenerator/addproducts' && !!hasLocalStorage('pageDAta')){
            window.localStorage.removeItem('pageData')
        }

        if(this.fromPubPortal) this.clearPublisherPortalCookies();

        this.props.getSiteList(this.props.queryParams.siteUUID);
    }

    siteSelected = () => !!this.props.currentSite && !!this.props.currentSite.value;

    clearPublisherPortalCookies = () => {
        clearCookies(['account_uuid', 'user_uuid', 'session_source',
            'site_uuid', 'embed_uuid', 'product_category',
            'show_terms_and_condition', 'agreement_uuid', 'source']);
    };

    handleRedirectFromPubPortal = () => {
        if(this.state.fromPubPortal){
            this.setState({ fromPubPortal: null });
            return '/apps/mockupgenerator/managesite'
        }
    };

    render() {
        if(this.props.fetchingSites || !this.props.siteList) return <LoadState/>;
        return (
            <Page name={`dashboard`} klass={this.props.classes.dashboard}>
                <div id={'main'}>
                    <Switch>
                        <PrivateRoute path='/apps/mockupgenerator/getstarted' permitted={!this.state.fromPubPortal} redirectTo={this.handleRedirectFromPubPortal()} component={GetStartedPage} />
                        <PrivateRoute path='/apps/mockupgenerator/managesite' component={ManageSitePage} permitted={this.siteSelected()} redirectTo={'/apps/mockupgenerator/getstarted'} />
                        <PrivateRoute path='/apps/mockupgenerator/createmockup' component={CreateMockupPage} permitted={this.siteSelected()} redirectTo={'/apps/mockupgenerator/getstarted'} />
                        <PrivateRoute path='/apps/mockupgenerator/manualmockup' component={ManualMockupPage} permitted={this.siteSelected()} redirectTo={'/apps/mockupgenerator/getstarted'} />
                        <PrivateRoute path='/apps/mockupgenerator/addproducts' component={AddProductsPage} permitted={this.siteSelected()} redirectTo={'/apps/mockupgenerator/getstarted'} />
                        <Redirect to='/error/404' />
                    </Switch>
                </div>
            </Page>
        );
    }
}



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(MockupGeneratorDashboard);
