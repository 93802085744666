/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm, disable } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// PUBLISHER DETAIL : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    custom: {
        '& .name-edit': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            '& .field': {
                width: pxToEm(370)
            }
        },
        '& .configurevalues': {
            display: 'flex',
            flexWrap: 'wrap',
            marginTop: pxToEm(20),
            '& .valuehighlighter': {
                width: pxToEm(370),
                height: pxToEm(340),
                overflow: 'scroll',
                marginRight: pxToEm(10),
                marginBottom: pxToEm(10),
                '& .label': {
                    '& .loader': {
                        fontSize: pxToEm(4)
                    }
                }
            },
            '& .lists': {
                display: 'flex',
                flex: 1,
                '& .list-label': {
                    marginTop: pxToEm(20),
                    '& .label': {
                        fontWeight: 'normal',
                        '&.adstxtvalues': {
                            marginLeft: pxToEm(10)
                        }
                    },
                    '& .selectablelist': {
                        '& .gridset': {
                            flexWrap: 'nowrap',
                            maxHeight: pxToEm(275),
                            minWidth: pxToEm(200),
                            overflow: 'scroll',
                            '& .list-item': {
                                marginTop: 0,
                                marginBottom: 0,
                                '&:first-child':{
                                    marginTop: pxToEm(10)
                                },
                                '& .option-text': {
                                    fontSize: pxToEm(14),
                                    opacity: 1
                                },
                                '& .radio': {
                                  transform: 'none'
                                },
                                '& .checkbox': {
                                    height: pxToEm(12),
                                    width: pxToEm(12),
                                    '& .check': {
                                        transform: 'scale(.5)'
                                    }
                                }
                            }
                        }
                    },
                    '& .cta': {
                        width: pxToEm(145),
                        marginTop: pxToEm(10),
                        marginLeft: pxToEm(10)
                    }
                }
            }
        },
        '&.loading-true': {
            ...disable
        },
        '&.edit-false': {
            '& .configurevalues': {
                ...disable,
                filter: 'none'
            },
        },
        '& .filevalues-custom': {
            height: '17.35em',
            width: '21.125em',
            '& .formelement': {
                height: '17.35em',
                width: '21.125em'
            },
            '& .textarea': {
                'white-space': 'pre',
                'overflow-x': 'auto',
                height: '17.35em',
                width: '20.125em'
            }
        }

    }
});
