/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state
import { createTaxonomyCategory } from 'apps/classification/state/actions/taxonomystatus';

// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import EditCTAs from 'apps/classification/material/taxonomyeditor/taxonomyeditorcontrols/variants/editCTAs/editCTAs';



/* ========== ~~~~~~~~~~ EDIT CTAs : PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ classificationState }) => ({
    newCategoryName: classificationState.taxonomyStatus.newCategoryName,
    newCategoryParentId: classificationState.taxonomyStatus.newCategoryParentId,
    taxonomy: classificationState.taxonomyTree.taxonomy,
    topLevelCategories: classificationState.taxonomyTree.topLevelCategories
});

// map actions
const mapDispatchToProps = dispatch => ({
    createTaxonomyCategory: category => dispatch(createTaxonomyCategory(category)),
});


/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditCTAs);
