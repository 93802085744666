/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
import { withRouter } from 'react-router'
// actions and reducers used to manipulate application state
import { fetchApprovalRequestData,  updateApprovalRequestStatus, clearApprovalsState } from 'apps/approvals/state/actions/approvalpagestatus'
// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import ApprovalPage from 'apps/approvals/pages/approvalpage/approvalpage';


/* ========== ~~~~~~~~~~ DASHBOARD: PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ authStatus, appStatus, commentApprovalState }) => ({
    isCASuperAdmin: authStatus.isCASuperAdmin,
    currentRoute: appStatus.currentPage,
    fetchingTableData: commentApprovalState.ApprovalPageStatus.fetchingTableData,
    currentPage: commentApprovalState.ApprovalPageStatus.currentPage,
    totalPages: commentApprovalState.ApprovalPageStatus.totalPages,
    totalRequests: commentApprovalState.ApprovalPageStatus.totalRequests,
    tableData: commentApprovalState.ApprovalPageStatus.tableData,
    updatingRequestStatus: commentApprovalState.ApprovalPageStatus.updatingRequestStatus
});

// map actions
const mapDispatchToProps = dispatch => ({
    fetchApprovalRequestData: ({ requestStatus, productType, pageNumber, approvalType }) => dispatch(fetchApprovalRequestData({ requestStatus, productType, pageNumber, approvalType })),
    updateApprovalRequestStatus: (commentUUID, requestStatus, approvalType) => dispatch(updateApprovalRequestStatus(commentUUID, requestStatus, approvalType)),
    clearApprovalsState: () => dispatch(clearApprovalsState())
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(ApprovalPage));
