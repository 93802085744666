/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import {pxToEm, replacePXinStringWithEM} from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// SSP APPROVALS : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    ssplist: {
        '& .ssplabel': {
            height: pxToEm(24),
            width: '33%',
            marginBottom: pxToEm(20),
            paddingBottom: pxToEm(5),
            borderBottom: '1px solid rgb(192, 201, 220)'
        },
        '& ul':{
            '& li':{
                listStyle: 'none',
                fontSize: pxToEm(14),
                fontWeight: 'normal'
            }
        }
    }
});
