import React from 'react';
//styles
import styles from 'apps/sspadstxt/pages/publishermanager/publisherdetail/editsite/seatindicator/seatindicator_styles';
import injectSheet from "react-jss";





const Seatindicator = props => {
    return (
        <span className={`seatindicator ${props.classes.seatindicator} seat-${props.seatType}`} >
        {!!props.seatType ? props.seatType : 'INSTICATOR'}
        </span>
    )
};

/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(Seatindicator);
