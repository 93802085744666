/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state
import {
    getClassificationQuestions, questionsLiveOrPending,
    selectAllQuestions, unselectAllQuestions,
    selectSingleQuestion, downloadCSV,
    updateSearchTerm, updateSearchTarget,
    setQuestionsLiveOnDMP, editSelection} from 'apps/classification/state/actions/questionstatus';
// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import QuestionManager from 'apps/classification/pages/questionmanager/questionmanager';



/* ========== ~~~~~~~~~~ QuestionManager: PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({appStatus, authStatus, classificationState }) => ({
    currentPage: appStatus.currentPage,
    // questionStatus: classificationState.questionStatus,
    searchTerm: classificationState.questionStatus.searchTerm,
    triggerRefetch: classificationState.questionStatus.triggerRefetch,
    totalPages: classificationState.questionStatus.totalPages,
    settingLiveOnDMP: classificationState.questionStatus.settingLiveOnDMP,
    paginatedPage: classificationState.questionStatus.currentPage,
    searchTarget: classificationState.questionStatus.searchTarget,
    questionsPerPage: classificationState.questionStatus.questionsPerPage,
    totalQuestions: classificationState.questionStatus.totalQuestions,
    questionsReceived: classificationState.questionStatus.questionsReceived,
    questions: classificationState.questionStatus.questions,
    filters: classificationState.questionStatus.filters,
    questionDataFetching: classificationState.questionStatus.questionDataFetching,
    selectedQuestions: classificationState.questionStatus.selectedQuestions,
    isLiveOnDMP: classificationState.questionStatus.isLiveOnDMP,
    exportingQuestionsCSV: classificationState.questionStatus.exportingQuestionsCSV,
    adminInformation: authStatus.adminInformation,
    isCASuperAdmin: authStatus.isCASuperAdmin,
    fellowAdmins: authStatus.fellowAdmins,
    pagesSelectedAll: classificationState.questionStatus.pagesSelectedAll
});

// map actions
const mapDispatchToProps = dispatch => ({
    getClassificationQuestions: data => dispatch(getClassificationQuestions(data)),
    questionsLiveOrPending: status => dispatch(questionsLiveOrPending(status)),
    unselectAllQuestions: () => dispatch(unselectAllQuestions()),
    selectAllQuestions: () => dispatch(selectAllQuestions()),
    selectSingleQuestion: questionID => dispatch(selectSingleQuestion(questionID)),
    downloadCSV: data => dispatch(downloadCSV(data)),
    updateSearchTerm: searchTerm => dispatch(updateSearchTerm(searchTerm)),
    updateSearchTarget: searchTarget => dispatch(updateSearchTarget(searchTarget)),
    setQuestionsLiveOnDMP: questions => dispatch(setQuestionsLiveOnDMP(questions)),
    editSelection: () => dispatch(editSelection())
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(QuestionManager);
