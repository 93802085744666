/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import { connect } from 'react-redux';
// actions
import { updateWhiteListedGeos } from 'apps/sspadstxt/state/actions/sspmanagerstatus';
// component
import GeoEnabling from 'apps/sspadstxt/pages/sspmanager/sspdetail/geoenabling/geoenabling';



/* ========== ~~~~~~~~~~ GEO ENABLING : CONTAINER ~~~~~~~~~~ ========== */
// map state
const mapStateToProps = ({ sspAdsTxtState }) => {
    const { enabledGeos } = sspAdsTxtState.sspManagerStatus.newSSPDetails;
    const { isEditMode, savingSSPDetails } = sspAdsTxtState.sspManagerStatus;
    return ({
        enabledGeos,
        isEditMode,
        savingSSPDetails
    });
};

// map actions
const mapDispatchToProps = dispatch => ({
    updateWhiteListedGeos: (geoCode) => dispatch(updateWhiteListedGeos(geoCode))
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GeoEnabling);
