import React from 'react';
//material
import { TextField, TextAreaField, Group, Tip, CheckboxField } from "@insticator/insticator-ui";
import ColumnData from 'apps/sspadstxt/pages/sspmanager/sspdetail/sspapprovalprocess/columndata/columndata'
//styles
import styles from 'apps/sspadstxt/pages/sspmanager/sspdetail/sspapprovalprocess/sspapprovalprocess_styles';
import injectSheet from "react-jss";





const SSPApprovalProcess = props => {

    return (<Group klass={`sspapprovalprocess ${props.classes.sspapprovalprocess}`} name={'approvalprocess'} >
                <div className={'labeltitle'} >Publisher Approval Process</div>
                <Group name={'descriptiongooglesheet'} >
                    <div className={'descriptionapprovals'} >
                        <TextAreaField type={'textarea'}
                                       label={'Description'}
                                       name={'description'}
                                       value={props.approvalProcessDescription.value}
                                       placeholder={'Type your description here'}
                                       handleChange={e => props.updateDescription(e.target.value)}
                        />
                        <TextField type={'number'}
                                   label={<div>Approval Turnaround Time (Days) <Tip content={'The average amount of time it takes for an SSP to approve a publisher after they have been submitted for review.'} /></div>}
                                   name={'turnaround'}
                                   value={props.approvalTurnaroundTime.value}
                                   placeholder={'2 Days'}
                                   handleChange={e => props.updateTurnaroundTime(e.target.value)}
                        />
                        <TextAreaField type={'textarea'}
                                       label={'SSP note'}
                                       name={'sspnote'}
                                       value={props.note.value}
                                       placeholder={'Type your ssp note here'}
                                       handleChange={e => props.updateNote(e.target.value)}
                        />
                        <CheckboxField label={<span>Exclude from Publisher's ads.txt Status <Tip space={'left'} icon={'question-circle'} content={<p>When the checkbox is selected, this SSP will not be included in the scan job to determine if a Publisher's ads.txt file is up to date.</p>} /></span>} name={'excluedfromstatus'} type={'checkbox'} formName={''} checked={!props.checkAdsTxtImplementation.value} handleChange={props.updateCheckAdImplementation} isList={false} />
                    </div>
                    <div className={'googlesheetinfo'} >
                        <div className={'googlelink'}>
                            <TextField type={'text'}
                                       name={'domain'}
                                       label={'Approval Google Sheet Link'}
                                       required
                                       value={props.link.value}
                                       valid={props.link.valid}
                                       placeholder={'https://googledoc.com'}
                                       handleChange={e => props.updateGoogleApprovalSheetLink(e.target.value)}
                            />
                        </div>
                        <div className={'submittedreview'} >
                            <div className={'submitted'}>
                                <TextField
                                    type={'text'}
                                    label={'Submission tab name'}
                                    value={props.submissionTab.value}
                                    placeholder={'Tab name'}
                                    handleChange={e => props.updateSubmissionTab(e.target.value)}
                                />
                                <ColumnData
                                    labelText={'Domain submitted column'}
                                    infoText={'The column on the SSP Google sheet to indicate the publisher\'s domain'}
                                    handleChange={props.updateSingleColumn}
                                    fieldName={'domainSubmittedColumn'}
                                    value={props.domainSubmittedColumn.value}
                                    placeholder={'A'}
                                    type={'single'} />
                                <ColumnData
                                    labelText={'Date submitted column'}
                                    infoText={'The column on the SSP Google sheet to indicate the date the request for approval was sent to the SSP'}
                                    handleChange={props.updateSingleColumn}
                                    fieldName={'dateSubmittedColumn'}
                                    value={props.dateSubmittedColumn.value}
                                    placeholder={'B'}
                                    type={'single'} />
                            </div>
                            <div className={'review'}>
                                <TextField
                                    type={'text'}
                                    label={<div>Review tab name <Tip content={'If the submission and review tab are the same, enter the same value in both fields'} /></div>}
                                    value={props.reviewTab.value}
                                    placeholder={'Tab name'}
                                    handleChange={e => props.updateReviewTab(e.target.value)}
                                />
                                <ColumnData
                                    labelText={'Domain reviewed column'}
                                    infoText={'If the submission and review tab are the same, enter the same value in both the Domain Submitted and Domain Reviewed fields'}
                                    handleChange={props.updateSingleColumn}
                                    fieldName={'domainReviewedColumn'}
                                    value={props.domainReviewedColumn.value}
                                    placeholder={'C'}
                                    type={'single'} />
                                <ColumnData
                                    labelText={'Date reviewed column'}
                                    infoText={'The column on the SSP Google sheet to indicate the date the request for approval was reviewed by the SSP'}
                                    handleChange={props.updateSingleColumn}
                                    fieldName={'dateReviewedColumn'}
                                    value={props.dateReviewedColumn.value}
                                    placeholder={'D'}
                                    type={'single'} />
                                <ColumnData
                                    labelText={'Approval status column'}
                                    infoText={'The column on the SSP Google sheet to indicate the status of the publisher\'s approval status'}
                                    handleChange={props.updateSingleColumn}
                                    fieldName={'approvalStatusColumn'}
                                    value={props.approvalStatusColumn.value}
                                    placeholder={'E'}
                                    type={'single'} />
                                <ColumnData
                                    labelText={'Rejected value(s)'}
                                    infoText={'Specific term used by the SSP in their approval spreadsheet to reject a publisher\'s URL'}
                                    handleChange={props.updateMultiColumn}
                                    fieldName={'rejectionTerms'}
                                    value={props.rejectionTerms}
                                    placeholder={'F'}
                                    type={'multi'}
                                    deleteColumn={props.deleteColumn}
                                    addColumn={props.addColumn} />
                                <ColumnData
                                    labelText={'Approved value(s)'}
                                    infoText={'Specific term used by the SSP in their approval spreadsheet to approve a publisher\'s URL'}
                                    handleChange={props.updateMultiColumn}
                                    fieldName={'approvalTerms'}
                                    value={props.approvalTerms}
                                    placeholder={'G'}
                                    type={'multi'}
                                    deleteColumn={props.deleteColumn}
                                    addColumn={props.addColumn} />
                                <ColumnData
                                    labelText={'Blacklisted value(s)'}
                                    infoText={'Specific term used by the SSP in their approval spreadsheet to blacklist a publisher\'s URL'}
                                    handleChange={props.updateMultiColumn}
                                    fieldName={'blacklistedTerms'}
                                    value={props.blacklistedTerms}
                                    placeholder={'H'}
                                    type={'multi'}
                                    deleteColumn={props.deleteColumn}
                                    addColumn={props.addColumn} />
                                <ColumnData
                                    labelText={'Paused value(s)'}
                                    infoText={'Specific term used by the SSP in their approval spreadsheet to pause a publisher\'s URL'}
                                    handleChange={props.updateMultiColumn}
                                    fieldName={'pausedTerms'}
                                    value={props.pausedTerms}
                                    placeholder={'I'}
                                    type={'multi'}
                                    deleteColumn={props.deleteColumn}
                                    addColumn={props.addColumn} />
                                <ColumnData
                                    labelText={'Review note column'}
                                    infoText={'The column on the SSP Google sheet to indicate notes that are left by Insticator users or the SSP'}
                                    handleChange={props.updateSingleColumn}
                                    fieldName={'reviewNoteColumn'}
                                    value={props.reviewNoteColumn.value}
                                    placeholder={'J'}
                                    type={'single'} />
                            </div>
                        </div>
                    </div>
                </Group>
            </Group>)

};

/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(SSPApprovalProcess);
