/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm, replacePXinStringWithEM } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- ITEM LIST : STYLES --------------- ++++++++++ */
export default {
    itemlist: {
        overflow: 'auto',
        maxHeight: pxToEm(150)
    }
};
