/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import { connect } from 'react-redux';
// actions
import { sendErrorMessage } from '@insticator/insticator-ui';
import { updatePageDetails, changePage } from 'state/actions/app_status';
import { updateCurrentSite, getSiteMockUps, getRedirectURL } from 'apps/mockupgenerator/state/actions/sitestatus';
import { createMockup, getMockup, updateURL, invalidateURL } from 'apps/mockupgenerator/state/actions/addproducts';
// component
import ManageSitePage from 'apps/mockupgenerator/pages/managesite/managesite';



/* ========== ~~~~~~~~~~ MANAGE SITE : CONTAINER ~~~~~~~~~~ ========== */
// map state
const mapStateToProps = ({ mockupGeneratorState }) => ({
    currentSite: mockupGeneratorState.siteStatus.currentSite,
    siteMockUps: mockupGeneratorState.siteStatus.siteMockUps,
    fetchingMockUps: mockupGeneratorState.siteStatus.fetchingMockUps,
    addProductsStatus: mockupGeneratorState.addProductsStatus,
    pageUrl: mockupGeneratorState.addProductsStatus.pageUrl,
    fetchingRedirect: mockupGeneratorState.siteStatus.fetchingRedirect,
    fetchingMockUp: mockupGeneratorState.addProductsStatus.fetchingMockUp,
    creatingMockup: mockupGeneratorState.addProductsStatus.creatingMockup,
});

// map actions
const mapDispatchToProps = dispatch => ({
    updateCurrentSite: (site) => dispatch(updateCurrentSite(site)),
    updateURL: (url) => dispatch(updateURL(url)),
    invalidateURL: (status) => dispatch(invalidateURL(status)),
    sendErrorMessage: (message) => dispatch(sendErrorMessage(message)),
    getSiteMockUps: (siteUUID) => dispatch(getSiteMockUps(siteUUID)),
    createMockup: (pageURL, siteUUID, pageCode) => dispatch(createMockup(pageURL, siteUUID, pageCode)),
    getMockup: (pageUUID) => dispatch(getMockup(pageUUID)),
    getRedirectURL: (siteUUID, source) => dispatch(getRedirectURL(siteUUID, source)),
    moveToPage: (location) => {
        dispatch(updatePageDetails(location));
        dispatch(changePage(location))
    }
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManageSitePage);
