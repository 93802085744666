export const transpileAuthData = data => {
    const userData = {
        userUUID: !!data.userUUID ? data.userUUID : null,
        permissions: !!data.permissions ? data.permissions : [],
        permissionsReceived: !!data.permissions,
        userEmail: !!data.email ? data.email : null,
        userFirstName: !!data.firstName ? data.firstName : null,
        userLastName: !!data.lastName ? data.lastName : null,
        userPhoneNumber: !!data.phoneNumber ? data.phoneNumber : null
    };
    const accountData =  !data.account_uuid ? {} : {
        accountUUID: data.account_uuid,
        isSignedIn: !!data.account_uuid && !!data.userUUID,
    };
    return { ...userData, ...accountData};
};