/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
import { updatePageDetails, changePage } from 'state/actions/app_status';

import GeoblockingNavControls from 'apps/geoblocking/material/geoblockingnavcontrols/geoblockingnavcontrols';



/* ========== ~~~~~~~~~~ GEOBLOCKING NAV CONTROLS : HEADER : PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ authStatus }) => ({
    adminInformation: authStatus.adminInformation
});
// map actions
const mapDispatchToProps = dispatch => ({
    moveToPage: (location) => {
        dispatch(updatePageDetails(location));
        dispatch(changePage(location))
    },
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GeoblockingNavControls);
