//APU Calls Utils
import * as authAPI from 'utils/api/auth';
import { displayErrorMessage } from '@insticator/insticator-ui'; // iui-state
// utils
import { batchActions } from "redux-batched-actions";
import { clearAutoLogoutTimer, clearCheckInactivityTimer } from '@insticator/insticator-ui'; // iui-utils
import { environment, url } from "@insticator/insticator-ui";


// import action variables
export const CLEAR_ALL_STATES = 'CLEAR_ALL_STATES';
export const SET_AUTH_TOKEN_DATA = 'SET_AUTH_TOKEN_DATA';
export const RECEIVE_ADMIN_PANEL_CREDENTIALS = 'RECEIVE_ADMIN_PANEL_CREDENTIALS';
export const FETCHING_USER_CREDENTIALS = 'FETCHING_USER_CREDENTIALS';
export const FETCHING_USER_ERROR = 'FETCHING_USER_ERROR';
export const RECEIVE_ADMINS = 'RECEIVE_ADMINS';


// ACTIONS
export const clearAllStates = () => ({
    type: CLEAR_ALL_STATES
});
export const setAuthTokenData = data => ({
    type: SET_AUTH_TOKEN_DATA,
    data
});
const receiveUserCredentials = (data) => ({
    type: RECEIVE_ADMIN_PANEL_CREDENTIALS,
    data
});

const fetchingUserCredentials = status => ({
    type: FETCHING_USER_CREDENTIALS,
    status
});

const fetchingUserCredentialsError = () => ({
    type: FETCHING_USER_ERROR
});

const fetchingUserCredentialsFail = () => batchActions([
        fetchingUserCredentials(false),
        fetchingUserCredentialsError()
    ]);

const fetchingUserCredentialsSuccess = data => batchActions([
    receiveUserCredentials(data),
    fetchingUserCredentials(false)
]);

const receiveAdmins = admins => ({
    type: RECEIVE_ADMINS,
    admins
});


// THUNKS
export const signOut = () => dispatch => {
    clearUserSessionData(dispatch);
    // clear the timers on logout
    clearAutoLogoutTimer();
    clearCheckInactivityTimer();

    window.location.href = url.embed.admin.url
};

const clearUserSessionData = (dispatch) => {
    dispatch(clearAllStates());
};

// -------------- ++++++++ -------------- START: CLASSIFICATION -------------- ++++++++ -------------- //

//TODO route to insticator marketing site on fetch fail
export const isUserLoggedIn = () => dispatch => {
    dispatch(fetchingUserCredentials(true));
    authAPI.isUserLoggedIn()
        .then(response => dispatch(fetchingUserCredentialsSuccess(response.data)))
        .catch(err => dispatch(fetchingUserCredentialsFail()))
};

//
export const asyncFetchUsers = () => dispatch => {
    authAPI.getAdmins()
        .then(response => dispatch(receiveAdmins(response.data)))
        .catch(err => dispatch(displayErrorMessage('There was an error fetching your fellow admins information')))
};
