/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm, replacePXinStringWithEM } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// DASHBOARD : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    pagecontrols: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'flex-end',
        '& .notecontainer': {
            flex: '1',
            marginRight: pxToEm(15),
            '& .notestitle': {
                marginBottom: pxToEm(6)
            }
        },
        '& .field': {
            width: '100%', // make it stretch with browser size //pxToEm(270),
            '& .formelement': {
                height: pxToEm(84),
                '& textarea': {
                    resize: 'none'
                }
            },
        },
        '& .pagenavigation': {
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            flexWrap: 'wrap',
            '& .editstate': {
                marginRight: pxToEm(10), // adding more space per mockup
            },
            '& .button': {
                margin: replacePXinStringWithEM('10px 10px 10px 0px'),
                '& .text, & icon': {
                    fontSize: theme.typography.size.xsmall.fontSize,
                }
            },
            '& .changessavedtext': {
                margin: `0 ${pxToEm(15)}`,
                fontSize: theme.typography.size.xsmall.fontSize,
                color: theme.color.secondary.medium.swatch,
            },
            '& .name-discard-changes': {
                width: pxToEm(160)
            },
            '& .name-save-changes': {
                width: pxToEm(120)
            },
            '& .pagenavbutton': {
                width: pxToEm(100)
            },
        },
    }
});