/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state
import { triggerTaxonomyEditor } from 'apps/classification/state/actions/taxonomystatus';

// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import Editor from 'apps/classification/pages/editor/editor';


/* ========== ~~~~~~~~~~ Editor: PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ authStatus, classificationState }) => ({
    showTaxonomyEditor: classificationState.taxonomyStatus.showTaxonomyEditor,
    isEditMode: classificationState.taxonomyStatus.isEditMode && authStatus.isCASuperAdmin,
    changesDetected: classificationState.taxonomyStatus.changesDetected,
    activeAnswers: classificationState.questionStatus.activeAnswers
});

// map actions
const mapDispatchToProps = dispatch => ({
    triggerTaxonomyEditor: params => dispatch(triggerTaxonomyEditor(params))

});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Editor);
