/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state
import {dispatchWithChangeDetection, removeQuestionTaxonomies, removeTaxonomy, flagAnswer, selectSingleAnswer} from 'apps/classification/state/actions/questionstatus';

// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import AnswerContainer from 'apps/classification/pages/editor/answercontainer/answercontainer';



/* ========== ~~~~~~~~~~ AnswerContainer: PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ classificationState }) => ({
    //ADD LISTENER FOR TREE STATE WHEN AVAILABLE (TAXONOMYSTATUS)
    taxonomyTree: classificationState.taxonomyTree.taxonomy,
    taxonomyReceived: classificationState.taxonomyTree.taxonomyReceived,
    activeQuestion: classificationState.questionStatus.activeQuestion
});

// map actions
const mapDispatchToProps = dispatch => ({
    removeAllTaxonomies: (answerIndex) => dispatchWithChangeDetection(dispatch, removeQuestionTaxonomies(answerIndex)),
    removeTaxonomy: (answerIndex, deepestChild, newDeepestChild) => 
                    dispatchWithChangeDetection(dispatch, removeTaxonomy(answerIndex, deepestChild, newDeepestChild)),
    flagAnswer: (answerIndex) => dispatchWithChangeDetection(dispatch, flagAnswer(answerIndex)),
    selectSingleAnswer: (answerIndex) => dispatchWithChangeDetection(dispatch, selectSingleAnswer(answerIndex))
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AnswerContainer);