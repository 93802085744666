//IMPORTS
import {displayErrorMessage, displaySuccessMessage, downloadLink, sendMessage} from '@insticator/insticator-ui';
import { batchActions } from 'redux-batched-actions';
import { updatePageDetails, changePage } from 'state/actions/app_status';
import { asyncGetPublishers, asyncGetSites,
    asyncGetAdminsList, asyncGetSiteSSPDetails,
    asyncGetSiteDetails, asyncUpdateSiteDetails,
    asyncDeleteSiteDetails, asyncGetMasterAdsTxtFileType,
    asyncGetSSPList,
    asyncGetSpecificSSPValues,
    exportPublisherAdsTxt } from 'apps/sspadstxt/utils/api/publishermanager';

//CONSTANTS
//list
export const RECEIVE_PUBLISHERS = 'RECEIVE_PUBLISHERS';
export const FETCHING_PUBLISHERS = 'FETCHING_PUBLISHERS';
export const SELECT_PUBLISHER = 'SELECT_PUBLISHER';
export const FETCHING_ADMINS_LIST = 'FETCHING_ADMINS_LIST';
export const RECEIVE_ADMINS_LIST = 'RECEIVE_ADMINS_LIST';

//detail
export const RECEIVE_SITES = 'RECEIVE_SITES';
export const SELECT_SITE = 'SELECT_SITE';
export const RECEIVE_SITE_DETAILS = 'RECEIVE_SITE_DETAILS';
export const RECEIVE_SITE_SSP_DATA = 'RECEIVE_SITE_SSP_DATA';
export const UPDATE_CUSTOM_FILE_NAME = 'UPDATE_CUSTOM_FILE_NAME';
export const INVALIDATE_CUSTOM_FILE = 'INVALIDATE_CUSTOM_FILE';
export const UPDATE_CUSTOM_AD_INVENTORY_IDS = 'UPDATE_CUSTOM_AD_INVENTORY_IDS';
export const UPDATE_PREFERRED_MASTER_TYPE = 'UPDATE_PREFERRED_MASTER_TYPE';
export const UPDATE_CUSTOM_MASTER_FILE_TYPE = 'UPDATE_CUSTOM_MASTER_FILE_TYPE';
export const UPDATE_CUSTOM_MASTER_FILE_TYPE_OKO = 'UPDATE_CUSTOM_MASTER_FILE_TYPE_OKO';
export const UPDATE_CUSTOM_MASTER_FILE_TYPE_S2S = 'UPDATE_CUSTOM_MASTER_FILE_TYPE_S2S';
export const UPDATE_CUSTOM_ADSTXT_VALUES = 'UPDATE_CUSTOM_ADSTXT_VALUES';
export const DETAILS_LOADING = 'DETAILS_LOADING';
export const HANDLE_EDIT = 'HANDLE_EDIT';
export const CLEAR_SELECTION = 'CLEAR_SELECTION';
export const RECEIVE_MASTER_ADS_TXT = 'RECEIVE_MASTER_ADS_TXT';
export const DISCARD_PREFERRED_CHANGES = 'DISCARD_PREFERRED_CHANGES';
export const UNMOUNT_PUBLISHER_DETAILS = 'UNMOUNT_PUBLISHER_DETAILS';

export const RECEIVE_SSP_LIST = 'RECEIVE_SSP_LIST';
export const STORE_SSP_VALUES = 'STORE_SSP_VALUES';
export const SELECT_SSP_ID = 'SELECT_SSP_ID';
export const UN_SELECT_SSP_ID = 'UN_SELECT_SSP_ID';
export const SHOW_STORED_VALUES = 'SHOW_STORED_VALUES';
export const DONT_SHOW_STORED_VALUES = 'DONT_SHOW_STORED_VALUES';

export const EXPORTING_PUBLISHERS_ADSTXT = 'EXPORTING_PUBLISHERS_ADSTXT';

//ACTIONS
//list
const receivePublishers = data => ({
    type: RECEIVE_PUBLISHERS,
    data
});

const fetchingPublishers = status => ({
    type: FETCHING_PUBLISHERS,
    status
});

const fetchingAdminsList = status => ({
    type: FETCHING_ADMINS_LIST,
    status
});

const receiveAdminList = admins => ({
    type: RECEIVE_ADMINS_LIST,
    admins
});
//detail
const receiveSites = sites => ({
    type: RECEIVE_SITES,
    sites
});

const selectSite = site => ({
    type: SELECT_SITE,
    site
});

const selectPublisher = publisher => ({
    type: SELECT_PUBLISHER,
    publisher
});

const receiveSiteDetails = details => ({
    type: RECEIVE_SITE_DETAILS,
    details
});

const receiveSiteSSPDetails = data => ({
    type: RECEIVE_SITE_SSP_DATA,
    data
});

export const updateCustomFileName = name => ({
    type: UPDATE_CUSTOM_FILE_NAME,
    name
});

export const invalidateCustomFile = () => ({
    type: INVALIDATE_CUSTOM_FILE
});

export const updateCustomAdInventoryIDs = id => ({
    type: UPDATE_CUSTOM_AD_INVENTORY_IDS,
    id
});

export const updateCustomAdsTxtValues = values => ({
    type: UPDATE_CUSTOM_ADSTXT_VALUES,
    values
});

const detailsLoading = (dataType, status) => ({
    type: DETAILS_LOADING,
    dataType,
    status
});

const receiveMasterAdsTxtFile = (fileType, values) => ({
    type: RECEIVE_MASTER_ADS_TXT,
    fileType,
    values
});

export const updatePreferredMasterType = fileType => ({
    type: UPDATE_PREFERRED_MASTER_TYPE,
    fileType
});

export const updateCustomMasterFile = (fileType, seatType) => {
    if (seatType === 'INSTICATOR') {
        return ({
            type: UPDATE_CUSTOM_MASTER_FILE_TYPE,
            fileType
        });
    } else if (seatType === 'OKO') {
        return updateCustomMasterFileOKO(fileType)
    } else if (seatType === 'S2S') {
        return updateCustomMasterFileS2S(fileType)
    }
}

export const updateCustomMasterFileOKO = fileType => ({
    type: UPDATE_CUSTOM_MASTER_FILE_TYPE_OKO,
    fileType
});

export const updateCustomMasterFileS2S = fileType => ({
    type: UPDATE_CUSTOM_MASTER_FILE_TYPE_S2S,
    fileType
});

const receiveSspList = list => ({
    type: RECEIVE_SSP_LIST,
    list
});

const storeSSPValues = (id, values) => ({
    type: STORE_SSP_VALUES,
    id,
    values
});

export const selectSSPID = (adInventoryID) => ({
    type: SELECT_SSP_ID,
    adInventoryID
});
export const unselectSSPID = (adInventoryID) => ({
    type: UN_SELECT_SSP_ID,
    adInventoryID
});

export const dontShowValues = (fileType, values) => ({
    type: DONT_SHOW_STORED_VALUES,
    fileType,
    values
});

export const showValues = (fileType, values) => ({
    type: SHOW_STORED_VALUES,
    fileType,
    values
});

export const handleEdit = status => ({
    type: HANDLE_EDIT,
    status
});

export const clearSelection = () => ({
    type: CLEAR_SELECTION
});

export const discardPreferredChanges = () => ({
    type: DISCARD_PREFERRED_CHANGES
});

export const unmountPublisherDetails = () => ({
    type: UNMOUNT_PUBLISHER_DETAILS
});

const exportingPublisherAdsTxt = status => ({
    type: EXPORTING_PUBLISHERS_ADSTXT,
    status
});

//BATCHED ACTIONS
//list
const getPublishersFail = () => batchActions([fetchingPublishers(false), displayErrorMessage('There was an issue fetching your publisher information')]);
const getPublishersSuccess = data => batchActions([fetchingPublishers(false), receivePublishers(data)]);

const getAdminsListFail = () => batchActions([fetchingAdminsList(false), displayErrorMessage('There was an issue fetching admins list for filters')]);
const getAdminsListSuccess = admins => batchActions([fetchingAdminsList(false), receiveAdminList(admins)]);


//detail
const getSitesFail = () => batchActions([displayErrorMessage('There was an issue fetching your publishers sites'), detailsLoading('getSites', false)]);
const getSitesSuccess = sites => batchActions([detailsLoading('getSites', false), receiveSites(sites)]);

const getSiteDetailsFail = () => batchActions([displayErrorMessage('There was an issue fetching your site details'), detailsLoading('getSiteDetails', false)]);
const getSiteDetailsSuccess = details => batchActions([detailsLoading('getSiteDetails', false), receiveSiteDetails(details)]);

const getSiteSSPDetailFail = () => batchActions([displayErrorMessage('There was an issue fetching ssp details for this site'), detailsLoading('getSiteSSPDetails', false)]);
const getSiteSSPDetailSuccess = data => batchActions([detailsLoading('getSiteSSPDetails', false), receiveSiteSSPDetails(data)]);

const updateSiteDetailsFail = () => batchActions([displayErrorMessage('There was an issue updating your site details'), detailsLoading('updatingSiteDetails', false)]);
const updateSiteDetailsSuccess = details => batchActions([detailsLoading('updatingSiteDetails', false), displaySuccessMessage('Your ads.txt values have been saved successfully!'), receiveSiteDetails(details)]);

const deleteSiteDetailsFail = () => batchActions([displayErrorMessage('There was an issue deleting your site details'), detailsLoading('deletingSiteDetails', false)]);
const deleteSiteDetailsSuccess = details => batchActions([detailsLoading('deletingSiteDetails', false), receiveSiteDetails(details)]);

const getMasterAdsTxtValuesFail = () => batchActions([detailsLoading('getMasterAdsTxt', false), displayErrorMessage('There was an issue fetching your master ads txt values')]);
const getMasterAdsTxtValuesSuccess = (fileType, values) => batchActions([updatePreferredMasterType(fileType), receiveMasterAdsTxtFile(fileType, values), detailsLoading('getMasterAdsTxt', false)]);

const getSSPListFail = () => batchActions([detailsLoading('getSSPList', false), displayErrorMessage('There was an issue fetching ssp ads.txt values')]);
const getSSPListSuccess = list => batchActions([receiveSspList(list), detailsLoading('getSSPList', false)]);

const handleMasterAdsTxtValuesFail = () => batchActions([detailsLoading('UPDATING_MASTER_VALUES'), displayErrorMessage('There was an issue adjusting your ssp values')]);

const handleSSPValuesFail = () => batchActions([detailsLoading('updatingSSPValues',false), displayErrorMessage('There was an issue adjusting your ssp ads.txt values')]);

export const selectSiteFromTable = (site, publisher) => dispatch => {
    dispatch(batchActions([selectPublisher(publisher), selectSite(site), updatePageDetails('apps/sspadstxt/publishermanager/publisherdetail')]));
    dispatch(changePage('apps/sspadstxt/publishermanager/publisherdetail'));
};

export const selectPublisherFromTable = (site, publisher) => dispatch => {
    dispatch(batchActions([ selectPublisher(publisher), selectSite(site), updatePageDetails('apps/sspadstxt/publishermanager/publisherdetail')]));
    dispatch(changePage('apps/sspadstxt/publishermanager/publisherdetail'));
};
//THUNKS
export const getPublishers = filters => dispatch => {
    dispatch(fetchingPublishers(true));
    asyncGetPublishers(filters)
        .then(response => dispatch(getPublishersSuccess(response.data)))
        .catch(err => console.log(err) || dispatch(getPublishersFail()))
};

export const getSites = accountUUID => dispatch => {
    dispatch(detailsLoading('getSites', true));
    asyncGetSites(accountUUID)
        .then(response => dispatch(getSitesSuccess(response.data)))
        .catch(err => console.log(err) || dispatch(getSitesFail()))
};

export const getAdminsList = () => dispatch => {
    dispatch(fetchingAdminsList(true));
    asyncGetAdminsList()
        .then(response => dispatch(getAdminsListSuccess(response.data['Account Manager'])))
        .catch(err => console.log(err) || dispatch(getAdminsListFail()))
};

export const getSiteDetails = site => dispatch => {
    dispatch(batchActions([detailsLoading('getSiteDetails', true), selectSite(site)]));
    asyncGetSiteDetails(site.value, site.seatType)
        .then(response => {
            if(response.data.preferredImplementation.preferredFileType !== 'CUSTOM'){
                dispatch(getMasterAdsTxtValues(response.data.preferredImplementation.preferredMasterFileType, true, site.seatType))
            }
            dispatch(getSiteDetailsSuccess(response.data))
        })
        .catch(err => console.log(err) || dispatch(getSiteDetailsFail()))
};

export const getSiteSSPDetails = site => dispatch => {
    dispatch(detailsLoading('getSiteSSPDetails', true));
    asyncGetSiteSSPDetails(site.value, site.seatType)
       .then(response => dispatch(getSiteSSPDetailSuccess(response.data)))
       .catch(err => console.log(err) || dispatch(getSiteSSPDetailFail()))
};

export const updateSiteDetails = ( publisherAdstxtID, site, details ) => dispatch => {
    dispatch(batchActions([detailsLoading('updatingSiteDetails', true), detailsLoading('getSiteSSPDetails', true)]));
    if (details.customFileDetails) {
        details.customFileDetails.customFileMasterValueTypeOKO = details.customFileDetails.customFileMasterValueTypeOKO.replace('OKO_', '')
        details.customFileDetails.customFileMasterValueTypeS2S = details.customFileDetails.customFileMasterValueTypeS2S.replace('S2S_', '')
    }
    asyncUpdateSiteDetails(publisherAdstxtID, details)
        .then(response => {
            dispatch(updateSiteDetailsSuccess(response.data));
            dispatch(getSiteSSPDetails(site))
        })
        .catch(err => console.log(err) || dispatch(updateSiteDetailsFail()))
};

export const deleteSiteDetails = (publisherAdstxtID, seatType) => dispatch => {
    dispatch(detailsLoading('deletingSiteDetails', true));
    asyncDeleteSiteDetails(publisherAdstxtID)
        .then(response => {
            dispatch(getMasterAdsTxtValues(response.data.preferredImplementation.preferredMasterFileType, true, seatType))
            dispatch(deleteSiteDetailsSuccess(response.data))
        })
        .catch(err => console.log(err) || dispatch(deleteSiteDetailsFail()))
};

export const getMasterAdsTxtValues = (fileType, includeInsticatorAdsTxtValue, seatType) => dispatch => {
    dispatch(detailsLoading('getMasterAdsTxt', true));
    asyncGetMasterAdsTxtFileType(fileType, includeInsticatorAdsTxtValue, seatType)
        .then(response => dispatch(getMasterAdsTxtValuesSuccess(fileType, response.data)))
        .catch(err => console.log(err) || dispatch(getMasterAdsTxtValuesFail()))
};

export const getSSPList = () => dispatch => {
    dispatch(detailsLoading('getSSPList', true));
    asyncGetSSPList()
        .then(response => dispatch(getSSPListSuccess(response.data)))
        .catch(err => console.log(err) || dispatch(getSSPListFail()))
};

export const handleMasterSSPValues = (masterFileTypeAdd, masterFileTypeRemove, addArr, removeArr, seatType) => dispatch => {
    dispatch(detailsLoading('updatingMasterValues', true));
    let add = addArr;
    let remove = removeArr;
    let fileTypeAddRequest = masterFileTypeAdd;
    if (seatType === 'OKO') {
        fileTypeAddRequest = fileTypeAddRequest.replace('OKO_', '')
    } else if (seatType === 'S2S') {
        fileTypeAddRequest = fileTypeAddRequest.replace('S2S_', '')
    }

    let fileTypeRemoveRequest = masterFileTypeRemove;
    if (seatType === 'OKO') {
        fileTypeRemoveRequest = fileTypeRemoveRequest.replace('OKO_', '')
    } else if (seatType === 'S2S') {
        fileTypeRemoveRequest = fileTypeRemoveRequest.replace('S2S_', '')
    }
    //lets go lol

    if (!!add && !!remove){
       return dispatch(batchActions([dontShowValues(masterFileTypeRemove, remove), showValues(masterFileTypeAdd, add), updateCustomMasterFile(masterFileTypeAdd, seatType), detailsLoading('updatingMasterValues', false)]))
    }
    else if (!add && !remove){
        asyncGetMasterAdsTxtFileType(fileTypeAddRequest, false, seatType)
            .then(response => add = response.data)
            .then(() => {
                asyncGetMasterAdsTxtFileType(fileTypeRemoveRequest, false, seatType)
                    .then(response => remove = response.data)
                    .then(() => dispatch(batchActions([
                        receiveMasterAdsTxtFile(masterFileTypeAdd, add),
                        receiveMasterAdsTxtFile(masterFileTypeAdd, remove),
                        dontShowValues(masterFileTypeAdd, remove),
                        showValues(masterFileTypeAdd, add),
                        updateCustomMasterFile(masterFileTypeAdd, seatType),
                        detailsLoading('updatingMasterValues', false)
                    ])))
            })
            .catch(err => console.log(err) || dispatch(handleMasterAdsTxtValuesFail()))
    }
    else if (!add){
        asyncGetMasterAdsTxtFileType(fileTypeAddRequest, false, seatType)
            .then(response => {
                add = response.data;
               return dispatch(batchActions([
                    receiveMasterAdsTxtFile(masterFileTypeAdd, add),
                    dontShowValues(masterFileTypeAdd, remove),
                    showValues(masterFileTypeAdd, add),
                    updateCustomMasterFile(masterFileTypeAdd, seatType),
                    detailsLoading('updatingMasterValues', false)
                ]))
            })
            .catch(err => console.log(err) || dispatch(handleMasterAdsTxtValuesFail()))
    }
    else {
        asyncGetMasterAdsTxtFileType(fileTypeRemoveRequest, false, seatType)
            .then(response => {
                remove = response.data;
               return dispatch(batchActions([
                    receiveMasterAdsTxtFile(masterFileTypeAdd, remove),
                    dontShowValues(masterFileTypeAdd, remove),
                    showValues(masterFileTypeAdd, add),
                    updateCustomMasterFile(masterFileTypeAdd, seatType),
                    detailsLoading('updatingMasterValues', false)
                ]))
            })
            .catch(err => console.log(err) || dispatch(handleMasterAdsTxtValuesFail()))
    }
};

export const removeSSPValues = (adInventoryID, values) => dispatch => {
    dispatch(detailsLoading('updatingSSPValues', true));
    if (!!values) {
        dispatch(batchActions([dontShowValues(adInventoryID, values), unselectSSPID(adInventoryID), detailsLoading('updatingSSPValues', false)]))
    } else {
        asyncGetSpecificSSPValues(adInventoryID)
            .then(response => dispatch(batchActions([storeSSPValues(adInventoryID, response.data), dontShowValues(adInventoryID, response.data), unselectSSPID(adInventoryID), detailsLoading('updatingSSPValues', false)])))
            .catch(err => console.log(err) || dispatch(handleSSPValuesFail()))
    }
};

export const addSSPValues = (adInventoryID, values) => dispatch => {
    dispatch(detailsLoading('updatingSSPValues', true));
    if (!!values) {
        dispatch(batchActions([showValues(adInventoryID, values), selectSSPID(adInventoryID), detailsLoading('updatingSSPValues', false)]))
    } else {
        asyncGetSpecificSSPValues(adInventoryID)
            .then(response => dispatch(batchActions([storeSSPValues(adInventoryID, response.data), showValues(adInventoryID, response.data), selectSSPID(adInventoryID), detailsLoading('updatingSSPValues', false)])))
            .catch(err => console.log(err) || dispatch(handleSSPValuesFail()))
    }
};

export const downloadPublisherAdsTxt = () => dispatch => {
    dispatch(exportingPublisherAdsTxt(true));
    exportPublisherAdsTxt()
        .then(response => {
            downloadLink(response.data);
            return dispatch(batchActions([sendMessage('Download Complete!'), exportingPublisherAdsTxt(false)]));
        })
        .catch(err => dispatch(batchActions([exportingPublisherAdsTxt(false), displayErrorMessage('There was an issue exporting publisher data.')])))
};

