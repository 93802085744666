/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// PUBLISHER DETAIL : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    currentimplementation: {
        display: 'flex',
        flexDirection: 'column',
        '& .fileinfo': {
            display: 'flex',
            '& .name-file': {
                fontSize: pxToEm(24)
            },
            '& .currentconfig': {
                marginTop: pxToEm(5),
                marginLeft: pxToEm(10),
                '& .filetype': {
                    fontSize: pxToEm(20),
                    fontWeight: 'bold',
                    height: pxToEm(22)
                },
                '& .filename': {
                    fontWeight: 'normal',
                    height: pxToEm(20)
                },
                '& .filestatus': {
                    marginTop: pxToEm(20),
                    fontWeight: 'normal',
                    color: theme.color.primary.medium.swatch,
                    '& .icon': {
                        fontSize: pxToEm(12)
                    },
                    '&.uptodate-false': {
                        color: theme.color.attention.medium.contrast
                    }
                },
                '& .testingwith': {
                    marginTop: pxToEm(40),
                    fontSize: pxToEm(14),
                    color: theme.color.supporting.xlight.swatch,
                    '& .testingtitle': {
                        '& .icon': {
                            marginRight: pxToEm(10)
                        }
                    },
                    '& .testinglist': {
                        marginTop: pxToEm(10),
                        marginLeft: pxToEm(20)
                    }
                }
            }
        }
    }
});
