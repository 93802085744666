/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import React, { Component } from 'react';
//material
import { CTAs, Button } from '@insticator/insticator-ui'; // iui-material
//styles
import injectSheet from 'react-jss';
import styles from 'apps/classification/material/taxonomyeditor/taxonomyeditorcontrols/variants/classifyCTAs/classifyCTAs_styles';



/* ========== ~~~~~~~~~~ CLASSIFY CTAs : CHILD ~~~~~~~~~~ ========== */
// PRESENTATIONAL COMPONENT
// receives app state & actions as props from parent "_container" component
// this child component handles local state and uses props passed from parent to read/write application state when necessary
const ClassifyCTAs = (props) => (
    <div className={`classifyctas ${props.classes.classifyctas}`}>
        <CTAs name={'classify'} >
            <Button variant={'system'} type={'action'} text={'Deselect All'} action={props.deselectTaxonomyCategories} disabled={!props.categoriesSelected} />
            <Button variant={'primary'} type={'action'} text={'Add to Classification'} action={() => props.setNewTaxonomy(props.selectedCategories)} disabled={!props.activeAnswersSelected || !props.categoriesSelected} />
        </CTAs>
    </div>
);



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// will be utilized in parent component after being infused with app state & actions
export default injectSheet(styles)(ClassifyCTAs);
