/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React from 'react';
//material
import { Title, ContentType, Icon } from '@insticator/insticator-ui'; // iui-material
//styles
import injectSheet from 'react-jss';
import styles from 'apps/classification/pages/editor/questiondetails/questiondetails_styles';



/* ========== ~~~~~~~~~~ QUESTIONDETAILS : CHILD ~~~~~~~~~~ ========== */
// PRESENTATIONAL COMPONENT
// receives app state & actions as props from parent "_container" component
// this child component handles local state and uses props passed from parent to read/write application state when necessary
const QuestionDetails = (props) => {
    const renderClassificationStatus = () => {
        const statusIcon = props.classified ? <Icon name={'check'} /> : <Icon name={'minus-circle'} />;
        const statusText = props.classified ? 'Complete' : 'Incomplete';
        const styleClass = props.classified ? 'complete' : 'incomplete';
        return <div className={'classificationstatus'}>
            Classification Status:
            <span className={`${styleClass}`}> {statusIcon} {statusText}</span>
        </div>
    }
    const renderQuestionNumber = () => (
        <div className={'questionnumber'}>
            <b>{props.questionIndex+1}</b> of {props.totalQuestions} total questions. 
        </div>
    )
    const renderQuestion = () => (
        <div className={'questiontitle'}>
            <Title>
                <h2>{props.question}</h2>
            </Title>
        </div>
    )
    return (
        <div className={`questiondetails ${props.classes.questiondetails}`}>
            <ContentType type={props.questionType.toLowerCase()} indicatorType={`indicator`} isLive={true} />
            <div className={'questiondisplay'}>
                {renderQuestion()}
                <div className={'questionnavigation'}>
                    {renderQuestionNumber()}
                    {renderClassificationStatus()}
                </div>
            </div>
        </div>
    );
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// will be utilized in parent component after being infused with app state & actions
export default injectSheet(styles)(QuestionDetails);