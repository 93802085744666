/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React from 'react';
// material
import { Icon } from '@insticator/insticator-ui'; // iui-material
import TaxonomyCategoryControls from 'apps/classification/material/taxonomyeditor/taxonomytree/category/taxonomycategorycontrols/taxonomycategorycontrols_container';
// styles
import injectSheet from 'react-jss';
import styles from 'apps/classification/material/taxonomyeditor/taxonomytree/category/taxonomycategoryname/taxonomycategoryname_styles';



/* ========== ~~~~~~~~~~ TAXONOMY CATEGORY NAME : CHILD ~~~~~~~~~~ ========== */
// PRESENTATIONAL COMPONENT
// receives app state & actions as props from parent "_container" component
// this child component handles local state and uses props passed from parent to read/write application state when necessary

const TaxonomyCategoryName = (props) => {

    const handleClick = () => !props.isEditMode ? props.isSelected ? props.deselectTaxonomyCategory(props.categoryId) : props.selectTaxonomyCategory(props.categoryId) : null;

    const getHighlightedText = () => {
        if(!props.searchTerm) return <span className={`fullcategoryname`}>{props.name}</span>;
        // Split on higlight term and include term into parts, ignore case
        let parts = props.name.split(new RegExp(`(${props.searchTerm})`, 'gi'));
        return (
            <div className={`fullcategoryname`}>
                { parts.map((part, idx) => <span key={idx} className={`categorynamepart highlighted-${part === props.searchTerm} scrollInvoked-${props.scrollInvoked}`}>{ part }</span>) }
            </div>
        )
    };

    return (
        <div className={`taxonomycategoryname ${props.classes.taxonomycategoryname} editmode-${props.isEditMode} new-${props.isNewCategory} selected-${props.isSelected}`} onClick={handleClick} >
            {/*<p>{props.name}<span className={`iab`}>{props.isIAB ? ' (IAB)' : ''}</span></p>*/}
            {getHighlightedText()}
            <span className={`iab`}>{props.isIAB ? ' (IAB)' : ''}</span>
            {props.isEditMode ? <TaxonomyCategoryControls categoryId={props.categoryId} /> : null}
        </div>
    );
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// will be utilized in parent component after being infused with app state & actions
export default injectSheet(styles)(TaxonomyCategoryName);
