/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { Component } from 'react';
// material
import { LoadState } from '@insticator/insticator-ui'; // iui-material
import TaxonomyCategory from 'apps/classification/material/taxonomyeditor/taxonomytree/category/taxonomycategory_container';
// styles
import injectSheet from 'react-jss';
import styles from 'apps/classification/material/taxonomyeditor/taxonomytree/taxonomytree_styles';



/* ========== ~~~~~~~~~~ TAXONOMY TREE : CHILD ~~~~~~~~~~ ========== */
// PRESENTATIONAL COMPONENT
// receives app state & actions as props from parent "_container" component
// this child component handles local state and uses props passed from parent to read/write application state when necessary
class TaxonomyTree extends Component {
    constructor(props) {
        super(props);
        this.treeElementRef = null;
    }

    shouldComponentUpdate(nextProps) {
        return JSON.stringify(nextProps) !== JSON.stringify(this.props)
    }

    componentDidUpdate(prevProps) {
        // scroll to the top when scrolled category id clears
        if(prevProps.scrolledToCategory && !this.props.scrolledToCategory) this.resetScroll();
        if(prevProps.isEditMode && !this.props.isEditMode) this.props.deselectTaxonomyCategories();
    }

    resetScroll = () => this.treeElementRef.scrollTo({ top: 0, behavior: 'auto' });

    renderCategories = () => Object.entries(this.props.topLevelCategories).map(([_, categoryId]) => <TaxonomyCategory key={`${categoryId}+0`} categoryId={categoryId} level={0} treeElementRef={this.treeElementRef} />);

    renderLoadingState = () => <LoadState graphicName={null} graphicText={`Browser with Search Icon`} bodycopy={`We're loading your data.`} />;

    render() {
        return (
            <div id={'tree'} className={`taxonomytree ${this.props.classes.taxonomytree}`} ref={ ref => this.treeElementRef = ref}>
                {!!this.props.taxonomyDataFetching ? this.renderLoadingState() : this.renderCategories()}
            </div>
        );
    }
}



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// will be utilized in parent component after being infused with app state & actions
export default injectSheet(styles)(TaxonomyTree);
