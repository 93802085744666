/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state
import { getMasterAdsTxtFiles, saveMasterFileNote, selectVersion, updateFileNote, okoSaveMasterFileNote, okoSelectVersion, okoUpdateFileNote, s2SSaveMasterFileNote, s2SSelectVersion, s2SUpdateFileNote } from 'apps/sspadstxt/state/actions/masteradstxtstatus';
// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import MasterAdsTxt from 'apps/sspadstxt/pages/adstxt/masteradstxt/masteradstxt';


/* ========== ~~~~~~~~~~ MASTER ADS.TXT: PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ sspAdsTxtState }) => ({
    fetchingMasterFiles: sspAdsTxtState.masterAdsTxtStatus.fetchingMasterFiles,
    masterFiles: sspAdsTxtState.masterAdsTxtStatus.masterFiles,
    masterFilesReceived: !!Object.keys(sspAdsTxtState.masterAdsTxtStatus.masterFiles).length,
    currentVersion: sspAdsTxtState.masterAdsTxtStatus.currentVersion,
    selectedVersion: sspAdsTxtState.masterAdsTxtStatus.selectedVersion,
    selectedVersionFiles: sspAdsTxtState.masterAdsTxtStatus.masterFiles[sspAdsTxtState.masterAdsTxtStatus.selectedVersion],
    savingNote: sspAdsTxtState.masterAdsTxtStatus.savingNote,
    okoMasterFiles: sspAdsTxtState.masterAdsTxtStatus.okoMasterFiles,
    okoMasterFilesReceived: !!Object.keys(sspAdsTxtState.masterAdsTxtStatus.okoMasterFiles).length,
    okoCurrentVersion: sspAdsTxtState.masterAdsTxtStatus.okoCurrentVersion || 0,
    okoSelectedVersion: sspAdsTxtState.masterAdsTxtStatus.okoSelectedVersion,
    okoSelectedVersionFiles: sspAdsTxtState.masterAdsTxtStatus.okoMasterFiles[sspAdsTxtState.masterAdsTxtStatus.okoSelectedVersion],
    okoSavingNote: sspAdsTxtState.masterAdsTxtStatus.okoSavingNote,
    s2SMasterFiles: sspAdsTxtState.masterAdsTxtStatus.s2SMasterFiles,
    s2SMasterFilesReceived: sspAdsTxtState.masterAdsTxtStatus.s2SMasterFiles ? !!Object.keys(sspAdsTxtState.masterAdsTxtStatus.s2SMasterFiles).length : 0 ,
    s2SCurrentVersion: sspAdsTxtState.masterAdsTxtStatus.s2SCurrentVersion || 0,
    s2SSelectedVersion: sspAdsTxtState.masterAdsTxtStatus.s2SSelectedVersion,
    s2SSelectedVersionFiles: sspAdsTxtState.masterAdsTxtStatus.s2SSelectedVersion ? sspAdsTxtState.masterAdsTxtStatus.s2SMasterFiles[sspAdsTxtState.masterAdsTxtStatus.s2SSelectedVersion]: '',
    s2SSavingNote: sspAdsTxtState.masterAdsTxtStatus.s2SSavingNote
});

// map actions
const mapDispatchToProps = dispatch => ({
    getMasterAdsTxtFiles: () => dispatch(getMasterAdsTxtFiles()),
    saveMasterFileNote: (versionID, note) => dispatch(saveMasterFileNote(versionID, note)),
    selectVersion: version => dispatch(selectVersion(version)),
    updateFileNote: note => dispatch(updateFileNote(note)),
    okoSaveMasterFileNote: (versionID, note) => dispatch(okoSaveMasterFileNote(versionID, note)),
    okoSelectVersion: version => dispatch(okoSelectVersion(version)),
    okoUpdateFileNote: note => dispatch(okoUpdateFileNote(note)),
    s2SSaveMasterFileNote: (versionID, note) => dispatch(s2SSaveMasterFileNote(versionID, note)),
    s2SSelectVersion: version => dispatch(s2SSelectVersion(version)),
    s2SUpdateFileNote: note => dispatch(s2SUpdateFileNote(note))
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(MasterAdsTxt);
