/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// import { log } from '@insticator/insticator-ui'; // iui-utils
import { history } from '@insticator/insticator-ui'; // iui-utils
import { UPDATE_PAGE_DETAILS, SET_EDIT_MODE, UPDATE_CURRENT_APP } from 'state/actions/app_status';
import { CLEAR_ALL_STATES } from 'state/actions/auth_status';




// ^-^ ^-^ ^-^ ^-^ ^-^   APP STATUS: DEFAULT STATE   ^-^ ^-^ ^-^ ^-^ ^-^ //

const defaultState = {
    currentApp: 'adminpanel',
    currentPage: history.location.pathname.replace('/',''), // this should match route in app.js
    previousPage: null,
    clientSites: {},
    messages: {},
    editMode: false
};

/* ========== ~~~~~~~~~~ APP STATUS : REDUCER ~~~~~~~~~~ ========== */
// REDUCER
// used to manipulate application state
// takes an action, checks its type, based on that performs certain functionality, then returns new slice of state
// utilized in "_container" components
const appStatus = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case UPDATE_CURRENT_APP:
            newState.currentApp = action.name;
            return newState;
        case UPDATE_PAGE_DETAILS:
            newState.previousPage = newState.currentPage;
            newState.currentPage = action.location;
            return newState;
        case CLEAR_ALL_STATES:
            return defaultState;
        case SET_EDIT_MODE:
            newState.editMode = action.editState;
            return newState;
        default:
            return newState;
    }
};




/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default appStatus;