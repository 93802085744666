/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router';
// material
import { Page, Content } from '@insticator/insticator-ui';
// pages
import ApprovalPage from 'apps/approvals/pages/approvalpage/approvalpage_container';
// utils
import { PrivateRoute } from '@insticator/insticator-ui'; // iui-utils
// styles
import injectSheet from 'react-jss';
import styles from 'apps/approvals/pages/dashboard/dashboard_styles';



/* ========== ~~~~~~~~~~ DASHBOARD : CHILD ~~~~~~~~~~ ========== */
// PRESENTATIONAL COMPONENT
// receives app state & actions as props from parent "_container" component
// this child component handles local state and uses props passed from parent to read/write application state when necessary
class Dashboard extends Component {
    componentDidMount() {
        this.props.updateCurrentApp('approvals');
    }
    render() {
        return (
            <Page name={`dashboard`} klass={this.props.classes.dashboard}>
                <div id={'main'}>
                    <Switch>
                        {/*Routes to subsections*/}
                        <PrivateRoute path='/apps/approvals' component={ApprovalPage} permitted={true} redirectTo={'/apps'} />
                        <Redirect to='/error/404' />
                    </Switch>
                </div>
            </Page>
        );
    }
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// will be utilized in parent component after being infused with app state & actions
export default injectSheet(styles)(Dashboard);
