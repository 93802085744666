/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state
import { createNewCategory, cancelNewCategory, displayTaxonomyEditorMessage, closeTaxonomyEditorMessage } from 'apps/classification/state/actions/taxonomystatus';
import { deleteTaxonomyCategory } from 'apps/classification/state/actions/taxonomytree';

// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import TaxonomyCategoryControls from 'apps/classification/material/taxonomyeditor/taxonomytree/category/taxonomycategorycontrols/taxonomycategorycontrols';



/* ========== ~~~~~~~~~~ NEW TAXONOMY CATEGORY : PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ classificationState }, { categoryId }) => ({
    name: classificationState.taxonomyTree.taxonomy[categoryId]['name'],
    isIAB: classificationState.taxonomyTree.taxonomy[categoryId]['isIAB'],
    parentId: classificationState.taxonomyTree.taxonomy[categoryId]['parentId'],
});

// map actions
const mapDispatchToProps = dispatch => ({
    createNewCategory: params => dispatch(createNewCategory(params)),
    cancelNewCategory: () => dispatch(cancelNewCategory()),
    displayTaxonomyEditorMessage: message => dispatch(displayTaxonomyEditorMessage(message)),
    closeTaxonomyEditorMessage: () => dispatch(closeTaxonomyEditorMessage()),
    deleteTaxonomyCategory: categoryId => dispatch(deleteTaxonomyCategory(categoryId))
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaxonomyCategoryControls);
