/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { combineReducers } from 'redux';
import ApprovalPageStatus from 'apps/approvals/state/reducers/approvalpagestatus';


/* ========== ~~~~~~~~~~ REPORTS STATE : ROOT REDUCER ~~~~~~~~~~ ========== */
// COMBINE REDUCERS
// used to comhine all various reducers together into an easily accesible object
// utilized in store.js
const commentApprovalState = combineReducers({
    ApprovalPageStatus
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default commentApprovalState;
