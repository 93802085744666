/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { RECEIVE_PUBLISHERS, FETCHING_PUBLISHERS,
    RECEIVE_SITES, SELECT_SITE, RECEIVE_SITE_DETAILS, RECEIVE_SITE_SSP_DATA, HANDLE_EDIT,
    RECEIVE_ADMINS_LIST, FETCHING_ADMINS_LIST, INVALIDATE_CUSTOM_FILE, EXPORTING_PUBLISHERS_ADSTXT,
    UPDATE_CUSTOM_FILE_NAME, UPDATE_CUSTOM_AD_INVENTORY_IDS, UNMOUNT_PUBLISHER_DETAILS,
    UPDATE_PREFERRED_MASTER_TYPE, UPDATE_CUSTOM_MASTER_FILE_TYPE, UPDATE_CUSTOM_MASTER_FILE_TYPE_OKO, UPDATE_CUSTOM_MASTER_FILE_TYPE_S2S, UPDATE_CUSTOM_ADSTXT_VALUES,
    DETAILS_LOADING, RECEIVE_MASTER_ADS_TXT, RECEIVE_SSP_LIST, SELECT_PUBLISHER,
    CLEAR_SELECTION, STORE_SSP_VALUES, SELECT_SSP_ID, UN_SELECT_SSP_ID, SHOW_STORED_VALUES,
    DONT_SHOW_STORED_VALUES, DISCARD_PREFERRED_CHANGES } from 'apps/sspadstxt/state/actions/publishermanagerstatus';

// ^-^ ^-^ ^-^ ^-^ ^-^   PUBLISHER MANAGER STATUS: DEFAULT STATE   ^-^ ^-^ ^-^ ^-^ ^-^ //

const newCustomFileDetails = data => ({
    savedCustomFile: !!data && !!data.customFileName,
    fileName: {value: !!data && !!data.customFileName ? data.customFileName : '', valid: !!data && !!data.customFileName ? true : null },
    adInventoryIds: !!data && !!data.customFileAdInventoryIds ? data.customFileAdInventoryIds : [],
    customMasterFileType: !!data && !!data.customFileMasterValueType ? data.customFileMasterValueType : 'NONE',
    customMasterFileTypeOKO: !!data && !!data.customFileMasterValueTypeOKO ? 'OKO_' + data.customFileMasterValueTypeOKO : 'OKO_NONE',
    customMasterFileTypeS2S: !!data && !!data.customFileMasterValueTypeS2S ? 'S2S_' + data.customFileMasterValueTypeS2S : 'S2S_NONE',
    adsTxtValues: !!data && !!data.customFileAdsTxtValues ? data.customFileAdsTxtValues : [],
    sspTestingWith: !!data && !!data.testingSsps ? data.testingSsps : []
});

const currentImplementation = data => ({
    fileType: !!data && !!data.implementedFileType ? data.implementedFileType : '',
    fileName: !!data && !!data.implementedFileName ? data.implementedFileName : '',
    status: !!data && !!data.implementationStatus ? data.implementationStatus === 'UP_TO_DATE' : null
});

const preferredImplementation = data => ({
    fileType: !!data && !!data.preferredFileType ? data.preferredFileType : '',
    masterFileType: !!data && data.preferredFileType === 'CUSTOM' ? 'CUSTOM' : !!data && !!data.preferredMasterFileType ? data.preferredMasterFileType : 'CUSTOM',
    adsTxtFileRows: !!data && !!data.adsTxtFileRows ? data.adsTxtFileRows : [],
    enableAutoInsert: !!data ? data.enableAutoInsert : false
});

const defaultState = {
    //list
    publishers: {},
    fetchingPublishers: false,
    currentPage: 1,
    totalRows: null,
    totalPages: null,
    adminsList: {},
    fetchingAdmins: false,
    //detail
    isEditMode: false,
    loading: {
        getMasterAdsTxt: false,
        getSites: false,
        getSiteDetails: false,
        getSiteSSPDetails: false,
        updatingSiteDetails: false,
        deletingSiteDetails: false,
        getSSPList: false,
        updatingMasterValues: false,
        updatingSSPValues: false,
        exportingPublisherAdsTxt: false
    },
    sites: {},
    selectedSite: null,
    siteDetailsReceived: false,
    selectedPubDetails: null,
    selectedSiteSSPDetails: {},
    siteAdstxtID: null,
    adUUID: null,
    migratedAdvertisement: null,
    sspList: {},
    storedSSPValues: {},
    storedMasterFiles: {"NONE": []},
    storedMasterFilesOKO: {"NONE": []},
    storedMasterFilesS2S: {"NONE": []},
    currentImplementation: currentImplementation(),
    storedPreferredImplementation: preferredImplementation(),
    preferredImplementation: preferredImplementation(),
    newCustomFileDetails: newCustomFileDetails(),
    customDetailsUpdated: false,
    storedCustomFileDetails: newCustomFileDetails()
};

/* ========== ~~~~~~~~~~ PUBLISHER MANAGER STATUS : REDUCER ~~~~~~~~~~ ========== */
// REDUCER
// used to manipulate application state
// takes an action, checks its type, based on that performs certain functionality, then returns new slice of state
// utilized in "_container" components
const publisherManagerStatus = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        //list
        case FETCHING_PUBLISHERS:
            newState.fetchingPublishers = action.status;
            return newState;
        case RECEIVE_PUBLISHERS:
            newState.publishers = action.data.publishers;
            newState.currentPage = action.data.currentPage;
            newState.totalRows = action.data.totalRows;
            newState.totalPages = action.data.totalPages;
            return newState;
        case RECEIVE_ADMINS_LIST:
            newState.adminsList = action.admins;
            return newState;
        case FETCHING_ADMINS_LIST:
            newState.fetchingAdmins = action.status;
            return newState;
            //detail
        case RECEIVE_SITES:
            newState.sites = action.sites;
            return newState;
        case SELECT_SITE:
            newState.selectedSite = action.site;
            newState.selectedSiteSSPDetails = defaultState.selectedSiteSSPDetails;
            return newState;
        case SELECT_PUBLISHER:
            newState.selectedPubDetails = action.publisher;
            return newState;
        case RECEIVE_SITE_DETAILS:
            newState.siteAdstxtID = action.details.id;
            newState.siteDetailsReceived = true;
            newState.currentImplementation = currentImplementation(action.details.currentImplementation);
            newState.preferredImplementation = preferredImplementation(action.details.preferredImplementation);
            newState.storedPreferredImplementation = preferredImplementation(action.details.preferredImplementation);
            newState.newCustomFileDetails = newCustomFileDetails(action.details.preferredImplementation.customFileDetails);
            newState.storedCustomFileDetails = newCustomFileDetails(action.details.preferredImplementation.customFileDetails);
            newState.isEditMode = false;
            return newState;
        case RECEIVE_SITE_SSP_DATA:
            newState.selectedSiteSSPDetails = action.data.publisherSspDetails;
            newState.migratedAdvertisement = action.data.migratedAdvertisement === null ? newState.migratedAdvertisement : action.data.migratedAdvertisement;
            newState.adUUID = !action.data.adUUID ? newState.adUUID : action.data.adUUID;
            return newState;
        case UPDATE_CUSTOM_FILE_NAME:
            newState.newCustomFileDetails.fileName.value = action.name;
            newState.newCustomFileDetails.fileName.valid = !!action.name.length ? true : null;
            newState.customDetailsUpdated = true;
            return newState;
        case INVALIDATE_CUSTOM_FILE:
            newState.newCustomFileDetails.fileName.valid = !!newState.newCustomFileDetails.fileName.value.length;
            return newState;
        case UPDATE_CUSTOM_AD_INVENTORY_IDS:
            newState.newCustomFileDetails.adInventoryIds = [...newState.newCustomFileDetails.customFileAdInventoryIds, action.id];
            return newState;
        case UPDATE_PREFERRED_MASTER_TYPE:
            //TODO will simplify this
            if(newState.preferredImplementation.masterFileType !== 'CUSTOM' && action.fileType === 'CUSTOM') {
                newState.storedMasterFiles = defaultState.storedMasterFiles;
                newState.storedMasterFilesOKO = defaultState.storedMasterFilesOKO;
                newState.storedMasterFilesS2S = defaultState.storedMasterFilesS2S;
            }
            if(newState.preferredImplementation.masterFileType === 'CUSTOM' && action.fileType !== 'CUSTOM'){
                newState.storedMasterFiles = defaultState.storedMasterFiles;
                newState.storedMasterFilesOKO = defaultState.storedMasterFilesOKO;
                newState.storedMasterFilesS2S = defaultState.storedMasterFilesS2S;
            }
            newState.preferredImplementation.masterFileType = action.fileType;
            return newState;
        case UPDATE_CUSTOM_MASTER_FILE_TYPE:
            console.log(action);
            newState.newCustomFileDetails.customMasterFileType = action.fileType;
            newState.customDetailsUpdated = true;
            return newState;
        case UPDATE_CUSTOM_MASTER_FILE_TYPE_OKO:
            newState.newCustomFileDetails.customMasterFileTypeOKO = action.fileType;
            newState.customDetailsUpdated = true;
            return newState;
        case UPDATE_CUSTOM_MASTER_FILE_TYPE_S2S:
            newState.newCustomFileDetails.customMasterFileTypeS2S = action.fileType;
            newState.customDetailsUpdated = true;
            return newState;
        case UPDATE_CUSTOM_ADSTXT_VALUES:
            newState.newCustomFileDetails.adsTxtValues = action.values;
            return newState;
        case DETAILS_LOADING:
            newState.loading[action.dataType] = action.status;
            return newState;
        case RECEIVE_MASTER_ADS_TXT:
            newState.storedMasterFiles[action.fileType] = action.values;
            return newState;
        case RECEIVE_SSP_LIST:
            newState.sspList = action.list;
            return newState;
        case HANDLE_EDIT:
            newState.isEditMode = action.status;
            return newState;
        case CLEAR_SELECTION:
            newState.newCustomFileDetails.adInventoryIds = [];
            newState.newCustomFileDetails.customMasterFileType = 'NONE';
            newState.newCustomFileDetails.customMasterFileTypeOKO = 'OKO_NONE';
            newState.newCustomFileDetails.customMasterFileTypeS2S = 'S2S_NONE';
            newState.newCustomFileDetails.adsTxtValues = [];
            return newState;
        case DISCARD_PREFERRED_CHANGES:
            newState.newCustomFileDetails = newState.storedCustomFileDetails;
            newState.customDetailsUpdated = false;
            return newState;
        case UNMOUNT_PUBLISHER_DETAILS:
            newState.isEditMode = false;
            newState.sites = {};
            newState.selectedSite = null;
            newState.siteDetailsReceived = false;
            newState.selectedPubDetails = null;
            newState.siteAdstxtID = null;
            newState.adUUID = null;
            newState.storedSSPValues = {};
            return newState;
        case STORE_SSP_VALUES:
            newState.storedSSPValues[action.id] = action.values;
            return newState;
        case SELECT_SSP_ID:
            newState.newCustomFileDetails.adInventoryIds.push(action.adInventoryID);
            newState.customDetailsUpdated = true;
            return newState;
        case UN_SELECT_SSP_ID:
            let idIndex = newState.newCustomFileDetails.adInventoryIds.indexOf(action.adInventoryID);
            newState.newCustomFileDetails.adInventoryIds.splice(idIndex, 1);
            newState.customDetailsUpdated = true;
            return newState;
        case SHOW_STORED_VALUES:
            newState.newCustomFileDetails.adsTxtValues = [...newState.newCustomFileDetails.adsTxtValues, ...action.values];
            return newState;
        case DONT_SHOW_STORED_VALUES:
            action.values.forEach(value => {
                let valIndex = newState.newCustomFileDetails.adsTxtValues.indexOf(value);
                if(valIndex >= 0){
                    newState.newCustomFileDetails.adsTxtValues.splice(valIndex, 1)
                }
            });
            // newState.newCustomFileDetails.adsTxtValues = newState.newCustomFileDetails.adsTxtValues.filter(value => !action.values.includes(value));
            return newState;
        case EXPORTING_PUBLISHERS_ADSTXT:
            newState.loading.exportingPublisherAdsTxt = action.status;
            return newState;
        default:
            return newState;
    }
};




/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default publisherManagerStatus;
