/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import { connect } from 'react-redux';
// actions
import { updatePageDetails, changePage } from 'state/actions/app_status';
import { updateEnteredPageURL } from 'apps/mockupgenerator/state/actions/sitestatus';
import { createMockup, updateURL } from 'apps/mockupgenerator/state/actions/addproducts';
// component
import CreateMockup from 'apps/mockupgenerator/pages/createmockup/createmockup';



/* ========== ~~~~~~~~~~ CREATE MOCKUP : CONTAINER ~~~~~~~~~~ ========== */
// map state
const mapStateToProps = ({ appStatus, mockupGeneratorState }) => ({
    currentPage: 'createmockup',
    currentSite: mockupGeneratorState.siteStatus.currentSite,
    addProductsStatus: mockupGeneratorState.addProductsStatus,
    pageUrl: mockupGeneratorState.addProductsStatus.pageUrl,
    fetchingMockUp: mockupGeneratorState.addProductsStatus.fetchingMockUp,
});

// map actions
const mapDispatchToProps = dispatch => ({
	updateEnteredPageURL: (url) => dispatch(updateEnteredPageURL(url)),
    createMockup: (pageURL, siteUUID, pageCode) => dispatch(createMockup(pageURL, siteUUID, pageCode)),
    updateURL: (url) => dispatch(updateURL(url)),
    moveToPage: (location) => {
        dispatch(updatePageDetails(location));
        dispatch(changePage(location))
    }
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateMockup);
