//Library to export json to xlsx
//https://www.npmjs.com/package/js2excel
import { json2excel } from 'js2excel';


export const downloadTxtFile = (fileName, text) => {
    const element = document.createElement("a");
    const file = new Blob([text], {type: 'text/plain'});
    element.href = URL.createObjectURL(file);
    element.download = fileName;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    document.body.removeChild(element);
};


export const exportMetricsExcelFile = (fileName, data, excludedColumns) =>{
    //temporary object that will hold each rows data till its appended to resultArray then cleared
    let tempObj = {};
    //array that will be used to hold each row and eventually be printed to an excel file
    let resultArray = [];
    //this forEach will loop through the data and filter each row of all excluded columns then append that row to the resultArray
    data.forEach(row => {
        //this line will take the 'total' column from the last position and move it to the first position
        if(Object.keys(row)[Object.keys(row).length -1] === 'total') tempObj[""] = row[Object.keys(row)[Object.keys(row).length -1]];
        for(let column = 0 ; column <= Object.keys(row).length - 2 ; column+=1 ){
           if(!excludedColumns.includes(Object.keys(row)[column]) ) {
               tempObj[Object.keys(row)[column]] = row[Object.keys(row)[column]]
           }
       }
       resultArray.push(tempObj);
       tempObj = {};
    });
    //this is the method imported from js2xcel that takes in data, filename, and date created
    json2excel({data: resultArray, name: fileName, formateDate: new Date()});
};

//-=-=-=-=-=HELPERS-=-=-=-=-=//


