/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { Component } from 'react';
// material
import {
    Page,
    Content,
    SimplePagination,
    NumberedPagination,
    Link,
    BarChart,
    Loader,
    ListTable,
    SearchBar,
    FilterMenu,
    numberWithCommas,
    debounce, Button,
    DateRangePickerDropDown
} from '@insticator/insticator-ui'; // iui-material

import pageUtils from 'apps/sspadstxt/pages/utils'

//styles
import injectSheet from 'react-jss';
import styles from 'apps/sspadstxt/pages/sspapprovals/sspapprovals_styles';



/* ++++++++++ --------------- SSP APPROVALS --------------- ++++++++++ */
class SSPApprovals extends Component {
    constructor(props){
        super(props);
        this.rowsPerPage = 50;
        this.activeFilters = {
            'ssps' : [],
            'sspStatus' : [],
            'accountName' : [],
            'siteURL' : [],
            'adType' : [],
            'approvalProgress' : [],
            'approvalStatus' : [],
            'sspNote' : []
        };
        this.barChartColorMap = {
            delayed: 'rgb(223, 29, 29)',
            completed: 'rgb(20, 148, 244)',
            pending: 'rgb(255, 207, 0)'
        };
        this.state = {
            searchTerm: '',
            searchType: {name: 'Publisher', value: 'PUBLISHER'},
            tableData: this.transpileTableData(),
            sortedColumn: null,
            dateRange: {
                from: null,
                to: null
            }
        };
    };
    componentDidMount() {
        if (!Object.keys(this.props.sspList).length) this.props.getSSPList();
        this.props.getSSPApprovalTableData(this.transpileFilterData(this.activeFilters, this.state.searchTerm, 1));
        this.props.getSSPApprovalStats();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.fetchingTableData !== prevProps.fetchingTableData && !this.props.fetchingTableData){
            this.setState({sortedColumn: null, tableData: this.transpileTableData()})
        }
    }

    searchSSPApprovals = (searchTerm) => {
        this.setState({searchTerm});
        this.props.getSSPApprovalTableData(this.transpileFilterData(this.activeFilters, searchTerm, 1));
    };

    applyFilters = (filters) => {
        this.updateActiveFilters(filters);
        this.props.getSSPApprovalTableData(this.transpileFilterData(filters, this.state.searchTerm, 1));
    };

    updateActiveFilters = filters => {
        Object.keys(filters).forEach(filterName => this.activeFilters[filterName] = filters[filterName])
    };

    updateDateRange = dateRange => this.setState({dateRange});

    customRangeClick = dateRange => this.setState({dateRange}, ()=> this.applyFilters(this.activeFilters));

    transpileFilterData = (filters, searchTerm, page) => ({
        sspIds: !!filters.ssps.length ? filters.ssps : null,
        sspStatuses: !!filters.sspStatus.length ? filters.sspStatus : null,
        adTypes: !!filters.adType.length ? filters.adType : null,
        approvalStatuses: !!filters.approvalStatus.length ? filters.approvalStatus : null,
        approvalProgress: !!filters.approvalProgress.length ? filters.approvalProgress : null,
        withNote: !filters.sspNote.length || filters.sspNote.length === 2 ? null : filters.sspNote[0],
        keywordSearchType: this.state.searchType.value,
        approvalStatusUpdatedStartDate: this.state.dateRange.from, // or null
        approvalStatusUpdatedEndDate: this.state.dateRange.to, // or null
        namePattern: !!searchTerm ? searchTerm : null,
        currentPage: page,
    });

    renderTableHeaders = () => ({
         sspName : 'SSP',
         sspStatus : 'SSP Status',
         accountName : 'Publisher',
         siteURL : 'Site',
         adType : 'Ad Type',
         approvalProgress : 'Approval Progress',
         approvalStatus : 'Approval Status',
         sspNote : 'SSP Note',
         approvalStatusUpdatedDate: 'Last Changed'
    });

    sortByColumnHead = (columnHead) => {
      this.setState({
          sortedColumn: columnHead === this.state.sortedColumn ? null : columnHead ,
          tableData: this.state.tableData.sort((a,b) => this.sortTextHelper(a[columnHead],b[columnHead],this.state.sortedColumn !== columnHead)) })
    };

    sortTextHelper = (rowA, rowB, ascending) => {
        rowA = typeof rowA === 'object' ? rowA.props.text.toLowerCase() : rowA.toLowerCase();
        rowB = typeof rowB === 'object' ? rowB.props.text.toLowerCase() : rowB.toLowerCase();
        return ascending ? (rowB > rowA ? -1 : rowA > rowB ? 1 : 0) : (rowA > rowB ? -1 : rowB > rowA ? 1 : 0);
    };

    transpileTableData = () => {
        return Object.keys(this.props.tableData).map((row) => ({
            sspName: <Link text={this.props.tableData[row].sspName} type={'action'} action={()=>this.props.getSSPDetails(this.props.tableData[row].sspId)}/>,
            sspStatus: pageUtils.formatCellString(this.props.tableData[row].sspStatus),
            accountName: !this.props.tableData[row].accountName || this.props.tableData[row].accountName === 'N/A' ? 'N/A' : <Link text={this.props.tableData[row].accountName} type={'action'} action={()=>this.handlePubSelect(this.props.tableData[row])}/>,
            siteURL: <Link text={this.props.tableData[row].siteUrl} type={'action'} action={()=>this.handleSiteSelect(this.props.tableData[row].siteUrl, this.props.tableData[row].siteUUID, this.props.tableData[row])}/>,
            adType: this.formInventoryType(this.props.tableData[row].adType),
            approvalProgress: pageUtils.formatCellString(this.props.tableData[row].approvalProgress),
            approvalStatus: pageUtils.formatCellString(this.props.tableData[row].approvalStatus),
            sspNote: this.props.tableData[row].sspNote,
            approvalStatusUpdatedDate: this.props.tableData[row].approvalStatusUpdatedDate
        }))
    };

    formInventoryType = receivedInventory => {
        switch (receivedInventory) {
            case 'VIDEO':
                return 'Video';
            case 'DISPLAY':
                return 'Display';
            case 'DISPLAY_AND_VIDEO':
                return 'Display & Video';
            default:
                return 'N/A'
        }
    };

    handleSiteSelect = (siteURL, siteUUID, data) => {
        let site = {value: siteUUID, name: siteURL};
        let pubData = {grade: data.grade, accountUUID: data.accountUUID, accountName: data.accountName};
        this.props.selectSiteFromTable(site, pubData);
    };

    handlePubSelect = data => {
        let pubData = {grade: data.grade, accountUUID: data.accountUUID, accountName: data.accountName};
        this.props.selectPublisherFromTable(pubData);
    };

    handlePrevious = () => {
        const page = this.props.currentPage - 1;
        this.props.getSSPApprovalTableData(this.transpileFilterData(this.activeFilters, this.state.searchTerm, page));
    };

    handleNext = () => {
        const page = this.props.currentPage + 1;
        this.props.getSSPApprovalTableData(this.transpileFilterData(this.activeFilters, this.state.searchTerm, page));
    };

    handlePage = (page) => {
        this.props.getSSPApprovalTableData(this.transpileFilterData(this.activeFilters, this.state.searchTerm, page));
    };

    generateSortableColumnFunctionality = () => {
        return Object.keys(this.renderTableHeaders());
    };

    getFilters = () => {
        const filters = {
            ssps: {
                title: `SSP`,
                icon: false,
                initialState: this.activeFilters.ssps,
                items: [
                    { value: 'ADEX', text: 'ADEX' },
                    { value: 'GUMGUM', text: 'GUMUM' },
                    { value: 'SURVEY', text: 'SURVEY' }
                ]
            },
            sspStatus: {
                title: `SSP Status`,
                icon: false,
                initialState: this.activeFilters.sspStatus,
                items: [
                    { value: 'INACTIVE', text: 'Inactive' },
                    { value: 'TESTING', text: 'Testing' },
                    { value: 'ACTIVE', text: 'Active' }
                ]
            },
            adType: {
                title: `Ad Type`,
                icon: false,
                initialState: this.activeFilters.adType,
                items: [
                    { value: 'DISPLAY', text: 'Display' },
                    { value: 'VIDEO', text: 'Video' },
                    { value: 'DISPLAY_AND_VIDEO', text: 'Display & Video' }
                ]
            },
            approvalProgress: {
                title: `Approval Progress`,
                icon: false,
                initialState: this.activeFilters.approvalProgress,
                items: [
                    { value: 'PENDING', text: 'Pending' },
                    { value: 'COMPLETE', text: 'Complete' },
                    { value: 'DELAYED', text: 'Delayed'}
                ]
            },
            approvalStatus: {
                title: `Approval Status`,
                icon: false,
                initialState: this.activeFilters.approvalStatus,
                items: [
                    { value: 'APPROVED', text: 'Approved' },
                    { value: 'REJECTED', text: 'Rejected' },
                    { value: 'NOT_REQUIRED', text: 'Not Required' },
                    { value: 'BLACKLISTED', text: 'Blacklisted' },
                    { value: 'PAUSED', text: 'Paused' }
                ]
            },
            sspNote: {
                title: `SSP Notes`,
                icon: false,
                initialState: this.activeFilters.sspNote,
                items: [
                    {value: true, text: 'Has Note'},
                    {value: false, text: 'No Note'}
                ]
            }
        };
        if (Object.keys(this.props.sspList).length) {
            filters.ssps.items = Object.keys(this.props.sspList).map(ssp => ({value: ssp, text: this.props.sspList[ssp].sspName}));
        }
        return filters;
    };

    transpileStatsData = () => Object.keys(this.props.stats).map(bar => {
        return {
            y: this.props.stats[bar],
            x: bar,
            color: this.barChartColorMap[bar]
        }
    });
    renderStats = () => {
        if(this.props.fetchingStats){
            return <Loader color={'primary'} />
        } else if(!!this.props.statsReceived) {
            return <BarChart type={'percent'} data={this.transpileStatsData()} />;
        }
    };

    handleSearchType = type => this.setState({searchType: type});

    handlePageNumAmount = (totalPages) => totalPages < 5 ? totalPages : 5;

    render() {
        return (
            <Page klass={`sspapprovals ${this.props.classes.sspapprovals}`}>
                <Content>
                    <div className={'searchfilterchart'}>
                        <div className={'paginationexport'} >

                            <div className={'paginationholder'}>
                                {this.props.totalRows > this.rowsPerPage &&
                                <SimplePagination
                                    handleNext={this.handleNext}
                                    handlePrevious={this.handlePrevious}
                                    itemsPerPage={this.rowsPerPage}
                                    currentPage={this.props.currentPage}
                                    currentIndex={this.props.currentPage * this.rowsPerPage}
                                    totalItems={this.props.totalRows}
                                    totalItemsDisplay={`${numberWithCommas(this.props.totalRows)} Approvals`}
                                />}
                            </div>
                            {!!this.props.totalRows && <Button
                                type={'action'}
                                text={'Export All'}
                                variant={'primary'}
                                action={this.props.exportSSPApprovals}
                                operation={'download'}
                                processing={this.props.exporting} />}
                        </div>
                        <div className={'searchstats'} >
                            <div className={'searchfilter'}>
                                <DateRangePickerDropDown
                                    handleApply={()=>this.applyFilters(this.activeFilters)}
                                    updateRange={this.updateDateRange}
                                    range={this.state.dateRange}
                                    disabledDates={[{after: new Date()}]}
                                    alignedRight={true}
                                    customDateRanges={[{"ctaText":"Anytime","range":null,"indicator":"Anytime"},{"ctaText":"Today","range":"TODAY","indicator":"Today"}, {"ctaText":"Yesterday","range":"YESTERDAY","indicator":"Yesterday"},{"ctaText":"Last 7 Days","range":7},{"ctaText":"Last 30 Days","range":30},{"ctaText":"Last 90 Days","range":90}]}
                                    customRangeClick={this.customRangeClick}
                                    indicatorTitle={'Date modified'} />
                                <FilterMenu
                                    name={`filtermenu`}
                                    onApply={this.applyFilters}
                                    filters={this.getFilters()}
                                />
                                <SearchBar
                                    placeholder={'Search'}
                                    onChange={debounce(this.searchSSPApprovals, 500)}
                                    selectCategory={this.handleSearchType}
                                    currentCategory={this.state.searchType}
                                    categories={[{name: 'Publisher', value: 'PUBLISHER'}, {name: 'Site', value: 'SITE'}]}
                                />
                            </div>
                            <div className={'chart'}>
                                {this.renderStats()}
                            </div>
                        </div>
                    </div>
                    <ListTable
                        name={`sspapprovals`}
                        size={`full`}
                        onSort={this.sortByColumnHead}
                        columnHeads={this.renderTableHeaders()}
                        sortableHeads={this.generateSortableColumnFunctionality()}
                        showTitle={false}
                        exportData={false}
                        moreDetails={false}
                        data={this.state.tableData}
                        loading={this.props.fetchingTableData}
                        />
                    </Content>
                {this.props.totalRows > this.rowsPerPage &&
                    <NumberedPagination
                        itemsPerPage={this.rowsPerPage}
                        currentIndex={this.props.currentPage * this.rowsPerPage}
                        totalItems={this.props.totalRows}
                        totalItemsDisplay={`${numberWithCommas(this.props.totalRows)} total approvals`}
                        currentPage={this.props.currentPage}
                        pageNumAmount={this.handlePageNumAmount(this.props.totalPages)}
                        handlePage={this.handlePage}
                        totalPages={this.props.totalPages}
                    />}
            </Page>
        );
    }
}



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(SSPApprovals);
