/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// SSP DETAIL : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    sspapprovalprocess: {
        '& .name-descriptiongooglesheet': {
            display: 'flex',
            flexWrap: 'wrap',
            '& .descriptionapprovals': {
                display: 'flex',
                flexDirection: 'column',
                // width: '100%',
                // minWidth: pxToEm(425),
                // maxWidth: pxToEm(425),
                marginRight: pxToEm(50),
                marginBottom: pxToEm(40),
                '& .name-description, .name-sspnote': {
                    '& .formelement': {
                        // width: '100%',
                        // maxWidth: pxToEm(369),
                        '& textarea': {
                            resize: 'none'
                        }
                    }
                },
                '& .name-description': { '& .formelement': { height: pxToEm(340) } },
                '& .name-sspnote': { '& .formelement': { height: pxToEm(165) } },
                '& .field': {
                    flex: 'none',
                    marginBottom: pxToEm(30),
                    '&:last-child': {
                        marginBottom: pxToEm(0)
                    }
                }
            },
            '& .googlesheetinfo':{
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                // width: '100%',
                // minWidth: pxToEm(400),
                '& .field': {
                    display: 'initial'
                },
                '& .googlelink': {
                  marginBottom: pxToEm(28), 
                  '& .field': {
                    // maxWidth: pxToEm(759) // make this same width as BOTH columns beneath it
                  }
                },
                '& .submittedreview': {
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'space-between',
                    '& .review, .submitted': {
                        flex: '1'
                        // width: '100%',
                        // maxWidth: pxToEm(370),
                        // minWidth: pxToEm(320)
                    },
                    '& .submitted': {
                        marginBottom: pxToEm(20),
                        marginRight: pxToEm(20)
                    }
                }
            }
        }
    }
});
