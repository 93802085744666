// api
import axios from 'axios';
// utils
import { url } from 'utils/urls';

export const isUserLoggedIn = () => axios.get(url.embed.admin.loggedIn, { withCredentials: true });

const getAdminsURL = `${url.embed.admin.permission}?permissions=newAdminPanel.csAdmin,newAdminPanel.csSuperAdmin`;
export const getAdmins = () => axios.get(getAdminsURL, { withCredentials: true });

export const extendClassSession = () => axios.post(url.embed.admin.extendSession, {}, { withCredentials: true });
