/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// PUBLISHER DETAIL : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    preferredimplementation: {
        flex: 1,
        maxWidth: pxToEm(900),
        '& .fileselector': {
            marginTop: pxToEm(20),
            marginBottom: pxToEm(40),
            display: 'flex',
            '& .file': {
                fontSize: pxToEm(20),
                fontWeight: 'bold',
                marginTop: pxToEm(2),
                '& .loader': {
                    fontSize: pxToEm(4),
                    marginLeft: pxToEm(10),
                    marginBottom: pxToEm(-3)
                }
            },
            '& .name-file': {
                fontSize: pxToEm(24)
            },
           '& .droptitle': {
                marginLeft: pxToEm(10),
               '& .dropdown': {
                    marginTop: pxToEm(10),
                   zIndex: 9 //yes 9
               }
           }
        },
        '& .checkbox-row': {
            marginBottom: pxToEm(30)
        }
    }
});
