/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import React, { PureComponent } from 'react';
//material
import TaxonomyTree from 'apps/classification/material/taxonomyeditor/taxonomytree/taxonomytree_container';
import TaxonomyEditorControls from 'apps/classification/material/taxonomyeditor/taxonomyeditorcontrols/taxonomyeditorcontrols_container'
import TaxonomyEditorHeader from 'apps/classification/material/taxonomyeditor/taxonomyeditorheader/taxonomyeditorheader_container';
import TaxonomyEditorSearch from 'apps/classification/material/taxonomyeditor/taxonomyeditorsearch/taxonomyeditorsearch_container';
import TaxonomyEditorModal from 'apps/classification/material/taxonomyeditor/taxonomyeditormodal/taxonomyeditormodal_container';
import { Fade } from '@insticator/insticator-ui'; // iui-material
//styles
import injectSheet from 'react-jss';
import styles from 'apps/classification/material/taxonomyeditor/taxonomyeditor_styles';


/* ========== ~~~~~~~~~~ TAXONOMY EDITOR ~~~~~~~~~~ ========== */
class TaxonomyEditor extends PureComponent {
    componentDidMount() {
        this.props.getTaxonomy()
    }
    render() {
        return (
            <div className={`taxonomyeditor visible-${this.props.visible} ${this.props.classes.taxonomyeditor}`}>
                <div className={`taxonomywrapper`}>
                    <TaxonomyEditorModal />
                    <TaxonomyEditorHeader />
                    <TaxonomyEditorSearch />
                    <TaxonomyTree />
                    <Fade />
                    <TaxonomyEditorControls />
                </div>
            </div>
        )
    }
}



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(TaxonomyEditor);
