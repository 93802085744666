/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
import { withRouter } from 'react-router'
import { updatePageDetails, changePage } from 'state/actions/app_status';
// actions and reducers used to manipulate application state

// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import AppCard from 'pages/dashboard/appmenu/appcard/appcard';



/* ========== ~~~~~~~~~~ DASHBOARD: PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ appStatus }) => ({
    appStatus,
});
// map actions
const mapDispatchToProps = dispatch => ({
    moveToPage: (location) => {
        dispatch(updatePageDetails(location));
        dispatch(changePage(location))
    },
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(AppCard));