/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React from 'react';
// material
import { Header, Group, BreadCrumbNav, BubbleNav, Link, Button, UnsavedMessage } from '@insticator/insticator-ui'; // iui-material
import TaxonomyEditorNavButton from 'apps/classification/material/taxonomyeditor/navbutton/navbutton_container';
// styles
import injectSheet from 'react-jss';
import styles from 'material/structure/header/header_styles';
import GeoblockingNavControls from 'apps/geoblocking/material/geoblockingnavcontrols/geoblockingnavcontrols_container';



/* ========== ~~~~~~~~~~ STRUCTURE : HEADER : CHILD ~~~~~~~~~~ ========== */
// PRESENTATIONAL COMPONENT
// receives app state & actions as props from parent "_container" component
// this child component handles local state and uses props passed from parent to read/write application state when necessary
const LocalHeader = (props) => {
	const classificationNavItems = [
			{ name: 'manager', text: 'Question Manager', icon: 'list', type: 'internal', action: 'apps/classification/manager' },
			{ name: 'editor', text: 'Classification Editor', icon: 'edit', type: 'internal', action: 'apps/classification/editor' }
        ];
	const geoblockingNavItems = [
			{ name: 'geoBlocking', text: 'Geo Blocking', icon: 'globe-americas', type: 'internal', action: 'apps/geoblocking/library' },
			{ name: 'listsEditor', text: 'Lists Editor', icon: 'list', type: 'internal', action: 'apps/geoblocking/editor' }
		];
	const geoblockingLogsNavItems = [
        	{ name: 'geoBlocking', text: 'Geo Blocking', icon: 'globe-americas', type: 'internal', action: 'apps/geoblocking/library' },
		];
	const commentWidgetApprovalNavItems = [{name: 'commentApproval', text: 'All Requests', icon: 'comment-alt', type: 'internal', action: 'apps/approvals'}];
    const sspAdsTxtNavItems = [
			{ name: 'sspManager', text: 'SSP Manager', icon: 'list', type: 'internal', action: 'apps/sspadstxt/sspmanager/sspslist' },
			{ name: 'masterAdsTxt', text: 'Master Ads.txt Files', icon: 'file', type: 'internal', action: 'apps/sspadstxt/adstxt/masteradstxt' },
			{ name: 'publisherAdsTxt', text: 'Publisher Ads.txt Files', icon: 'copy', type: 'internal', action: 'apps/sspadstxt/publishermanager/publisherslist' },
        	{ name: 'sspApprovals', text: 'Publisher SSP Approvals', icon: 'chart-bar', type: 'internal', action: 'apps/sspadstxt/sspapprovals' }
		];
	const mockupGeneratorNavItems = [
			{ name: 'getstarted', text: 'Choose Site', icon: 'sitemap', type: 'internal', action: 'apps/mockupgenerator/getstarted' },
			{ name: 'managesite', text: 'Manage Site', icon: 'info-circle', type: 'internal', action: 'apps/mockupgenerator/managesite' },
			{ name: 'createmockup', text: 'Create Mockup', icon: 'image', type: 'internal', action: 'apps/mockupgenerator/createmockup' },
			{ name: 'addproducts', text: 'Add Products', icon: 'code', type: 'internal', action: 'apps/mockupgenerator/addproducts' }
        ];
	let signOutClicked = false;
	const handleSignOut = () => {
		if (props.isEditMode) {
			return props.displayModal(true, <UnsavedMessage primaryAction={processSignOut} />);
		}
		return processSignOut();
	};
	const processSignOut = () => { signOutClicked = true; props.signOut() };
	const isClassification = () => props.currentApp === 'classification';
	const isGeoblocking = () => props.currentApp === 'geoblocking';
    const isSspAdsTxt = () => props.currentApp === 'sspadstxt';
    const isGeoblockingLogs = () => props.currentPage === 'apps/geoblocking/checklogs';
	const isCommentApproval = () => props.currentPage === 'apps/approvals';
	const isMockupGenerator = () => props.currentApp === 'mockupgenerator';
	return (
		<Header
			klass={`${props.classes.header} currentApp-${props.currentApp}`}
			logo={{ type: 'internal', action: 'apps' }}
			content={
				<Group name={`navs`}>
					<Group name={`sections`}>
						{isClassification() && <BreadCrumbNav name={'topnav'} currentPage={props.currentPage} items={classificationNavItems} />}
                        {isGeoblocking() && !isGeoblockingLogs() ? <BreadCrumbNav name={'topnav'} currentPage={props.currentPage} items={geoblockingNavItems} /> : null }
                        {isGeoblocking() && isGeoblockingLogs() ? <BreadCrumbNav name={'topnav'} currentPage={props.currentPage} items={geoblockingLogsNavItems} /> : null }
                        {isGeoblocking() ? <GeoblockingNavControls currentPage={props.currentPage} /> : null }
						{isCommentApproval() && <BreadCrumbNav name={'topnav'} currentPage={props.currentPage} items={commentWidgetApprovalNavItems} />}
                        {isSspAdsTxt() && <BubbleNav name={'topnav'} currentPage={props.currentPage} items={sspAdsTxtNavItems} />}
                        {isMockupGenerator() && <BreadCrumbNav name={'topnav'} currentPage={props.currentPage} items={mockupGeneratorNavItems} />}
					</Group>
	                <Group name={`account`}>
						{ isClassification() && props.isCASuperAdmin ? <TaxonomyEditorNavButton /> : null }
	                    {/*<Link type={'custom'} disabled={signOutClicked} name={`logout`} text={`Go to OLD Admin Panel`} action={`logout`} handleClick={handleSignOut} icon={`sign-out-alt`} />*/}
	                    {props.currentPage === 'apps'|| <Link type={'internal'} name={`backtoapps`} text={`Back to Apps`} action={`apps`} icon={`sign-out-alt`} />}
	                    <Button name={'logout'} disabled={signOutClicked} variant={'control'} text={'Old Admin Panel'} icon={'arrow-circle-right'} iconRight operation={'link'} behavior={'none'} action={'logout'} handleClick={handleSignOut} />
					</Group>
				</Group>
			}
		/>
	);
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// will be utilized in parent component after being infused with app state & actions
export default injectSheet(styles)(LocalHeader);
