/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state
import { getPublishers, getAdminsList, selectSiteFromTable, selectPublisherFromTable, downloadPublisherAdsTxt } from 'apps/sspadstxt/state/actions/publishermanagerstatus';
// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import PublishersList from 'apps/sspadstxt/pages/publishermanager/publisherslist/publisherslist';


/* ========== ~~~~~~~~~~ PUBLISHERS LIST: PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ sspAdsTxtState }) => ({
    publishers: sspAdsTxtState.publisherManagerStatus.publishers,
    publishersReceived: !!Object.keys(sspAdsTxtState.publisherManagerStatus.publishers).length,
    fetchingPublishers: sspAdsTxtState.publisherManagerStatus.fetchingPublishers,
    currentPage: sspAdsTxtState.publisherManagerStatus.currentPage,
    totalRows: sspAdsTxtState.publisherManagerStatus.totalRows,
    totalPages: sspAdsTxtState.publisherManagerStatus.totalPages,
    adminsList: sspAdsTxtState.publisherManagerStatus.adminsList,
    adminListReceived: !!Object.keys(sspAdsTxtState.publisherManagerStatus.adminsList).length,
    exporting: sspAdsTxtState.publisherManagerStatus.loading.exportingPublisherAdsTxt
});

// map actions
const mapDispatchToProps = dispatch => ({
    getPublishers: filters => dispatch(getPublishers(filters)),
    selectPublisherFromTable: (site, publisher) => dispatch(selectPublisherFromTable(site, publisher)),
    selectSiteFromTable: (site, publisher) => dispatch(selectSiteFromTable(site, publisher)),
    getAdminsList: () => dispatch(getAdminsList()),
    downloadPublisherAdsTxt: () => dispatch(downloadPublisherAdsTxt())
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PublishersList);
