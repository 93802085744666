/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm, pxToRem, replacePXinStringWithEM } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- MANAGE SITE : STYLES --------------- ++++++++++ */
export default theme => ({
    managesite: {
        '& .textandgraphiclayout': {
            alignContent: 'flex-start',
            alignItems: 'flex-start',
            '& .name-message': {
                maxWidth: pxToEm(600),
                '& .bodycopy': {
                    marginBottom: pxToEm(40)
                },
                '& .separator': {
                    margin: replacePXinStringWithEM('25px auto'),
                },
                '& .ctas': {
                    justifyContent: 'flex-start',
                    '& .field': { flex: '1', marginRight: pxToRem(7) },
                    '& .button': { width: pxToEm(140) }
                }
            },
            '& .name-visual': {
                '& .illustration': {
                    marginBottom: pxToEm(0),
                    width: pxToEm(900),
                    height: pxToEm(700)
                },
                '& .management': {
                    textAlign: 'center'
                }
            }
        }
    }
});