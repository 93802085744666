/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
import { withRouter } from 'react-router'
// actions and reducers used to manipulate application state
import { updateCurrentApp } from 'state/actions/app_status';
import { triggerTaxonomyEditor, setTaxonomyEditorMode } from 'apps/classification/state/actions/taxonomystatus';
// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import Dashboard from 'apps/classification/pages/dashboard/dashboard';



/* ========== ~~~~~~~~~~ DASHBOARD: PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ authStatus, appStatus, classificationState }) => ({
    showTaxonomyEditor: classificationState.taxonomyStatus.showTaxonomyEditor,
    isCASuperAdmin: authStatus.isCASuperAdmin,
    isEditMode: classificationState.taxonomyStatus.isEditMode && authStatus.isCASuperAdmin,
    currentPage: appStatus.currentPage,
    questionsSelected: classificationState.questionStatus.selectedQuestions.length > 0
});

// map actions
const mapDispatchToProps = dispatch => ({
	updateCurrentApp: name => dispatch(updateCurrentApp(name)),
	triggerTaxonomyEditor: params => dispatch(triggerTaxonomyEditor(params)),
	setTaxonomyEditorMode: editMode => dispatch(setTaxonomyEditorMode(editMode))
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(Dashboard));
