/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// DASHBOARD : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    questionanswers: {
        display: 'flex',
        justifyContent: 'stretch',
        flexWrap: 'wrap',
        marginBottom: pxToEm(33),
        '& .answercontainer': {
            marginRight: pxToEm(20)
        }
    }
});