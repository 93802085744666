/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state
import { updateSiteDetails } from 'apps/sspadstxt/state/actions/publishermanagerstatus';
// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import Master from 'apps/sspadstxt/pages/publishermanager/publisherdetail/editsite/preferredimplementation/type/master/master';


/* ========== ~~~~~~~~~~ MASTER: CHILD ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ authStatus, sessionStatus, sspAdsTxtState }) => ({
    masterAdsTxtValues: sspAdsTxtState.publisherManagerStatus.storedMasterFiles[sspAdsTxtState.publisherManagerStatus.preferredImplementation.masterFileType],
    masterFileType: sspAdsTxtState.publisherManagerStatus.preferredImplementation.masterFileType,
    storedMasterFileType: sspAdsTxtState.publisherManagerStatus.storedPreferredImplementation.masterFileType,
    selectedAccountUUID: sspAdsTxtState.publisherManagerStatus.selectedPubDetails.accountUUID,
    siteAdstxtID: sspAdsTxtState.publisherManagerStatus.siteAdstxtID,
    selectedAccountOverrideId: sspAdsTxtState.publisherManagerStatus.selectedPubDetails.accountOverrideId,
    selectedSite: sspAdsTxtState.publisherManagerStatus.selectedSite,
    adsTxtFileRows: sspAdsTxtState.publisherManagerStatus.preferredImplementation.adsTxtFileRows,
    seatType: sspAdsTxtState.publisherManagerStatus.selectedPubDetails.okosite ? 'OKO' : 'INSTICATOR',
    storedEnableAutoInsert: sspAdsTxtState.publisherManagerStatus.preferredImplementation.enableAutoInsert
});

// map actions
const mapDispatchToProps = dispatch => ({
    updateSiteDetails: (adstxtID, selectedSite, details) => console.log(selectedSite) || dispatch(updateSiteDetails(adstxtID, selectedSite, details))
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Master);
