/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import {mediaQuery, pxToEm} from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// MASTER ADS.TXT : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    masteradstxt: {
        '& .title': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            flexWrap: 'wrap',
            marginBottom: pxToEm(50),
            '& h1': {
                fontSize: pxToEm(26),
                fontWeight: '600',
                minWidth: 'fit-content'
            },
            '& span': {
                fontWeight: 'normal'
            }
        },
        '& .name-timelineandcontent':{
            display: 'flex',
            flexWrap: 'wrap',
            '& .name-timeline': {
                width: pxToEm(280),
                marginRight: pxToEm(40),
                position: 'relative'
            },
            '& .name-adstxtcolumns': {
                justifyContent: 'flex-end',
                flexWrap: 'wrap'
            },
            '& .name-content': {
                display: 'flex',
                flexDirection: 'column',
                flex: 1,
                '& .name-sspandnotes': {
                    padding: `${pxToEm(80)} ${pxToEm(25)}`,
                    '& .notes' :{
                        display: 'flex',
                        flexDirection: 'column',
                        '& .field': {
                            marginTop: pxToEm(100),
                            maxWidth: pxToEm(340),
                            marginBottom: pxToEm(10),
                                '& .formelement': {
                                    height: pxToEm(150)
                            },
                            '& .label': {
                                fontSize: pxToEm(14)
                            }
                        },
                        '& .cta':{
                            maxWidth: pxToEm(145)
                        }
                    }
                }
            }
        },
        '& .name-timelineandcontent, .name-adstxtcolumns':{
            display: 'flex',
            width: '100%'
        },
        '& .tabhead': {
            height: pxToEm(50),
            paddingTop: pxToEm(30),
        }
    },
    [mediaQuery.thinnest]: {
        masteradstxt: {
            '& .name-timelineandcontent': {
                '& .name-timeline': {
                    width: '100%',
                    marginBottom: pxToEm(20),
                    '& .adstxttimeline': {
                        height: pxToEm(600),
                        overflow: 'scroll'
                    }
                }
            }
        }
    }
});
