/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { useState } from 'react';
// material
import { Link, Tip, Icon, Label } from '@insticator/insticator-ui'; // iui-material
// helpers
import { isInViewport, scrollToEl } from '../../helpers';
//styles
import injectSheet from 'react-jss';
import styles from './listitem_styles';



/* ++++++++++ --------------- LIST ITEM --------------- ++++++++++ */
const ListItem = (props) => {
	const [highlighted, setHighlighted] = useState(false);
	const product = props.product;
	const productType = props.productType;
	const isActiveProductListItem = (product) => {
		return (product.id === props.state.selectedProductData.id) && (props.state.inspectorEnabled);
	}
	const renderTipDetails = () => {
		return (
			<div className={'list'}>
				<span className={'listitem name'}>
					<Label>Name:</Label>
					<span className={'text'}>{product.name}</span>
				</span>
				<span className={'listitem id'}>
					<Label>Id:</Label>
					<span className={'text'}>{product.id}</span>
				</span>
				<span className={'listitem type'}>
					<Label>Type:</Label>
					<span className={'text'}>{product.type}</span>
				</span>
				{productType === 'ads' &&
					<span className={'additionaldetail'}>
						<span className={'listitem device'}>
							<Label>Device:</Label>
							<span className={'text'}>{product.device}</span>
						</span>
						<span className={'listitem unit'}>
							<Label>Unit:</Label>
							<span className={'text'}>{product.unit}</span>
						</span>
						<span className={'listitem size'}>
							<Label>Size:</Label>
							<span className={'text'}>{product.size}</span>
						</span>
					</span>
				}
			</div>
		);
	}
	const handleMouseEnter = () => {
		if (product.inserted) {
			setHighlighted(true);
			const frameBody = document.querySelector('#mockupframe').contentDocument.body;
			const productToHighlight = frameBody.querySelector(`#product-${product.id}`);
			productToHighlight.classList.add('highlight');
			!isInViewport(productToHighlight, frameBody) && scrollToEl(productToHighlight);
		}
	}
	const handleMouseLeave = () => {
		if (product.inserted) {
			setHighlighted(false);
			const productToHighlight = document.querySelector('#mockupframe').contentDocument.body.querySelector(`#product-${product.id}`);
			productToHighlight.classList.remove('highlight');
		}
	};
	const handleRedirect = () => window.location.href = product.redirectUrl;

	if(productType === 'embeds' && !product.name.toLowerCase().includes(props.state.productSearchTerm.toLowerCase())) return null;

	return (
		<div key={product.id} className={`listitem ${props.classes.listitem} inserted-${product.inserted} isActive-${isActiveProductListItem(product)} isHighlighted-${highlighted} inspectorEnabled-${props.state.inspectorEnabled} changesOccured-${props.state.changesOccured}`} id={`listitem-${product.id}`}>
			<div className={'itemcontents'}>
				<div className={'iconography'} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
					<Icon name={'eye'} />
				</div>
				<div className={'itemdetails'}>
					<span className={'itemdetail name'}>{product.name}</span>
					{productType === 'ads' &&
						<span className={'addetails'}>
							{/*<span className={'itemdetail device'}>{product.device}</span>*/}
							<span className={'itemdetail size'}>{product.size}</span>
						</span>
					}
				</div>
				{/*<Tip icon={'info-circle'} content={renderTipDetails()} />*/}
			</div>
			<div className={'itemctas'}>
				{isActiveProductListItem(product) ?
					<Link name={'cancel'} type={'action'} color={'accent'} text={'Cancel'} icon={'times-circle'} operation={'delete'} behavior={'none'} action={() => props.handleCancelInsert(product)} />
					:
					<Link name={'insertproduct'} type={'action'} color={'secondary'} text={'Insert'} icon={'location-arrow'} operation={'create'} behavior={'none'} action={() => props.handleInsertCTAClick(product)} />
				}
    			<Link name={'removeproduct'} type={'action'} color={'error'} text={'Remove'} icon={'trash-alt'} operation={'delete'} behavior={'none'} action={() => props.handleRemoveProduct(product, productType)} />
    			{productType === 'embeds' && <Link name={'editembed'} type={'action'} disabled={props.disableEditButton} color={'primary'} text={'Edit'} icon={'edit'} operation={'edit'} behavior={'none'} action={handleRedirect} />}
			</div>
		</div>
	)
}



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(ListItem);
