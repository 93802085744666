/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { RECEIVE_APPROVAL_REQUESTS, FETCHING_APPROVAL_REQUESTS, UPDATE_REQUEST_STATUS, UPDATING_REQUEST_STATUS, CLEAR_APPROVALS } from 'apps/approvals/state/actions/approvalpagestatus';
// ^-^ ^-^ ^-^ ^-^ ^-^   APPROVAL PAGE STATUS: DEFAULT STATE   ^-^ ^-^ ^-^ ^-^ ^-^ //

const defaultState = {
    fetchingTableData: false,
    currentPage: 0,
    totalPages: 0,
    totalRequests: 0,
    tableData: {},
    updatingRequestStatus: {}
};


/* ========== ~~~~~~~~~~ APPROVAL PAGE STATUS : REDUCER ~~~~~~~~~~ ========== */
// REDUCER
// used to manipulate application state
// takes an action, checks its type, based on that performs certain functionality, then returns new slice of state
// utilized in "_container" components
const ApprovalPageStatus = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case RECEIVE_APPROVAL_REQUESTS:
            newState.tableData = action.tableData;
            newState.currentPage = action.currentPage;
            newState.totalPages = action.totalPages;
            newState.totalRequests = action.totalRequests;
            return newState;
        case FETCHING_APPROVAL_REQUESTS:
            newState.fetchingTableData = action.status;
            return newState;
        case UPDATE_REQUEST_STATUS:
            newState.tableData[action.UUID] = action.rowData;
            return newState;
        case UPDATING_REQUEST_STATUS:
            let commentRequesting = action.UUID;
            if(newState.updatingRequestStatus[commentRequesting]){
                delete newState.updatingRequestStatus[commentRequesting];
                return newState;
            }
            newState.updatingRequestStatus[commentRequesting] = action.requestStatus;
            return  newState;
        case CLEAR_APPROVALS:
            newState = defaultState;
            return newState;
        default:
            return state;
    }
};

/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default ApprovalPageStatus;
