/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm } from '@insticator/insticator-ui'; // iui-utils
import { colorCodeTableData } from '../../../../../../utils/styles/general';



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// PUBLISHER DETAIL : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    editsite: {
        '& .implementations': {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            '& .preferredimplementation': {
                '& .typecontrols': {
                    marginTop: pxToEm(15)
                }
            }
        },
        '& .table': {
            minHeight: pxToEm(100),
            marginBottom: pxToEm(40),
            '& .cta':{
                color: theme.color.primary.medium.swatch
            },
            '& .tbody': colorCodeTableData()
        },
        '& .labeltitle': {
            fontSize: pxToEm(20),
            fontWeight: 'normal',
            height: 'auto',
            width: '100%',
            marginTop: pxToEm(50),
            marginBottom: pxToEm(20),
            paddingBottom: pxToEm(5),
            borderBottom: '1px solid rgb(192, 201, 220)'
        },
        '& .sectiontitle': {
            fontWeight: 'normal',
            height: pxToEm(19),
            minWidth: pxToEm(235),
            marginBottom: pxToEm(20)
        }
    }
});
