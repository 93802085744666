/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { useState, Component } from 'react';
// material
import {
	Page,
	Content,
	LoadState,
	TextAndGraphicLayout,
	Separator,
	Button,
	TextField,
	CTAs,
	Label,
	url,
	validation
} from '@insticator/insticator-ui'; // iui-material
import MockupDetails from './mockupdetails/mockupdetails_container';
//styles
import injectSheet from 'react-jss';
import styles from 'apps/mockupgenerator/pages/managesite/managesite_styles';



/* ++++++++++ --------------- SITE DETAIL --------------- ++++++++++ */
class ManageSitePage extends Component {
	constructor(props){
		super(props);
	}
	componentDidMount() {
		this.props.currentSite && this.props.getSiteMockUps(this.props.currentSite.value)
	}
	createMockup = () => {
		// pageUrl, siteUUID, pageCode
		// this.props.invalidateURL()
		if(!validation.domain.test.test(this.props.pageUrl.value)) {
			this.props.sendErrorMessage('Enter a valid url');
			return this.props.invalidateURL(false)
		}
		this.props.createMockup(this.props.pageUrl.value, this.props.currentSite.value, null)
	};
	updatePageUrl = url => {
		if(this.props.pageUrl.valid === false) {
			this.props.invalidateURL(validation.domain.test.test(url))
		}
		this.props.updateURL(url);
	};
	renderEditAdsUrl = () => {
		if(this.props.currentSite){
			return this.props.siteMockUps.migratedAdvertisement ?
				`${url.embed.admin.url}/manageadvertisementsv2?adUUID=${this.props.siteMockUps.adUUID}&siteUUID=${this.props.currentSite.value}`
				: `${url.embed.admin.url}/manageadvertisements?adUUID=${this.props.siteMockUps.adUUID}&siteUUID=${this.props.currentSite.value}`;
		}
	};
	renderExistingMockups = () => {
		if(Object.keys(this.props.siteMockUps.pages).length === 0){
			return <h4>You dont have any existing mock ups.</h4>
		}
		return Object.keys(this.props.siteMockUps.pages).map(page =>(<MockupDetails
			openMockup={this.props.getMockup}
			siteMockUpDetails={this.props.siteMockUps.pages[page].siteMockUpDetails}
			active={this.props.siteMockUps.pages[page].active}
			loading={this.props.siteMockUps.pages[page].loading}
			dataReceived={this.props.siteMockUps.pages[page].dataReceived}
			page={{name: this.props.siteMockUps.pages[page].url, pageUUID: page}} />))
	};
	getRedirectURL = () => this.props.getRedirectURL(this.props.currentSite.value, 'managesite');

	render(){
		return (
			<Page klass={`managesite ${this.props.classes.managesite}`}>
				{this.props.fetchingMockUps ? <LoadState graphicName={'error-404'} bodycopy={<span>We're loading the mockups for your selected site.</span>} /> :
					<Content>
						<TextAndGraphicLayout
							headline={'Manage Site'}
							title={this.props.currentSite && this.props.currentSite.name}
							bodycopy={
								<p>Below find the details for this given site. You can create a new mockup or edit an existing one.</p>
							}
							additionalMessageContent={
								<div className={'mockups'}>
									<div className={'newmockup'}>
										<Label>Create new mockup</Label>
										<CTAs>
											<TextField
												name={'pageurl'}
												defaultValue={this.props.pageUrl.value}
												value={this.props.pageUrl.value}
												valid={this.props.pageUrl.valid}
												handleChange={e=>this.updatePageUrl(e.target.value)}
												placeholder={'ex: https://www.autoblog.com/2020/06/12/porsche-911-turbo-s-vs-taycan-turbo/'} />
											<Button
												type={`action`}
												action={this.createMockup}
												operation={'load'}
												processing={this.props.creatingMockup}
												variant={`primary`}
												name={`create`}
												text={`Create Mockup`} />
										</CTAs>
									</div>
									<Separator />
									<div className={'existingmockups'}>
										<Label>Select existing mockup</Label>
										{this.renderExistingMockups()}
									</div>
								</div>
							}
							illustration={'product-content-rail-mobile'}
							additionalVisualContent={
								<div className={'management'}>
									<Button
										type={`action`}
										action={this.getRedirectURL}
										operation={'load'}
										processing={this.props.fetchingRedirect}
										variant={`secondary`}
										name={`editembeds`}
										text={`Edit Products`} />
									<Separator />
									<Button
										type={`external`}
										action={this.renderEditAdsUrl()}
										variant={`primary`} name={`editads`}
										text={`Edit Ads`}
										disabled={!this.props.currentSite} />
								</div>
							}
						/>
					</Content>}
			</Page>
		)
	}
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(ManageSitePage);
