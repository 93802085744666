/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React from 'react';
import { Switch, Redirect } from 'react-router';
// material
// pages
import SSPsList from 'apps/sspadstxt/pages/sspmanager/sspslist/sspslist_container';
import SSPDetail from 'apps/sspadstxt/pages/sspmanager/sspdetail/sspdetail_container';

// utils
import { PrivateRoute } from '@insticator/insticator-ui'; // iui-utils

/* ========== ~~~~~~~~~~ SSP / ADS.TXT SSP MANAGER ~~~~~~~~~~ ========== */
// PRESENTATIONAL COMPONENT
// receives app state & actions as props from parent "_container" component
// this child component handles local state and uses props passed from parent to read/write application state when necessary
export default () => (
    <Switch>
        {/*Routes to subsections*/}
        <PrivateRoute path='/apps/sspadstxt/sspmanager/sspslist' component={SSPsList} permitted={true} redirectTo={'/apps'} />
        <PrivateRoute path='/apps/sspadstxt/sspmanager/sspdetail' component={SSPDetail} permitted={true} redirectTo={'/apps'} />
        <Redirect to='/error/404' />
    </Switch>
)


/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// will be utilized in parent component after being infused with app state & actions
