import { pxToEm } from '@insticator/insticator-ui'


export default theme => ({
    approvereject:{
        '& .cta':{
            marginRight: pxToEm(15),
            marginBottom: pxToEm(10),
            '&:last-child': {
                marginBottom: 0
            },
            '& .loader': {
                display: 'none'
            }
        }
    }
})