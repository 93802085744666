/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { Component } from 'react';
// material
import SSPInfo from 'apps/sspadstxt/pages/sspmanager/sspdetail/sspinfo/sspinfo_container';
import SSPApprovalProcess from 'apps/sspadstxt/pages/sspmanager/sspdetail/sspapprovalprocess/sspapprovalprocess_container';
import SSPAdsTxt from 'apps/sspadstxt/pages/sspmanager/sspdetail/sspadstxt/sspadstxt_container';
import S2SAdsTxt from 'apps/sspadstxt/pages/sspmanager/sspdetail/s2sadstxt/s2sadstxt_container';
import GeoEnabling from 'apps/sspadstxt/pages/sspmanager/sspdetail/geoenabling/geoenabling_container';
import { Page, Content, Button, PopupMessage } from '@insticator/insticator-ui'; // iui-material
//styles
import injectSheet from 'react-jss';
import styles from 'apps/sspadstxt/pages/sspmanager/sspdetail/sspdetail_styles';



/* ++++++++++ --------------- SSP DETAIL --------------- ++++++++++ */
class SSPDetail extends Component {
    constructor(props){
        super(props);
    }

    componentWillUnmount() {
        this.props.clearDetailState()
    }

    handleSaveOrUpdate = () => {
        if(!!this.props.newSSPDetails.id.value){
            this.props.updateSSPDetails(this.transpileSSPData(this.props.newSSPDetails))
        } else {
            this.props.saveNewSSPDetails(this.transpileSSPData(this.props.newSSPDetails))
        }
    };

    save = () => {
        this.handleSaveOrUpdate();
        this.props.displayModal(false);
    };

    saveAction = () => {
        if(this.validationCheck()){
            if(this.props.sspStoredDetails.status !== this.props.newSSPDetails.status.value){
                if (this.props.newSSPDetails.status.value === 'ACTIVE'){
                    this.props.displayModal(true, this.activateModal(), true);
                }
                else if(this.props.newSSPDetails.status.value === 'INACTIVE') {
                    this.props.displayModal(true, this.deactivateModal(), true);
                } else {
                    this.handleSaveOrUpdate();
                }
            }
            else {
                this.handleSaveOrUpdate();
            }
        }
        else {
            this.props.sendErrorMessage('Please fill out the required fields!')
        }

    };

    validationCheck = () => {
        this.props.invalidateSSPDetails();
        return !!this.props.newSSPDetails.approvalSheetDetails.link.valid && !!this.props.newSSPDetails.name.valid;
    };

    deactivateModal = () => (
        <PopupMessage
            buttonPrimary={{text:'Yes, de-activate', variant: 'delete', action: this.save}}
            buttonSecondary={{text:'No, Cancel', variant: 'system', action:()=>this.props.displayModal(false)}}
            body={this.confirmDeactivateCopy()} />
    );

    confirmDeactivateCopy = () => (
        <div className={'confirmdeactivation'}>
            <div>
                {`Deactivating ${this.props.newSSPDetails.name.value} will remove its ads.txt values from the master ads.txt file and create a new version.`}
            </div>
            <br/>
            <div>
                It will also turn off header bidding requests from all publishers who are calling this SSP.
            </div>
            <br/>
            <div>
                Are you sure you want to deactivate?
            </div>
            <br/>
        </div>
    );

    activateModal = () => (
        <PopupMessage
            buttonPrimary={{text:'Yes, activate', variant: 'primary', action: this.save}}
            buttonSecondary={{text:'No, cancel', variant: 'system', action:()=>this.props.displayModal(false)}}
            title={'Confirm Activation'}
            body={this.confirmActivationCopy()}/>
    );

    confirmActivationCopy = () => (
        <div className={'confirmactivation'}>
            <div>
                Activating this SSP will automatically append it’s ads.txt values to the following master ads.txt files:
            </div>
            <br/>
            <ul>
                <li> Master video 'short' ads.txt file </li>
                <li> Master video 'long' ads.txt file </li>
                <li> Combined 'short' ads.txt file </li>
                <li> Combined 'long' ads.txt file </li>
            </ul>
            <div>
                You will also create a new version of the ads.txt master file.
            </div>
            <br/>
            <div>
                {`Would you like to activate ${this.props.newSSPDetails.name.value} now?`}
            </div>
            <br/>
        </div>
    );


    formatAdsTxtForBE = values => !!values ? values.split('\n') : [];

    transpileSSPData = data => ({
        id: !!data.id.value ? data.id.value : null,
        name: data.name.value,
        adInventoryType: data.adInventoryType.value,
        approvalRequired: data.approvalRequired.value,
        hasS2SIntegration: !!data.hasS2SIntegration ? data.hasS2SIntegration.value: false,
        validateAdsTxt: !!data.validateAdsTxt ? data.validateAdsTxt.value: false,
        checkAdsTxtImplementation: data.checkAdsTxtImplementation.value,
        bidderNames: data.bidderNames.value,
        enabledGeos: data.enabledGeos.value,
        approvalProcessDescription: data.approvalProcessDescription.value,
        approvalTurnaroundTime: Number(data.approvalTurnaroundTime.value),
        seatType: data.seatType.value,
        status: data.status.value,
        note: data.note.value ? data.note.value : null,
        accountReps: this.transpileAccountReps(data.accountReps[0]), //only send one at a time
        approvalSheetDetails: this.transpileApprovalSheetDetails(data.approvalSheetDetails),
        displayAdsTxtValues: {
            shortValues: this.formatAdsTxtForBE(data.displayAdsTxtValues.short),
            longValues: this.formatAdsTxtForBE(data.displayAdsTxtValues.long)
        },
        videoAdsTxtValues: {
            shortValues: this.formatAdsTxtForBE(data.videoAdsTxtValues.short),
            longValues: this.formatAdsTxtForBE(data.videoAdsTxtValues.long)
        },
        s2SAdsTxtValues: {
            shortValues: this.formatAdsTxtForBE(data.s2SAdsTxtValues.short),
            longValues: this.formatAdsTxtForBE(data.s2SAdsTxtValues.long)
        },
    });

    transpileAccountReps = rep => ([{name: rep.name.value, email: rep.email.value}]);

    transpileApprovalSheetDetails = details => ({
        link: details.link.value,
        submissionTab: details.submissionTab.value,
        domainSubmittedColumn: details.domainSubmittedColumn.value,
        dateSubmittedColumn: details.dateSubmittedColumn.value,
        reviewTab: details.reviewTab.value,
        domainReviewedColumn: details.domainReviewedColumn.value,
        dateReviewedColumn: details.dateReviewedColumn.value,
        approvalStatusColumn: details.approvalStatusColumn.value,
        reviewNoteColumn: details.reviewNoteColumn.value,
        rejectionTerms: this.transpileMultipleTerms(details.rejectionTerms),
        approvalTerms: this.transpileMultipleTerms(details.approvalTerms),
        blacklistedTerms: this.transpileMultipleTerms(details.blacklistedTerms),
        pausedTerms: this.transpileMultipleTerms(details.pausedTerms)
    });

    transpileMultipleTerms = terms => terms.map(term => term.value);

    isDataValid = fields => {
        for (let fieldName in fields) { if(!fields[fieldName]['valid']) return null; }
        return true;
    };

    render() {
        return (
            <Page klass={`sspdetail ${this.props.classes.sspdetail} saving-${this.props.savingSSPDetails}`} >
                <Content>
                    <SSPInfo/>
                    <SSPApprovalProcess/>
                    <SSPAdsTxt/>
                    {(this.props.newSSPDetails.hasS2SIntegration.value) ?  <S2SAdsTxt/> : (<div></div>)}
                    <GeoEnabling />
                    <Button
                        name={'save'}
                        text={'Save'}
                        variant={'primary'}
                        type={'action'}
                        operation={'save'}
                        processing={this.props.savingSSPDetails}
                        action={this.saveAction} />
                </Content>
            </Page>
        )
    }
}



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(SSPDetail);
