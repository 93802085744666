
/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm, pxToEmWithBaseline, replacePXinStringWithEM, mediaQuery } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// CLASSIFY CTAs : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    editctas: {
        display: 'flex',
        justifyContent: 'flex-end',
        '& .ctas': {
            fontSize: pxToEm(14),
            '& .button': {
                maxWidth: pxToEmWithBaseline({ value: 152, fontSizeBaseline: 14 }),
            }
        },

    },
    [mediaQuery.thinnest]: {
        editctas: {
            flexDirection: 'column',
            alignItems: 'center',
            '& .ctas': {
                '& .button':{
                    width: '100%'
                }
            }
        }
    }
});
