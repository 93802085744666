/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { Component } from 'react';
//material
import { CTAs, Button } from '@insticator/insticator-ui'; // iui-material
//utils
import { exporttaxonomytree } from 'apps/classification/utils/exporttaxonomytree.js';
//styles
import injectSheet from 'react-jss';
import styles from 'apps/classification/material/taxonomyeditor/taxonomyeditorcontrols/variants/editCTAs/editCTAs_styles';



/* ========== ~~~~~~~~~~ EDIT CTAs : CHILD ~~~~~~~~~~ ========== */
// PRESENTATIONAL COMPONENT
// receives app state & actions as props from parent "_container" component
// this child component handles local state and uses props passed from parent to read/write application state when necessary
class EditCTAs extends Component {
    constructor(props){
        super(props);
        this.state = {
            exporting: false
        }
    }
    exportTaxonomyTreeToCSV = () => {
        this.setState({exporting: true});
        exporttaxonomytree(this.props.taxonomy, this.props.topLevelCategories);
        this.setState({exporting: false});
    };
    render(){
        return (
            <div className={`editctas ${this.props.classes.editctas}`}>
                <CTAs name={'export'} >
                    <Button variant={'system'} type={'action'} text={'Export to .csv'} action={this.exportTaxonomyTreeToCSV} processing={this.state.exporting} operation={'download'} disabled={false} />
                </CTAs>
            </div>
        )
    }
}



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// will be utilized in parent component after being infused with app state & actions
export default injectSheet(styles)(EditCTAs);
