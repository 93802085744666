/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state
import { getTaxonomy } from 'apps/classification/state/actions/taxonomystatus';

// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import TaxonomyEditor from 'apps/classification/material/taxonomyeditor/taxonomyeditor';




/* ========== ~~~~~~~~~~ TAXONOMY TREE: PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ classificationState }) => ({
    taxonomy: classificationState.taxonomyTree.taxonomy,
    taxonomyDataFetching: classificationState.taxonomyStatus.taxonomyDataFetching,
    topLevelCategories: classificationState.taxonomyTree.topLevelCategories,
    showTaxonomyEditor: classificationState.taxonomyStatus.showTaxonomyEditor,
    changesDetected: classificationState.taxonomyStatus.changesDetected
});

// map actions
const mapDispatchToProps = dispatch => ({
    getTaxonomy: data => dispatch(getTaxonomy(data)),
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TaxonomyEditor);
