/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { Component } from 'react';
// material
import {
    Page,
    Content,
    Link, Button,
    SimplePagination,
    NumberedPagination,
    numberWithCommas,
    FilterMenu,
    SearchBar,
    debounce,
    ListTable,
    LoadState
} from '@insticator/insticator-ui'; // iui-material
//pages

//styles
import injectSheet from 'react-jss';
import styles from 'apps/sspadstxt/pages/sspmanager/sspslist/sspslist_styles';


/* ++++++++++ --------------- SSPs LIST --------------- ++++++++++ */
class SSPsList extends Component {
    constructor(props){
        super(props);
        this.rowsPerPage = 50;
        this.activeFilters = {
            seatTypes: [],
            inventoryTypes: [],
            statuses: [],
            hasS2SIntegration: [],
            notes: []
        };
        this.lastSortedColumn = 'id';

        this.state = {
            searchTerm: '',
            tableData: this.transpileTableData(),
            sortedColumn: 'id',
            orderDesc: false
        };
    };
    componentDidMount() {
        this.props.getSSPs(this.transpileFilterData(this.activeFilters, this.state.searchTerm, 1, this.state.sortedColumn, this.state.orderDesc));
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.fetchingSSPs !== prevProps.fetchingSSPs && !this.props.fetchingSSPs){
            this.setState({ tableData: this.transpileTableData() })
        }
    }

    searchSSPs = searchTerm => {
        this.setState({ searchTerm });
        this.props.getSSPs(this.transpileFilterData(this.activeFilters, searchTerm, 1, this.state.sortedColumn, this.state.orderDesc));
    };

    applyFilters = filters => {
        this.updateActiveFilters(filters);
        this.props.getSSPs(this.transpileFilterData(filters, this.state.searchTerm, 1, this.state.sortedColumn, this.state.orderDesc));
    };

    updateActiveFilters = filters => {
        Object.keys(filters).forEach(filterName => this.activeFilters[filterName] = filters[filterName])
    };

    transpileFilterData = (filters, searchTerm, page, sortBy, orderDesc) => ({
        seatTypes: !!filters.seatTypes.length ? filters.seatTypes : null,
        inventoryTypes: !!filters.inventoryTypes.length ? filters.inventoryTypes : null,
        statuses: !!filters.statuses.length ? filters.statuses : null,
        hasS2SIntegration: !!filters.hasS2SIntegration.length ? filters.hasS2SIntegration.length  === 2 ? null : filters.hasS2SIntegration[0] : null,
        hasNote: !!filters.notes.length ? filters.notes.length  === 2 ? null : filters.notes[0] : null,
        namePattern: !!searchTerm ? searchTerm : null,
        currentPage: page,
        sortBy: this.formColumnHeader(sortBy),
        orderDesc: orderDesc
    });

    renderTableHeaders = () => ({
        sspName : 'SSP',
        seatType: 'Seat Type',
        inventoryType : 'Inventory Type',
        hasS2SIntegration: 'S2S Integration',
        status : 'Status',
        note : 'Notes'
    });

    sortByColumnHead = (columnHead) => {
        let orderDesc = columnHead === this.state.sortedColumn ? !this.state.orderDesc : this.state.orderDesc;
        this.setState({
            sortedColumn: columnHead,
            orderDesc: orderDesc,
            // tableData: this.state.tableData.sort((a,b) => this.sortTextHelper(a[columnHead],b[columnHead],this.state.sortedColumn !== columnHead))
        });
        this.lastSortedColumn = columnHead;
        this.props.getSSPs(this.transpileFilterData(this.activeFilters, this.state.searchTerm, 1, columnHead, orderDesc));
    };

    renderSortedData = (columnHead, orderDesc) => {
        return this.transpileTableData().sort((a,b) => this.sortTextHelper(a[columnHead],b[columnHead], orderDesc));
    };

    sortTextHelper = (rowA, rowB, descending) => {
        return descending ? (rowA > rowB ? -1 : rowB > rowA ? 1 : 0) : (rowB > rowA ? -1 : rowA > rowB ? 1 : 0);
    };

    transpileTableData = () => {
        return Object.keys(this.props.ssps).map((sspUUID) => ({
            sspName: <Link text={this.props.ssps[sspUUID].name} type={'action'} action={()=>this.props.getSSPDetails(sspUUID)} disabled={!!this.props.fetchingSSPDetails} operation={'load'} processing={this.props.fetchingSSPDetails === sspUUID}/>,
            seatType: this.props.ssps[sspUUID].seatType,
            inventoryType: this.formInventoryType(this.props.ssps[sspUUID].inventoryType),
            hasS2SIntegration: this.hasS2SIntegrationString(this.props.ssps[sspUUID].hasS2SIntegration),
            status: this.capitalize(this.props.ssps[sspUUID].status),
            note: this.props.ssps[sspUUID].note ? this.props.ssps[sspUUID].note : '-'
        }))
    };

    capitalize = value => {
        return value.toLowerCase().replace(/(?:^|\s)\S/g, function(a) { return a.toUpperCase(); });
    };

    hasS2SIntegrationString = value => {
        return value ? 'Yes' : 'No'
    }

    formInventoryType = receivedInventory => {
        // let inventoryType = []
        switch (receivedInventory) {
            case 'VIDEO':
                return 'Video';
            case 'DISPLAY':
                return 'Display';
            case 'DISPLAY_AND_VIDEO':
                return 'Display & Video';
            default:
                return 'N/A'
        }
    };

    formColumnHeader = receivedCoumnHeader => {
        switch (receivedCoumnHeader) {
            case 'sspName':
                return 'name';
            case 'seatType':
                return 'seat_type';
            case 'inventoryType':
                return 'ad_type';
            default:
                return 'id'
        }
    }

    handlePrevious = () => {
        const page = this.props.currentPage - 1;
        this.props.getSSPs(this.transpileFilterData(this.activeFilters, this.state.searchTerm, page, this.state.sortedColumn, this.state.orderDesc));
    };

    handleNext = () => {
        const page = this.props.currentPage + 1;
        this.props.getSSPs(this.transpileFilterData(this.activeFilters, this.state.searchTerm, page, this.state.sortedColumn, this.state.orderDesc));
    };

    handlePage = (page) => {
        this.props.getSSPs(this.transpileFilterData(this.activeFilters, this.state.searchTerm, page, this.state.sortedColumn, this.state.orderDesc));
    };

    generateSortableColumnFunctionality = () => {
        return Object.keys(this.renderTableHeaders());
    };

    getFilters = () => ({
        seatTypes: {
            title: `Seat Type`,
            icon: false,
            initialState: this.activeFilters.seatTypes,
            items: [
                { value: 'INSTICATOR', text: 'INSTICATOR' },
                { value: 'OKO', text: 'OKO' }
            ]
        },
        inventoryTypes: {
            title: `Inventory Type`,
            icon: false,
            initialState: this.activeFilters.inventoryTypes,
            items: [
                { value: 'DISPLAY', text: 'Display' },
                { value: 'VIDEO', text: 'Video' },
                { value: 'DISPLAY_AND_VIDEO', text: 'Display & Video' }
            ]
        },
        hasS2SIntegration: {
            title: `S2S Integration`,
            icon: false,
            initialState: this.activeFilters.hasS2SIntegration,
            items: [
                {value: true, text: 'Yes'},
                {value: false, text: 'No'}
            ]
        },
        statuses: {
            title: `Status`,
            icon: false,
            initialState: this.activeFilters.statuses,
            items: [
                { value: 'INACTIVE', text: 'Inactive' },
                { value: 'TESTING', text: 'Testing' },
                { value: 'ACTIVE', text: 'Active' }
            ]
        },
        notes: {
            title: `Notes`,
            icon: false,
            initialState: this.activeFilters.notes,
            items: [
                {value: true, text: 'Has Note'},
                {value: false, text: 'No Note'}
            ]
        }
    });

    renderTopPagination = () => {
        if(this.props.totalRows > this.rowsPerPage){
            return (
                <SimplePagination
                    handleNext={this.handleNext}
                    handlePrevious={this.handlePrevious}
                    itemsPerPage={this.rowsPerPage}
                    currentPage={this.props.currentPage}
                    currentIndex={this.props.currentPage * this.rowsPerPage}
                    totalItems={this.props.totalRows}
                    totalItemsDisplay={`${numberWithCommas(this.props.totalRows)} SSPs`}
                />
            )
        }
        else {
            return (
                <div className={'numberofssps'}>{`${this.props.totalRows} SSPs`}</div>
            )
        }
    };

    handlePageNumAmount = (totalPages) => totalPages < 5 ? totalPages : 5;

    render() {

        return (
            <Page klass={`sspslist ${this.props.classes.sspslist}`}>
                <Content>
                    {this.renderTopPagination()}
                    <div className={'searchaddssp'}>
                        <div className={'searchfilter'}>
                            <FilterMenu
                                name={`filtermenu`}
                                kind={`filter`}
                                onApply={this.applyFilters}
                                filters={this.getFilters()}
                            />
                            <SearchBar
                                placeholder={'Search'}
                                onChange={debounce(this.searchSSPs, 500)}
                            />
                        </div>
                        <Button
                            text={'Add SSP'}
                            icon={'plus'}
                            type={'action'}
                            action={() => this.props.moveToPage('apps/sspadstxt/sspmanager/sspdetail')}
                            variant={'primary'}
                        />
                    </div>
                    <ListTable
                        name={`ssplist`}
                        size={`full`}
                        onSort={this.sortByColumnHead}
                        columnHeads={this.renderTableHeaders()}
                        sortableHeads={this.generateSortableColumnFunctionality()}
                        showTitle={false}
                        exportData={false}
                        moreDetails={false}
                        data={this.renderSortedData(this.lastSortedColumn, this.state.orderDesc)}
                        loading={this.props.fetchingSSPs}
                    />
                </Content>
                {this.props.totalRows > this.rowsPerPage &&
                <NumberedPagination
                    itemsPerPage={this.rowsPerPage}
                    currentIndex={this.props.currentPage * this.rowsPerPage}
                    totalItems={this.props.totalRows}
                    totalItemsDisplay={`${numberWithCommas(this.props.totalRows)} total approvals`}
                    currentPage={this.props.currentPage}
                    pageNumAmount={this.handlePageNumAmount(this.props.totalPages)}
                    handlePage={this.handlePage}
                    totalPages={this.props.totalPages}
                />}
            </Page>
        );
    }
}



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(SSPsList);
