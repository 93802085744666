/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React from 'react';
//material
import { AddableFields } from '@insticator/insticator-ui';
//styles
import styles from 'apps/sspadstxt/pages/sspmanager/sspdetail/sspbiddername/sspbiddername_styles';
import injectSheet from "react-jss";



/* ++++++++++ --------------- SSP BIDDER NAME --------------- ++++++++++ */
const SSPBidderName = props => {
    return (
        <div className={`sspbiddername ${props.classes.sspbiddername}`}>
            <div className={'labeltitle'} >SSP Bidder Name(s)</div>
            <AddableFields
                fieldData={props.bidderNames.value}
                name={'addablefield'}
                label={'SSP Bidder Name Variation(s)'}
                placeholder={'Type value ...'}
                handleChange={props.updateBidderName}
                removeField={props.removeBidderName}
                newField={props.addBidderName}
                ctaText={'Add a new bidder name variation'} />
        </div>
    );
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(SSPBidderName);
