/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import { connect } from 'react-redux';
import { withRouter } from 'react-router'
// actions
import { updateCurrentApp } from 'state/actions/app_status';
// component
import AppMenu from 'pages/dashboard/appmenu/appmenu';



/* ========== ~~~~~~~~~~ DASHBOARD : CONTAINER ~~~~~~~~~~ ========== */
// map state
const mapStateToProps = ({ appStatus, authStatus }) => ({
    currentApp: appStatus.currentApp,
    adminInformation: authStatus.adminInformation
});
// map actions
const mapDispatchToProps = dispatch => ({
	updateCurrentApp: name => dispatch(updateCurrentApp(name))
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(AppMenu));
