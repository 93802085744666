/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import {pxToEm, replacePXinStringWithEM} from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// SSP APPROVALS : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    sspapprovals: {
        '& .searchfilterchart': {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: pxToEm(34),
            '& .searchfilter': {
                display: 'flex',
                width: '70%',
                '& .searchbar':{
                    width: '100%',
                    maxWidth: pxToEm(655),
                    marginLeft: pxToEm(10)
                },
                '& .datepickerdropdown': {
                    marginRight: pxToEm(10),
                    zIndex: '2'
                }
            },
            '& .paginationsearchfilter':{
                width: '100%'
            },
            '& .chart':{
                marginLeft: 'auto',
                '& .barchart':{
                    padding: '0'
                },
                '& .loader':{
                    height: pxToEm(16),
                    width: pxToEm(40),
                    '& div':{
                        height: pxToEm(25),
                        width: pxToEm(25)
                    }
                }
            },
            '& .paginationexport, .searchstats': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'baseline'
            }
        },
        '& .table':{
            fontWeight: 'normal',
            '& .cta':{
                color: theme.color.primary.medium.swatch
            }
        }
    }
});
