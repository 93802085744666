/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm, replacePXinStringWithEM, ellipsis, disable } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// EMBED CARD : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
	appcard: {
		background: theme.color.light.light.swatch,
		borderRadius: pxToEm(3),
		border: `1px solid #ECF0F7`,
		boxShadow: replacePXinStringWithEM(`0 1px 1px 0 #EDF1FA`),
		minWidth: pxToEm(150), //pxToEm(310),
		position: 'relative',
		'&:hover': {
			boxShadow: theme.boxShadow.highlightCard
		},
		// grey out inactive embeds for better user visual
		'&.offline': {
			opacity: '.4'
		},
		'&.hasImage-false': {
			'& .cover': {
				backgroundColor: '#EEF3F8',
				backgroundSize: '20%',
				backgroundPosition: '50%',
				'& .overlay': {
					background: `rgba(238,243,248, .5)`
				},
				'& .details': {
					'& .name': {
						color: theme.color.supporting.dark.swatch
					}
				}
			}
		},
        '&.disabled-true':{
            extend: disable,
            filter: `grayscale(1) opacity(0.45)`
		},

		'& .link.a': {
			width: '100%',
			height: '100%'
		},

		// __________..... COVER .....__________ //
		'& .cover': {
			cursor: 'pointer', // this sends user to edit mode
			// background image in component itself coming from data
			backgroundSize: 'cover',
			backgroundRepeat: 'no-repeat',
			backgroundPosition: '50% 20%',
			backgroundColor: 'transparent',
			borderRadius: 'inherit',
			borderBottomRightRadius: '0',
			borderBottomLeftRadius: '0',
			width: '100%',
			height: pxToEm(203),
			position: 'relative',
            '&.disabled-true':{
				pointerEvents: 'none',
			},

			// ..... OVERLAY ..... //
			'& .overlay': {
				borderRadius: 'inherit',
				background: "radial-gradient(ellipse at center, rgba(82,83,84,0.15) 0%, rgba(0,10,10,0.25) 100%)",
				width: '100%',
				height: '100%',
				position: 'absolute',
				top: '0',
				left: '0',
				zIndex: '1'
			},

			// ..... STATUS ..... //
			'& .status': {
				pointerEvents: 'none',
				position: 'absolute',
				top: pxToEm(20),
				right: pxToEm(20),
				zIndex: '2'
			},
		},
		// ..... DETAILS ..... //
		'& .details': {
			padding: pxToEm(15),
			'& .name': {
				color: theme.color.supporting.dark.swatch,
				extend: ellipsis,
				fontSize: pxToEm(18),
				fontWeight: '600',
				marginBottom: pxToEm(7)
			},
			'& .desc': {
				color: '#96a6c5',
				fontSize: pxToEm(14)
				// width: pxToEm(380)
			}
		},
		// __________..... INFO .....__________ //
		'& .info': {
			height: pxToEm(45), // needed because when we have no content the embed card height varies
			padding: pxToEm(10),
			display: 'flex',
			alignItems: 'center',

			// ..... CONTAINS LABEL ..... //
			'& label': {
				flex: '0',
				color: theme.color.neutral.medium.swatch,
				fontSize: pxToEm(14),
				textAlign: 'right'
			},

			// ..... NO CONTENT ..... //
			'& .nocontent': {
				color: theme.color.neutral.medium.swatch,
				fontSize: pxToEm(14),
				fontStyle: 'italic',
				flex: '1',
				textAlign: 'right'
			},
		},

		// __________..... CONTROLS .....__________ //
		'& .actions': {
			padding: replacePXinStringWithEM('20px 10px'),
			paddingTop: '0',
			display: 'flex',
			justifyContent: 'space-between',
			'& .status':{
				paddingRight: pxToEm(10),
				display:'flex',
				alignItems:'flex-end',
				'& .text': {
					fontSize: pxToEm(12),
					marginLeft: pxToEm(5)
				}
			},
			'& .controls': {
				display: 'flex',
				justifyContent: 'flex-start',
				'& .link': {
					fontSize: pxToEm(18),
					marginRight: pxToEm(15),
					color: theme.color.supporting.xlight.swatch
				},
				'& .loading': {
					fontSize: pxToEm(14),
					color: theme.color.primary.medium.swatch,
					paddingTop: pxToEm(3),
					paddingBottom: pxToEm(3),
					'& .name-loader': {
						height: pxToEm(1),
						width: pxToEm(16),
						paddingBottom: pxToEm(20),
						marginRight: pxToEm(10),
						'& div': {
							borderColor: `${theme.color.primary.medium.swatch} transparent transparent transparent`,
							borderWidth: pxToEm(2),
							height: pxToEm(16),
							width: pxToEm(16)
						}
					}
				}
			}
		}
	}
});
