//IMPORTS
import { batchActions } from 'redux-batched-actions';
import { updatePageDetails, changePage } from 'state/actions/app_status';
import { displayErrorMessage, displaySuccessMessage } from '@insticator/insticator-ui';
import { asyncGetSSPs, asyncGetSSPDetails, asyncSaveNewSSP, asyncUpdateSSPDetails } from 'apps/sspadstxt/utils/api/sspsmanager';


//CONSTANTS
//ssps list
export const RECEIVE_SSPS = 'RECEIVE_SSPS';
export const SET_FETCHING_SSPS = 'SET_FETCHING_SSPS';
export const UPDATE_SSP_MANAGER_FILTER = 'UPDATE_SSP_MANAGER_FILTER';
//ssp details
export const RECEIVE_SSP_DETAILS = 'RECEIVE_SSP_DETAILS';
export const FETCHING_SSP_DETAILS = 'FETCHING_SSP_DETAILS';
export const SAVING_SSP_DETAILS = 'SAVING_SSP_DETAILS';
export const UPDATE_EDIT_MODE = 'UPDATE_EDIT_MODE';
export const SET_SAVED_SSP_DETAILS = 'SET_SAVED_SSP_DETAILS';
//update details
export const UPDATE_NAME = 'UPDATE_NAME';
export const UPDATE_ACCOUNT_REP = 'UPDATE_ACCOUNT_REP';
export const UPDATE_INVENTORY_TYPE = 'UPDATE_INVENTORY_TYPE';
export const UPDATE_APPROVAL_REQUIRED = 'UPDATE_APPROVAL_REQUIRED';
export const HAS_S2S_INTEGRATION = 'HAS_S2S_INTEGRATION';
export const VALIDATE_ADS_TXT = 'VALIDATE_ADS_TXT';
export const UPDATE_DESCRIPTION = 'UPDATE_DESCRIPTION';
export const UPDATE_NOTE = 'UPDATE_NOTE';
export const UPDATE_TURNAROUND_TIME = 'UPDATE_TURNAROUND_TIME';
export const UPDATE_APPROVAL_GOOGLE_SHEET = 'UPDATE_APPROVAL_GOOGLE_SHEET';
export const UPDATE_REVIEW_TAB = 'UPDATE_REVIEW_TAB';
export const UPDATE_SUBMISSION_TAB = 'UPDATE_SUBMISSION_TAB';
export const UPDATE_SINGLE_COLUMN = 'UPDATE_SINGLE_COLUMN';
export const UPDATE_MULTI_COLUMN = 'UPDATE_MULTI_COLUMN';
export const ADD_COLUMN = 'ADD_COLUMN';
export const DELETE_COLUMN = 'DELETE_COLUMN';
export const UPDATE_ADSTXT_VALUES = 'UPDATE_ADSTXT_VALUES';
export const UPDATE_S2S_ADSTXT_VALUES = 'UPDATE_S2S_ADSTXT_VALUES';
export const UPDATE_SSP_STATUS = 'UPDATE_SSP_STATUS';
export const UPDATE_SSP_SEAT_TYPE = 'UPDATE_SSP_SEAT_TYPE';
export const UPDATE_WHITE_LISTED_GEOS = 'UPDATE_WHITE_LISTED_GEOS';
export const UPDATE_CHECK_AD_IMPLEMENTATION = 'UPDATE_CHECK_AD_IMPLEMENTATION';
export const UPDATE_BIDDER_NAME = 'UPDATE_BIDDER_NAME';
export const REMOVE_BIDDER_NAME = 'REMOVE_BIDDER_NAME';
export const ADD_BIDDER_NAME = 'ADD_BIDDER_NAME';
export const CLEAR_DETAIL_STATE = 'CLEAR_DETAIL_STATE';
export const INVALIDATE_SSP_DETAILS = 'INVALIDATE_SSP_DETAILS';


//ACTIONS
//ssp manager table
const receiveSSPs = (ssps, paginationData) => ({
    type: RECEIVE_SSPS,
    ssps,
    paginationData
});

export const updateSSPManagerFilter = (filterName, filterData) => ({
    type: UPDATE_SSP_MANAGER_FILTER,
    filterName,
    filterData
});

const setFetchingSSPs = status => ({
    type: SET_FETCHING_SSPS,
    status
});

//ssp detail

const receiveSSPDetails = data => ({
    type: RECEIVE_SSP_DETAILS,
    data
});

const fetchingSSPDetails = ssp => ({
    type: FETCHING_SSP_DETAILS,
    ssp
});

const setSavedSSPDetails = data => ({
    type: SET_SAVED_SSP_DETAILS,
    data
});

const savingSSPDetails = status => ({
    type: SAVING_SSP_DETAILS,
    status
});

const updateEditMode = status => ({
    type: UPDATE_EDIT_MODE,
    status
});

//update details

export const updateName = name => ({
    type: UPDATE_NAME,
    name
});

export const updateAccountRep = (fieldName, text) => ({
    type: UPDATE_ACCOUNT_REP,
    fieldName,
    text
});

export const updateInventoryType = inventoryType => ({
    type: UPDATE_INVENTORY_TYPE,
    inventoryType
});

export const updateApprovalRequired = status => ({
    type: UPDATE_APPROVAL_REQUIRED,
    status
});

export const updateHasS2SIntegration = hasS2SIntegration => ({
    type: HAS_S2S_INTEGRATION,
    hasS2SIntegration
});

export const updateValidateAdsTxt = validateAdsTxt => ({
   type: VALIDATE_ADS_TXT,
    validateAdsTxt
});

export const updateDescription = description => ({
    type: UPDATE_DESCRIPTION,
    description
});

export const updateNote = note => ({
    type: UPDATE_NOTE,
    note
});

export const updateTurnaroundTime = time => ({
    type: UPDATE_TURNAROUND_TIME,
    time
});

export const updateGoogleApprovalSheetLink = link => ({
    type: UPDATE_APPROVAL_GOOGLE_SHEET,
    link
});

export const updateReviewTab = text => ({
    type: UPDATE_REVIEW_TAB,
    text
});

export const updateSubmissionTab = text => ({
    type: UPDATE_SUBMISSION_TAB,
    text
});

export const updateSingleColumn = (fieldName, value) => ({
    type: UPDATE_SINGLE_COLUMN,
    value,
    fieldName
});

export const updateMultiColumn = (fieldName, index, value) => ({
    type: UPDATE_MULTI_COLUMN,
    value,
    fieldName,
    index
});

export const deleteColumn = (fieldName, index) => ({
    type: DELETE_COLUMN,
    fieldName,
    index
});

export const addColumn = fieldName => ({
    type: ADD_COLUMN,
    fieldName
});

export const updateAdsTxtValues = (values, adType, fileLength) => ({
    type: UPDATE_ADSTXT_VALUES,
    values,
    adType,
    fileLength
});

export const updateS2SAdsTxtValues = (values, adType, fileLength) => ({
    type: UPDATE_S2S_ADSTXT_VALUES,
    values,
    adType,
    fileLength
});

export const updateSSPStatus = status => ({
    type: UPDATE_SSP_STATUS,
    status
});

export const updateSSPSeatType = seatType => ({
    type: UPDATE_SSP_SEAT_TYPE,
    seatType
});

export const updateWhiteListedGeos = geoCode => ({
    type: UPDATE_WHITE_LISTED_GEOS,
    geoCode
});

export const updateCheckAdImplementation = () => ({
    type: UPDATE_CHECK_AD_IMPLEMENTATION
});

export const updateBidderName = (name, index) => ({
    type: UPDATE_BIDDER_NAME,
    index,
    name
});

export const removeBidderName = index => ({
    type: REMOVE_BIDDER_NAME,
    index
});

export const addBidderName = () => ({
    type: ADD_BIDDER_NAME
});

export const clearDetailState = () => ({
    type: CLEAR_DETAIL_STATE
});

export const invalidateSSPDetails = () => ({
    type: INVALIDATE_SSP_DETAILS
});

//BATCHED ACTIONS
//ssp approval table
const fetchSSPsFail = () => batchActions([displayErrorMessage('There was an issue fetching ssp data.'), setFetchingSSPs(false)]);
const fetchSSPsSuccess = (ssps, paginationData) => batchActions([receiveSSPs(ssps, paginationData), setFetchingSSPs(false)]);
//ssp details
const fetchSSPDetailsFail = () => batchActions([displayErrorMessage('There was an issue fetching detailed data for this ssp.'), fetchingSSPDetails(null)]);
const fetchSSPDetailsSuccess = data => batchActions([receiveSSPDetails(data), fetchingSSPDetails(null), updatePageDetails('apps/sspadstxt/sspmanager/sspdetail')]);

const savingSSPDetailsFail = () => batchActions([displayErrorMessage('There was an issue saving this SSP\'s details.' ), savingSSPDetails(false)]);
const savingSSPDetailsSuccess = data => batchActions([
    displaySuccessMessage('Your new SSP has been created successfully!'),
    savingSSPDetails(false),
    updateEditMode(false),
    setSavedSSPDetails(data)
]);
const updatingSSPDetailsSuccess = data => batchActions([
    displaySuccessMessage('You have successfully updated details for this SSP!'),
    savingSSPDetails(false),
    updateEditMode(false),
    setSavedSSPDetails(data)
]);
//THUNKS
//ssp approval table
export const getSSPs = filterData => dispatch => {
    dispatch(setFetchingSSPs(true));
    asyncGetSSPs(filterData)
        .then(response => {
            const { currentPage, totalRows, totalPages } = response.data;
            dispatch(fetchSSPsSuccess(response.data.ssps, { currentPage, totalRows, totalPages }))
        })
        .catch(err => console.log(err) || dispatch(fetchSSPsFail()))
};

export const getSSPDetails = sspID => dispatch => {
    dispatch(fetchingSSPDetails(sspID));
    asyncGetSSPDetails(sspID)
        .then(response => dispatch(fetchSSPDetailsSuccess(response.data)))
        .then(() => dispatch(changePage('apps/sspadstxt/sspmanager/sspdetail')))
        .catch(err => console.log(err) || dispatch(fetchSSPDetailsFail()))
};

export const saveNewSSPDetails = data => dispatch => {
    dispatch(savingSSPDetails(true));
    asyncSaveNewSSP(data)
        .then(response => dispatch(savingSSPDetailsSuccess(response.data)))
        .catch(err => console.log(err) || dispatch(savingSSPDetailsFail()))
};

export const updateSSPDetails = data => dispatch => {
    dispatch(savingSSPDetails(true));
    asyncUpdateSSPDetails(data)
        .then(response => dispatch(updatingSSPDetailsSuccess(response.data)))
        .catch(err => console.log(err) || dispatch(savingSSPDetailsFail()))
};
