/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state
import { updateName,
    updateAccountRep,
    updateInventoryType,
    updateApprovalRequired,
    updateHasS2SIntegration,
    updateValidateAdsTxt } from 'apps/sspadstxt/state/actions/sspmanagerstatus';
// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import SSPInfo from 'apps/sspadstxt/pages/sspmanager/sspdetail/sspinfo/sspinfo';


/* ========== ~~~~~~~~~~ SSP INFO: CHILD ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ sspAdsTxtState }) => {
    const { name, accountReps, adInventoryType, approvalRequired, hasS2SIntegration, validateAdsTxt } = sspAdsTxtState.sspManagerStatus.newSSPDetails;
    const { isEditMode, savingSSPDetails } = sspAdsTxtState.sspManagerStatus;
    const { status } = sspAdsTxtState.sspManagerStatus.sspStoredDetails;
    const { seatType } = sspAdsTxtState.sspManagerStatus.sspStoredDetails;
    return ({
        name,
        accountReps,
        adInventoryType,
        approvalRequired,
        hasS2SIntegration,
        validateAdsTxt,
        isEditMode,
        savingSSPDetails,
        storedStatus: status,
        storedSeatType: seatType,
    });
};

// map actions
const mapDispatchToProps = dispatch => ({
    updateName: name => dispatch(updateName(name)),
    updateAccountRep: (fieldName, text) => dispatch(updateAccountRep(fieldName, text)),
    updateInventoryType: inventoryType => dispatch(updateInventoryType(inventoryType)),
    updateApprovalRequired: status => dispatch(updateApprovalRequired(status)),
    updateHasS2SIntegration: status => dispatch(updateHasS2SIntegration(status)),
    updateValidateAdsTxt: validateAdsTxt => dispatch(updateValidateAdsTxt(validateAdsTxt)),
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SSPInfo);
