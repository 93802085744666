/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { Component } from 'react';
import DomInspector from 'dom-inspector';
// material
import { Page, Content, TextAndGraphicLayout, Label, CTAs, Button, TextField, TextAreaField, LoadState, validation } from '@insticator/insticator-ui'; // iui-material
//styles
import injectSheet from 'react-jss';
import styles from 'apps/mockupgenerator/pages/manualmockup/manualmockup_styles';



/* ++++++++++ --------------- MANUAL MOCKUP --------------- ++++++++++ */
class ManualMockupPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			scrapping: false,
			pageSrc: '',
			pageSrcValid: null
		};
	}
	handleCreate = () => {
		// (pageURL, siteUUID, pageCode)
		if(!validation.domain.test.test(this.props.pageUrl.value)) {
			this.props.sendErrorMessage('Enter a valid url');
			return this.props.invalidateURL(false)
		}
		if(!this.state.pageSrc) {
			this.setState({pageSrcValid: false});
			return this.props.sendErrorMessage('Enter your page source code.');
		}
		this.props.manualMock(this.props.pageUrl.value, this.props.currentSite.value, this.state.pageSrc);
	};
	handleVisit = () => {
		const win = window.open(this.props.pageUrl.value, '_blank');
  		win.focus();
	};
	handleChange = url => {
		if(this.props.pageUrl.valid === false) {
			this.props.invalidateURL(validation.domain.test.test(url))
		}
		this.props.updateURL(url);
	};
	handlePageSrcChange = (e) => this.setState({pageSrc: e.target.value, pageSrcValid: !!e.target.value});
	render() {
		return (
			<Page klass={`manualmockup ${this.props.classes.manualmockup}`}>
				{this.props.fetchingMockUps ? <LoadState graphicName={'error-404'} bodycopy={<span>We're generating a mockup based off the code you input.</span>} /> :
			    <Content>
			    	<TextAndGraphicLayout
		        		headline={'Sorry!'}
		        		title={`We couldn't get that page code.`}
		        		bodycopy={
		        			<div>
			        			<p>Please follow these instructions to create the page manually:</p>
			        			<ol>
			        				<li>Visit the URL of the page you want to mockup</li>
			        				<li>Right-click anywhere and select "View Page Source" from the context menu</li>
			        				<li>Copy the entire contents of that code</li>
			        				<li>Paste into the text field below</li>
			        			</ol>
			        		</div>
		        		}
		        		additionalMessageContent={
		        			<div>
		        				<Label>Enter the URL of the page you want to mockup</Label>
			        			<CTAs>
				                	<TextField
										name={'pageurl'}
										placeholder={'ex: https://www.autoblog.com/2020/06/12/porsche-911-turbo-s-vs-taycan-turbo/'}
										defaultValue={this.props.pageUrl.value}
										value={this.props.pageUrl.value}
										valid={this.props.pageUrl.valid}
										handleChange={e => this.handleChange(e.target.value)} />
				                	<Button type={`action`} action={this.handleVisit} variant={`secondary`} name={`visit`} text={`Visit Page`} />
				                </CTAs>
				                <Label>Paste the code from that page</Label>
				                <CTAs name={'entercode'}>
				                	<TextAreaField type={'textarea'} name={'pagecode'} value={this.state.pageSrc} valid={this.state.pageSrcValid} handleChange={this.handlePageSrcChange}  />
				                	<Button type={`action`} action={this.handleCreate} variant={`primary`} name={`create`} text={`Create Mockup`} />
				                </CTAs>
				            </div>
			            }
			            illustration={'error-404'}
			        />
			    </Content>}
			</Page>
		)
	}
}



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(ManualMockupPage);
