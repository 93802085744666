/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { Component } from 'react';
// material
import {
    Group,
    ListTable,
    Link
} from '@insticator/insticator-ui-classic'; // iui-material
import PubSSPModal from 'apps/sspadstxt/material/pubsspmodal/pubsspmodal';
import CurrentImplementation from 'apps/sspadstxt/pages/publishermanager/publisherdetail/editsite/currentimplementation/currentimplementation_container';
import PreferredImplementation from 'apps/sspadstxt/pages/publishermanager/publisherdetail/editsite/preferredimplementation/preferredimplementation_container';
import pageUtils from 'apps/sspadstxt/pages/utils'
import SeatIndicator from 'apps/sspadstxt/pages/publishermanager/publisherdetail/editsite/seatindicator/seatindicator';
//styles
import injectSheet from 'react-jss';
import styles from 'apps/sspadstxt/pages/publishermanager/publisherdetail/editsite/editsite_styles';



/* ++++++++++ --------------- PUBLISHER DETAIL --------------- ++++++++++ */

class EditSite extends Component {
    constructor(props){
        super(props);

        this.lastSortedColumn = null;
        this.state = {
            sortedColumn: null
        };
    }

    renderTableHeaders = () => ({
        sspName : 'SSP',
        approvalStatus : 'Approval Status',
        type : 'Type',
        adsTxtStatus : 'Ads.txt Status',
        traffickedTags: 'Trafficked Tags',
        onOff: 'On/Off',
        action: 'Action',
        adsTxtUpdatedDate: 'Ads.txt Updated',
        approvalStatusUpdatedDate: 'Status Updated'
    });

    sortByColumnHead = (columnHead) => {
        this.setState({
            sortedColumn: columnHead === this.state.sortedColumn ? null : columnHead ,
            // tableData: this.state.tableData.sort((a,b) => this.sortTextHelper(a[columnHead],b[columnHead],this.state.sortedColumn !== columnHead))
        });
        this.lastSortedColumn = columnHead;
    };

    renderSortedData = (columnHead) => {
        return columnHead ? this.transpileTableData(this.props.siteTableData).sort((a,b) => this.sortTextHelper(a[columnHead],b[columnHead],this.state.sortedColumn === columnHead)) : this.transpileTableData(this.props.siteTableData);
    };

    sortTextHelper = (rowA, rowB, ascending) => {
        rowA = typeof rowA === 'object' ? rowA.props.text.toLowerCase() : rowA.toLowerCase();
        rowB = typeof rowB === 'object' ? rowB.props.text.toLowerCase() : rowB.toLowerCase();
        return ascending ? (rowB > rowA ? -1 : rowA > rowB ? 1 : 0) : (rowA > rowB ? -1 : rowB > rowA ? 1 : 0);
    };

    generateSortableColumnFunctionality = () => {
        return Object.keys(this.renderTableHeaders());
    };

    transpileTableData = data => {
        if(!data) return [];
        return Object.keys(data).map((sspUUID, i) => ({
            sspName : data[sspUUID].sspName,
            approvalStatus : pageUtils.formatCellString(data[sspUUID].siteApprovalStatus),
            type : data[sspUUID].adType,
            adsTxtStatus : !!data[sspUUID].adsTxtValuesDetails ? <Link
                text={data[sspUUID].adsTxtValuesStatus}
                type={'action'}
                action={()=>this.showSSPDetailModal(
                    data[sspUUID].sspName,
                    { title: 'Missing Ads.txt values', list: data[sspUUID].adsTxtValuesDetails.missingAdsTxtValues },
                    { title: 'Matching Ads.txt values', list: data[sspUUID].adsTxtValuesDetails.matchingAdsTxtValues }
                    )}/> : data[sspUUID].adsTxtValuesStatus,
            traffickedTags: !!data[sspUUID].tagsTraffickedDetails ? <Link
                text={data[sspUUID].tagsTraffickedStatus}
                type={'action'}
                action={()=>this.showSSPDetailModal(
                    data[sspUUID].sspName,
                    { title: 'Untrafficked Tags', list: data[sspUUID].tagsTraffickedDetails.unTraffickedTags },
                    { title: 'Trafficked Tags', list: data[sspUUID].tagsTraffickedDetails.traffickedTags }
                )}/> : data[sspUUID].tagsTraffickedStatus,
            onOff: data[sspUUID].enableStatus,
            action: data[sspUUID].enableAction,
            adsTxtUpdatedDate: data[sspUUID].adsTxtUpdatedDate,
            approvalStatusUpdatedDate: data[sspUUID].approvalStatusUpdatedDate
        }))
    };

    showSSPDetailModal = (sspName, list1, list2) => {
        this.props.displayModal(true, <PubSSPModal
            title={sspName}
            list1={list1}
            list2={list2}
        />, true)
    };

    render(){
        return (
            <div className={`editsite ${this.props.classes.editsite}`} >
                <div className={'labeltitle'} >{this.props.siteURL} - <SeatIndicator seatType={this.props.seatType}/> </div>
                <ListTable
                    variant={'slim'}
                    name={`ssplist`}
                    size={`full`}
                    onSort={this.sortByColumnHead}
                    columnHeads={this.renderTableHeaders()}
                    sortableHeads={this.generateSortableColumnFunctionality()}
                    showTitle={false}
                    exportData={false}
                    moreDetails={false}
                    data={this.renderSortedData(this.lastSortedColumn)}
                    loading={this.props.siteTableDataLoading}
                    addDataAttribute={true}
                />
                <Group klass={'implementations'}>
                    <CurrentImplementation />
                    <PreferredImplementation />
                </Group>
            </div>
        );
    }
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(EditSite);
