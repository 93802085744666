import React from 'react';
//styles
import styles from 'apps/sspadstxt/pages/sspmanager/sspdetail/sspinfo/seatindicator/seatindicator_styles';
import injectSheet from "react-jss";





const Seatindicator = props => {
    return (
        <div className={`seatindicator ${props.classes.seatindicator} seat-${props.seatType}`} >
        {!!props.seatType ? props.seatType : 'INSTICATOR'}
        </div>
    )
};

/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(Seatindicator);
