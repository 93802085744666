import React from 'react';
//material
import { Group, SelectableList, Tip } from "@insticator/insticator-ui";
//styles
import styles from 'apps/sspadstxt/pages/sspmanager/sspdetail/sspseat/sspseat_styles';
import injectSheet from "react-jss";





const Sspseat = props => {

    return (<Group klass={`sspseat ${props.classes.sspseat}`} name={'sspseatsave'} >
                <div className={'labeltitle'} >SSP Seat Type</div>
                <SelectableList
                    kind={'selectable'} type={'radio'}
                    selected={props.seatType.value}
                    items={[
                        {value: 'INSTICATOR', text: <div>INSTICATOR <Tip content={'This SSP is currently using INSTICATOR Seat.'}/></div>},
                        {value: 'OKO', text: <div>OKO <Tip content={'This SSP is currently using OKO SEAT.'}/></div>}]}
                    handleChange={props.updateSSPSeatType}/>
            </Group>)

};

/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(Sspseat);
