/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import React from 'react';
// material
import ClassifyCTAs from 'apps/classification/material/taxonomyeditor/taxonomyeditorcontrols/variants/classifyCTAs/classifyCTAs_container'
import EditCTAs from 'apps/classification/material/taxonomyeditor/taxonomyeditorcontrols/variants/editCTAs/editCTAs_container'
// styles
import injectSheet from 'react-jss';
import styles from 'apps/classification/material/taxonomyeditor/taxonomyeditorcontrols/taxonomyeditorcontrols_styles';




/* ========== ~~~~~~~~~~ TAXONOMY EDITOR CONTROLS : CHILD ~~~~~~~~~~ ========== */
const TaxonomyEditorControls = (props) => {
    const renderVariant = () => props.isEditMode ? <EditCTAs /> : <ClassifyCTAs />;
    return (
        <div className={`taxonomyeditorcontrols ${props.classes.taxonomyeditorcontrols}`}>
            { renderVariant(props.variant) }
        </div>
    );
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// will be utilized in parent component after being infused with app state & actions
export default injectSheet(styles)(TaxonomyEditorControls);
