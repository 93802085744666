/* ++++++++++ --------------- HELPERS --------------- ++++++++++ */
import { url } from 'utils/urls'; // iui-utils




/* ++++++++++ --------------- SCROLL HELPERS --------------- ++++++++++ */
// (should move these to IUI)
export const isInViewport = (elem, container=window) => {
    let bounding = elem.getBoundingClientRect();
    return (
        bounding.top >= 0 &&
        bounding.left >= 0 &&
        bounding.bottom <= container.innerHeight &&
        bounding.right <= container.innerWidth
        // bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        // bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
};
export const scrollToEl = (el) => {
  el.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'nearest'});
};





/* ++++++++++ --------------- PATH TO ELEMENT --------------- ++++++++++ */
// https://stackoverflow.com/questions/2631820/how-do-i-ensure-saved-click-coordinates-can-be-reloaed-to-the-same-place-even-i/2631931#2631931
// http://jsfiddle.net/luisperezphd/L8pXL/
export const getXPathOfElement = (element) => {
    if (element.id!=='')
        return 'id("'+element.id+'")';
    if (element===document.body)
        return element.tagName;

    let ix= 0;
    const siblings= element.parentNode.childNodes;
    for (let i= 0; i<siblings.length; i++) {
        const sibling= siblings[i];
        if (sibling===element)
            return getXPathOfElement(element.parentNode)+'/'+element.tagName+'['+(ix+1)+']';
        if (sibling.nodeType===1 && sibling.tagName===element.tagName)
            ix++;
    }
};




/* ++++++++++ --------------- ELEMENT SELECTION (via xpath) --------------- ++++++++++ */
export const getElementByXpath = (xpath, doc=document) => {
  return doc.evaluate(xpath, doc, null, XPathResult.FIRST_ORDERED_NODE_TYPE, null).singleNodeValue;
};




/* ++++++++++ --------------- CREATE DOM --------------- ++++++++++ */
export const createDom = (markupString, format='text/html') => {
  return new DOMParser().parseFromString(markupString, format);
};




/* ++++++++++ --------------- CREATE PRODUCT CONTAINER --------------- ++++++++++ */
export const createProductContainer = (product) => {
  const productType = product.type.toLowerCase();
  const productContainer = document.createElement('div');
  productContainer.setAttribute('id', `product-${product.id}`);
  productContainer.setAttribute('class', 'insticator-product');
  // productContainer.classList.add(product.name);
  if (productType === 'content' || productType === 'commenting') {
    productContainer.classList.add('insticator-embed-product');
    productContainer.classList.add(`insticator-${productType}-product`);
  } else {
    productContainer.classList.add('insticator-ad-product');
  }
  return productContainer;
};





/* ++++++++++ --------------- CREATE HEADER CODE --------------- ++++++++++ */
export const createHeaderScript = (siteUUID) => {
    const headerScript = document.createElement('script');
    headerScript.id = 'insticator-headercode';
    headerScript.textContent = `(function (a, c, s, u){'Insticator'in a || (a.Insticator={ad:{loadAd: function (b){Insticator.ad.q.push(b)}, q: []}, helper:{}, embed:{}, version: "4.0", q: [], load: function (t, o){Insticator.q.push({t: t, o: o})}}); var b=c.createElement(s); b.src=u; b.async=!0; var d=c.getElementsByTagName(s)[0]; d.parentNode.insertBefore(b, d)})(window, document, 'script', '${url.headerCode.adsCode}/${siteUUID}.js')`;
    return headerScript;
};

export const createOnLoadScript = () => {
    const onloadScript = document.createElement('script');
    onloadScript.id = 'insticator-onloadcode';
    onloadScript.textContent = `
      window.addEventListener('DOMContentLoaded', (event) => {
        console.log('DOM fully loaded and parsed');
      });
      console.log('Here is the log')
      console.log('document.readyState', document.readyState);
    `;
    return onloadScript;
}




/* ++++++++++ --------------- CREATE CONTENT EMBED --------------- ++++++++++ */
export const createContentEmbed = (siteUUID, embed, withAds=true) => {
  // <!-- Insticator API Embed -->
// <div id="insticator-container" class="embedid-886ad3bf-eb45-422a-b919-59e629160b81">
//  <div id="div-insticator-ad-1"></div>
//  <div id="insticator-embed"></div>
//  <div id="div-insticator-ad-2"></div>
//  <script data-cfasync='false' type="text/javascript">Insticator.ad.loadAd("div-insticator-ad-1");Insticator.ad.loadAd("div-insticator-ad-2");Insticator.load("em",{id : "886ad3bf-eb45-422a-b919-59e629160b81"})</script>
// </div>
// <!-- End Insticator API Embed -->

  // create new wrapper DOM element to store insticator-container contents
  const contentTag = createProductContainer(embed);

  // create insticator container
  const insticatorContainer = document.createElement('div');
  insticatorContainer.setAttribute('id', 'insticator-container');
  insticatorContainer.classList.add(`embedid-${embed.id}`);

  // create embed DIV
  const embedContainer = document.createElement('div');
  embedContainer.setAttribute('id', 'insticator-embed');

  // create ads
  const ad2TextSpan = document.createElement('span');
  const ad1TextSpan = document.createElement('span');
  ad1TextSpan.innerText = 'Attach Ad';
  ad2TextSpan.innerText = 'Attach Ad';
  const ad1Container = document.createElement('div');
  ad1Container.setAttribute('class', 'insticator-hotspot');
  ad1Container.setAttribute('id', 'insticator-hotspot-1');
  ad1Container.appendChild(ad1TextSpan);
  const ad2Container = document.createElement('div');
  ad2Container.setAttribute('class', 'insticator-hotspot');
  ad2Container.setAttribute('id', 'insticator-hotspot-2');
  ad2Container.appendChild(ad2TextSpan);


  // create new SCRIPT DOM element (must do this to execute script once loaded)
  const embedScript = document.createElement('script');
  embedScript.textContent = `Insticator.load("em",{id : "${embed.id}"});`;

  // form full tag
  insticatorContainer.appendChild(ad1Container);
  insticatorContainer.appendChild(embedContainer);
  insticatorContainer.appendChild(ad2Container);
  insticatorContainer.appendChild(embedScript);
  contentTag.appendChild(insticatorContainer);

  // now store this content embed in state
  return contentTag;
};



/* ++++++++++ --------------- CREATE COMMENTING EMBED --------------- ++++++++++ */
export const createCommentingEmbed = (siteUUID, embed, pageUUID, pageURL, withAds=true) => {
  // <div class="insticator-unit type-commenting">
//  <div class="insticator-ads">
//    <div id="div-insticator-ad-cmt-1"></div>
//    <div id="div-insticator-ad-cmt-2"></div>
//    <script data-cfasync="false" type="text/javascript">Insticator.ad.loadAd("div-insticator-ad-cmt-1");Insticator.ad.loadAd("div-insticator-ad-cmt-2");</script>
//  </div>
//  <div id="insticator-commenting">
//    <div class="so-comments" data-integration-id="1d05439b-4b6d-4af7-a252-554950ca4069"></div>
//    <script src="https://soapps.net/test/loader/bundle.js"></script>
//  </div>
//  <div class="insticator-ads additional">
//    <div id="div-insticator-ad-cmt-3"></div>
//    <div id="div-insticator-ad-cmt-4"></div>
//    <script data-cfasync="false" type="text/javascript">Insticator.ad.loadAd("div-insticator-ad-cmt-3");Insticator.ad.loadAd("div-insticator-ad-cmt-4");</script>
//  </div>
// </div>

  // create div containers
  const cmmtTag = createProductContainer(embed);

  const insUnit = document.createElement('div');
  insUnit.setAttribute('class', 'insticator-unit');
  insUnit.classList.add('type-commenting');

  const insCommenting = document.createElement('div');
  insCommenting.setAttribute('id', 'insticator-commenting');

  const soComments = document.createElement('div') ;
  soComments.classList.add('so-comments');
  soComments.setAttribute('data-integration-id', siteUUID);
  soComments.setAttribute('data-ext-page-id', pageUUID);
  soComments.setAttribute('data-page-url', pageURL);

  // create new script
  const cmmtScript = document.createElement('script');
  cmmtScript.src = `${url.commenting.base}/loader/bundle.js`;

  // create ads
  const topAdsContainer = document.createElement('div');
  topAdsContainer.setAttribute('class', 'insticator-ads');
  const bottomAdsContainer = document.createElement('div');
  bottomAdsContainer.setAttribute('class', 'insticator-ads');
  bottomAdsContainer.classList.add('additional');

  const ad1Container = document.createElement('div');
  ad1Container.setAttribute('class', 'insticator-hotspot');
  ad1Container.setAttribute('id', 'insticator-hotspot-1');
  const ad2Container = document.createElement('div');
  ad2Container.setAttribute('class', 'insticator-hotspot');
  ad2Container.setAttribute('id', 'insticator-hotspot-2');
  const ad3Container = document.createElement('div');
  ad3Container.setAttribute('class', 'insticator-hotspot');
  ad3Container.setAttribute('id', 'insticator-hotspot-3');
  const ad4Container = document.createElement('div');
  ad4Container.setAttribute('class', 'insticator-hotspot');
  ad4Container.setAttribute('id', 'insticator-hotspot-4');

  topAdsContainer.appendChild(ad1Container);
  topAdsContainer.appendChild(ad2Container);
  bottomAdsContainer.appendChild(ad3Container);
  bottomAdsContainer.appendChild(ad4Container);


  // append unit
  insCommenting.appendChild(soComments);
  insCommenting.appendChild(cmmtScript);
  insUnit.appendChild(topAdsContainer);
  insUnit.appendChild(insCommenting);
  insUnit.appendChild(bottomAdsContainer);
  cmmtTag.appendChild(insUnit);

  // now store this comment embed in state
  return cmmtTag;
};



/* ++++++++++ --------------- CREATE AD EMBED --------------- ++++++++++ */
export const createAd = (ad) => {
  // create ad tag
  const adDiv = document.createElement('div');
  adDiv.setAttribute('id', ad.name);

  // now create a wrapping tag
  const adTag = createProductContainer(ad);

  // create new SCRIPT DOM element (must do this to execute script once loaded)
  const adScript = document.createElement('script');
  adScript.textContent = `Insticator.ad.loadAd("${ad.name}");`;

  // now append new DOM
  adTag.appendChild(adDiv);
  adTag.appendChild(adScript);

  // now store this comment embed in state
  return adTag;
};





/* ++++++++++ --------------- DRAG ELEMENT --------------- ++++++++++ */
// used for toolbar so users can easily move it out of the way
export const dragElement = (elmnt, dragHandle) => {
  var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
  if (dragHandle) {
    // if present, the header is where you move the DIV from:
    dragHandle.onmousedown = dragMouseDown;
  } else {
    // otherwise, move the DIV from anywhere inside the DIV:
    elmnt.onmousedown = dragMouseDown;
  }

  function dragMouseDown(e) {
    e = e || window.event;
    e.preventDefault();
    // get the mouse cursor position at startup:
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // call a function whenever the cursor moves:
    document.onmousemove = elementDrag;
  }

  function elementDrag(e) {
    e = e || window.event;
    e.preventDefault();
    // calculate the new cursor position:
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    elmnt.style.top = (elmnt.offsetTop - pos2) + 'px';
    elmnt.style.left = (elmnt.offsetLeft - pos1) + 'px';
  }

  function closeDragElement() {
    // stop moving when mouse button is released:
    document.onmouseup = null;
    document.onmousemove = null;
  }
};

// implementation
// dragElement(document.getElementById('toolbar'));




