/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React from 'react';
//material
import { Link } from '@insticator/insticator-ui'; // iui-material
import AnswerTaxonomy from 'apps/classification/pages/editor/answercontainer/answertaxonomy/answertaxonomy';
// styles
import injectSheet from 'react-jss';
import styles from 'apps/classification/pages/editor/answercontainer/answercontainer_styles';


/*  THIS COMPONENT ACCEPTS PROPS OF THE FOLLOWING FORM:
    const props = {
         text: "Atlanta",
         selected: true,
         correct: true,
         flagged: true,
         answerID: "dnkwjend1213",
         classification: ["lkmsdklf3kl3090l", "lkmsdklf3kl3090l",
                          "lkmsdklf3kl3090l", "lkmsdklf3kl3090l"]
    }

Note that the classification property is an array of deepest child IDs, which are used to populate each of the classifications
for the current answer. Each classification corresponds to a separate box (which may or may not have nested levels) in the taxonomy container.
*/


/* ========== ~~~~~~~~~~ ANSWERCONTAINER : CHILD ~~~~~~~~~~ ========== */
// PRESENTATIONAL COMPONENT
// receives app state & actions as props from parent "_container" component
// this child component handles local state and uses props passed from parent to read/write application state when necessary

const AnswerContainer = (props) => {
    //given the deepestChildID, returns an ordered list of the child IDs used in a single classification by iteratively
    //travelling up the taxonomy tree
    const getTaxonomyIDsFromTree = (deepestChildID) => {
        let currentID = deepestChildID;
        const taxonomyIDs = [];
        while (!!currentID){
            taxonomyIDs.unshift(currentID); //add the currentID to the front of the taxonomyID array, so the highest parent is at the front of the array.
            currentID = props.taxonomyTree[currentID] ? props.taxonomyTree[currentID].parentId : null; //set the currentID to the parent's ID
        }
        return taxonomyIDs;
    };
    const getTaxonomyTextFromID = (id) => props.taxonomyTree[id] ? (props.taxonomyTree[id].name) : 'This taxonomy was deleted by a super admin';
    const getDeepestChildID = (id) => props.taxonomyTree[id] ? (props.taxonomyTree[id].parentId) : null;
    //const getTaxonomyTextFromID = (id) => id;

    const renderTitle = () => (
        props.correct ? <div className={'answerTitle'}>
            <h5>{props.text}</h5>
            <h5>(correct answer)</h5></div>
            : <span className={'answertitle'}><h5>{props.text}</h5></span>
    );

    const renderClassifications = () => (
        props.classification.map((classification) => {
            //uncomment when taxonomy tree structure/actions are completed
            const classificationAsArray = getTaxonomyIDsFromTree(classification);
           // const classificationAsArray = classification.categoryPath.split(', ');
            return (<div className={'classificationcontainer'}>
                {renderTaxonomies(classificationAsArray, classificationAsArray.length, classification)}</div>)
        })
    );

    //renders all the taxonomies for the given classificiation by recursively rendering the AnswerTaxonomy component
    const renderTaxonomies = (classification, length, deepestChildID) => {
        if (classification.length === 0)
            return null;
        else {
            const taxonomyText = getTaxonomyTextFromID(classification[0]);
            const deepestChild = getDeepestChildID(classification[0]);
            const removeTaxonomyEvent = getRemoveTaxonomyEvent(deepestChildID, deepestChild);
            return (<AnswerTaxonomy text={taxonomyText} removeTaxonomyEvent={removeTaxonomyEvent} depth={length - classification.length} valueDeleted={!props.taxonomyTree[classification[0]]} >
                    {renderTaxonomies(classification.slice(1), length, deepestChildID)}
                </AnswerTaxonomy>)
        }
    };

    const getRemoveTaxonomyEvent = (deepestChildID, newDeepestChildID) => (()=>props.removeTaxonomy(props.answerIndex, deepestChildID, newDeepestChildID))

    const handleClearClick = () => {
        props.removeAllTaxonomies(props.answerIndex);
    };

    const handleFlagClick = () => {
        props.flagAnswer(props.answerIndex);
    };

    const handleAnswerSelect = (e) => {
        if (e.target.className === 'answertaxonomycontainer') //check if only answerbox itself was selected, not one of the classifications inside it.
            props.selectSingleAnswer(props.answerIndex);
    };

    const showFlagged = () => (props.flagged ? 'flagged' : null);

    const renderControls = () => (
        <div className={'controlcontainer'}>
            <div className={`clearbuttoncontainer control`}>
                <Link type={`custom`} handleClick={handleClearClick} icon={`times`} />
            </div >
            <div className={`flagbuttoncontainer control ${showFlagged()}`}>
                <Link type={`custom`} handleClick={handleFlagClick} disabled={props.activeQuestion.classified} icon={`flag`} />
            </div >
        </div>
    );
    return (
        <div className={`answercontainer ${props.classes.answercontainer}`}>
            <div className={'answerheader'}>
                {renderTitle()}
                {renderControls()}
            </div>
            <div className={'answertaxonomycontainer'} onClick={handleAnswerSelect}>
                {props.taxonomyReceived && renderClassifications()}
            </div>
        </div>
    );
};


/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// will be utilized in parent component after being infused with app state & actions
export default injectSheet(styles)(AnswerContainer);
