/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { useState } from 'react';
import { createPortal } from 'react-dom';



/* ++++++++++ --------------- IFRAME --------------- ++++++++++ */
const Iframe = ({ children, ...props }) => {
  const [contentRef, setContentRef] = useState(null)
  const mountNode = contentRef && contentRef.contentWindow.document.body

  return (
    <iframe id={props.id || 'frame'} className={'frame'} {...props} ref={setContentRef}>
      {mountNode &&
        createPortal(
          React.Children.only(children),
          mountNode
        )}
    </iframe>
  )
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default Iframe;
