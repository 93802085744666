/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React from 'react';
// material
import { Title, Group, Control } from '@insticator/insticator-ui'; // iui-material
// styles
import injectSheet from 'react-jss';
import styles from 'apps/classification/material/taxonomyeditor/taxonomyeditorheader/taxonomyeditorheader_styles';



/* ========== ~~~~~~~~~~ TAXONOMY EDITOR HEADER : CHILD ~~~~~~~~~~ ========== */
// PRESENTATIONAL COMPONENT
// receives app state & actions as props from parent "_container" component
// this child component handles local state and uses props passed from parent to read/write application state when necessary
const TaxonomyEditorHeader = (props) => {
    const handleClassifyMode = () => props.setTaxonomyEditorMode({ editMode: false });
    const handleEditMode = () => {
        if(props.categoriesSelected) props.deselectTaxonomyCategories();
        props.setTaxonomyEditorMode({ editMode: true });
    };
    const isSelected = () => props.isEditMode ? 'EDIT' : 'CLASSIFY';
    const renderControls = () => (
        <Group name={'editorstatecontrols'}>
            <div className={'controls'}>
                <Control selected={isSelected()} text={`Classify`} value={`CLASSIFY`} disabled={!props.classifyPermitted} handleSelect={handleClassifyMode} />
                <Control selected={isSelected()} text={`Edit`} value={`EDIT`} disabled={!props.classifyPermitted} handleSelect={handleEditMode} />
            </div>
        </Group>
    );

    return (
        <div className={`taxonomyeditorheader ${props.classes.taxonomyeditorheader}`}>
            <Group name={'editorheader'}>
                <Group name={'editortitle'}>
                    <Title variant={`withtip`}>
                        <h5>{'Insticator Taxonomy'}</h5>
                    </Title>
                </Group>
                { props.isCASuperAdmin ? renderControls() : null }
            </Group>
        </div>
    );
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// will be utilized in parent component after being infused with app state & actions
export default injectSheet(styles)(TaxonomyEditorHeader);
