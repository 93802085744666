/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
export default theme => ({
    pubsspmodal: {
        width: '60vw',
        display: 'flex',
        flexDirection: 'column',
        '& .title': {
            fontSize: pxToEm(20),
            fontWeight: 'bold',
            marginBottom: pxToEm(20)
        },
        '& .field': {
            marginBottom: pxToEm(20),
            '&:last-child': {
                marginBottom: 0
            },
            '& .formelement': {
                height: pxToEm(150)
            },
            '& .textarea': {
                whiteSpace: 'break-spaces'
            }
        }
    }
});
