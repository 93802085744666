/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { Component } from 'react';
import { Switch, Redirect } from 'react-router';
// material
import { Page } from '@insticator/insticator-ui';
// pages
import SSPManager from 'apps/sspadstxt/pages/sspmanager/sspmanager';
import AdxTxt from 'apps/sspadstxt/pages/adstxt/adstxt';
import PublisherManager from 'apps/sspadstxt/pages/publishermanager/publishermanager_container';
import SSPApprovals from 'apps/sspadstxt/pages/sspapprovals/sspapprovals_container'
// utils
import { PrivateRoute } from '@insticator/insticator-ui'; // iui-utils
// styles
import injectSheet from 'react-jss';
import styles from 'apps/classification/pages/dashboard/dashboard_styles';



/* ========== ~~~~~~~~~~ SSP / ADS.TXT DASHBOARD : CHILD ~~~~~~~~~~ ========== */
// PRESENTATIONAL COMPONENT
// receives app state & actions as props from parent "_container" component
// this child component handles local state and uses props passed from parent to read/write application state when necessary
class SSPAdxTxtDashboard extends Component {
    componentDidMount() {
        this.props.updateCurrentApp('sspadstxt');
    }
    render() {
        return (
            <Page name={`dashboard`} klass={this.props.classes.dashboard}>
                <div id={'main'}>
                    <Switch>
                        {/*Routes to subsections*/}
                        <PrivateRoute path='/apps/sspadstxt/sspmanager' component={SSPManager} permitted={true} redirectTo={'/apps'} />
                        <PrivateRoute path='/apps/sspadstxt/adstxt/masteradstxt' component={AdxTxt} permitted={true} redirectTo={'/apps'} />
                        <PrivateRoute path='/apps/sspadstxt/publishermanager' component={PublisherManager} permitted={true} redirectTo={'/apps'} />
                        <PrivateRoute path='/apps/sspadstxt/sspapprovals' component={SSPApprovals} permitted={true} redirectTo={'/apps'} />
                        <Redirect to='/error/404' />
                    </Switch>
                </div>
            </Page>
        );
    }
}


/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// will be utilized in parent component after being infused with app state & actions
export default injectSheet(styles)(SSPAdxTxtDashboard);
