import axios from 'axios';
import { url } from 'utils/urls';

const withCredentials = true;

export const fetchTaxonomy = () =>  axios.get(`${url.ca.category}/tree`, { withCredentials });

export const createCategory = category => {
    const headers = { "Content-Type": "application/json" };
    return axios.post(url.ca.category, category, { headers, withCredentials: true})
};

export const deleteCategory = categoryId => axios.delete(`${url.ca.category}/${categoryId}`, { withCredentials });

export const tempFilteredTaxonomy = (taxonomy, categoryId) => {
    delete taxonomy[categoryId];
    return taxonomy
};
