/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { combineReducers } from 'redux';
import siteStatus from './sitestatus';
import addProductsStatus from './addproducts';



/* ========== ~~~~~~~~~~ MOCKUP GENERATOR : ROOT REDUCER ~~~~~~~~~~ ========== */
const mockupGeneratorState = combineReducers({
    siteStatus,
    addProductsStatus
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default mockupGeneratorState;
