/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React from 'react';
import { Switch, Redirect } from 'react-router';
// material
// pages
import PublishersList from 'apps/sspadstxt/pages/publishermanager/publisherslist/publisherslist_container';
import PublisherDetail from 'apps/sspadstxt/pages/publishermanager/publisherdetail/publisherdetail_container';

// utils
import { PrivateRoute } from '@insticator/insticator-ui'; // iui-utils

/* ========== ~~~~~~~~~~ SSP / ADS.TXT SSP MANAGER ~~~~~~~~~~ ========== */
// PRESENTATIONAL COMPONENT
// receives app state & actions as props from parent "_container" component
// this child component handles local state and uses props passed from parent to read/write application state when necessary
export default (props) => (
    <Switch>
        {/*Routes to subsections*/}
        <PrivateRoute path='/apps/sspadstxt/publishermanager/publisherslist' component={PublishersList} permitted={true} redirectTo={'/apps'} />
        <PrivateRoute path='/apps/sspadstxt/publishermanager/publisherdetail' component={PublisherDetail} permitted={!!props.selectedPubDetails} redirectTo={'/apps/sspadstxt/publishermanager/publisherslist'} />
        <Redirect to='/error/404' />
    </Switch>
)


/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// will be utilized in parent component after being infused with app state & actions
