/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state
import { getSSPs, getSSPDetails, updateSSPManagerFilter } from 'apps/sspadstxt/state/actions/sspmanagerstatus';
import { updatePageDetails, changePage } from 'state/actions/app_status';
// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import SSPsList from 'apps/sspadstxt/pages/sspmanager/sspslist/sspslist';


/* ========== ~~~~~~~~~~ SSPs LIST: PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ sspAdsTxtState }) => ({
    filters: sspAdsTxtState.sspManagerStatus.sspManagerFilter,
    ssps: sspAdsTxtState.sspManagerStatus.ssps,
    fetchingSSPs: sspAdsTxtState.sspManagerStatus.fetchingSSPs,
    currentPage: sspAdsTxtState.sspManagerStatus.currentPage,
    totalRows: sspAdsTxtState.sspManagerStatus.totalRows,
    totalPages: sspAdsTxtState.sspManagerStatus.totalPages,
    fetchingSSPDetails: sspAdsTxtState.sspManagerStatus.fetchingSSPDetails
});


// map actions
const mapDispatchToProps = dispatch => ({
    getSSPs: filterData => dispatch(getSSPs(filterData)),
    getSSPDetails: sspUUID => dispatch(getSSPDetails(sspUUID)),
    updateSSPManagerFilter: (filterName, filterData) => dispatch(updateSSPManagerFilter(filterName, filterData)),
    moveToPage: (location) => {
        dispatch(updatePageDetails(location));
        dispatch(changePage(location))
    },
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SSPsList);
