/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import { connect } from 'react-redux';
import { withRouter } from 'react-router'
// actions
import { updateCurrentApp, updatePageDetails, changePage } from 'state/actions/app_status';

import { getSiteList, updateCurrentSite, getSiteMockUps } from 'apps/mockupgenerator/state/actions/sitestatus';
import { getMockup } from 'apps/mockupgenerator/state/actions/addproducts';
// component
import MockupGeneratorDashboard from 'apps/mockupgenerator/pages/dashboard/dashboard';



/* ========== ~~~~~~~~~~ MOCKUP GENERATOR DASHBOARD ~~~~~~~~~~ ========== */
// map state
const mapStateToProps = ({ appStatus, mockupGeneratorState }) => ({
    currentPage: appStatus.currentPage,
    siteStatus: mockupGeneratorState.siteStatus,
    fetchingSites: mockupGeneratorState.siteStatus.fetchingSites,
    siteList: mockupGeneratorState.siteStatus.siteList,
    currentSite: mockupGeneratorState.siteStatus.currentSite,
    queryParams: mockupGeneratorState.siteStatus.queryParams,
});

// map actions
const mapDispatchToProps = dispatch => ({
	updateCurrentApp: name => dispatch(updateCurrentApp(name)),
    getSiteList: siteUUID => dispatch(getSiteList(siteUUID)),
    updateCurrentSite: site => dispatch(updateCurrentSite(site)),
    getSiteMockUps: (siteUUID) => dispatch(getSiteMockUps(siteUUID)),
    getMockUp: pageUUID => dispatch(getMockup(pageUUID)),
    moveToPage: (location) => {
        dispatch(updatePageDetails(location));
        dispatch(changePage(location))
    },
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default withRouter(connect(
    mapStateToProps,
    mapDispatchToProps
)(MockupGeneratorDashboard));
