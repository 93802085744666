/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { combineReducers } from 'redux';
import publisherManagerStatus from 'apps/sspadstxt/state/reducers/publishermanagerstatus'
import masterAdsTxtStatus from 'apps/sspadstxt/state/reducers/masteradstxtstatus'
import sspManagerStatus from 'apps/sspadstxt/state/reducers/sspmanagerstatus'
import sspApprovalsStatus from 'apps/sspadstxt/state/reducers/sspapprovalsstatus'

/* ========== ~~~~~~~~~~ SSP / ADS.TXT STATE : ROOT REDUCER ~~~~~~~~~~ ========== */
// COMBINE REDUCERS
// used to comhine all various reducers together into an easily accesible object
// utilized in store.js
const sspAdsTxtState = combineReducers({
    publisherManagerStatus,
    masterAdsTxtStatus,
    sspManagerStatus,
    sspApprovalsStatus
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default sspAdsTxtState;
