/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { validation } from '@insticator/insticator-ui'; // iui-utils



/* --------------- UTILITIES : VALIDATION --------------- */
const uniqueValidation = {
    // +++ STANDARD VALIDATION +++ //
    ...validation,

    //MOCK UP GENERATOR
    createSite:{
        test: /^(https?:\/\/(?:www\.|(?!www))[^\s\.]+\.[a-z]{2,})?[^\/-]+?$/m,
        message: 'You need to enter a valid url with no trailing path.'
    }
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export { uniqueValidation as validation };
