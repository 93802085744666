/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// PUBLISHER DETAIL : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    master: {
        width: '100%',
        '& .valuehighlighter': {
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            maxHeight: pxToEm(183),
            overflow: 'scroll'
        }
    }
});