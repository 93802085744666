/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { Component } from 'react';
// material
import { Icon, DeleteMessage } from '@insticator/insticator-ui'; // iui-material
// styles
import injectSheet from 'react-jss';
import styles from 'apps/classification/material/taxonomyeditor/taxonomyeditormodal/taxonomyeditormodal_styles';



/* ========== ~~~~~~~~~~ MODAL : CHILD ~~~~~~~~~~ ========== */
// PRESENTATIONAL COMPONENT
// receives app state & actions as props from parent "_container" component
// this child component handles local state and uses props passed from parent to read/write application state when necessary
class TaxonomyEditorModal extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.visible !== prevProps.visible) {
            if (this.props.visible) {
                document.addEventListener('mousedown', this.handleOutsideClick, false);
            } else {
                document.removeEventListener('mousedown', this.handleOutsideClick, false);
            }
        }
    }

    handleClose = () => {
        this.props.closeTaxonomyEditorMessage();
    };

    handleOutsideClick = (e) => {
        // ignore clicks on the component itself
        if (this.node.contains(e.target)) { return; }
        this.handleClose();
    };

    renderMessage = () => {
        switch (this.props.messageType) {
            case 'delete':
                return <DeleteMessage itemType={'taxonomycategory'}
                                      itemName={'taxonomycategory'}
                                      uniqueProps={this.props.uniqueProps}
                                      primaryAction={() => this.props.deleteTaxonomyCategory(this.props.uniqueProps.categoryId)}
                                      secondaryAction={this.props.closeTaxonomyEditorMessage} />;
            default:
                return null;
        }
    };

    render() {
        if(!this.props.visible) return null;
        return (
            <div id={`modal`} className={`modal ${this.props.classes.taxonomyeditormodal} ${this.props.visible ? 'active' : 'inactive'}`} ref={node => {this.node=node;}}>
                    <div className={`modalcontent`}>
                        <div className={`modalpage`}>
                            {!!this.props.showCloseButton && <div className={`close`} onClick={this.handleClose}><Icon name={`times-circle`} /></div>}
                            {this.renderMessage()}
                        </div>
                    </div>
            </div>
        );
    }
}



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(TaxonomyEditorModal);
