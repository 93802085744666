/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { Component } from 'react';
//material
import { Page, Content, Group, CTAs, LoadState, Icon, SimplePagination, NumberedPagination, Control, Button, CheckBox, DynamicTable, SearchBar, ContentType, FilterMenu } from '@insticator/insticator-ui'; // iui-material
import QuestionStatusIndicator from 'apps/classification/pages/questionmanager/questionstatusindicator/questionstatusindicator';
//utils
import { debounce, numberWithCommas } from '@insticator/insticator-ui'; // iui-utils
//styles
import injectSheet from 'react-jss';
import styles from 'apps/classification/pages/questionmanager/questionmanager_styles';



/* ++++++++++ --------------- QUESTION MANAGER --------------- ++++++++++ */
class QuestionManager extends Component {
    constructor(props) {
        super(props);
        this.state = { sortedByAnswerCount: false, sorting: false };
        this.activeFilters = {
            questionType: [],
            classificationStatus: [],
            flagged: [],
            notes: [],
            adminUUIDs: []
        }
    }

    componentWillMount() {
        this.filterSearch({ page: 1 });
    }

    componentDidUpdate(prevProps) {
        if(this.props.isLiveOnDMP !== prevProps.isLiveOnDMP) this.filterSearch({ page: 1 });
        if(this.props.triggerRefetch !== prevProps.triggerRefetch && this.props.triggerRefetch) this.filterSearch({});
    }

    filterSearch = ({ searchTerm, filters, page }) => {
        const params = this.transpileReducerForFetch({
            searchTerm: typeof searchTerm !== 'undefined'? searchTerm : this.props.searchTerm,
            filters: !!filters ? filters : this.activeFilters,
            page: !!page ? page : this.props.paginatedPage,
            download: false
        });
        this.props.getClassificationQuestions(params);
    };

    appyFilters = (filters) => {
        this.updateActiveFilters(filters);
        this.filterSearch({ filters, page: 1 });
    };

    updateActiveFilters = filters => {
        Object.keys(filters).forEach(filterName => this.activeFilters[filterName] = filters[filterName])
    };

    transpileReducerForFetch = ({ searchTerm, filters, page, download }) => ({
        questionType: this.handleNullArrayCases(filters.questionType, 3),
        liveOnDMP: this.props.isLiveOnDMP,
        classificationStatus: this.handleNullArrayCases(filters.classificationStatus, 2, true),
        flagged: this.handleNullArrayCases(filters.flagged, 2, true),
        notes: this.handleNullArrayCases(filters.notes, 2, true),
        adminUUIDs: this.handleNullArrayCases(!!filters.adminUUIDs ? filters.adminUUIDs : this.activeFilters.adminUUIDs, Object.keys(this.props.fellowAdmins).length),
        searchTarget: this.props.searchTarget.value,
        searchTerm: typeof searchTerm === 'string' ? searchTerm : this.props.searchTerm,
        pageNumber: page ? page : this.props.paginatedPage,
        questionsPerPage: this.props.questionsPerPage,
        exportData: !!download,
        adminUUIDAndNameMap: !!download ? this.props.fellowAdmins : null
    });

    //leave this ternary alone
    handleNullArrayCases = (arr, numOfElements, string) => !!arr.length ? (arr.length !== numOfElements ? (arr.length === 1 && string) ? arr.join('') : arr : null) : null;

    handlePrevious = () => {
        const page = this.props.paginatedPage - 1;
        this.filterSearch({ page });
    };

    handleNext = () => {
        const page = this.props.paginatedPage + 1;
        this.filterSearch({ page });
    };

    handlePage = (page) => {
        this.filterSearch({ page });
    };

    allQuestionsSelected = () => this.props.pagesSelectedAll.includes(this.props.paginatedPage);

    sortByAnswerCount = () => {
        let sortedByAnswerCount = !this.state.sortedByAnswerCount;
        this.setState({sortedByAnswerCount, sorting: true});
    };

    searchQuestions = (e) => {
        this.filterSearch({ searchTerm: e, page: 1 });
        this.props.updateSearchTerm(e);
    };

    transpileQuestionData = (questionData) => {
        let questions = [];
        Object.keys(questionData).forEach(question => {
            questions.push({
                selected: <CheckBox handleClick={()=>this.props.selectSingleQuestion(parseInt(question))} on={this.props.selectedQuestions.includes(parseInt(question))} />,
                questionType: questionData[question].questionType,
                answerCount: questionData[question].answerCount,
                question: questionData[question].question,
                subRowData: questionData[question].answers.map(answer => (
                    {
                        answer: answer.correct ? `${answer.text} (correct answer)` : answer.text,
                        classification: answer.classification.map(classification => classification.categoryPath).join(' : '),
                        flag: answer.flagged ? <Icon name={'flag'} /> : null
                    })
                ),
                classificationStatus: <QuestionStatusIndicator status={questionData[question].classified}/>,
                notes: questionData[question].notes ? `"${questionData[question].notes}"` : null,
                admin: this.props.fellowAdmins[questionData[question].adminUUID]
            })
        });
        if(this.state.sorting) this.setState({sorting: false});
        questions = this.state.sortedByAnswerCount ? questions.sort((q1,q2) => q1.answerCount - q2.answerCount) : questions.sort((q1,q2) => q2.answerCount - q1.answerCount);
        return questions;
    };

    renderQuestionTableHeaders = () => [
        {text:'', value: 'selected'},
        {text:'Question Type', value: 'questionType'},
        {text: 'Answer Count', value: 'answerCount', sort: true, sorted: this.state.sortedByAnswerCount, action: this.sortByAnswerCount},
        {text: 'Question', value: 'question'},
        {text: 'Answer', value: 'answer'},
        {text: 'Classification', value: 'classification'},
        {text: 'Flag', value:'flag'},
        {text: 'Classification Status', value: 'classificationStatus'},
        {text: 'Notes', value: 'notes'},
        {text: 'Admin', value: 'admin'}
    ];

    renderDynamicTableState = () => {
        const tableData = this.transpileQuestionData(this.props.questions);
        return (this.props.questionDataFetching || this.state.sorting) ? this.renderLoadingTable() : this.renderDynamicTable(tableData)
    };

    renderDynamicTable = (tableData) => (<DynamicTable headers={this.renderQuestionTableHeaders()} subHeaderValues={['answer', 'classification', 'flag']} data={tableData}/>);
    renderLoadingTable = () => (<LoadState graphicName={`insticator-icon`} graphicText={`Browser with Search Icon`} bodycopy={`Insticator is loading your data.`} />);

    renderExportAll = shouldI => shouldI ? <Button type={'action'} action={this.downloadCSV} variant={'system'} operation={'download'} processing={this.props.exportingQuestionsCSV} text={'Export to .csv'} icon={'download'} /> : null;
    renderSetLiveOnDMP = shouldI => shouldI ? <Button variant={'primary'} type={'action'} action={this.setQuestionsLiveOnDMP} text={'Set Live in DMP'} icon={'address-card'} processing={this.props.settingLiveOnDMP} operation={'load'} disabled={!this.props.selectedQuestions.length || this.props.isLiveOnDMP} /> : null;

    setQuestionsLiveOnDMP = () => this.props.setQuestionsLiveOnDMP(this.props.selectedQuestions);
    downloadCSV = () => this.props.downloadCSV(this.transpileReducerForFetch({
        searchTerm: this.props.searchTerm,
        filters: this.activeFilters,
        page: this.props.paginatedPage,
        download: true
    }));

    getFilters = () => {
        const filters = {
            questionType: {
                title: `Question Type:`,
                icon: `question`,
                initialState: this.activeFilters.questionType,
                items: [
                    { value: 'QUIZ', text: <ContentType isLive={true} type={'quiz'} /> },
                    { value: 'POLL', text: <ContentType isLive={true} type={'poll'} /> },
                    { value: 'SURVEY', text: <ContentType isLive={true} type={'profile'} /> }
                ]
            },
            classificationStatus: {
                title: `Classification Status:`,
                icon: `columns`,
                initialState: this.activeFilters.classificationStatus,
                items: [
                    { value: 'INCOMPLETE', text: <QuestionStatusIndicator status={false}/> },
                    { value: 'COMPLETE', text: <QuestionStatusIndicator status={true}/> }
                ]
            },
            flagged: {
                title: `Flagged:`,
                icon: `flag`,
                initialState: this.activeFilters.flagged,
                items: [
                    { value: 'FLAGGED', text: 'Yes' },
                    { value: 'NOT_FLAGGED', text: 'No' }
                ]
            },
            notes: {
                title: `Notes:`,
                icon: `file`,
                initialState: this.activeFilters.notes,
                items: [
                    { value: 'WITH_NOTES', text: 'Has notes' },
                    { value: 'WITHOUT_NOTES', text: 'No notes' }
                ]
            }
        };

        if(Object.keys(this.props.fellowAdmins).length > 0) filters['adminUUIDs'] = this.getAdminsFilter();

        return filters;
    };

    getAdminsFilter = () => ({
        title: `Admins:`,
        icon: `users`,
        initialState: this.activeFilters.adminUUIDs,
        items: Object.keys(this.props.fellowAdmins).map(key =>
            ({ value: key, text: this.props.fellowAdmins[key]}))
    });

    render() {
        return (
            <Page klass={`questionmanager ${this.props.classes.questionmanager}`}>
                <Content>
                    <Group name={'dmpcontrols'}>
                        <div className={'controls'}>
                            <Control selected={this.props.isLiveOnDMP} text={`Pending Questions`} value={false} handleSelect={this.props.questionsLiveOrPending} />
                            <Control selected={this.props.isLiveOnDMP} text={`Questions in DMP`} value={true} handleSelect={this.props.questionsLiveOrPending} />
                        </div>
                    </Group>
                    <Group name={'paginationexport'}>
                        {!this.props.questionsReceived ? null :
                            <SimplePagination
                                handleNext={this.handleNext}
                                handlePrevious={this.handlePrevious}
                                itemsPerPage={this.props.questionsPerPage}
                                currentPage={this.props.paginatedPage}
                                currentIndex={this.props.paginatedPage * this.props.questionsPerPage}
                                totalItems={this.props.totalQuestions}
                                totalItemsDisplay={`${numberWithCommas(this.props.totalQuestions)} total questions`}
                            />
                        }
                        {this.renderExportAll(this.props.isCASuperAdmin)}
                    </Group>
                    <Group name={'filtersandctas'}>
                        <div className={'selectall'}>
                            <CheckBox handleClick={this.allQuestionsSelected() ? this.props.unselectAllQuestions : this.props.selectAllQuestions} on={this.allQuestionsSelected()} />
                            <span className={'text'}>Select All</span>
                        </div>
                        <div className={'searchfilter'}>
                            <FilterMenu name={`filtermenu`} onApply={this.appyFilters} filters={this.getFilters()} />
                            <SearchBar placeholder={'Search'} onChange={debounce(this.searchQuestions, 500)} selectCategory={this.props.updateSearchTarget} currentCategory={this.props.searchTarget} categories={[{name: 'Categories', value: 'CATEGORY'}, {name: 'Questions', value: 'QUESTION'}]}/>
                        </div>
                        <CTAs>
                            <Button variant={'action'} type={'action'} action={this.props.editSelection} text={'Edit Selection'} icon={'edit'} disabled={!this.props.selectedQuestions.length} />
                            {this.renderExportAll(!this.props.isCASuperAdmin)}
                            {this.renderSetLiveOnDMP(this.props.isCASuperAdmin)}
                        </CTAs>
                    </Group>
                </Content>
                <Group name={'questionstable'}>
                    {this.renderDynamicTableState()}
                    <Group name={'selectedquestions'} >
                        {!!this.props.selectedQuestions.length && <div className={'questioncount'}>
                            (<span className={'amount'}>{this.props.selectedQuestions.length}</span>
                            {` question${this.props.selectedQuestions.length === 1 ? '' : 's'} selected`})
                        </div>}
                    </Group>
                    {!this.props.questionsReceived ? null :
                        <NumberedPagination
                            handleNext={this.handleNext}
                            handlePrevious={this.handlePrevious}
                            itemsPerPage={this.props.questionsPerPage}
                            currentIndex={this.props.paginatedPage * this.props.questionsPerPage}
                            totalItems={this.props.totalQuestions}
                            totalItemsDisplay={`${numberWithCommas(this.props.totalQuestions)} total questions`}
                            currentPage={this.props.paginatedPage}
                            pageNumAmount={5}
                            handlePage={this.handlePage}
                            totalPages={this.props.totalPages}
                        />
                    }
                </Group>
            </Page>
        )
    }
}


/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(QuestionManager);
