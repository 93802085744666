/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router';
// material
import { Page, Content } from '@insticator/insticator-ui';
import TaxonomyEditor from 'apps/classification/material/taxonomyeditor/taxonomyeditor_container';
// pages
import QuestionManager from 'apps/classification/pages/questionmanager/questionmanager_container';
import Editor from 'apps/classification/pages/editor/editor_container';
// utils
import { PrivateRoute } from '@insticator/insticator-ui'; // iui-utils
// styles
import injectSheet from 'react-jss';
import styles from 'apps/classification/pages/dashboard/dashboard_styles';



/* ========== ~~~~~~~~~~ DASHBOARD : CHILD ~~~~~~~~~~ ========== */
// PRESENTATIONAL COMPONENT
// receives app state & actions as props from parent "_container" component
// this child component handles local state and uses props passed from parent to read/write application state when necessary
class Dashboard extends Component {
    componentDidMount() {
        this.props.updateCurrentApp('classification');
    }
    closeTaxonomy = () => !this.props.currentPage.includes('editor') ? this.props.triggerTaxonomyEditor(false) : this.props.setTaxonomyEditorMode({ editMode: false });
    showDimmer = () => this.props.showTaxonomyEditor && this.props.isEditMode;
    render() {
        return (
            <Page name={`dashboard`} klass={this.props.classes.dashboard}>
                <div className={'wrapper'}>
                    <TaxonomyEditor visible={this.props.showTaxonomyEditor} />
                    <div id={'main'}>
                        <div className={`dimmer visible-${this.showDimmer()}`} onClick={this.closeTaxonomy} />
                        <Switch>
                            {/*Routes to subsections*/}
                            <PrivateRoute path='/apps/classification/editor' component={Editor} permitted={this.props.questionsSelected} redirectTo={'/apps/classification/manager'} />
                            <Route path='/apps/classification/manager' component={QuestionManager} />
                            <Redirect to='/error/404' />
                        </Switch>
                    </div>
                </div>
            </Page>
        );
    }
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// will be utilized in parent component after being infused with app state & actions
export default injectSheet(styles)(Dashboard);
