/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import {
    TAXONOMY_RECEIVED
} from 'apps/classification/state/actions/taxonomystatus';

import {
    REMOVE_TAXONOMY_CATEGORY,
} from 'apps/classification/state/actions/taxonomytree';



// ^-^ ^-^ ^-^ ^-^ ^-^   TAXONOMY STATUS: DEFAULT STATE   ^-^ ^-^ ^-^ ^-^ ^-^ //

const defaultState = {
    taxonomy: {},
    topLevelCategories: [],
    taxonomyReceived: false
};


/* ========== ~~~~~~~~~~ TAXONOMY TREE : REDUCER ~~~~~~~~~~ ========== */
// REDUCER
// used to manipulate application state
// takes an action, checks its type, based on that performs certain functionality, then returns new slice of state
// utilized in "_container" components
const taxonomyTree = (state = defaultState, action) => {
    switch (action.type) {
        case TAXONOMY_RECEIVED:
            return processTaxonomy(defaultState, action.taxonomy);
        case REMOVE_TAXONOMY_CATEGORY:
            let newState = JSON.parse(JSON.stringify(state));
            return killCategoryById(action.categoryId, newState);
        default:
            return state;
    }
};


// ^-^ ^-^ ^-^ ^-^ ^-^   TAXONOMY TREE: TRANSPILERS/HELPER FUNCTIONS   ^-^ ^-^ ^-^ ^-^ ^-^ //
const processTaxonomy = (passedState, receivedTaxonomy) => {
    const newState = JSON.parse(JSON.stringify(passedState));
    Object.entries(receivedTaxonomy).forEach(([id, category]) => {
        newState.taxonomy[id] = { ...category, isSelected: false, isDeleted: false };
        if(category.parentId === null) newState.topLevelCategories.push(id)
    });
    //TODO hey Vlad you can remove or update this logic if you want to
    newState.taxonomyReceived = true;
    return newState;
};


const killCategoryById = (categoryId, state) => {
    let newState = JSON.parse(JSON.stringify(state));

    // 1. Remove the category id from its parent list of children or from the list of top level categories.
    const parentId = newState.taxonomy[categoryId]['parentId'];
    const siblings = !!parentId ? newState.taxonomy[parentId]['children'] : newState.topLevelCategories;
    const categoryIdx = siblings.indexOf(categoryId);
    if (categoryIdx > -1) { siblings.splice(categoryIdx, 1) }
    if(!!parentId) {
        newState.taxonomy[parentId]['children'] = siblings;
    } else {
        newState.topLevelCategories = siblings;
    }

    // 4. Recursively delete category's children.
    const childrenIds = newState.taxonomy[categoryId]['children'].slice(0);
    for (let childId of childrenIds) newState = killCategoryById(childId, newState);

    // 3. Delete itself.
    delete newState.taxonomy[categoryId];
    return newState
};

/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default taxonomyTree;
