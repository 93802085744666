/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// QUESTION EDITOR : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    editor: {
        '& #content': {
            width: '100%',
            paddingLeft: pxToEm(25),
            paddingBottom: pxToEm(0),
            '& .editorcontent': {
                paddingBottom: pxToEm(70)
            },
            '& .controlbar': {
            	background: '#fff',
            	position: 'sticky',
            	bottom: '0',
                paddingBottom: pxToEm(24),
            	'& .fade': {
            		marginTop: '0',
            		position: 'absolute',
            		top: pxToEm(-120)
            	}
            }
        }
    }
});