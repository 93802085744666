/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'theming';
import { theme } from '@insticator/insticator-ui'; // iui-utils
import store from 'state/store/store';
import App from 'app_container';
import 'utils/api/interceptors';



/* /////////////// RENDER \\\\\\\\\\\\\\\ */
// MAIN ENTRY POINT
// utilizes "provider" for redux application state management 
// "provider" helps child components have access to store (application state)
render(
	<Provider store={store}>
		<ThemeProvider theme={theme}>
			<App />
		</ThemeProvider>
	</Provider>, 
	document.getElementById('root')
);
