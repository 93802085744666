/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm, replacePXinStringWithEM, pxToEmWithBaseline } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// TAXONOMY TREE : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    taxonomytree: {
        margin: replacePXinStringWithEM(`18px 0 0`),
        height: pxToEmWithBaseline({ value: 658, fontSizeBaseline: 14 }),
        overflow: 'auto',
        fontSize: pxToEm(14),
        paddingBottom: pxToEm(150), // done because of the fade effect at the bottom to ensure we can scroll bottom elements into view to be clickable
        position: 'relative',
        scrollBehavior: 'auto',
        '& .loadstate': {
        	'& .illustration, .bodycopy, .loader': {
        		opacity: '1' // for some reason the 0 value is causing this to never show, temp override here, but need to investigate
        	}
        }
    }
});
