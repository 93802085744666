/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state
import { getSSPList, updateCustomFileName,
    updateCustomAdsTxtValues, invalidateCustomFile,
    handleMasterSSPValues, discardPreferredChanges,
    removeSSPValues, addSSPValues, updateSiteDetails,
    deleteSiteDetails, handleEdit, clearSelection } from 'apps/sspadstxt/state/actions/publishermanagerstatus';
import { displayModal, sendErrorMessage } from '@insticator/insticator-ui';

// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import Custom from 'apps/sspadstxt/pages/publishermanager/publisherdetail/editsite/preferredimplementation/type/custom/custom';


/* ========== ~~~~~~~~~~ MASTER: CHILD ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ authStatus, sessionStatus, sspAdsTxtState }) => ({
    masterAdsTxtValues: sspAdsTxtState.publisherManagerStatus.masterAdsTxtValues,
    selectedSite: sspAdsTxtState.publisherManagerStatus.selectedSite,
    sspList: sspAdsTxtState.publisherManagerStatus.sspList,
    fileName: sspAdsTxtState.publisherManagerStatus.newCustomFileDetails.fileName,
    customFileSaved: sspAdsTxtState.publisherManagerStatus.newCustomFileDetails.savedCustomFile,
    adInventoryIds: sspAdsTxtState.publisherManagerStatus.newCustomFileDetails.adInventoryIds,
    storedFileType: sspAdsTxtState.publisherManagerStatus.storedPreferredImplementation.masterFileType,
    customMasterFileType: sspAdsTxtState.publisherManagerStatus.newCustomFileDetails.customMasterFileType,
    customMasterFileTypeOKO: sspAdsTxtState.publisherManagerStatus.newCustomFileDetails.customMasterFileTypeOKO,
    customMasterFileTypeS2S: sspAdsTxtState.publisherManagerStatus.newCustomFileDetails.customMasterFileTypeS2S,
    storedMasterFiles: sspAdsTxtState.publisherManagerStatus.storedMasterFiles,
    storedMasterFilesOKO: sspAdsTxtState.publisherManagerStatus.storedMasterFilesOKO,
    storedMasterFilesS2S: sspAdsTxtState.publisherManagerStatus.storedMasterFilesS2S,
    selectedAccountUUID: sspAdsTxtState.publisherManagerStatus.selectedPubDetails.accountUUID,
    selectedOverrideAccountId: sspAdsTxtState.publisherManagerStatus.selectedPubDetails.accountOverrideId,
    storedSSPValues: sspAdsTxtState.publisherManagerStatus.storedSSPValues,
    adsTxtValues: sspAdsTxtState.publisherManagerStatus.newCustomFileDetails.adsTxtValues,
    siteAdstxtID: sspAdsTxtState.publisherManagerStatus.siteAdstxtID,
    isEditMode: sspAdsTxtState.publisherManagerStatus.isEditMode,
    loading: sspAdsTxtState.publisherManagerStatus.loading,
    adsTxtFileRows: sspAdsTxtState.publisherManagerStatus.preferredImplementation.adsTxtFileRows,
    storedEnableAutoInsert: sspAdsTxtState.publisherManagerStatus.preferredImplementation.enableAutoInsert
});

// map actions
const mapDispatchToProps = dispatch => ({
    getSSPList: () => dispatch(getSSPList()),
    updateCustomFileName: name => dispatch(updateCustomFileName(name)),
    updateCustomAdsTxtValues: values => dispatch(updateCustomAdsTxtValues(values)),
    invalidateCustomFile: () => dispatch(invalidateCustomFile()),
    updateSiteDetails: (adstxtID, selectedSite, details) => dispatch(updateSiteDetails(adstxtID, selectedSite, details)),
    delete: (adstxtID, seatType) => dispatch(deleteSiteDetails(adstxtID, seatType)),
    displayModal: (isVisible, contentToDisplay, showCloseButton) => dispatch(displayModal(isVisible, contentToDisplay, showCloseButton)),
    sendErrorMessage: message => dispatch(sendErrorMessage(message)),
    handleEdit: status => dispatch(handleEdit(status)),
    clearSelection: () => dispatch(clearSelection()),
    handleMasterSSPValues: (masterFileTypeAdd, masterFileTypeRemove, addArr, removeArr, seatType) => dispatch(handleMasterSSPValues(masterFileTypeAdd, masterFileTypeRemove, addArr, removeArr, seatType)),
    removeSSPValues: (adInventoryID, values) => dispatch(removeSSPValues(adInventoryID, values)),
    addSSPValues: (adInventoryID, values) => dispatch(addSSPValues(adInventoryID, values)),
    discardPreferredChanges: () => dispatch(discardPreferredChanges())
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Custom);
