import axios from 'axios';
import { url } from 'utils/urls'

const withCredentials = true;
const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };

export const asyncGetPublishers = filters => axios.post(`${url.spm.url}publishers/adstxt/list`, filters, { withCredentials });

export const asyncGetAdminsList = () => axios.get(`${url.embed.admin.url}/role?roles=Account_Manager`);

export const asyncGetSites = accountUUID => axios.get(`${url.ppa.accounts}/${accountUUID}/sites?filterRonSites=false`, { withCredentials });

export const asyncGetSiteDetails = (siteUUID, seatType) => axios.get(`${url.spm.url}publishers/adstxt/sites/${siteUUID}?seatType=${seatType}`, { withCredentials });

export const asyncGetSiteSSPDetails = (siteUUID, seatType) => axios.get(`${url.spm.url}publishers/ssps/sites/${siteUUID}?seatType=${seatType}`, { withCredentials });

export const asyncUpdateSiteDetails = ( publisherAdstxtID, details ) => axios.put(`${url.spm.url}publishers/adstxt/${publisherAdstxtID}`, details, { withCredentials });

export const asyncDeleteSiteDetails = publisherAdstxtID => axios.delete(`${url.spm.url}publishers/adstxt/custom/${publisherAdstxtID}`, { withCredentials });

export const asyncGetMasterAdsTxtFileType = (fileType, includeInsticatorAdsTxtValue, seatType) => axios.get(`${url.spm.url}masteradstxt/values?fileType=${fileType}&includeInsticatorAdsTxtValue=${includeInsticatorAdsTxtValue}&seatType=${seatType}`, { withCredentials });

export const asyncGetSSPList = () => axios.get(`${url.spm.url}ssps/adstxt/types`, { withCredentials });

export const asyncGetSpecificSSPValues = adInventoryID => axios.get(`${url.spm.url}ssps/adstxtinventories/${adInventoryID}`, { withCredentials });

export const exportPublisherAdsTxt = () => axios.get(`${url.spm.url}publishers/adstxt/export/all`, { withCredentials });


const mockSiteSSPDetails = {
    "123": {
    "sspId" : "123",
        "sspName" : "OpenX",
        "approvalStatus" : "Approved",
        "sspType" : "Display And Video",
        "adsTxtValuesStatus" : "Some Values (2/5)",
        "adsTxtValuesDetails" : {
        "matchingAdsTxtValues" : [
            "openx.com, 537117488, RESELLER, 6a698e2ec38604c6",
            "openx.com, 540105394, RESELLER, 6a698e2ec38604c6"
        ],
            "missingAdsTxtValues" : [
            "openx.com, 537117488, RESELLER, 6a698e2ec38604c6",
            "openx.com, 540105394, RESELLER, 6a698e2ec38604c6",
            "openx.com, 540613307, RESELLER, 6a698e2ec38604c6"
        ]
    },
    "enableStatus": true,
        "enableAction" : "No Action",
        "tagsTraffickedStatus": "Some Trafficked (4/6)",
        "tagsTraffickedDetails": {
        "traffickedTags": [
            "web: div-insticator-ad-1: the-numbers.com_Web_300x600_1",
            "web: div-insticator-ad-2: the-numbers.com_Web_300x600_2",
            "web: sticky: the-numbers.com_Web_728x90_InView",
            "web: div-insticator-ad-cmt-1: the-numbers.com_Web_728x90_2"
        ],
            "unTraffickedTags": [
            "web: div-insticator-ad-3: the-numbers.com_Web_300x600_3",
            "web: div-insticator-ad-4: the-numbers.com_Web_300x600_4"
        ],
            "unMappedTags": [
            "the-numbers.com_Web_300x600_5",
            "the-numbers.com_Web_300x600_6"
        ]
    }
},
    "234": {
    "sspId" : "234",
        "sspName" : "Amazon",
        "approvalStatus" : "Approved",
        "sspType" : "Display",
        "adsTxtValuesStatus" : "Some Values (5/7)",
        "adsTxtValuesDetails" : {
        "matchingAdsTxtValues" : [
            "districtm.io, 100962, RESELLER",
            "aps.amazon.com, 70fb13d1-ab65-42ac-a7ca-0b4e680d5c92, DIRECT",
            "rubiconproject.com, 18020, RESELLER, 0bfd66d529a55807",
            "pubmatic.com, 157150, RESELLER, 5d62403b186f2ace",
            "rhythmone.com, 1654642120, RESELLER, a670c89d4a324e47"
        ],
            "missingAdsTxtValues" : [
            "adtech.com, 12068, RESELLER",
            "openx.com, 540191398, RESELLER, 6a698e2ec38604c6"
        ]
    },
    "enableStatus": false,
        "enableAction" : "Turn On (Display)",
        "tagsTraffickedStatus": "All Trafficked",
        "tagsTraffickedDetails": {
        "traffickedTags": [
            "web: div-insticator-ad-1: the-numbers.com_Web_300x600_1",
            "web: div-insticator-ad-2: the-numbers.com_Web_300x600_2",
            "web: sticky: the-numbers.com_Web_728x90_InView",
            "web: div-insticator-ad-cmt-1: the-numbers.com_Web_728x90_2",
            "web: div-insticator-ad-3: the-numbers.com_Web_300x600_3",
            "web: div-insticator-ad-4: the-numbers.com_Web_300x600_4"
        ],
            "unTraffickedTags": [],
            "unMappedTags": [
            "the-numbers.com_Web_300x600_5",
            "the-numbers.com_Web_300x600_6"
        ]
    }
},
    "345": {
    "sspId" : "345",
        "sspName" : "EngageBDR",
        "approvalStatus" : "Approved",
        "sspType" : "Video",
        "adsTxtValuesStatus" : "All Values",
        "adsTxtValuesDetails" : {
        "matchingAdsTxtValues" : [
            "engagebdr.com, 10008, RESELLER",
            "synacor.com, 82291, RESELLER, e108f11b2cdf7d5b",
            "openx.com, 540401713, RESELLER, 6a698e2ec38604c6",
            "pubmatic.com, 156344, RESELLER, 5d62403b186f2ace"
        ],
            "missingAdsTxtValues" : []
    },
    "enableStatus": false,
        "enableAction" : "N/A",
        "tagsTraffickedStatus": "None Trafficked",
        "tagsTraffickedDetails": {
        "traffickedTags": [],
            "unTraffickedTags": [
            "web: div-insticator-ad-1: the-numbers.com_Web_300x600_1",
            "web: div-insticator-ad-2: the-numbers.com_Web_300x600_2",
            "web: sticky: the-numbers.com_Web_728x90_InView",
            "web: div-insticator-ad-cmt-1: the-numbers.com_Web_728x90_2",
            "web: div-insticator-ad-3: the-numbers.com_Web_300x600_3",
            "web: div-insticator-ad-4: the-numbers.com_Web_300x600_4"
        ],
            "unMappedTags": [
            "the-numbers.com_Web_300x600_5",
            "the-numbers.com_Web_300x600_6"
        ]
    }
}
}
