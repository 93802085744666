/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm, pxToEmWithBaseline, replacePXinStringWithEM } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// TAXONOMY CATEGORY CONTROLS : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    taxonomycategorycontrols: {
        // display: 'flex',
        '& .categorycontrol': {
            display: 'inline-flex',
            justifyContent: 'center',
            alignContent: 'center',
            alignItems: 'center',
            fontSize: pxToEmWithBaseline({ value: 12, fontSizeBaseline: 14 }),
            color: theme.color.supporting.dark.swatch,
            height: pxToEmWithBaseline({ value: 26, fontSizeBaseline: 12 }),
            width: pxToEmWithBaseline({ value: 26, fontSizeBaseline: 12 }),
            border: replacePXinStringWithEM('#ECF0F7 1px solid'),
            borderRadius: pxToEmWithBaseline({ value: 4, fontSizeBaseline: 12 }),
            cursor: 'pointer',
            transition: 'all .15s linear 0s',
            '&.delete': {
                marginLeft: pxToEmWithBaseline({ value: 8, fontSizeBaseline: 12 }),
            },
            '&.add-sibling': {
                marginLeft: pxToEmWithBaseline({ value: 4, fontSizeBaseline: 12 })
            },
            '&.add-child': {
                marginLeft: pxToEmWithBaseline({ value: 4, fontSizeBaseline: 12 }),
                '& .add-child-icons': {
                    transform: 'rotate(90deg)',
                    '& .icon': {
                        fontSize: pxToEmWithBaseline({ value: 9, fontSizeBaseline: 12 }),
                        lineHeight: 'normal',
                        '&:last-child': {
                            fontSize: pxToEmWithBaseline({ value: 12, fontSizeBaseline: 12 })
                        }
                    }
                }
            },
            '&:hover': {
                backgroundColor: '#f2f4f8',
                transition: 'background-color 0.5s ease'
            }
        }
    }
});
