/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
export default theme => ({
    adstxttimeline: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        '& .timelinecardcontainer': {
        	height: '100%',
        	overflow: 'auto',
        	paddingLeft: pxToEm(8),
        	'& .timelineline': {
        		width: pxToEm(1.4),
			    height: '100%',
			    position: 'absolute',
			    top: '0',
			    left: pxToEm(7),
			    zIndex: '10',
			    background: 'rgb(192, 201, 220)'
        	},
	        '& .timelinecardwrapper': {
	        	height: `calc(100% - ${pxToEm(96)})`
	        }
	    }
    }
});
