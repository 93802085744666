// UTILS
import { batchActions } from 'redux-batched-actions';
import { displayErrorMessage, displaySuccessMessage } from '@insticator/insticator-ui';
import { updatePageDetails, changePage } from 'state/actions/app_status';
import {  asyncDeleteMockUp } from 'apps/mockupgenerator/utils/api/addproducts';
import { asyncGetSiteList, asyncCreateSite, asyncGetSiteMocks, asyncGetRedirectURL, asyncGetPageExistingIntegrations } from 'apps/mockupgenerator/utils/api/sitestatus';

// CONSTANTS
//getstarted
export const RECEIVE_SITES = 'RECEIVE_SITES';
export const FETCHING_SITES = 'FETCHING_SITES';
export const UPDATE_CURRENT_SITE = 'UPDATE_CURRENT_SITE';
export const UPDATE_ENTERED_PAGE_URL = 'UPDATE_ENTERED_PAGE_URL';
export const CREATING_SITE = 'CREATING_SITE';
//managesite
export const RECEIVE_SITE_PAGES = 'RECEIVE_SITE_PAGES';
export const FETCHING_PAGES = 'FETCHING_PAGES';
export const ACTIVATE_MOCK_DETAILS = 'ACTIVATE_MOCK_DETAILS';
export const RECEIVE_MOCK_DETAILS = 'RECEIVE_MOCK_DETAILS';
export const FETCHING_MOCK_DETAILS = 'FETCHING_MOCK_DETAILS';

export const FETCHING_REDIRECT = 'FETCHING_REDIRECT';

//delete mock up
export const DELETING_MOCK_UP = 'DELETING_MOCK_UP';
export const DELETE_MOCK_UP = 'DELETE_MOCK_UP';



// ACTIONS
export const updateCurrentSite = site => ({
    type: UPDATE_CURRENT_SITE,
    site
});

export const updateEnteredPageURL = url => ({
    type: UPDATE_ENTERED_PAGE_URL,
    url
});

const fetchingSites = status => ({
    type: FETCHING_SITES,
    status
});

export const receiveSites = (sites, siteUUID) => ({
    type: RECEIVE_SITES,
    sites,
    siteUUID
});

const creatingSite = status => ({
    type: CREATING_SITE,
    status
});

const receiveSitePages = pagesData => ({
    type: RECEIVE_SITE_PAGES,
    pagesData
});

const fetchingPages = status => ({
    type: FETCHING_PAGES,
    status
});

export const activateMockDetails = pageUUID => ({
    type: ACTIVATE_MOCK_DETAILS,
    pageUUID
});

const receiveMockDetails = (details, pageUUID) => ({
    type: RECEIVE_MOCK_DETAILS,
    details,
    pageUUID
});

const fetchingMockDetails = (status, pageUUID) => ({
    type: FETCHING_MOCK_DETAILS,
    status,
    pageUUID
});

const fetchingRedirect = status => ({
    type: FETCHING_REDIRECT,
    status
});

const deletingMockUp = status => ({
    type: DELETING_MOCK_UP,
    status
});

const removeMockUp = pageUUID => ({
    type: DELETE_MOCK_UP,
    pageUUID
});

//BATCHED ACTIONS

const fetchSitesSuccess = (sites, siteUUID) => batchActions([receiveSites(sites, siteUUID), fetchingSites(false)]);
const fetchSitesFail = () => batchActions([displayErrorMessage('There was an issue fetching your site list.'), fetchingSites(false)]);

const createSiteSuccess = currentSite => batchActions([updateCurrentSite(currentSite), creatingSite(false)]);
const createSiteFail = () => batchActions([displayErrorMessage('There was an issue creating this site. Try checking if this site already exists'), creatingSite(false)]);

const getSitePagesSuccess = pagesData => batchActions([fetchingPages(false), receiveSitePages(pagesData)]);
const getSitePagesFail = () => batchActions([fetchingPages(false), displayErrorMessage('There was an issue fetching pages for this site.')]);

const getMockDetailsSuccess = (data, pageUUID) => batchActions([receiveMockDetails(data, pageUUID), fetchingMockDetails(false, pageUUID)]);
const getMockDetailsFail = (pageUUID) => batchActions([displayErrorMessage('There was an issue fetching details for this page'), fetchingMockDetails(false, pageUUID)]);

const fetchingRedirectFail = () => batchActions([displayErrorMessage('We are having trouble redirecting you.'), fetchingRedirect(false)]);

const deleteMockUpFail = () => batchActions([deletingMockUp(false), displayErrorMessage('There was an issue deleting this mock up.')]);
const deleteMockUpSuccess = (pageUUID) => batchActions([deletingMockUp(false), displaySuccessMessage('Mock up deleted successfully!'), removeMockUp(pageUUID)]);
//THUNKS
//getstarted
export const getSiteList = siteUUID => dispatch => {
    dispatch(fetchingSites(true));
    asyncGetSiteList()
        .then(response => dispatch(fetchSitesSuccess(response.data, siteUUID)))
        .catch(err => console.log(err) || dispatch(fetchSitesFail()))
};

export const createSite = (siteURL, source) => dispatch => {
    dispatch(creatingSite(true));
    asyncCreateSite(siteURL, source)
        .then(response => {
            dispatch(createSiteSuccess({name: siteURL, value: response.data.siteUUID}));
            window.location.href = response.data.redirectUrl;
        })
        .then(() => {
            const destination = 'apps/mockupgenerator/createmockup';
            dispatch(updatePageDetails(destination));
            dispatch(changePage(destination));
        })
        .catch(err => console.log(err) || dispatch(createSiteFail()))
};
//managesite

export const getSiteMockUps = siteUUID => dispatch => {
    dispatch(fetchingPages(true));
    asyncGetSiteMocks(siteUUID)
        .then(response => dispatch(getSitePagesSuccess(response.data)))
        .catch(err => console.log(err) || dispatch(getSitePagesFail()))
};

export const getPageExistingMock = (pageUUID, source) => dispatch => {
    dispatch(fetchingMockDetails(true, pageUUID));
    asyncGetPageExistingIntegrations(pageUUID, source)
        .then(response => dispatch(getMockDetailsSuccess(response.data, pageUUID)))
        .catch(err => console.log(err) || dispatch(getMockDetailsFail(pageUUID)))
};

export const getRedirectURL = (siteUUID, source) => dispatch => {
    dispatch(fetchingRedirect(true))
    asyncGetRedirectURL(siteUUID, source)
        .then(response => {
            dispatch(fetchingRedirect(false));
            window.location.href = response.data;
        })
        .catch(err => console.log(err) || dispatch(fetchingRedirectFail()))
};

export const deleteMockUp = (pageUUID, location) => dispatch => {
    dispatch(deletingMockUp(true));
    asyncDeleteMockUp(pageUUID)
        .then(response => {
            dispatch(deleteMockUpSuccess(pageUUID));
            if(location === 'ADD_PRODUCTS'){
                dispatch(updatePageDetails('apps/mockupgenerator/managesite'));
                dispatch(changePage('apps/mockupgenerator/managesite'));
            }
        })
        .catch(err => console.log(err) || dispatch(deleteMockUpFail()))
};


