/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state

// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import PublisherManager from 'apps/sspadstxt/pages/publishermanager/publishermanager';


/* ========== ~~~~~~~~~~ PUBLISHER MANAGER: PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ sspAdsTxtState }) => ({
    selectedPubDetails: sspAdsTxtState.publisherManagerStatus.selectedPubDetails
});

// map actions
const mapDispatchToProps = dispatch => ({

});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PublisherManager);
