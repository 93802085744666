/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import {
    GEOBLOCKLIST_RECEIVED,
    GEOBLOCKLIST_FETCHING,
    ACTIVESSPLIST_RECEIVED,
    ACTIVESSPLIST_FETCHING,
    GEOBLOCKLOGS_RECEIVED,
    GEOBLOCKLOGS_FETCHING
} from 'apps/geoblocking/state/actions/geostatus';

// ^-^ ^-^ ^-^ ^-^ ^-^   GEOBLOCK STATUS: DEFAULT STATE   ^-^ ^-^ ^-^ ^-^ ^-^ //
const defaultState = {
    geoblocklistReceived: false,
    geoblocklist: [],
    geoblockdataFetching: false,
    activessplistReceived: false,
    activessplist: [],
    activesspdataFetching: false,
    geoblocklogsFetching: false,
    geoblocklogsReceived: false,
    geoblocklogs: []
};

/* ========== ~~~~~~~~~~ GEOBLOCK STATUS : REDUCER ~~~~~~~~~~ ========== */
// REDUCER
// used to manipulate application state
// takes an action, checks its type, based on that performs certain functionality, then returns new slice of state
// utilized in "_container" components
const geoStatus = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case GEOBLOCKLIST_RECEIVED:
            newState.geoblocklistReceived = true;
            newState.geoblocklist = action.geoblocklist;
            return newState;
        case ACTIVESSPLIST_RECEIVED:
            newState.activessplistReceived = true;
            newState.activessplist = action.activessplist;
            return newState;
        case GEOBLOCKLIST_FETCHING:
            if(action.status) newState.geoblocklist = defaultState.geoblocklist;
            newState.geoblockdataFetching = action.status;
            return newState;
        case ACTIVESSPLIST_FETCHING:
            if(action.status) newState.activessplist = defaultState.activessplist;
            newState.activesspdataFetching = action.status;
            return newState;
        case GEOBLOCKLOGS_FETCHING:
            if(action.status) newState.geoblocklogs = defaultState.geoblocklogs;
            newState.geoblocklogsFetching = action.status;
            return newState;
        case GEOBLOCKLOGS_RECEIVED:
            newState.geoblocklogsReceived = true;
            newState.geoblocklogs = action.geoblocklogs;
            return newState;
        default:
            return newState;
    }
};




/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default geoStatus;
