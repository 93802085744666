/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { theme, pxToEm, replacePXinStringWithEM } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- CONTROL SECTION : STYLES --------------- ++++++++++ */
export default {
    controlsection: {
    	marginBottom: pxToEm(30),
        // control headline
	    '& .headline': {
	        border: replacePXinStringWithEM(`1px solid ${theme.color.primary.medium.swatch}`),
	        borderRadius: pxToEm(5),
	        padding: replacePXinStringWithEM('7px 7px 7px 10px'),
	        marginBottom: pxToEm(0),
	        minHeight: pxToEm(55),
	        display: 'flex',
	        justifyContent: 'space-between',
	        alignItems: 'center',
	        '& h3': {
	            fontSize: pxToEm(16)
	        },
	        '& .cta': {
	            fontSize: pxToEm(12),
	            width: pxToEm(120),
	            '& .icon': {
	                fontSize: '85%'
	            }
	        }
	    },

	    // control contents
	    '& .contents': {
	        padding: replacePXinStringWithEM('0 7px'),
	        '& .search': {
	            borderBottom: replacePXinStringWithEM(`1px solid ${theme.color.grey.medium.swatch}`)
	        },
	        '& .detail': {
	            marginBottom: pxToEm(20),
	            fontSize: pxToEm(12),
	            '& .label': {
	                fontWeight: '600',
	                '& .name-deletemockup': {
	                	marginLeft: pxToEm(10),
	                	'& .icon': {
	                		fontSize: '70%',
	                		marginRight: pxToEm(5),
	                	}
	                }
	            },
	            '& .ctas': {
	                justifyContent: 'flex-start',
	                alignItems: 'center',
	                '& .field, .copyfield': {
	                    flex: '1'
	                },
	                '& .name-go': {
	                    width: pxToEm(90)
	                }
	            }
	        }
	    },

	    // total amounts
	    '& .amount': {
	    	display: 'flex',
	    	justifyContent: 'flex-end',
	    	color: '#aaa',
	    	fontSize: pxToEm(12),
	    	padding: replacePXinStringWithEM('20px 20px 0'),
	    	'& .number': {
	    		borderTop: replacePXinStringWithEM(`1px solid #aaa`),
	    		paddingLeft: pxToEm(20),
	    		paddingTop: pxToEm(10)
	    	}
	    }
    }
};
