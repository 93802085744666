/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React from 'react';
// insticator material iui
import {  Icon } from '@insticator/insticator-ui'; // iui-material
//pages (different sections of classification editor page)
//styles
import injectSheet from 'react-jss';
import styles from 'apps/sspadstxt/material/adstxttimeline/timelinecard/timelinecard_styles';



/* ++++++++++ --------------- COMPONENT ADS.TXT TIMELINE --------------- ++++++++++ */
const TimelineCard = props => {

    const renderVersionTitle = () => {
        return <div className={'version'} > <div className={'dot'}/>{`Version ${props.version}`}</div>
    };

    const renderUpdatedOn = () => {
        return <div className={'updated'} >{`Updated on ${props.updated}`}</div>
    };

    const renderCurrentVersionIndicator = () => {
        return props.currentVersion ? <div className={'currentindicator'} ><Icon  name={'check-circle'}/> Current Version</div> : null;
    };

    const renderPartialBorder = () => {
        return <div className={'partialborder'} />;
    };

    const onSelect = () => props.selectedVersion ? null : props.onSelect(props.version);

    return (
        <div className={`timelinecard ${props.classes.timelinecard} currentversion-${props.currentVersion} selectedVersion-${props.selectedVersion}`} onClick={onSelect}>
            {renderVersionTitle()}
            {renderUpdatedOn()}
            {renderCurrentVersionIndicator()}
            {renderPartialBorder()}
        </div>
    )
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(TimelineCard);
