/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state
import { updateSSPSeatType } from 'apps/sspadstxt/state/actions/sspmanagerstatus';
// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import SSPSeatType from 'apps/sspadstxt/pages/sspmanager/sspdetail/sspseat/sspseat';


/* ========== ~~~~~~~~~~ SSP INFO: CHILD ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ sspAdsTxtState }) => {
    const { seatType } = sspAdsTxtState.sspManagerStatus.newSSPDetails;
    const { isEditMode, savingSSPDetails } = sspAdsTxtState.sspManagerStatus;
    return ({
        seatType,
        isEditMode,
        savingSSPDetails
    });
};

// map actions
const mapDispatchToProps = dispatch => ({
    updateSSPSeatType: seatType => dispatch(updateSSPSeatType(seatType))
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SSPSeatType);
