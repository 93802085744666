/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// DASHBOARD : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    questiondetails: {
        marginBottom: pxToEm(50),
        '& .contenttype': {
            marginBottom: pxToEm(10)
        },
        '& .questiondisplay': {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& .questiontitle': {
                width: pxToEm(500),
                '& .title': {
                    '& h2': {
                        lineHeight: 'normal',
                        fontSize: pxToEm(28),
                        fontWeight: 'bold'
                    },
                }
            },
            '& .questionnavigation': {
                fontSize: theme.typography.size.xsmall.fontSize,
                textAlign: 'right',
                display: 'block',
                '& .questionnumber': {
                    marginBottom: pxToEm(5)
                },
                '& .classificationstatus': {
                    color: '#c0c9dc',
                    '& .complete': {
                        color: theme.color.secondary.medium.swatch
                    }
                }
            }
        }
    }
});