import { exportcsv } from '@insticator/insticator-ui'; // iui-utils



let array = [];


const taxonomyToArray = (id, taxonomy, currentRow) => {
    let newCurrentRow = currentRow ? `${currentRow},${taxonomy[id].name}` : `${taxonomy[id].name}`;
   array.push(newCurrentRow);
    if(taxonomy[id].children.length){
        taxonomy[id].children.forEach(child => taxonomyToArray(child, taxonomy, newCurrentRow))
    }
};


export const exporttaxonomytree = (taxonomy, topLevelCategories) => {
    topLevelCategories.forEach(id => taxonomyToArray(id, taxonomy, ''));
    exportcsv(array.join("\n"), `taxonomytree_${new Date().getTime()}.csv`);
    return true;
};