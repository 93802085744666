// custom theme for DOM inspector
// '& .custom-inspector': {
//     '& .margin': { backgroundColor: convertHexToRGB(theme.color.secondary.medium.swatch, 50) },
//     '& .border': { backgroundColor: convertHexToRGB(theme.color.supporting.medium.swatch, 50) },
//     '& .padding': { backgroundColor: convertHexToRGB(theme.color.primary.light.swatch, 50) },
//     '& .content': { backgroundColor: convertHexToRGB(theme.color.primary.medium.swatch, 50) },
// },


/* ++++++++++ --------------- DOM INSPECTOR STYLES --------------- ++++++++++ */
export const DomInspectorStyles = `
  .dom-inspector {
      position: fixed;
      pointer-events: none;
  }

  .dom-inspector>div {
    position: absolute;
  }

  .dom-inspector .tips {
    background-color: #333740;
    font-size: 0;
    line-height: 18px;
    padding: 3px 10px;
    position: fixed;
    border-radius: 4px;
    display: none;
  }

  .dom-inspector .tips.reverse{

  }

  .dom-inspector .tips .triangle {
    width: 0;
    height: 0;
    position: absolute;
    border-top: 8px solid #333740;
    border-right: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid transparent;
    left: 10px;
    top: 24px;
  }

  .dom-inspector .tips.reverse .triangle {
    border-top: 8px solid transparent;
    border-right: 8px solid transparent;
    border-bottom: 8px solid #333740;
    border-left: 8px solid transparent;
    left: 10px;
    top: -16px;
  }

  .dom-inspector .tips>div {
    display: inline-block;
    vertical-align: middle;
    font-size: 12px;
    font-family: Consolas, Menlo, Monaco, Courier, monospace;
    overflow: auto;
  }

  .dom-inspector .tips .tag {
    color: #e776e0;
  }

  .dom-inspector .tips .id {
    color: #eba062;
  }

  .dom-inspector .tips .class {
    color: #8dd2fb;
  }

  .dom-inspector .tips .line {
    color: #fff;
  }

  .dom-inspector .tips .size {
    color: #fff;
  }

  .dom-inspector-theme-default {

  }

  .dom-inspector-theme-default .margin {
    background-color: rgba(255, 81, 81, 0.75);
  }

  .dom-inspector-theme-default .border {
    background-color: rgba(255, 241, 81, 0.75);
  }

  .dom-inspector-theme-default .padding {
    background-color: rgba(81, 255, 126, 0.75);
  }

  .dom-inspector-theme-default .content {
    background-color: rgba(81, 101, 255, 0.75);
  }
`;