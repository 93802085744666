/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// SSP DETAIL : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    sspadstxt: {
        '& .name-adstxtcolumns': {
            display: 'flex',
            justifyContent: 'flex-end',
            flexWrap: 'wrap',
            flex: 1,
            '& .adstxtcolumn': {
                padding: `0 ${pxToEm(20)}`
            }
        }
    }
});
