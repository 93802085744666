import React from 'react';
//material
import { Link } from '@insticator/insticator-ui';
//styles
import injectSheet from 'react-jss';
import styles from 'material/approverejectcolumn/approverejectcolumn_styles';

const ApproveReject = (props) => {
        switch (props.status) {
            case 'PENDING':
               return <div className={`approvereject ${props.classes.approvereject}`}><Link type={'action'} action={props.approve} text={'Approve'} icon={'thumbs-up'} processing={props.loading === 'APPROVED'} /> <Link text={'Reject'} icon={'thumbs-down'} type={'action'} action={props.reject} processing={props.loading === 'REJECTED'} /></div>;
            case 'REJECTED':
                return <div className={`approvereject ${props.classes.approvereject}`}><Link type={'action'} action={props.approve} text={'Approve'} icon={'thumbs-up'} processing={props.loading === 'APPROVED'} /></div>;
            default:
                return  null;
        }
};

export default injectSheet(styles)(ApproveReject);
