/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { Component } from 'react';
// material
import { Icon, SingleSelectDropDown, Loader } from '@insticator/insticator-ui'; // iui-material
import Master from 'apps/sspadstxt/pages/publishermanager/publisherdetail/editsite/preferredimplementation/type/master/master_container';
import Custom from 'apps/sspadstxt/pages/publishermanager/publisherdetail/editsite/preferredimplementation/type/custom/custom_container';

//styles
import injectSheet from 'react-jss';
import styles from 'apps/sspadstxt/pages/publishermanager/publisherdetail/editsite/preferredimplementation/preferredimplementation_styles';




/* ++++++++++ --------------- PUBLISHER DETAIL --------------- ++++++++++ */

const PreferredImplementation = props => {

    const renderImplementationConfiguration = fileType => {
        if(fileType === 'CUSTOM'){
            return <Custom/>
        }
        return <Master/>
    };

    const dropDownItems = ({
         'DISPLAY_LONG' : 'Display long',
         'DISPLAY_SHORT' : 'Display short',
         'VIDEO_LONG' : 'Video long',
         'VIDEO_SHORT' : 'Video short',
         'COMBINED_LONG' : 'Combined long',
         'COMBINED_SHORT' : 'Combined short',
         'CUSTOM' : 'Custom'});

    const seatTypes = ({
        'INSTICATOR': 'INSTICATOR',
        'OKO': 'OKO',
        'S2S': 'S2S'
    });

    const formattedSeatTypes = Object.keys(seatTypes).map(item => ({value: item, name: seatTypes[item]}));

    const formattedDropDownItems = Object.keys(dropDownItems).map(item => ({value: item, name: dropDownItems[item]}))

    const selectedItem = props.masterFileType ?  ({value: props.masterFileType, name: dropDownItems[props.masterFileType]}) : '';

    const selectedSeatType = props.seatType ?  ({value: props.seatType, name: seatTypes[props.seatType]}) : '';

    const handleFileTypeSelect = fileType => {
      if(fileType === 'CUSTOM'){
          props.updatePreferredMasterType(fileType)
      }else{
          props.getMasterAdsTxtValues(fileType, true, props.seatType)
      }
    };

    const handleSeatTypeSelect = seatType => {
        props.getMasterAdsTxtValues(props.masterFileType, true, seatType)
        props.getSiteDetails({ value: props.selectedSite.value, name: props.selectedSite.name, seatType: seatType })
        props.getSiteSSPDetails({ value: props.selectedSite.value, name: props.selectedSite.name, seatType: seatType })
    };

    const fileName = () => props.masterFileType === 'CUSTOM' ? 'Custom File' : 'Master File';

    const handleLoader = () => {
        if(props.masterFileType !== 'CUSTOM' && props.loading.getMasterAdsTxt){
            return <Loader />
        }
    };

    const seatType = () => {
        if (props.masterFileType !== 'CUSTOM') {
            return <div className={'droptitle'}>
                    <div className={'file'}>Seat Type</div>
                    <SingleSelectDropDown name='name'
                                          title='title'
                                          searchable={false}
                                          items={formattedSeatTypes}
                                          onSelect={e => handleSeatTypeSelect(e.value)}
                                          selected={selectedSeatType} />
                </div>
        }
    }

    return (<div className={`preferredimplementation ${props.classes.preferredimplementation}`}>
                <span className={'sectiontitle'}>Preferred Implementation:</span>
                <div className={'fileselector'}>
                    <Icon name={'file'}/>
                    <div className={'droptitle'}>
                        <div className={'file'}>{fileName()}{handleLoader()}</div>
                        <SingleSelectDropDown name='name'
                                              title='title'
                                              searchable={false}
                                              items={formattedDropDownItems}
                                              onSelect={e => handleFileTypeSelect(e.value)}
                                              selected={selectedItem} />
                    </div>
                    {seatType()}
                </div>
                {renderImplementationConfiguration(props.masterFileType)}
            </div>);
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(PreferredImplementation);
