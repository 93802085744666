/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import { connect } from 'react-redux';
// actions
import { updateBidderName, removeBidderName, addBidderName } from 'apps/sspadstxt/state/actions/sspmanagerstatus';
// component
import SSPBidderName from 'apps/sspadstxt/pages/sspmanager/sspdetail/sspbiddername/sspbiddername';


/* ========== ~~~~~~~~~~ SSP BIDDER NAME: CONTAINER ~~~~~~~~~~ ========== */
// map state
const mapStateToProps = ({ sspAdsTxtState }) => {
    const { bidderNames } = sspAdsTxtState.sspManagerStatus.newSSPDetails;
    const { isEditMode, savingSSPDetails } = sspAdsTxtState.sspManagerStatus;
    const { status } = sspAdsTxtState.sspManagerStatus.sspStoredDetails;
    return ({
        bidderNames,
        isEditMode,
        savingSSPDetails,
        storedStatus: status,
    });
};

// map actions
const mapDispatchToProps = dispatch => ({
    updateBidderName: (name, index) => dispatch(updateBidderName(name, index)),
    removeBidderName: (index) => dispatch(removeBidderName(index)),
    addBidderName: () => dispatch(addBidderName())
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SSPBidderName);
