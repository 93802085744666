/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import React, { useState } from 'react';

// material
import { BubbleNav, Icon } from '@insticator/insticator-ui';

// styles
import injectSheet from 'react-jss';
import styles from 'material/structure/header/header_styles';



/* ========== ~~~~~~~~~~ GEOBLOCKING NAV CONTROLS ~~~~~~~~~~ ========== */
const GeoblockingNavControls = (props) => {
    const handleCheckLogsClick = () => {
        if(props.adminInformation.permissions.indexOf('newAdminPanel.geoBlocking') > -1) props.moveToPage('apps/geoblocking/checklogs');
    };

    const isActive = () => props.currentPage === 'apps/geoblocking/checklogs';

    return (
        <BubbleNav
            name={`topnav`}
            items={[
                {
                    name: 'checklogs',
                    text: 'Logs',
                    icon: 'history',
                    type: 'action',
                    action: handleCheckLogsClick,
                    allowMultiClick: false,
                    isActive: isActive(),
                    disabled: false
                }
            ]}
        />
    );
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(GeoblockingNavControls);
