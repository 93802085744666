/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
import { updatePageDetails, changePage } from 'state/actions/app_status';
import { displayModal } from '@insticator/insticator-ui';
import { updateUserStatus } from 'state/actions/user_status';
import { displayMessage } from "@insticator/insticator-ui";
import { signOut } from 'state/actions/auth_status';
// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import LocalHeader from 'material/structure/header/header';



/* ========== ~~~~~~~~~~ STRUCTURE : HEADER : PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ appStatus, authStatus, sessionStatus, classificationState }) => ({
    currentApp: appStatus.currentApp,
    currentPage: appStatus.currentPage,
    userFirstName: sessionStatus.userFirstName,
    isEditMode: classificationState.questionStatus.activeQuestionChangeDetected,
    isCASuperAdmin: authStatus.isCASuperAdmin,

});
// map actions
const mapDispatchToProps = dispatch => ({
    moveToPage: (location) => {
        dispatch(updatePageDetails(location));
        dispatch(changePage(location))
    },
    displayMessage: message => dispatch(displayMessage(message)),
    displayModal: (isVisible, contentToDisplay, showCloseButton) => dispatch(displayModal(isVisible, contentToDisplay, showCloseButton)),
    signOut: () => dispatch(signOut()),
    updateUserStatus: value => dispatch(updateUserStatus(value))
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LocalHeader);
