/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm, replacePXinStringWithEM, mediaQuery } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// STRUCTURE : HEADER : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
	header: {
		background: theme.color.supporting.light.swatch,
		borderBottom: replacePXinStringWithEM(`1px solid #ddd`),
		color: theme.color.dark.dark.contrast,
		height: pxToEm(80),
		width: '100%',
		padding: replacePXinStringWithEM(`10px ${theme.spacing.sides}`),
		position: 'relative',
		zIndex: '2',

		// layout
		display: 'flex',
		alignContent: 'center',
		alignItems: 'center',
		justifyContent: 'flex-start',

		// animations
		opacity: '0',
		animationName: 'fadeInDown',
		animationDelay: '.1s',
		animationDuration: '.2s',
		animationFillMode: 'forwards',


		// ----- GROUPS ----- //
		'& > .group': {
			// +++++ logo +++++ //
			'&.name-logo': {
				flex: '0',
				marginRight: pxToEm(30)
			},
			// +++++ navs +++++ //
			'&.name-navs': {
				// paddingLeft: pxToEm(20),
				flex: '1',
				display: 'flex',
				flexWrap: 'wrap',
				justifyContent: 'flex-start',
				// topnav
				'& .name-sections': {
					flex: '1',
					display: 'flex',
					width: `calc(100% - ${pxToEm(200)})`,
					margin: replacePXinStringWithEM('7px 0')
				},
                '& .name-account': {
                    flex: '0',
                    display: 'flex'
                },
				'& .name-backtoapps': {
					marginRight: pxToEm(15),
					color: theme.color.supporting.dark.swatch,
					fontWeight: 600,
					cursor: 'pointer !important',
					pointerEvents: 'initial !important'
				},
				'& .name-logout': {
					'& .icon': {
						fontSize: '70%'
					}
				}
			}
		},

		// ----- APP SPECIFIC STYLES ----- //
		'&.currentApp': {
			'&-mockupgenerator': {
				position: 'sticky',
				top: '0'
			}
		}
	},

	// ((((((((((((((( MEDIA QUERIES ))))))))))))))) //
	[mediaQuery.thinnest]: {
		header: {
			height: 'auto',
			justifyContent: 'space-between',
			'& > .group': {
				// +++++ navs +++++ //
				'&.name-navs': {
					flexDirection: 'column',
					justifyContent: 'flex-start',
					alignItems: 'flex-start',
					'& .name-sections': {
						width: '100%',
						display: 'flex',
						justifyContent: 'flex-start',
						marginBottom: pxToEm(10)
					},
				}
			}
		}
	}
});
