/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import sessionStatus from 'state/reducers/session_status';
import appStatus from 'state/reducers/app_status';
import { modalStatus } from '@insticator/insticator-ui';
import { messageStatus } from '@insticator/insticator-ui';
import authStatus from 'state/reducers/auth_status';
import userStatus from 'state/reducers/user_status';
import classificationState from 'apps/classification/state/reducers/root';
import geoblockingState from 'apps/geoblocking/state/reducers/root';
import commentApprovalState from 'apps/approvals/state/reducers/root';
import sspAdsTxtState from 'apps/sspadstxt/state/reducers/root';
import mockupGeneratorState from 'apps/mockupgenerator/state/reducers/root';



/* ========== ~~~~~~~~~~ ROOT REDUCER ~~~~~~~~~~ ========== */
const rootReducer = combineReducers({
	router: routerReducer,
    sessionStatus,
    appStatus,
    modalStatus,
    messageStatus,
    authStatus,
    userStatus,
    classificationState,
    geoblockingState,
    commentApprovalState,
    sspAdsTxtState,
    mockupGeneratorState
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default rootReducer;
