/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state
import {dispatchWithChangeDetection, flagSelectedAnswers, clearSelectedAnswersTaxonomy, toggleQuestionClassificationStatus, selectAllAnswers, unselectAllAnswers} from 'apps/classification/state/actions/questionstatus';
// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import AnswerControls from 'apps/classification/pages/editor/answercontrols/answercontrols';


/* ========== ~~~~~~~~~~ ANSWERCONTROLS: PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({classificationState}) => {
    const questionStatus = classificationState.questionStatus
    return {
        classified: questionStatus.activeQuestion.classified,
        selectedAnswers: questionStatus.activeAnswers.length,
        totalAnswers: questionStatus.activeQuestion.answers.length,
        activeQuestion: questionStatus.activeQuestion
    }
};

// map actions
const mapDispatchToProps = dispatch => ({
    flagSelected: () => dispatchWithChangeDetection(dispatch, flagSelectedAnswers()),
    clearSelected: () => dispatchWithChangeDetection(dispatch, clearSelectedAnswersTaxonomy()),
    toggleClassificationStatus: (status) => dispatchWithChangeDetection(dispatch, toggleQuestionClassificationStatus(status)),
    selectAllAnswers: () => dispatchWithChangeDetection(dispatch, selectAllAnswers()),
    unselectAllAnswers: () => dispatchWithChangeDetection(dispatch, unselectAllAnswers())
});


/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AnswerControls);