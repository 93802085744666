// api
import axios from 'axios';
// utils
import { url } from 'utils/urls';
const withCredentials = { withCredentials: true };
export const asyncGetSiteList = (id) => axios.get(`${url.mockup.url}sites`, { withCredentials } );
// new Promise(resolve => {
//     setTimeout(() => {
//         resolve({data: siteList});
//     }, 2000);
// });

export const asyncCreateSite = (siteUrl, source) => axios.post(`${url.mockup.url}sites`,{ siteUrl, source }, { withCredentials } );

// new Promise(resolve => {
//     setTimeout(() => {
//         resolve({data: newSite});
//     }, 2000);
// });

export const asyncGetSiteMocks = (siteUUID) => axios.get(`${url.mockup.url}sites/${siteUUID}/pages`, { withCredentials });
//     new Promise(resolve => {
//     setTimeout(() => {
//         resolve({data: sitePages});
//     }, 2000);
// });

export const asyncGetPageExistingIntegrations = (pageUUID, source) => axios.get(`${url.mockup.url}pages/${pageUUID}/integrations?isOnlyInsertedData=true&source=${source}`, { withCredentials });
//     new Promise(resolve => {
//     setTimeout(() => {
//         resolve({data: integrations});
//     }, 2000);
// });

export const asyncGetRedirectURL = (siteUUID, source) => axios.get(`${url.mockup.url}pp-mock?siteUUID=${siteUUID}&source=${source}`, { withCredentials });

const siteList = {
    "a491ef71-b9ba-4792-b8c4-8008bfd1ef2b": "https://www.gvnews.com",
    "505b484e-32bc-4328-8208-89a4d9af6837": "http://www.tvparty.com",
    "e29d349f-77ea-4cb2-ada8-88f6907196d7": "https://www.misryon.com"};

const newSite = { "siteUUID": "4be89490-0e27-47ee-99fe-b34fe132b572", "redirectUrl": 'https://publisher.hunchme.com/dashboard/embeds?from=mockupgenerator'};

const sitePages = {
    "pages": {
        "18694dc1-46fa-45d2-95fe-934706ee0877": "https://www.allkpop.com/article/2020/07/netizens-react-saying-apologize-already-jimin",
        "f93f075b-8f8d-4a75-94ae-3486e35b6e35": "https://www.allkpop.com/article/2020/07/mamamoos-hwa-sa-talks-about-lee-hyoris-offer-to-join-project-girl-group",
        "fd39938a-fc26-4b33-9d55-dd0596ad493b": "https://www.allkpop.com/article/2020/07/fans-are-loving-blackpink-jisoos-korean-alphabet-nail-art"
    },
    "adUUID": "18694dc1-46fa-45d2-95fe-934706ee0877",
    "isMigrated": true
};

const integrations = {
    insertedProducts: {
        "uuid1" : {
            "name": "rght_rail_test",
            "category": "CONTENT", //or "COMMENTING"
            "inserted": true
        },
        "uuid2" : {
            "name": "rght_rail_test",
            "category": "CONTENT", // or "COMMENTING",
            "inserted": true
        }
    },
    insertedAdUnits: {
        "adUUID1" : {
            "name": "div-insticator-ad-1",
            "type": "display",// or "video"
            "device": "desktop",// or "mobile",
            "unit": "commenting",// or "standalone"
            "size": "300x250",
            "inserted": true
        },
        "adUUID2" : {
            "name": "div-insticator-ad-2",
            "type": "display",// or "video"
            "device": "desktop",// or "mobile",
            "unit": "commenting",// or "standalone"
            "size": "300x250",
            "inserted": true
        }
    }
}
