/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// PUBLISHER DETAIL : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    publisherdetail: {
        '& .publisherinfo': {
            '& .namescan': {
                display: 'flex',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
                '& .namegrade': {
                    flex: 1,
                    '& .name': {
                        fontSize: pxToEm(24),
                        fontWeight: 'bold',
                        height: pxToEm(28)
                    },
                    '& .grade': {
                        fontSize: pxToEm(16),
                        fontWeight: 'normal',
                        height: pxToEm(22)
                    }
                },
                '& .lastscan': {
                    fontWeight: 'normal'
                }
            },
            '& .ctasdropdown': {
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: pxToEm(20),
                '& .singleselectdropdown': {
                  zIndex: '9'
                },
                '& .ctas': {
                    '& .cta': {
                        marginRight: pxToEm(10),
                        '&:last-child': {
                            marginRight: 0
                        }
                    }
                }
            }
        }
    }
});
