/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React from 'react';
// material
import { Icon } from '@insticator/insticator-ui'; // iui-material
// styles
import injectSheet from 'react-jss';
import styles from 'apps/classification/material/taxonomyeditor/taxonomytree/category/taxonomycategorycontrols/taxonomycategorycontrols_styles';



/* ========== ~~~~~~~~~~ TAXONOMY EDITOR CONTROLS : CHILD ~~~~~~~~~~ ========== */
// PRESENTATIONAL COMPONENT
// receives app state & actions as props from parent "_container" component
// this child component handles local state and uses props passed from parent to read/write application state when necessary
const TaxonomyCategoryControls = (props) => {

    const addChild = event => {
        // this will prevent the category from getting selected as well
        event.stopPropagation();
        props.createNewCategory({ activeCategoryId: props.categoryId, parentId: props.categoryId })
    };

    const addSibling = event => {
        event.stopPropagation();
        props.createNewCategory({ activeCategoryId: props.categoryId, parentId: props.parentId })
    };

    const deleteCategory = event => {
        event.stopPropagation();
        props.cancelNewCategory();
        props.displayTaxonomyEditorMessage({
            visible: true,
            messageType: 'delete',
            uniqueProps: { categoryId: props.categoryId, categoryName: props.name },
            showCloseButton: true
        })
    };

    return (
        <span className={`taxonomycategorycontrols ${props.classes.taxonomycategorycontrols}`}>
            <span className={`categorycontrol add-sibling`} onClick={addSibling}><Icon name={`plus`}/></span>
            <span className={`categorycontrol add-child`} onClick={addChild}><div className={'add-child-icons'} ><Icon name={`plus`} /><Icon name={`level-up-alt`} /></div></span>
            {!props.isIAB ? <span className={`categorycontrol delete`} onClick={deleteCategory}><Icon name={`trash-alt`}/></span> : null}
        </span>
    );
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// will be utilized in parent component after being infused with app state & actions
export default injectSheet(styles)(TaxonomyCategoryControls);
