//IMPORTS
//ASYNC
import * as questionstatusAPI from 'apps/classification/utils/api/questions';
//UTILS
import { batchActions } from 'redux-batched-actions';
import { displayErrorMessage, displaySuccessMessage, exportcsv } from '@insticator/insticator-ui';
import { updatePageDetails, changePage } from 'state/actions/app_status';
import { triggerTaxonomyEditor, resetTaxonomyTreeState } from 'apps/classification/state/actions/taxonomystatus';

//CONSTANTS
export const QUESTIONS_RECEIVED = 'QUESTIONS_RECEIVED';
export const QUESTIONS_FETCHING = 'QUESTIONS_FETCHING';
export const SELECT_SINGLE_QUESTION = 'SELECT_SINGLE_QUESTION';
export const SELECT_ALL_QUESTIONS = 'SELECT_ALL_QUESTIONS';
export const UNSELECT_ALL_QUESTIONS = 'UNSELECT_ALL_QUESTIONS';
export const CLEAR_FILTER = 'CLEAR_FILTER';
export const SELECT_FILTER = 'SELECT_FILTER';
export const UPDATE_SEARCH_TERM = 'UPDATE_SEARCH_TERM';
export const UPDATE_SEARCH_TARGET = 'UPDATE_SEARCH_TARGET';
export const UPDATE_QUESTION_CLASSIFICATION_STATUS = 'UPDATE_QUESTION_CLASSIFICATION_STATUS';
export const QUESTIONS_PENDING_OR_LIVE = 'QUESTIONS_PENDING_OR_LIVE';
export const SET_ACTIVE_QUESTION = 'SET_ACTIVE_QUESTION';
export const UPDATE_ACTIVE_QUESTION_INDEX = 'UPDATE_ACTIVE_QUESTION_INDEX';
export const SETTING_LIVE_ON_DMP = 'SETTING_LIVE_ON_DMP';
export const DOWNLOADING_CSV = 'DOWNLOADING_CSV';
export const SELECT_SINGLE_ANSWER = 'SELECT_SINGLE_ANSWER';
export const SELECT_ALL_ANSWERS = 'ANSWER_ALL_ANSWERS';
export const UNSELECT_ALL_ANSWERS = 'UNSELECT_ALL_ANSWERS';
export const CLEAR_SELECTED_ANSWERS_TAXONOMY = 'CLEAR_SELECTED_ANSWERS_TAXONOMY';
export const SET_NEW_TAXONOMY = 'SET_NEW_TAXONOMY';
export const REMOVE_TAXONOMY = 'REMOVE_TAXONOMY';
export const REMOVE_ALL_QUESTION_TAXONOMIES = 'REMOVE_ALL_QUESTION_TAXONOMIES';
export const FLAG_ANSWER = 'FLAG_ANSWER';
export const FLAG_SELECTED_ANSWER = 'FLAG_SELECTED_ANSWER';
export const UPDATE_QUESTION_NOTE = 'UPDATE_QUESTION_NOTE';
export const DISCARD_QUESTION_CHANGES = 'DISCARD_QUESTION_CHANGES';
export const SAVING_QUESTION = 'SAVING_QUESTION';
export const ACTIVE_QUESTION_CHANGE_DETECTED = 'ACTIVE_QUESTION_CHANGE_DETECTED';
export const ACTIVE_QUESTION_IS_SAVED = 'ACTIVE_QUESTION_IS_SAVED';
export const UPDATE_ACTIVE_QUESTION = 'UPDATE_ACTIVE_QUESTION';
export const TRIGGER_REFETCH = 'TRIGGER_REFETCH';
//when setting questions live on dmp we will remove questions from current data blob to avoid a refetch
export const REMOVE_QUESTIONS_FROM_QUESTIONS_STATE = 'REMOVE_QUESTIONS_FROM_QUESTIONS_STATE';

//HELPERS
const downloadCSVData = data => exportcsv(data, `questions_${new Date().getTime()}.csv`);

//ACTIONS
const questionsReceived = questions => ({
    type: QUESTIONS_RECEIVED,
    questions
});

const questionFetchStatus = status => ({
    type: QUESTIONS_FETCHING,
    status
});

export const selectSingleQuestion = questionID => ({
    type: SELECT_SINGLE_QUESTION,
    questionID
});

export const selectAllQuestions = () => ({
    type: SELECT_ALL_QUESTIONS
});

export const unselectAllQuestions = () => ({
    type: UNSELECT_ALL_QUESTIONS
});

export const clearFilter = () => ({
    type: CLEAR_FILTER
});

export const selectFilter = (filterValue, filter) => ({
    type: SELECT_FILTER,
    filter,
    filterValue
});

export const setActiveQuestion = index => ({
    type: SET_ACTIVE_QUESTION,
    index
});

export const updateSearchTerm = searchTerm => ({
    type: UPDATE_SEARCH_TERM,
    searchTerm
});

export const updateSearchTarget = searchTarget => ({
   type: UPDATE_SEARCH_TARGET,
   searchTarget
});

export const editSelection = () => dispatch => {
    //not batched to prevent error with changePage
    dispatch(setActiveQuestion(0));
    dispatch(triggerTaxonomyEditor({ showEditor: true, editMode: false, classifyPermitted: true }));
    dispatch(changePage('apps/classification/editor'));
    dispatch(updatePageDetails('apps/classification/editor'));
};

export const activeQuestionIndex = index => ({
    type: UPDATE_ACTIVE_QUESTION_INDEX,
    index
});

//component will listen for this prop to change, firing a fetch when it is toggled using same filters and searchterm from previous toggle(As per product)
export const questionsLiveOrPending = (status) => ({
    type: QUESTIONS_PENDING_OR_LIVE,
    status
});
export const toggleQuestionClassificationStatus = (classificationStatus) => ({
    type: UPDATE_QUESTION_CLASSIFICATION_STATUS,
    classificationStatus
});

const downloadingCSV = status => ({
    type: DOWNLOADING_CSV,
    status
});

const settingLiveOnDMP = status => ({
    type: SETTING_LIVE_ON_DMP,
    status
});

const removeQuestions = questions => ({
    type: REMOVE_QUESTIONS_FROM_QUESTIONS_STATE,
    questions
})

export const selectSingleAnswer = index => ({
    type: SELECT_SINGLE_ANSWER,
    index
});

export const selectAllAnswers = () => ({
    type: SELECT_ALL_ANSWERS
});

export const unselectAllAnswers = () => ({
   type: UNSELECT_ALL_ANSWERS
});

export const setNewTaxonomy = deepestChildren => ({
    type: SET_NEW_TAXONOMY,
    deepestChildren
});

export const clearSelectedAnswersTaxonomy = () => ({
   type: CLEAR_SELECTED_ANSWERS_TAXONOMY
});

//When clicking the x button pass this action the child above the one clicked and this willl be the new deepest child of that taxonomy

export const removeTaxonomy = (answerIndex, deepestChildID, newDeepestChildID) => ({
    type: REMOVE_TAXONOMY,
    answerIndex,
    deepestChildID,
    newDeepestChildID
});

export const removeQuestionTaxonomies = answerIndex => ({
   type: REMOVE_ALL_QUESTION_TAXONOMIES,
   answerIndex
});

export const flagAnswer = answerIndex => ({
    type: FLAG_ANSWER,
    answerIndex
});

export const flagSelectedAnswers = () => ({
   type: FLAG_SELECTED_ANSWER
});

export const updateQuestionNote = notes => ({
    type: UPDATE_QUESTION_NOTE,
    notes
});
//TODO the component that fires this should have a modal that confirms this action
export const discardQuestionChanges = () => ({
    type: DISCARD_QUESTION_CHANGES
});

const savingQuestion = status => ({
    type: SAVING_QUESTION,
    status
});

export const activeQuestionChangeDetected = (status) => ({
    type: ACTIVE_QUESTION_CHANGE_DETECTED,
    status
});

const activeQuestionIsSaved = (status) => ({
    type: ACTIVE_QUESTION_IS_SAVED,
    status
});

const updateActiveQuestion = questionData => ({
    type: UPDATE_ACTIVE_QUESTION,
    questionData
});
const triggerRefetchOfQuestions = () =>({
    type: TRIGGER_REFETCH
});
//BATCHED ACTIONS
const questionFetchSuccess = questions => batchActions([
    questionsReceived(questions),
    questionFetchStatus(false)
]);

const questionFetchFail = () => batchActions([
   questionFetchStatus(false),
   displayErrorMessage('There was an issue fetching your questions')
]);

const downloadCSVFail = () => batchActions([
   downloadingCSV(false),
   displayErrorMessage('There was an issue downloading your CSV file')
]);

const setLiveOnDMPFail = () => batchActions([
   settingLiveOnDMP(false),
   displayErrorMessage('There was an issue setting your questions live on DMP')
]);

const setLiveOnDMPSuccess = (questions) => batchActions([
    displaySuccessMessage('Your questions were successfully set live'),
    settingLiveOnDMP(false),
    removeQuestions(questions),
    triggerRefetchOfQuestions()
]);

export const nextActiveQuestion = index => dispatch => dispatch(batchActions([
    activeQuestionIndex(index),
    setActiveQuestion(index),
    resetTaxonomyTreeState()
]));

const saveQuestionFail = () => dispatch => dispatch(batchActions([
    savingQuestion(false),
    displayErrorMessage('There was an issue saving your question')
]));

const saveQuestionSuccess = (questionData) => dispatch => dispatch(batchActions([
    displaySuccessMessage('Your question was saved successfully'),
    activeQuestionChangeDetected(false),
    activeQuestionIsSaved(true),
    updateActiveQuestion(questionData)
]));

//actions must be batched with the change detected action
export const dispatchWithChangeDetection = (dispatch, action, questionChangeDetected=true) => (
    dispatch(batchActions([activeQuestionChangeDetected(questionChangeDetected), action]))
)

//THUNKS

//we can use getClassificationQuestions thunk for "Apply Filter" button
export const getClassificationQuestions = data => dispatch => {
    dispatch(questionFetchStatus(true));
    questionstatusAPI.asyncFetchClassificationQuestions(data)
        .then(response => dispatch(questionFetchSuccess(response.data)))
        .catch(err => dispatch(questionFetchFail()))
};

export const downloadCSV = data => dispatch => {
    dispatch(downloadingCSV(true));
    questionstatusAPI.asyncDownloadQuestionCSVFile(data)
        .then(response => {
            downloadCSVData(response.data);
            dispatch(downloadingCSV(false))
        })
        .catch(err => dispatch(downloadCSVFail()))
};
export const setQuestionsLiveOnDMP = questions => dispatch => {
    dispatch(settingLiveOnDMP(true));
    questionstatusAPI.asyncSetQuestionsLiveOnDMP(questions)
        .then(response => dispatch(setLiveOnDMPSuccess(questions)))
        .catch(err => dispatch(setLiveOnDMPFail()))
};

export const saveQuestion = (questionData) => dispatch => {
    dispatch(savingQuestion(true));
    questionstatusAPI.asyncSaveQuestion(questionData)
        .then(response => dispatch(saveQuestionSuccess(questionData)))
        .catch(err => dispatch(saveQuestionFail()))
};


