/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm } from '@insticator/insticator-ui'; // iui-utils
import { colorCodeTableData } from '../../../../../utils/styles/general';



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// PUBLISHERS LIST : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    publisherslist: {
        '& .searchfilterscan': {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: pxToEm(34),
            '& .searchfilter': {
                display: 'flex',
                width: '70%',
                '& .searchbar':{
                    width: '100%',
                    maxWidth: pxToEm(655),
                    marginLeft: pxToEm(10)
                },
                '& .datepickerdropdown': {
                    marginRight: pxToEm(10),
                    zIndex: '2'
                }
            },
            '& .paginationsearchfilter':{
                flex: 1
            },
            '& .lastscan': {
                fontWeight: 'normal'
            },
            '& .paginationexport': {
                display: 'flex',
                justifyContent: 'space-between'
            },
            '& .searchscan': {
                display: 'flex',
                alignItems: 'flex-end',
                justifyContent: 'space-between'
            }
        },
        '& .table':{
            fontWeight: 'normal',
            '& .cta':{
                color: theme.color.primary.medium.swatch
            },
            '& .tbody': colorCodeTableData()
        },
        '& .filterholder': {
            maxWidth: '90vw'
        }
    }
});
