/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import {
    RECEIVE_MASTER_ADSTXT_FILES, FETCHING_MASTER_ADSTXT_FILES,
    UPDATE_MASTER_FILE_NOTE, SAVING_MASTER_FILE_NOTE, SELECT_VERSION,
    OKO_SELECT_VERSION, OKO_UPDATE_MASTER_FILE_NOTE, OKO_SAVING_MASTER_FILE_NOTE,
    S2S_SELECT_VERSION, S2S_UPDATE_MASTER_FILE_NOTE, S2S_SAVING_MASTER_FILE_NOTE } from 'apps/sspadstxt/state/actions/masteradstxtstatus';




// ^-^ ^-^ ^-^ ^-^ ^-^   MASTER ADS.TXT STATUS: DEFAULT STATE   ^-^ ^-^ ^-^ ^-^ ^-^ //

const defaultState = {
    fetchingMasterFiles: false,
    masterFiles: {},
    currentVersion: null,
    selectedVersion: null,
    currentNote: '',
    savingNote: false,
    okoMasterFiles: {},
    okoCurrentVersion: null,
    okoSelectedVersion: null,
    okoCurrentNote: '',
    okoSavingNote: false,
    s2SMasterFiles: {},
    s2SCurrentVersion: null,
    s2SSelectedVersion: null,
    s2SCurrentNote: '',
    s2SSavingNote: false
};

/* ========== ~~~~~~~~~~ MASTER ADS.TXT STATUS : REDUCER ~~~~~~~~~~ ========== */
// REDUCER
// used to manipulate application state
// takes an action, checks its type, based on that performs certain functionality, then returns new slice of state
// utilized in "_container" components
const masterAdsTxtStatus = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case RECEIVE_MASTER_ADSTXT_FILES:
            newState.masterFiles = action.data.insticator;
            newState.currentVersion = action.data.insticator.currentVersion;
            newState.selectedVersion = action.data.insticator.currentVersion;
            delete newState.masterFiles.currentVersion;
            newState.okoMasterFiles = action.data.oko;
            newState.okoCurrentVersion = action.data.oko.currentVersion;
            newState.okoSelectedVersion = action.data.oko.currentVersion;
            delete newState.okoMasterFiles.currentVersion;
            newState.s2SMasterFiles = action.data.s2s;
            newState.s2SCurrentVersion = action.data.s2s.currentVersion;
            newState.s2SSelectedVersion = action.data.s2s.currentVersion;
            delete newState.s2SMasterFiles.currentVersion;
            return newState;
        case FETCHING_MASTER_ADSTXT_FILES:
            newState.fetchingMasterFiles = action.status;
            return newState;
        case UPDATE_MASTER_FILE_NOTE:
            newState.masterFiles[newState.selectedVersion].versionNotes = action.note;
            return newState;
        case OKO_UPDATE_MASTER_FILE_NOTE:
            newState.okoMasterFiles[newState.okoSelectedVersion].versionNotes = action.note;
            return newState;
        case S2S_UPDATE_MASTER_FILE_NOTE:
            newState.s2SMasterFiles[newState.s2SSelectedVersion].versionNotes = action.note;
            return newState;
        case SAVING_MASTER_FILE_NOTE:
            newState.savingNote = action.status;
            return newState;
        case OKO_SAVING_MASTER_FILE_NOTE:
            newState.okoSavingNote = action.status;
            return newState;
        case S2S_SAVING_MASTER_FILE_NOTE:
            newState.s2SSavingNote = action.status;
            return newState;
        case SELECT_VERSION:
            newState.selectedVersion = action.version;
            return newState;
        case OKO_SELECT_VERSION:
            newState.okoSelectedVersion = action.version;
            return newState;
        case S2S_SELECT_VERSION:
            newState.s2SSelectedVersion = action.version;
            return newState;
        default:
            return newState;
    }
};




/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default masterAdsTxtStatus;
