/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm, pxToEmWithBaseline, replacePXinStringWithEM, boxShadow, convertHexToRGB } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// NEW TAXONOMY CATEGORY : STYLES
export default theme => ({
    newtaxonomycategory: {
        // transition: 'background .2s linear 0s',
        background: theme.color.supporting.light.swatch,
        '& .taxonomynameinput': {
            width: pxToEmWithBaseline({ value: 177, fontSizeBaseline: 14 }),
            height: pxToEmWithBaseline({ value: 26, fontSizeBaseline: 14 }),
            color: theme.color.supporting.dark.swatch,
            paddingLeft: pxToEmWithBaseline({ value: 5, fontSizeBaseline: 14 }),
            paddingRight: pxToEmWithBaseline({ value: 5, fontSizeBaseline: 14 }),
            fontSize: pxToEmWithBaseline({ value: 14, fontSizeBaseline: 14 }),
            // transition: 'all .1s linear 0s',
            '&.valid-false': {
                background: theme.color.attention.medium.swatch,
                transition: 'background-color 0.5s ease'
            },
            '&.valid-true': {
                // background: theme.color.attention.medium.swatch,
                transition: 'background-color 0.5s ease-out'
            }
            // '&:focus': {

            // }
        },
        '& .newcategorybutton': {
            fontSize: pxToEmWithBaseline({ value: 12, fontSizeBaseline: 14 }),
            fontWeight: 500,
            marginLeft: pxToEmWithBaseline({ value: 11, fontSizeBaseline: 14 }),
            '&:hover': {
                cursor: 'pointer'
            }
        },
        '&.child-true': {
            marginLeft: pxToEmWithBaseline({ value: 28, fontSizeBaseline: 14 }),
        }

    }
});



