/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { Component } from 'react';
// material
import { Page, Group, Icon, Content, CTAs, Button, SingleSelectDropDown, LoadState, EmptyState, url } from '@insticator/insticator-ui'; // iui-material
import EditSite from 'apps/sspadstxt/pages/publishermanager/publisherdetail/editsite/editsite_container'
//utils
import { lastScan } from 'apps/sspadstxt/pages/publishermanager/lastscan';
//styles
import injectSheet from 'react-jss';
import styles from 'apps/sspadstxt/pages/publishermanager/publisherdetail/publisherdetail_styles';




/* ++++++++++ --------------- PUBLISHER DETAIL --------------- ++++++++++ */

class PublisherDetail extends Component {
    constructor(props){
        super(props);
    };

    componentDidMount() {
        if(!!this.props.selectedPubDetails.accountUUID && this.props.selectedPubDetails.accountUUID !== 'N/A'){
            this.props.getSites(this.props.selectedPubDetails.accountUUID);
        } else if(!!this.props.selectedSite){
            this.props.selectedPubDetails.selectedSite.seatType = this.props.selectedSite.seatType;
            this.props.getSiteDetails(this.props.selectedPubDetails.selectedSite);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(Object.keys(this.props.sites).length !== Object.keys(prevProps.sites).length){
            if(!this.props.selectedSite){
                let site = {value: Object.keys(this.props.sites)[0], name: this.props.sites[Object.keys(this.props.sites)[0]]};
                this.props.getSiteDetails(site);
                this.props.getSiteSSPDetails(site)
            } else {
                this.props.getSiteDetails(this.props.selectedSite);
                this.props.getSiteSSPDetails(this.props.selectedSite);
            }
        }
    }

    componentWillUnmount() {
        this.props.unmountPublisherDetails();
    }

    getSiteData = site => {
        let siteData = {value: site.value, name: site.name, seatType: this.props.selectedSite.seatType};
        this.props.getSiteDetails(siteData);
        this.props.getSiteSSPDetails(siteData);
    };

    dropDownSites = (sitesObj) => Object.keys(sitesObj).map(site => ({value: site, name: sitesObj[site] }));

    renderEditSite = () => {
        if(this.props.loading.getSiteDetails || this.props.loading.getSites){
            return <LoadState/>
        } else if( !this.props.siteDetailsReceived ){
            return <EmptyState graphicName={'error-403'} bodyCopy={'Something went wrong'}/>
        }
        else{
           return <EditSite siteURL={this.props.selectedSite.name} siteTableData={this.props.selectedSiteSSPDetails} siteTableDataLoading={this.props.loading.getSiteSSPDetails} seatType={this.props.selectedSite.seatType}/>
        }
    };

    renderGrade = grade => grade === 'N/A' ? grade : grade.split('_')[1];

    handleEmail = () => window.location.href = "mailto:";

    renderEditAdsUrl = () => {
        if(this.props.siteDetailsReceived){
            return this.props.migratedAdvertisement ?
                `${url.embed.admin.url}/manageadvertisementsv2?adUUID=${this.props.adUUID}&siteUUID=${this.props.selectedSite.value}`
                : `${url.embed.admin.url}/manageadvertisements?adUUID=${this.props.adUUID}&siteUUID=${this.props.selectedSite.value}`;
        }
    };

    render() {
        return (
            <Page klass={`publisherdetail ${this.props.classes.publisherdetail}`} >
                <Content>
                    <Group klass={`publisherinfo`} >
                        <div className={'namescan'} >
                            <div className={'namegrade'} >
                                <div className={'name'} >
                                    {this.props.selectedPubDetails.accountName}
                                </div>
                                <div className={'grade'}>
                                    {`Grade ${this.renderGrade(this.props.selectedPubDetails.grade)}`}
                                </div>
                            </div>
                            <div className={'lastscan'} >
                                <Icon name={'history'} />
                                <span> {`Ads.txt files scanned ${lastScan()}hr ago`}</span>
                            </div>
                        </div>
                        <div className={'ctasdropdown'} >
                            <CTAs>
                                <Button variant={'primary'} icon={'envelope'} text={'Email Publisher'} type={'action'} action={this.handleEmail} />
                                <Button variant={'action'} icon={'pen'} text={'Edit Ads'} type={'external'} action={this.renderEditAdsUrl()} disabled={!this.props.siteDetailsReceived} />
                            </CTAs>
                            <SingleSelectDropDown title={'Site'}
                                                  name={'sites'}
                                                  items={this.dropDownSites(this.props.sites)}
                                                  selected={this.props.selectedSite}
                                                  onSelect={site => this.getSiteData(site)}
                            />
                        </div>
                    </Group>
                    {this.renderEditSite()}
                </Content>
            </Page>
        );
    }
}



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(PublisherDetail);
