/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import {
    UPDATE_CURRENT_SITE, UPDATE_ENTERED_PAGE_URL,
    FETCHING_SITES, RECEIVE_SITES,
    CREATING_SITE,
    RECEIVE_SITE_PAGES, FETCHING_PAGES,
    ACTIVATE_MOCK_DETAILS, RECEIVE_MOCK_DETAILS,
    FETCHING_MOCK_DETAILS, FETCHING_REDIRECT,
    DELETING_MOCK_UP, DELETE_MOCK_UP
} from '../actions/sitestatus';
import {parseQueryString} from "@insticator/insticator-ui";




// ^-^ ^-^ ^-^ ^-^ ^-^   SITE STATUS: DEFAULT STATE   ^-^ ^-^ ^-^ ^-^ ^-^ //
//helper functions

const transpileSitePages = (pages = {}) => {
    Object.keys(pages)
        .forEach(page => pages[page] = ({
            url: pages[page],
            active: false,
            dataReceived: false,
            loading: false,
            siteMockUpDetails: {
                products: {},
                adUnits: {}
            }
        }));
    return pages;
};

const defaultState = {
    siteList: null,
    fetchingSites: false,
    currentSite: null,
    creatingSite: false,
    enteredPageURL: '',
    siteMockUps: {
        pages: {},
        adUUID: null,
        migratedAdvertisement: null
    },
    deletingMockUp: false,
    queryParams: parseQueryString(document.location.search),
    fetchingMockUps: false,
    fetchingRedirect: false
};



/* ========== ~~~~~~~~~~ SITE STATUS : REDUCER ~~~~~~~~~~ ========== */
const siteStatus = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        //getstarted
        case FETCHING_SITES:
            newState.fetchingSites = action.status;
            return newState;
        case RECEIVE_SITES:
            newState.siteList = action.sites;
            if(action.siteUUID){
                newState.currentSite = {name: action.sites[action.siteUUID], value: action.siteUUID}
            }
            return newState;
        case UPDATE_CURRENT_SITE:
            newState.currentSite = action.site;
            return newState;
        case UPDATE_ENTERED_PAGE_URL:
            newState.enteredPageURL = action.url;
            return newState;
        case CREATING_SITE:
            newState.creatingSite = action.status;
            return newState;
        //managesite
        case RECEIVE_SITE_PAGES:
            newState.siteMockUps = action.pagesData;
            newState.siteMockUps.pages = transpileSitePages(action.pagesData.pages);
            return newState;
        case FETCHING_PAGES:
            newState.fetchingMockUps = action.status;
            return newState;
        case ACTIVATE_MOCK_DETAILS:
            newState.siteMockUps.pages[action.pageUUID].active = !newState.siteMockUps.pages[action.pageUUID].active;
            return newState;
        case FETCHING_MOCK_DETAILS:
            newState.siteMockUps.pages[action.pageUUID].loading = action.status;
            return newState;
        case RECEIVE_MOCK_DETAILS:
            newState.siteMockUps.pages[action.pageUUID].siteMockUpDetails = action.details;
            newState.siteMockUps.pages[action.pageUUID].dataReceived = true;
            newState.siteMockUps.pages[action.pageUUID].active = true;
            return newState;
        case FETCHING_REDIRECT:
            newState.fetchingRedirect = action.status;
            return newState;
        case DELETING_MOCK_UP:
            newState.deletingMockUp = action.status;
            return newState;
        case DELETE_MOCK_UP:
            delete newState.siteMockUps.pages[action.pageUUID];
            return newState;
        default:
            return state;
    }
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default siteStatus;
