/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { connect } from 'react-redux';
// actions and reducers used to manipulate application state
import { setNewTaxonomy } from 'apps/classification/state/actions/questionstatus'
import { deselectTaxonomyCategories } from 'apps/classification/state/actions/taxonomystatus';
// actual presentational component that will utilize application state and actions (will be exported after being infused with state/actions)
import ClassifyCTAs from 'apps/classification/material/taxonomyeditor/taxonomyeditorcontrols/variants/classifyCTAs/classifyCTAs';




/* ========== ~~~~~~~~~~ CLASSIFY CTAs : PARENT ~~~~~~~~~~ ========== */
// CONTAINER COMPONENT
// interacts with redux (store of application state)
// infuses child component (imported above) with state & actions by mapping and then connecting them

// map state
const mapStateToProps = ({ classificationState }) => ({
    categoriesSelected: classificationState.taxonomyStatus.selectedCategories.length > 0,
    selectedCategories: classificationState.taxonomyStatus.selectedCategories,
    activeAnswersSelected: classificationState.questionStatus.activeAnswers.length > 0
});

// map actions
const mapDispatchToProps = dispatch => ({
    deselectTaxonomyCategories: () => dispatch(deselectTaxonomyCategories()),
    setNewTaxonomy: deepestChildren => dispatch(setNewTaxonomy(deepestChildren))
});



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// utilizes "connect" functionality from redux in order to infuse state/actions as props to child component
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ClassifyCTAs);
