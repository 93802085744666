/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm, pxToRem, replacePXinStringWithEM } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- MANUAL MOCKUP : STYLES --------------- ++++++++++ */
export default theme => ({
    manualmockup: {
        '& .label': {
            marginTop: pxToEm(20)
        },
        '& .ctas': {
            justifyContent: 'flex-start',
            '& .field': { flex: '1', marginRight: pxToRem(7) },
            '& .button': { width: pxToEm(140) }
        },
        '& .entercode': {
            justifyContent: 'flex-start',
            alignItems: 'center',
            '& .field': { 
                flex: '1', 
                marginRight: pxToRem(7),
                '& .formelement': {
                    height: pxToEm(30)
                }
            },
            // '& .button': { width: pxToEm(90) }
        },
        '& .name-visual': {
            '& .illustration': {
                fontSize: '155% !important'
            }
        }
    }
});