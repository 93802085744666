/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- SSP INFO : STYLES --------------- ++++++++++ */
export default theme => ({
    sspinfo: {
        '& .infogroup': {
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-end',
            '& .infoarea': {
                flex: '1',
                marginRight: pxToEm(100),
                '&:last-child': {
                    marginRight: '0'
                },
                '& .contact': {
                    display: 'flex',
                    flexDirection: 'column',
                    '& .field': {
                        marginBottom: pxToEm(20),
                        '&:last-child': {
                            marginBottom: '0'
                        },
                        '& .label': {
                            marginBottom: pxToEm(10)
                        }
                    }
                }
            }
        },
        '& .nameindicator': {
            display: 'flex',
            alignItems: 'flex-end',
            '& .name-name': {
                marginRight: pxToEm(100),
                '& input': {
                    fontSize: pxToEm(32),
                    fontWeight: 'bold'
                }
            }
        },
        '& .inventory': {
            marginBottom: pxToEm(40),
            '& .inventoryfields': {
                flex: 1,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                // maxWidth: pxToEm(450),
                // width: '100%',
                '& .field.type-checkbox': {
                    marginRight: pxToEm(25),
                    '&:last-child': {
                        marginRight: '0'
                    },
                    '& .checkbox': {
                        width: pxToEm(14),
                        height: pxToEm(14)
                    }
                }
            },
            '& .s2sAdsTxtValues': {
                marginTop: pxToEm(20),
                '& textarea': {
                    width: pxToEm(425),
                }
            }
        }
    }
});
