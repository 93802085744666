/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React from 'react';
// material
import { Link, Icon } from '@insticator/insticator-ui'; // iui-material
import ListItem from './listitem/listitem';
//styles
import injectSheet from 'react-jss';
import styles from './itemlist_styles';



/* ++++++++++ --------------- ITEM LIST --------------- ++++++++++ */
const ItemList = (props) => {
	return (
		<div className={`itemlist ${props.classes.itemlist}`} id={`list-${props.productType}`}>
			{props.productData.map(product => <ListItem
				key={product.id}
				product={product}
				{...props}
			/>)}
		</div>
	)
}



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(ItemList);
