/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm, disable } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- MOCKUP DETAILS : STYLES --------------- ++++++++++ */
export default theme => ({
    mockupdetails: {
        '& .mockupcontents': {
            '& .mockupcontent': {
                marginBottom: pxToEm(30),
                '& h4': {
                    marginBottom: pxToEm(10)
                },
                '& .mockupdetails': {
                    display: 'flex',
                    flexDirection: 'column',
                    maxHeight: pxToEm(150),
                    overflow: 'auto',
                    '& .embed': {
                        marginBottom: pxToEm(5)
                    },
                    '& .ad': {
                        marginBottom: pxToEm(5),
                        display: 'flex',
                        justifyContent: 'space-between',
                        '& .size': {
                            display: 'flex',
                            justifyContent: 'flex-end'
                        }
                    }
                }
            },
            '& .view': {
                marginTop: pxToEm(40),
                fontSize: pxToEm(14),
                display: 'flex',
                justifyContent: 'flex-end',
                '& .cta': {
                    marginRight: pxToEm(10),
                    width: pxToEm(150),
                    '&:last-child': {
                        marginRight: '0'
                    }
                }
            }
        },
        '&.deleting-true': {
            extend: disable
        }
    }
});