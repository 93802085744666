import React from 'react';
//material
import { Group, SelectableList, Tip } from "@insticator/insticator-ui";
//styles
import styles from 'apps/sspadstxt/pages/sspmanager/sspdetail/sspstatus/sspstatus_styles';
import injectSheet from "react-jss";





const SSPStatus = props => {

    return (<Group klass={`sspstatus ${props.classes.sspstatus}`} name={'sspstatussave'} >
                <div className={'labeltitle'} >SSP Status</div>
                <SelectableList
                    kind={'selectable'} type={'radio'}
                    selected={props.status.value}
                    items={[
                        {value: 'INACTIVE', text: <div>Inactive <Tip content={'This SSP is currently inactive and will not be returning any impressions or bidding.'}/></div>},
                        {value: 'TESTING', text: <div>Testing <Tip content={'This SSP is currently live with a limited number of publishers as we test.'}/></div>},
                        {value: 'ACTIVE', text: <div>Active <Tip content={'This SSP is active and generating impressions and bidding.'}/></div>}]}
                    handleChange={props.updateSSPStatus}/>
            </Group>)

};

/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(SSPStatus);