/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import {pxToEm, replacePXinStringWithEM} from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// SSP APPROVALS : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    filevalues: {
        flex: 1,
        padding: `${pxToEm(25)} 0`,
        '& .field': {
            width: '100%',
            '& .label': {
                fontSize: pxToEm(14),
                fontWeight: '500',
                marginBottom: pxToEm(10)
            },
            '& textarea':{
                resize: 'none'
            }
        },
        '&.Short-file': {
            '& .formelement': {
                height: pxToEm(183)
            }
        },
        '&.Long-file': {
            '& .formelement': {
                height: pxToEm(340)
            }
        },
        '& .ctas': {
            justifyContent: 'space-between',
            marginTop: pxToEm(20),
            '& .cta': {
                flex: 1,
                marginRight: pxToEm(10),
                marginBottom: pxToEm(10),
                '&:last-child': {
                    marginRight: '0'
                }
            }
        }
    }
});
