/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { Component } from 'react';
// material
import { Page, Content, TextAndGraphicLayout, Button, TextField,
	CTAs, Label, Separator, SingleSelectDropDown, parseQueryString } from '@insticator/insticator-ui'; // iui-material
import { validation } from 'utils/validation';
//styles
import injectSheet from 'react-jss';
import styles from 'apps/mockupgenerator/pages/getstarted/getstarted_styles';



/* ++++++++++ --------------- GET STARTED --------------- ++++++++++ */
class GetStartedPage extends Component {
		constructor(props){
		super(props);
		this.queryParams = parseQueryString(document.location.search);
		this.state = {
			enteredUrlValid: null
		};
	}

	componentDidMount() {
		const { source, siteUUID } = this.queryParams;
		if(source === 'PUBLISHER_PORTAL' && !!siteUUID) this.navigateToManageSite(siteUUID)
	}

	updateURL = url => {
		if(this.state.enteredURLValid === false) {
			this.setState({enteredURLValid: validation.createSite.test.test(url)})
		}
		this.props.updateEnteredPageURL(url);
	};

	navigateToManageSite = siteUUID => {
		const item = { value: siteUUID, name: this.props.siteList[siteUUID] };
		this.handleSelectedSite(item);
		this.showMockups()
	};

	handleSelectedSite = (item) => this.props.updateCurrentSite(item);

	showMockups = () => this.props.moveToPage('apps/mockupgenerator/managesite');

	handleCreateSite = () => {
		if(!validation.createSite.test.test(this.props.enteredPageURL)) {
			this.props.sendErrorMessage('Enter a valid url');
			return this.setState({enteredURLValid: false})
		}
		for (let siteUUID in this.props.siteList) {
			if (this.props.siteList[siteUUID] === this.props.enteredPageURL) {
				this.props.sendErrorMessage('This site already exists');
				return this.setState({enteredURLValid: false})
			}
		}
		this.props.createSite(this.props.enteredPageURL, 'getstarted');
	};

	renderSitesInDropDown = () => Object.keys(this.props.siteList).map(site => ({name: this.props.siteList[site], value: site}));

	render (){
		return (
			<Page klass={`getstarted ${this.props.classes.getstarted}`}>
				<Content>
					<TextAndGraphicLayout
						headline={'Mockup Generator'}
						title={'Get Started!'}
						bodycopy={
							<ul>
								<li>Choose the URL of a publisher you'd like to create a sample implementation for.</li>
								<li>Then start customizing their products and advertisements.</li>
							</ul>
						}
						additionalMessageContent={
							<div className={'siteselection'}>
								<div className={'choosesite'}>
									<CTAs>
										<SingleSelectDropDown
											name={'Choose an existing site'}
											onSelect={this.handleSelectedSite}
											selected={this.props.currentSite}
											items={this.renderSitesInDropDown()}
											paginated={true}
											itemsPerPage={100}
											disabled={false}
											searchable={true} />
										<Button
											type={`action`}
											action={this.showMockups}
											disabled={!this.props.currentSite || this.props.creatingSite}
											variant={`secondary`}
											name={`manage`}
											text={`Manage Site`} />
									</CTAs>
								</div>
								<Separator />
								<div className={'entersite'}>
									<Label>Enter a new site</Label>
									<CTAs>
										<TextField
											type={'url'}
											name={'clientsite'}
											placeholder={'ex: https://www.techraptor.net'}
											value={this.props.enteredPageURL}
											valid={this.state.enteredURLValid}
											handleChange={e => this.updateURL(e.target.value)}
										/>
										<Button
											type={`action`}
											action={this.handleCreateSite}
											disabled={!this.props.enteredPageURL}
											variant={`primary`}
											name={`create`}
											text={`Create Site`}
											operation={'load'}
											processing={this.props.creatingSite}
										/>
									</CTAs>
								</div>
							</div>
						}
						illustration={'product-commenting'}
					/>
				</Content>
			</Page>
		)
	}
}



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(GetStartedPage);
