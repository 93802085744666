/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm, nthChildIterator, mediaQuery } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// DASHBOARD : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    appmenu: {
        width: '100%',
        minHeight: pxToEm(700),
        '&.dashboard-card-content': {
            position: 'relative',
            '& .title.name-numberof': {
                opacity: '0',
                animationName: 'fadeInUp',
                animationDelay: '.3s',
                animationDuration: '.2s',
                animationFillMode: 'forwards'
            }
        },
        '& .card-container': {
            width: '100%',
            marginTop: pxToEm(20),
            display: 'flex',
            '& .appcard': {
                marginRight: '0.65%',
                marginLeft: '0.65%',
                marginBottom: pxToEm(20),
                width: '32%',
                transition: 'all .2s linear 0s',

                // animate in
                opacity: '0',
                animationName: 'fadeInUp',
                animationDuration: '.2s',
                animationFillMode: 'forwards',
                extend: nthChildIterator(100, .3, .05),
            },
        }
    },
    [mediaQuery.thinnest]: {
        appmenu: {
            '& .card-container': {
                '& .appcard': {
                    width: '100%',
                    marginRight: '0 !important',
                    height: pxToEm(350)
                }
            }
        }
    }
});
