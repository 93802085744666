// api
import axios from 'axios';
// utils
import { url } from 'utils/urls';
const withCredentials = { withCredentials: true };

///v1/pagesr
export const asyncCreateMockUp = (pageUrl, siteUUID, pageCode) => axios.post(`${url.mockup.url}pages`, { pageUrl, siteUUID }, { withCredentials });

export const asyncGetMockUp = (pageUUID) => axios.get(`${url.mockup.url}pages/${pageUUID}`, { withCredentials });

export const asyncManualMock = (pageUrl, siteUUID, pageCode) => axios.post(`${url.mockup.url}pages`, {pageUrl, siteUUID, pageCode: encodeURIComponent(pageCode) }, { withCredentials });

export const asyncGetEmbedsAds = (pageUUID, source) => axios.get(`${url.mockup.url}pages/${pageUUID}/integrations?isOnlyInsertedData=false&source=${source}`, { withCredentials });

export const asyncSaveMockUp = (pageUUID, pageCode) => axios.put(`${url.mockup.url}pages/${pageUUID}`, { pageCode: encodeURIComponent(pageCode) }, { withCredentials });

export const asyncSaveIntegrations = (pageUUID, data) => axios.put(`${url.mockup.url}pages/${pageUUID}/integrationsandadunits`, data, { withCredentials });

export const asyncSaveProducts = (pageUUID, data) => axios.put(`${url.mockup.url}pages/${pageUUID}/integrations`, { integrations: data }, { withCredentials });

export const asyncDeleteMockUp = (pageUUID) => axios.delete(`${url.mockup.url}pages/${pageUUID}`, { withCredentials });
