/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// SSP APPROVALS : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    timelinecard: {
        backgroundColor: 'rgb(245, 246, 249)',
        padding: pxToEm(22),
        position: 'relative',
        transition: 'all .15s ease-in-out 0s',
        '& .version': {
            position: 'relative',
            color: 'rgb(6, 43, 117)',
            fontSize: pxToEm(16),
            fontWeight: 'bold',
            marginBottom: pxToEm(10)
        },
        '& .updated': {
            color: 'rgb(130, 149, 186)',
            fontSize: pxToEm(12),
            fontWeight: 'normal',
            marginBottom: pxToEm(10)
        },
        '&.currentversion-true': {
            paddingTop: pxToEm(0),
            borderBottom: '1.4px solid rgb(192, 201, 220)',
            '& .currentindicator': {
                fontWeight: '500',
                fontSize: pxToEm(14),
                marginTop: pxToEm(15)
            },
            '& .version, .updated, .currentindicator':{
                color: 'rgb(20, 148, 244)'
            },
            '& .dot': {
                height: pxToEm(16),
                width: pxToEm(16),
                left: '-29px'
            },
            '& .partialborder': {
                display: 'none'
            },
            '&.selectedVersion-false': {
                paddingTop: pxToEm(1),
                background: `linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(245,246,249,1) 100%)`, // add faded background so we don't see hard line at the top since no padding
                '&:hover': {
                    background: `linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(244,244,245,1) 100%)` // add faded background so we don't see hard line at the top since no padding
                }
            },
            '&:hover': {
                boxShadow: 'none', // need to remove this so we dont have hard line at top
            }
        },
        '&.selectedVersion-true':{
            background: '#fefefe',
            marginTop: pxToEm(1),
            '&:hover': {
                background: '#fefefe',
                boxShadow: 'none',
                cursor: 'default'
            }
        },
        '& .dot': {
            position: 'absolute',
            left: '-26px',
            bottom: '4px',
            backgroundColor: 'rgb(192, 201, 220)',
            borderRadius: '50%',
            height: pxToEm(8),
            width: pxToEm(8)
        },
        '& .partialborder':{
            height: pxToEm(1),
            width: '90%',
            backgroundColor: 'rgb(192, 201, 220)',
            position: 'absolute',
            bottom: '0px',
            right: '0px'
        },
        '&:hover': {
            background: '#eee',
            boxShadow: theme.boxShadow.inset,
            cursor: 'pointer'
        }
    }
});
