/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm, replacePXinStringWithEM } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- QUESTION MANAGER --------------- ++++++++++ */
export default theme => ({
    questionmanager: {
         // pulled table outside of the standard CONTENT area, so we can achieve visual design in comp, so now removing bottom spacing so things line up correctly
        '& #content': {
            minHeight: 'auto',
            paddingBottom: '0'
        },
        '& .name-dmpcontrols':{
            marginBottom: pxToEm(45),
            padding: replacePXinStringWithEM('0px 20px'),
            '& .controls':{
                display: 'inline-flex',
                '& .control':{
                    // padding: `${pxToEm(16)} ${pxToEm(36)}`,
                    fontSize: pxToEm(14),
                    height: pxToEm(46),
                    width: pxToEm(185)
                }
            }
        },
        '& .name-paginationexport':{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            padding: replacePXinStringWithEM('0px 20px'),
            '& .arrow':{
              height: pxToEm(48),
              width: pxToEm(48)
            },
            '& button':{
                width: pxToEm(188),
                marginBottom: pxToEm(27)
            }
        },
        '& .name-filtersandctas':{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'flex-start',
            alignItems: 'center',
            position: 'relative', // used for multifiltercontainer_styles
            padding: replacePXinStringWithEM('0px 20px'),
            '& .selectall': {
                display: 'flex',
                alignItems: 'center',
                marginRight: pxToEm(15),
                marginBottom: pxToEm(26),
                '& .text':{
                    fontSize: pxToEm(14),
                    whiteSpace: 'nowrap'
                },
                '& .checkbox':{
                    height: pxToEm(24),
                    width: pxToEm(24),
                    marginRight: pxToEm(10)
                }
            },
            '& .searchfilter': {
                display: 'flex',
                flex: replacePXinStringWithEM('1 0 400px'),
                marginRight: pxToEm(15),
                marginBottom: pxToEm(26),
            },
            '& .searchbar':{
                border: replacePXinStringWithEM(`1px solid rgb(236, 240, 247)`),
                width: '100%',
                transition: 'background .2s linear 0s',
                '&:hover': {
                    backgroundColor: 'rgb(240, 240, 240)',
                },
                '& .search': {
                    background: 'none'
                }
            },
            '& .ctas':{
                '& .button':{
                    width: pxToEm(188),
                    height: pxToEm(48),
                    marginRight: pxToEm(15),
                    marginBottom: pxToEm(26),
                    '&:last-child': {
                        marginRight: '0'
                    }
                }
            }
        },
        '& .name-selectedquestions':{
            fontSize: pxToEm(14),
            fontStyle: 'italic',
            height: pxToEm(15),
            marginTop: pxToEm(15),
            color: '#9498b5',
            '& .amount': {
                fontWeight: '600'
            },
            //animation
            '& .questioncount':{
                opacity: '0',
                animationName: 'fadeInDown',
                animationDuration: '.2s',
                animationFillMode: 'forwards',
                animationDelay: '0'
            }
            // extend: nthChildIterator(50, .5, .03)
        },
        '& .name-questionstable':{
            background: 'rgb(242, 244, 248)',
            padding: replacePXinStringWithEM(`0 ${theme.spacing.sides} 70px`),
            '& .loadstate': {
                padding: '1px 0 60px',
                height: pxToEm(550)
            },
            '& .table': {
                height: pxToEm(550)
            },
            '& .pagination':{
                marginRight: 'auto',
                marginLeft: 'auto',
                marginTop: pxToEm(30)
            }
        }
    }
});
