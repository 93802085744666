//IMPORTS
import { batchActions } from 'redux-batched-actions';
import { displayErrorMessage, sendMessage, downloadLink } from '@insticator/insticator-ui';
import { asyncGetSSPApprovalData, asyncGetSSPApprovalStats, exportSSPApprovalStats } from 'apps/sspadstxt/utils/api/sspapprovals';
//CONSTANTS
    //ssp approval table
export const RECEIVE_SSP_APPROVALS = 'RECEIVE_SSP_APPROVALS';
export const FETCHING_SSP_APPROVALS = 'FETCHING_SSP_APPROVALS';
export const UPDATE_SSP_APPROVALS_FILTER = 'UPDATE_SSP_APPROVALS_FILTER';
export const EXPORTING_SSP_APPROVALS = 'EXPORTING_SSP_APPROVALS';
    //ssp approval stats
export const RECEIVE_SSP_APPROVAL_STATS = 'RECEIVE_SSP_APPROVAL_STATS';
export const FETCHING_SSP_APPROVAL_STATS = 'FETCHING_SSP_APPROVAL_STATS';

//ACTIONS
    //ssp approval table
const receiveSSPApprovals = (tableData, paginationData) => ({
    type: RECEIVE_SSP_APPROVALS,
    tableData,
    paginationData
});

export const updateSSPApprovalFilter = (filterName, filterData) => ({
    type: UPDATE_SSP_APPROVALS_FILTER,
    filterName,
    filterData
});
    //ssp approval table
const fetchingSSPApprovals = status => ({
    type: FETCHING_SSP_APPROVALS,
    status
});
    //ssp approval stats
const receiveSSPApprovalStats = data => ({
    type: RECEIVE_SSP_APPROVAL_STATS,
    data
});

const fetchingSSPApprovalStats = status => ({
    type: FETCHING_SSP_APPROVAL_STATS,
    status
});

const exportingSSPApprovals = status => ({
    type: EXPORTING_SSP_APPROVALS,
    status
});

//BATCHED ACTIONS
    //ssp approval table
const fetchSSPApprovalsFail = () => batchActions([displayErrorMessage('There was an issue fetching ssp approval data.'), fetchingSSPApprovals(false)]);
const fetchSSPApprovalsSuccess = (tableData, paginationData) => batchActions([receiveSSPApprovals(tableData, paginationData), fetchingSSPApprovals(false)]);
    //ssp approval stats
const fetchSSPApprovalStatsFail = () => batchActions([displayErrorMessage('There was an issue fetching ssp approval stats.'), fetchingSSPApprovalStats(false)]);
const fetchSSPApprovalStatsSuccess = data => batchActions([receiveSSPApprovalStats(data), fetchingSSPApprovalStats(false)]);
//THUNKS
    //ssp approval table
export const getSSPApprovalData = (filterData) => dispatch => {
    dispatch(fetchingSSPApprovals(true));
    asyncGetSSPApprovalData(filterData)
        .then(response => {
            const { currentPage, totalRows, totalPages, publisherSspApprovals } = response.data;
            dispatch(fetchSSPApprovalsSuccess(publisherSspApprovals, { currentPage, totalRows, totalPages }))
        })
        .catch(err => console.log(err) || dispatch(fetchSSPApprovalsFail()))
};
    //ssp approval stats
export const getSSPApprovalStats = () => dispatch => {
  dispatch(fetchingSSPApprovalStats(true));
    asyncGetSSPApprovalStats()
        .then(response => dispatch(fetchSSPApprovalStatsSuccess(response.data)))
        .catch(err => console.log(err) || fetchSSPApprovalStatsFail())
};

export const exportSSPApprovals = () => dispatch => {
    dispatch(exportingSSPApprovals(true));
    exportSSPApprovalStats()
        .then(response => {
            downloadLink(response.data);
            return dispatch(batchActions([sendMessage('Download Complete!'), exportingSSPApprovals(false)]))
        })
        .catch(err => dispatch(batchActions([displayErrorMessage('There was an issue exporting your file.'), exportingSSPApprovals(false)])))
};

