/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React from 'react';
// material
//utils
import copy from 'apps/sspadstxt/utils/copytoclipboard';
import { downloadTxtFile } from 'utils/downloadFiles';
// insticator material
import { CTAs, Button, TextAreaField } from '@insticator/insticator-ui';
//styles
import injectSheet from 'react-jss';
import styles from 'apps/sspadstxt/material/adstxtcolumn/filevalues/filevalues_styles';

/* ++++++++++ --------------- COMPONENT ADS.TXT TIMELINE --------------- ++++++++++ */

const FileValues = props => {

    //MICHELE REQUESTED TO REMOVE COPYING FROM ADSTXT // ONLY COMMENTED OUT SO WE CAN REVERT IF NEEDED

    // const copyToClipBoard = () => {
    //    let x = document.getElementsByName(`${props.type}-${props.fileType}`)[0].select();
    //     document.execCommand('copy');
    //     props.sendMessage(`${props.type} file values copied to clipboard!`)
    // };

    const downloadFile = () => downloadTxtFile(`${props.type} file values`, props.file);

    return (
        <div className={`filevalues ${props.classes.filevalues} ${props.type}-file`}>
            <TextAreaField name={`${props.type}-${props.fileType}`} type={'textarea'} value={props.file} label={`${props.type} file values`} placeholder={'Ads.txt values'} readOnly={!props.onChange} handleChange={props.onChange}/>
            <CTAs>
                {/*<Button text={'Copy Values'} variant={'primary'} type={'action'} action={copyToClipBoard}/>*/}
                <Button text={'Download File'} variant={'system'} type={'action'} action={downloadFile}/>
            </CTAs>
        </div>
    )
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(FileValues);
