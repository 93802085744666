/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { Component } from 'react';
// material
import { Page, Group, Icon, Content, CTAs, Button, MultiSelectDropDown } from '@insticator/insticator-ui'; // iui-material
//pages

//styles
import injectSheet from 'react-jss';
import styles from 'apps/sspadstxt/pages/publishermanager/publisherdetail/editsite/currentimplementation/currentimplementation_styles';




/* ++++++++++ --------------- PUBLISHER DETAIL --------------- ++++++++++ */

const CurrentImplementation = props => {

    const renderValuesStatus = status => {
        if( status === null ) {
            return null;
        }
        return status ? <div><Icon name={'check-circle'} /> Up to date</div> : <div><Icon name={'times-circle'} /> Values are missing</div>;
    };

    const renderTestingList = ssps => ssps.map(ssp => <li key={ssp}>{ssp}</li>);

    const renderTestingDiv = () => {
        if(props.sspTestingWith && props.sspTestingWith.length){
            return (<div className={'testingwith'} >
                        <div className={'testingtitle'}><Icon name={'flag'}/>Testing with:</div>
                            <ul className={'testinglist'}>
                                {renderTestingList(props.sspTestingWith)}
                            </ul>
                    </div>);
        }
    };

    const renderFileType = fileType => fileType ? `${props.fileType} File` : 'N/A';
    const renderFileName = fileName => fileName ? fileName : 'N/A';

    return (<div className={`currentimplementation ${props.classes.currentimplementation}`}>
                <span className={'sectiontitle'}>Current Implementation:</span>
                <div className={'fileinfo'}>
                    <Icon name={'file'}/>
                    <div className={'currentconfig'}>
                        <div className={'filetype'}>{renderFileType(props.fileType)}</div>
                        <div className={'filename'}>{renderFileName(props.fileName)}</div>
                        <div className={`filestatus uptodate-${props.status}`} >{renderValuesStatus(props.status)}</div>
                        {renderTestingDiv()}
                    </div>
                </div>
            </div>);
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(CurrentImplementation);
