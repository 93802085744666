/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { replacePXinStringWithEM } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// DASHBOARD : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    dashboard: {
        '& #main': {
            width: '100%', // needed for when the contents of the table don't stretch far enough width wise
            transition: 'all .3s ease-in-out 0s',
        }
    }
});
