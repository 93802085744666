/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// SSP SEAT INDICATOR : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    seatindicator: {
        alignItems: 'center',
        justifyContent: 'center',
        height: pxToEm(24),
        width: pxToEm(100),
        borderRadius: pxToEm(4),
        paddingLeft: pxToEm(3),
        paddingRight: pxToEm(3),
        textTracks: 'capitalize',
        color: theme.color.primary.medium.contrast,
        background: theme.color.neutral.dark.swatch,
        '&.seat-INSTICATOR': {
            background: theme.color.secondary.medium.swatch
        },
        '&.seat-OKO': {
            background: theme.color.accent.medium.swatch
        },
        '&.seat-S2S': {
            background: theme.color.accent.medium.dark
        }
    }
});
