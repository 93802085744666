/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// DASHBOARD : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    approvalpage: {
        '& .title': {
            fontSize: pxToEm(28),
            fontWeight: 'bold'
        },
        '& .dropdownwithlabel':{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'baseline',
            '& .searchabledropdown':{
                zIndex: '10',
                marginBottom: pxToEm(21),
                marginLeft: pxToEm(5),
                maxWidth: pxToEm(232)
            }
        },
        '& .pagination':{
            display: 'flex',
            alignItems: 'baseline',
            '& .simplepagination':{
            '& .arrows, .status':{
                marginBottom: '0',
                marginTop: pxToEm(20)
            }
            },
            '& h2':{
                marginLeft: pxToEm(5)
            }
        },
        '& .numberedpagination':{
            marginTop: pxToEm(77)
        },
        '& .table':{
            '& td':{
                fontSize: pxToEm(14),
                fontWeight: 'normal'
            },
            '& .cta':{
                color: theme.color.primary.medium.swatch
            }
        }
    }
});