/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import {
    RECEIVE_SSP_APPROVALS,
    FETCHING_SSP_APPROVALS,
    UPDATE_SSP_APPROVALS_FILTER,
    RECEIVE_SSP_APPROVAL_STATS,
    FETCHING_SSP_APPROVAL_STATS,
    EXPORTING_SSP_APPROVALS
} from 'apps/sspadstxt/state/actions/sspapprovalsstatus';


// ^-^ ^-^ ^-^ ^-^ ^-^   SSP APPROVALS STATUS: DEFAULT STATE   ^-^ ^-^ ^-^ ^-^ ^-^ //

const defaultState = {
    //approval data
    filterSettings: {
        ssps : [],
        sspStatus : [], // enum: "INACTIVE", "ACTIVE", "TESTING"
        adTypes : [], // enum: "DISPLAY", "VIDEO"
        approvalProgress : [], // enum: "PENDING", "DELAYED", "COMPLETE"
        approvalStatus : [], // enum: "APPROVED", "REJECTED", "N/A"
        withNote : [],
        namePattern : ""
    },
    exporting: false,
    sspTableData: {},
    fetchingSSPTableData: false,
    currentPage : 1,
    totalRows: 0,
    totalPages: 0,
    //approval stats
    fetchingApprovalStats: false,
    approvalStats: {}
};


/* ========== ~~~~~~~~~~ SSP APPROVALS STATUS : REDUCER ~~~~~~~~~~ ========== */
// REDUCER
// used to manipulate application state
// takes an action, checks its type, based on that performs certain functionality, then returns new slice of state
// utilized in "_container" components
const sspApprovalsStatus = (state = defaultState, action) => {
    let newState = JSON.parse(JSON.stringify(state));
    switch (action.type) {
        case RECEIVE_SSP_APPROVALS:
            newState.sspTableData = action.tableData;
            newState.currentPage = action.paginationData.currentPage;
            newState.totalRows = action.paginationData.totalRows;
            newState.totalPages = action.paginationData.totalPages;
            return newState;
        case FETCHING_SSP_APPROVALS:
            newState.fetchingSSPTableData = action.status;
            return newState;
        case UPDATE_SSP_APPROVALS_FILTER:
            newState.filterSettings[action.filterName] = action.filterData;
            return newState;
        case RECEIVE_SSP_APPROVAL_STATS:
            newState.approvalStats = action.data;
            return newState;
        case FETCHING_SSP_APPROVAL_STATS:
            newState.fetchingApprovalStats = action.status;
            return newState;
        case EXPORTING_SSP_APPROVALS:
            newState.exporting = action.status;
            return newState;
        default:
            return newState;
    }
};




/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default sspApprovalsStatus;
