/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React, { Component } from 'react';
import DomInspector from 'dom-inspector'; // enables inspecting of elements in the DOM // go here https://github.com/luoye-fe/dom-inspector/issues/5 for help inspecting iframe elements
import { DomInspectorStyles } from './mockup/dom_inspector_styles';
import InnerHTML from 'dangerously-set-html-content'; // needed to run scripts from the embedded website
// material
import {
    Page,
    Icon,
    Button,
    CTAs,
    SearchBar,
    DiscardButton,
    SaveButton,
    Link,
    Label,
    TextField,
    CopyField,
    LoadState,
    url,
    hasLocalStorage,
    checkForLocalStorage,
    PopupMessage
} from '@insticator/insticator-ui'; // iui-material
import Frame from './mockup/iframe';
import ControlSection from './controlsection/controlsection';
import ItemList from './itemlist/itemlist';
// helpers
import { dragElement, createDom, createHeaderScript, createContentEmbed, createCommentingEmbed, createAd, isInViewport, scrollToEl, getElementByXpath, getXPathOfElement, createOnLoadScript } from './helpers';
//styles
import injectSheet from 'react-jss';
import styles from 'apps/mockupgenerator/pages/addproducts/addproducts_styles';



/* ++++++++++ --------------- ADD PRODUCTS --------------- ++++++++++ */
class AddProducts extends Component {
    constructor(props) {
        super(props);
        // helper vars
        this.siteUUID = this.props.currentSite.value;
        this.domInspector = ''; // the dom inspector we'll use to inspect elements
        this.iframePageDocument = ''; // grab the iframe document if we need to manipulate the dom for highlights and other features
        // now set state
        this.state = {
            inspectorEnabled: false, // whether the DOM inspector is enabled or not
            selectedElement: '', // populated after dom inspector is enabled (user clicks insert), and then user clicks on an element
            selectedProductData: '', // gets populated when user clicks insert from toolbar on a product
            insticatorifiedMockup: '', // stores a mockup string with the header code removed and units wrapped in product
            reconcilingProductsStates: true,
            toolbarPosition: localStorage.getItem(`insticatorMockupToolbarPosition`) || 'bottom-right',
            toolbarSize: localStorage.getItem(`insticatorMockupToolbarSize`) || 'medium',
            productSearchTerm: '',
            redirectLinkClick: null
        };
    }

    componentDidMount() {
        // get embed ads
        this.props.getEmbedsAds(this.props.pageUUID, 'addproducts');

        // store iframe element
        this.iframePageDocument = document.querySelector('#mockupframe').contentDocument;

        // create an instance of the dom inspector and confine it only to the mockup page
        this.createDomInspector();

        // add specific product styles (highlights product(embed/ad) on hover of toolbar list item)
        this.addProductStyles();

        // listen to ANY click in the DOM (for dom inspector)
        this.handleClickToInsertProduct();
        //setting current site/page data in local storage to use on refresh
        if(checkForLocalStorage()){
            const { currentSite, pageUUID, pageUrl } = this.props;
            const pageData = { currentSite, pageUUID, pageUrl };
            window.localStorage.setItem('pageData', JSON.stringify(pageData))
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fetchingEmbedsAds && !this.props.fetchingEmbedsAds) {
			this.setState({
            	insticatorifiedMockup: this.insticatorifyMockupString(this.props.mockupCode)
        	});
            let timeout = setTimeout(() => {
                const products = { ...this.props.embeds, ...this.props.ads };
                this.reconcileProductsStates({ products, onLoad: true });
                clearTimeout(timeout)
            }, 2000);
        }
    }

    componentWillUnmount() {
        this.props.addProductsUnmount();
    }

    insticatorifyMockupString = (mockupCode) => {
    	// convert mockup string to DOM
        const mockupDom = createDom(mockupCode);

        // replace or add mockup gen header code to BE returned mockup code string
        this.updateMockupCodeWithHeaderCode(mockupDom);

        // remove existing production products
        this.handleExistingProductionProductsInString(Object.values(this.props.embeds), mockupDom);
        this.handleExistingProductionProductsInString(Object.values(this.props.ads), mockupDom);

        // convert DOM back into mockup string
        const revisedMockupCode = mockupDom.querySelector('html').outerHTML;

        // now return revised string
        return revisedMockupCode;
    };

    updateMockupCodeWithHeaderCode = (mockupDom) => {
        // loop script tags and if header code exists remove it
        mockupDom.querySelectorAll('script').forEach(script => {
        	if (script.innerHTML.includes('Insticator.ad.q.push')) {
        		script.remove();
        	}
        });
        // append our header code (with dynamic environment variable and ID for easy identification)
        mockupDom.head.appendChild(createHeaderScript(this.siteUUID));
    };

     handleExistingProductionProductsInString = (productType, mockupDom) => {
        productType.forEach(product => {
            switch (product.type.toLowerCase()) {
                case 'content':
                    const allEmbeds = mockupDom.querySelectorAll('#insticator-container');
                    allEmbeds.forEach(embed => {
                        const embedScript = embed.querySelector('script');
                        if (embedScript) {
	                        if (embedScript.textContent.includes(product.id)) {
	                            this.removeExistingProduct(embed);
	                            return false;
	                        }
	                    }
                    });
                    break;
                case 'commenting':
                    // this.replaceExistingProduct(product, mockupDom.querySelector(`[data-integration-id="${this.siteUUID}]"`));
                    this.removeExistingProduct(mockupDom.querySelector(`[data-integration-id="${this.siteUUID}]"`));
                    break;
                case 'display':
                    //fall through
                case 'video':
                    // this.replaceExistingProduct(product, mockupDom.querySelector(`#${product.name}`));
                    this.removeExistingProduct(mockupDom.querySelector(`#${product.name}`));
            }
        });
    };

    reconcileProductsStates = ({ products, onLoad }) => {
        if(!this.state.reconcilingProductsStates) this.setState({ reconcilingProductsStates: true });
        Object.values(products).forEach(product => {
            if (!onLoad) this.removeExistingProduct(this.iframePageDocument.querySelector(`#product-${product.id}`));
            if (product.inserted) this.insertProductByXpath(product)
        });
        //after we reconcile we will make sure our products have hover events
        this.setState({ reconcilingProductsStates: false }, this.handleInsertedProductHover);
    };

    removeExistingProduct = (productEl) => {
        // if that product exists in the DOM remove it entirely
        if (productEl !== null) {
        	productEl.remove();
        }
    };

    handleClickToInsertProduct = () => {
        // listen to ANY click in the iframe DOM (for dom inspector and product insertion)
        this.iframePageDocument.addEventListener('click', (e) => {
            if (this.state.inspectorEnabled) {
                const controls = document.getElementById('toolbar');
                if (e.target !== controls && !controls.contains(e.target)) {
                    this.insertProductOnClick();
                }
            }
        });
    };

    insertProductOnClick = () => {
        try {
            // inserting the product directly into the iframe for better ux (if we update state mockupStringToRender then we will trigger a render which will have to load the whole site again, instead we are updating BOTH the iframe DOM and then a string that we can save to the DB separately)
            this.domInspector.target['after'](this.createProduct(this.state.selectedProductData));

            // update inserted state in redux
            const targetedElementPath = getXPathOfElement(this.domInspector.target); // !!!!! using CUSTOM function here to get a shortened XPATH that works in more instances (previously path from html/body etc would break because of variations in post-execution markup)
            console.log(targetedElementPath)
            console.log(this.domInspector.getXPath(this.domInspector.target))
            console.log(this.domInspector.getSelector(this.domInspector.target))
            console.log(getXPathOfElement(this.domInspector.target));


            this.props.setProductInserted({
                id: this.state.selectedProductData.id,
                inserted: true,
                location: targetedElementPath
            });

            // set state for inspector and selected element textarea
            this.setState({ inspectorEnabled: false }, this.handleInsertedProductHover);
        } catch (err) {
            this.props.sendErrorMessage('Sorry! We could not insert after that element. Please try again, or choose a different element to insert after.');
            console.log(err);
        }

        // disable the inspector (until the user clicks to inspect again)
        this.domInspector.disable();
    };

    insertProductByXpath = (product) => {
        const parentElement = getElementByXpath(product.location, this.iframePageDocument);
        if (parentElement) parentElement.appendChild(this.createProduct(product))
    };

    handleCancelInsertClick = (product) => {
        this.domInspector.disable();
        this.setState({
            inspectorEnabled: false,
            selectedProductData: product
        });
    };

    handleInsertedProductHover = () => {
        if (this.iframePageDocument) {
            // setting a timeout here is a hack to ensure allProducts actually finds to inserted products in the DOM (this is fragile and should really be hooked to some kind of iframe load or contents changed event)
            setTimeout(() => {
                const allProducts = this.iframePageDocument.body.querySelectorAll('.insticator-product');
                allProducts.forEach(product => {
                    // helper vars
                    const productId = product.id.replace('product-', '');
                    const productType = product.classList.contains('insticator-ad-product') ? 'ads' : 'embeds';
                    const listItem = document.querySelector(`#listitem-${productId}`);
                    const thisProduct = this.iframePageDocument.body.querySelector(`#${product.id}`);
                    const adHotspots = product.querySelectorAll(`.insticator-hotspot`);
                    // remove or show hotspots based on whether ads have been inserted
                    if (adHotspots) {
                        adHotspots.forEach((hotspot, index) => {
                            if (hotspot.nextSibling) hotspot.nextSibling.classList.contains('insticator-ad-product') && hotspot.classList.add('hide');
                        });
                    }
                    // remove event listeners if they exist (just precaution)
                    // product.removeEventListener('mouseenter');
                    // product.removeEventListener('mouseleave');
                    // add event listeners for hover highlighting
                    product.addEventListener('mouseenter', (e) => {
                        // scroll to el
                        const relativeList = document.querySelector(`#list-${productType}`);
                        const listItemToHighlight = document.querySelector(`#listitem-${productId}`);
                        !isInViewport(listItemToHighlight, relativeList) && scrollToEl(listItemToHighlight);
                        // highlight els
                        //only highlight ad hotspots when inspector is enabled
                        product.classList.add('highlight');
                        this.state.selectedProductData.type === 'display' && this.state.inspectorEnabled && product.classList.add('hotspot-active');
                        listItem.classList.add('highlight');
                    });
                    product.addEventListener('mouseleave', (e) => {
                        // remove highlights
                        this.state.selectedProductData.type === 'display' && this.state.inspectorEnabled && product.classList.remove('hotspot-active');
                        product.classList.remove('highlight');
                        listItem.classList.remove('highlight');
                    });
                });
            }, 100);
        }
    };

    createDomInspector = () => {
        // need to create a style tag and append inspector styles INSIDE the iframe for them to work properly
        const style = document.createElement('style');
        style.textContent = DomInspectorStyles;
        this.iframePageDocument.head.appendChild(style);

        // create the inspector itself with the iframe contents as the root
        this.domInspector = new DomInspector({
            root: this.iframePageDocument.body,
            // exclude: ['#insticator-embed']
        }); //, theme: 'custom-inspector'
    };

    addProductStyles = () => {
        if (!this.iframePageDocument.head.querySelector('#insticator-product-styles')) {
            const productStyles = document.createElement('style');
            productStyles.id = 'insticator-product-styles';
            productStyles.textContent = `
                #insticator-container { text-align: center }
				.insticator-product { transition: all .15s ease-out 0s; } 
				.insticator-product.insticator-ad-product { width: fit-content; }
				.insticator-product.highlight { background: rgba(20,148,244,.3); box-shadow: 0px 0px 24px 0px rgba(20,148,244,1); padding: 10px; }
				.insticator-product .insticator-hotspot { display: none; }
				.insticator-product.highlight.hotspot-active .insticator-hotspot { display: inline-block; width: 300px; height: 250px; background-color: #1494f4; border: 1px solid #81C9FF; border-radius: 3px; }
				.insticator-product .insticator-hotspot span { height: 100%; display: flex; justify-content: center; align-items: center; }
				.insticator-product.highlight .insticator-hotspot.hide { display: none; position: absolute; z-index: -1; }
			`;
            this.iframePageDocument.head.appendChild(productStyles);
        }
    };

    handleEditEmbeds = (link) => {
        this.setState({ redirectLinkClick: link });
        this.props.getRedirectURL(this.siteUUID, 'addproducts');
    };

    handleEditAds = (e) => {
        window.location.href = this.props.siteMockUps.migratedAdvertisement ?
            `${url.embed.admin.url}/manageadvertisementsv2?adUUID=${this.props.siteMockUps.adUUID}&siteUUID=${this.props.currentSite.value}`
            : `${url.embed.admin.url}/manageadvertisements?adUUID=${this.props.siteMockUps.adUUID}&siteUUID=${this.props.currentSite.value}`;
    };

    handleUniqueEmbedLink = (embed) => {
        this.setState({ selectedProductData: embed });
        const win = window.open('https://publisher.hunchme.com/dashboard/embeds?from=mockupgenerator', '_blank');
        win.focus();
    };

    handleInsertCTAClick = (product) => {
        // enable inspector
        this.domInspector.enable();
        this.setState({
            inspectorEnabled: true,
            selectedProductData: product
        });
    };

    handleRemoveProduct = (product, productType='embeds') => {
        // get product wrapper element to remove it from the DOM
        const productElement = this.iframePageDocument.querySelector(`#product-${product.id}`);

        // collect product's id and the ids of its children to update their status in the application state
        const productsToRemove = [product.id, ...this.getNestedProductIds(productElement)];

        // remove product from the DOM
        productElement.remove();

        // update inserted state in redux
        this.props.removeProducts(productsToRemove);
    };

    getNestedProductIds = (productElement) => {
        const childrenProducts = productElement.querySelectorAll('[id^="product-"]');
        if (childrenProducts.length === 0) return [];
        let nestedProducts = [];
        childrenProducts.forEach(product => {
            if(!!product.id) nestedProducts = nestedProducts.concat([product.id.substring(8), ...this.getNestedProductIds(product)])
        });
        return nestedProducts;
    };

    createProduct = (product) => {
        // create product
        switch (product.type.toLowerCase()) {
            case 'content':
                return createContentEmbed(this.siteUUID, product);
            case 'commenting':
                return createCommentingEmbed(this.siteUUID, product, this.props.pageUUID, this.props.pageUrl);
            default:
                return createAd(product);
        }
    };

    handleSaveChanges = () => {
        const { embeds, ads } = this.props;
        const products = Object.values({ ...embeds, ...ads })
            .filter(product => product.inserted)
            .map(product => ({
                uuid: product.id,
                location: encodeURIComponent(product.location),
                type: product.type === 'display'|| product.type === 'mobile' ? 'AD_UNIT' : product.type
            }));
        this.props.saveMockup(this.props.pageUUID, products, this.state.insticatorifiedMockup)
    };

    handleDiscardChanges = () => {
        // revert visual changes to the mockup
        const products = { ...this.props.updatedProducts.embeds, ...this.props.updatedProducts.ads };
        this.reconcileProductsStates({ products, onLoad: false });
        // revert any changes to the application state
        this.props.discardProductsChange()
    };

    handleSearch = (productSearchTerm) => {
        this.setState({ productSearchTerm });
    };

    toolbarControlClassToActOn = (e) => {
    	const targetEl = e.target;
    	const toolbarControl = targetEl.classList.value.includes('toolbarcontrol') ? targetEl : targetEl.closest('.toolbarcontrol');
    	const classToActOn = toolbarControl.classList[2];
    	return classToActOn;
    };

    handleMoveToolbar = (e) => {
    	const newPosition = this.toolbarControlClassToActOn(e);
    	this.setState({ toolbarPosition: newPosition });
    	localStorage.setItem(`insticatorMockupToolbarPosition`, newPosition);
    };

    handleToolBarResizeIncrementer = (e) => {
    	const sizes = ['xsmall', 'small', 'medium', 'large', 'xlarge'];
    	const selectedVal = this.toolbarControlClassToActOn(e);
    	const currentSizeIndex = sizes.indexOf(this.state.toolbarSize);
    	let newSize;
    	switch (selectedVal) {
    		case 'increase':
    			newSize = sizes[currentSizeIndex + 1];
    			break;
    		case 'decrease':
    			newSize = sizes[currentSizeIndex - 1];
    			break;
    		default:
    			return;
    	}
    	this.setState({ toolbarSize: newSize });
    	localStorage.setItem(`insticatorMockupToolbarSize`, newSize);
    };

    handleToolBarResize = (e) => {
    	const newSize = this.toolbarControlClassToActOn(e);
    	this.setState({ toolbarSize: newSize });
    	localStorage.setItem(`insticatorMockupToolbarSize`, newSize);
    };
    handleDeleteMockup = () => {
        this.props.displayModal(true, <PopupMessage
            type={'delete'}
            title={'Are you sure you want to delete this mock up?'}
            buttonPrimary={{ text: 'Yes, delete this mock up', variant: 'action', action: this.deleteMockUpAction }}
            buttonSecondary={{ text: 'Cancel', variant: 'system', action: () => this.props.displayModal(false) }}
        />);
    };
    deleteMockUpAction = () => {
        this.props.deleteMockUp(this.props.addProductsStatus.pageUUID);
        this.props.displayModal(false);
    };

    renderAdsControlContent = () => {
        if(!!Object.keys(this.props.ads).length) {
            return(
                <ItemList
                    productType={'ads'}
                    productData={Object.values(this.props.ads)}
                    state={this.state}
                    handleCancelInsert={this.handleCancelInsertClick}
                    handleInsertCTAClick={this.handleInsertCTAClick}
                    handleRemoveProduct={this.handleRemoveProduct}
                />)
        }
        return <div className={'itemlistnodata'}>No Ads for this site. <Link text={'Create ads.'} type={'action'} action={this.handleEditAds} icon={'arrow-circle-right'} behavior={'none'} iconRight={true} color={'primary'} /></div>
    };

    renderEmbedsControlContent = () => {
        if(!!Object.keys(this.props.embeds).length) {
            return(
                <div>
                    <SearchBar type={'search'} placeholder={'Search ...'} onChange={this.handleSearch} />
                    <ItemList
                        productType={'embeds'}
                        productData={Object.values(this.props.embeds)}
                        state={this.state}
                        disableEditButton={this.props.productsChangeDetected}
                        handleCancelInsert={this.handleCancelInsertClick}
                        handleInsertCTAClick={this.handleInsertCTAClick}
                        handleRemoveProduct={this.handleRemoveProduct}
                        handleUniqueEmbedLink={this.handleUniqueEmbedLink}
                    />
                </div>)
        }
        return <div className={'itemlistnodata'}>No Products for this site. <Link text={'Manage Products.'} type={'action'} action={() => this.handleEditEmbeds('ITEM_LIST')} icon={'arrow-circle-right'} operation={'load'} processing={this.props.fetchingRedirect && this.state.redirectLinkClick === 'ITEM_LIST'} behavior={'none'} iconRight={true} color={'primary'} /></div>
    };

    render() {
        return (
            <Page klass={`addproducts ${this.props.classes.addproducts} inspectorEnabled-${this.state.inspectorEnabled}`}>
                <div id="mockup">
                    {this.state.insticatorifiedMockup === '' && <LoadState graphicName={'insticator-icon'} bodycopy={<span>Insticator is loading your data.</span>} />}
                    <Frame id={'mockupframe'}><InnerHTML html={this.state.insticatorifiedMockup} /></Frame>
                </div>
                <div id="toolbar" className={`position-${this.state.toolbarPosition} size-${this.state.toolbarSize}`}>
                    <div id='mockuptoolbar'>
                        <div className={'title'}>
                            <h2>Mockup Toolbar</h2>
                            <CTAs klass={`isActive-${this.props.productsChangeDetected}`}>
                                {this.props.productsChangeDetected && <DiscardButton disabled={this.props.savingMockup} discardChanges={this.handleDiscardChanges} />}
                                <SaveButton handleSave={this.handleSaveChanges} variant={'action'} buttonText={'Save'} savingData={this.props.savingMockup} disabled={!this.props.productsChangeDetected || this.props.savingMockup} />
                            </CTAs>
                        </div>
                        <div id='controlbar'>
	                		<div className='sizers'>
	                			<div className='incrementers'>
									<div className='toolbarcontrol sizer increase' onClick={this.handleToolBarResizeIncrementer}><Icon name={'plus'} /></div>
									<div className='toolbarcontrol sizer decrease' onClick={this.handleToolBarResizeIncrementer}><Icon name={'minus'} /></div>
								</div>
								<div className='directsize'>
									<div className='toolbarcontrol sizer xsmall' onClick={this.handleToolBarResize}><span>xsmall</span></div>
									<div className='toolbarcontrol sizer small' onClick={this.handleToolBarResize}><span>small</span></div>
									<div className='toolbarcontrol sizer medium' onClick={this.handleToolBarResize}><span>medium</span></div>
									<div className='toolbarcontrol sizer large' onClick={this.handleToolBarResize}><span>large</span></div>
									<div className='toolbarcontrol sizer xlarge' onClick={this.handleToolBarResize}><span>xlarge</span></div>
								</div>
							</div>
	                    	<div className='movers'>
	                    		<div className='movers-group movers-left'>
	                        		<div className='toolbarcontrol mover top-left' onClick={this.handleMoveToolbar}><Icon name={'chevron-up'} /></div>
	                        		<div className='toolbarcontrol mover bottom-left' onClick={this.handleMoveToolbar}><Icon name={'chevron-up'} /></div>
	                        	</div>
	                        	<div className='movers-group movers-right'>
	                        		<div className='toolbarcontrol mover bottom-right' onClick={this.handleMoveToolbar}><Icon name={'chevron-up'} /></div>
	                        		<div className='toolbarcontrol mover top-right' onClick={this.handleMoveToolbar}><Icon name={'chevron-up'} /></div>
	                        	</div>
	                    	</div>
						</div>
                        <div className={`controls disabled-${this.state.reconcilingProductsStates || this.props.savingMockup || this.props.deletingMockUp}`}>
                            <ControlSection
                                id={'details'}
                                contents={
                                    <div>
                                        <div className={'detail page'}>
                                            <Label>Page URL</Label>
                                            <CTAs>
                                                <TextField type={'text'} disabled={this.props.productsChangeDetected} name={'pageurl'} readOnly={true} value={this.props.pageUrl} />
                                                <Button type={`external`} disabled={this.props.productsChangeDetected} action={this.props.pageUrl} variant={`secondary`} name={`go`} text={`Go`} />
                                            </CTAs>
                                        </div>
                                        <div className={'detail mockup'}>
                                            <Label>Mockup URL <Link name={'deletemockup'} type={'action'} variant={'delete'} text={'delete'} icon={'trash-alt'} operation={'link'} behavior={'none'} action={this.handleDeleteMockup} iconRight={false} color={'error'} /></Label>
                                            <CTAs>
                                                <CopyField name={'mockupurl'} fieldDisabled={this.props.productsChangeDetected} buttonDisabled={this.props.productsChangeDetected} nowrap={true} defaultValue={this.props.mockupUrl} height={'inline'} readyOnly={true} margin={'0px'} ctaVariant={'primary'} icon={'clipboard'} ctaPosition={'inline'} />
                                                <Button type={`external`} disabled={this.props.productsChangeDetected} action={this.props.mockupUrl} variant={`secondary`} name={`go`} text={`Go`} />
                                            </CTAs>
                                        </div>
                                    </div>
                                }
                            />
                            <ControlSection
                                id={'embeds'}
                                headline={'Products'}
                                buttonAction={() => this.handleEditEmbeds('MANAGE')}
                                buttonProcessing={this.props.fetchingRedirect && this.state.redirectLinkClick === 'MANAGE'}
                                buttonDisabled={this.props.productsChangeDetected}
                                products={Object.values(this.props.embeds)}
                                contents={this.renderEmbedsControlContent()}
                            />
                            <ControlSection
                                id={'ads'}
                                headline={'Ads'}
                                buttonAction={this.handleEditAds}
                                buttonDisabled={this.props.productsChangeDetected}
                                products={Object.values(this.props.ads)}
                                contents={this.renderAdsControlContent()}
                            />
                        </div>
                    </div>
                </div>
            </Page>
        )
    }
}



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(AddProducts);
