/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm, replacePXinStringWithEM, convertHexToRGB } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// MODAL
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    taxonomyeditormodal: {
        background: convertHexToRGB(theme.color.dark.dark.swatch, 30),
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: '0',
        left: '0',
        zIndex: '-1',
        opacity: '0',
        overflow: 'auto', // make sure modal is scrollable for shorter

        // interior

        '& .modalcontent': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '80%',

            '& .modalpage': {
                display: 'inline-block', // inline this element so it will center properly (and apply modal design styles below)
                background: '#fff',
                padding: pxToEm(16),
                border: `${pxToEm(1)} solid #EDEDED`,
                boxShadow: `0 ${pxToEm(1)} ${pxToEm(1)} 0 #EDF1FA`,
                position: 'relative',
                textAlign: 'left',

                // close button
                '& .close': {
                    background: theme.color.light.light.swatch,
                    border: replacePXinStringWithEM(`1px solid #E6E9F1`),
                    borderTop: '0',
                    borderRight: '0',
                    cursor: 'pointer',
                    padding: replacePXinStringWithEM(`10px 15px`),
                    position: 'absolute',
                    top: '0',
                    right: '0',
                    zIndex: '4',
                    transition: 'filter .2s linear 0s',
                    '& .icon': {
                        color: convertHexToRGB(theme.color.supporting.dark.swatch, 50),
                        fontSize: '125%'
                    },
                    '&:hover': {
                        '& .icon': {
                            filter: 'brightness(65%)'
                        }
                    }
                },

                // page / subpage
                '& .subpage': {
                    width: '100%' // extend to full width of viewport (each page/subpage will have a max width they will expand too) ... this will allow the modal to flex with the browser size and stop at specific sizes we desire
                }
            }
        },



        // modal active
        '&.active': {
            animationName: 'fadeIn',
            animationDuration: '.3s',
            animationDelay: '0s',
            animationFillMode: 'forwards',
            zIndex: '10000', // needs to be this to be on top of tooltips
            '& .close': {
                animationName: 'rotateIn',
                animationDuration: '.6s',
                animationDelay: '0s',
                animationFillMode: 'forwards',
            }
        }
    }
});
