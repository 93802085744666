/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import {pxToEm, replacePXinStringWithEM} from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// SSP APPROVALS : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    adstxtcolumn: {
        padding: `0 ${pxToEm(27)}`,
        borderRight: 'dashed 1px rgb(192, 201, 220)',
        borderWidth: '1px',
        flex: '1',
        '&.editing-true': {
            border: 'none',
            '& .filevalues': {
                '& .field': {
                    maxWidth: 'none' // want this to stretch to entire column width for better visual at wider screens
                },
                '& .ctas': {
                    display: 'none'
                }
            }
        },
        '&:last-child': {
            borderRight: 'none',
            paddingRight: '0'
        },
        '& .label': {
            display: 'flex',
            alignItems: 'center',
            fontSize: pxToEm(16),
            fontWeight: 'bold',
            '& .icon': {
                fontSize: pxToEm(32),
                marginRight: pxToEm(5)
            }
        }
    }
});
