/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm, replacePXinStringWithEM } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// ANSWER CONTAINER : STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    answercontainer: {
        flex: replacePXinStringWithEM('1 0 205px'),
        // width: pxToEm(205),
        height: pxToEm(500),
        '& .answerheader': {
            height: pxToEm(100),
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            marginBottom: pxToEm(5),
            '& .answertitle': {
                '& h5': {
                    fontWeight: '600'
                }
            },
            '& .controlcontainer': {
                display: 'flex',
                justifyContent: 'flex-end',
                '& .control': {
                    height: pxToEm(40),
                    width: pxToEm(40),
                    background: theme.color.supporting.light.swatch,
                    border: `${pxToEm(1)} #f2f4f8 solid`,
                    transition: 'filter .2s linear 0s',
                    '& .link': {
                        width: '100%',
                        display: 'block',
                        '& .inner': {
                            width: '100%',
                            '& .icon': {
                                margin: 0,
                                lineHeight: pxToEm(38),
                                fontSize: pxToEm(16)
                            }
                        }
                    },
                    '&:hover': {
                        filter: 'brightness(97%)'
                    }
                },
                '& .clearbuttoncontainer': {
                    marginRight: pxToEm(5)
                }
            }
        },
        '& .answertaxonomycontainer': {
            height: pxToEm(380),
            padding: pxToEm(9),
            backgroundColor: props => props.selected ? 'rgb(255, 207, 0, 0.15)' : theme.color.supporting.light.swatch,
            border: props => `${pxToEm(1)} ${props.selected ? '#ffcf00' : '#f2f4f8'} solid`,
            overflowY: 'scroll',
            boxSizing: 'border-box',
            cursor: 'pointer',
            transition: 'all .2s linear 0s',
            '&:hover': {
                filter: props => props.selected ? 'none' : 'brightness(97%)',
                background: props => props.selected ? 'rgb(255, 207, 0, 0.22)' : theme.color.supporting.light.swatch,
            },
            '& .classificationcontainer': {
                backgroundColor: theme.color.light.light.swatch,
                marginBottom: pxToEm(18),
                '& .selected': {
                    backgroundColor: '#f2f4f8',
                    '&.deletedvalue-true':{
                        background: theme.color.attention.medium.swatch,
                        filter: 'brightness(97%)'
                    }
                }
            }
        },
        '& .flagged': {
            background: `${theme.color.attention.medium.swatch} !important`,
            borderColor: `${theme.color.attention.dark.swatch} !important`,
            '& .icon': {
                color: theme.color.attention.dark.swatch
            }
        }
    }
});