/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm, replacePXinStringWithEM } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// TAXONOMY EDITOR
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
    taxonomyeditor: {
    	borderRight: replacePXinStringWithEM(`1px solid rgb(242, 244, 248)`),
        position: 'relative',
        '& .taxonomywrapper': {
        	padding: replacePXinStringWithEM(`34px 30px 34px 55px`)
        }
    }
});
