/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { baselineAppStyles, pxToEm, replacePXinStringWithEM } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- EXPORT --------------- ++++++++++ */
// APP STYLES
// utliizes utils and CSS-IN-JS (JS objects converted to CSS using a library)
export default theme => ({
	...baselineAppStyles,
	app: {
		// overrides to help create system like layout of vertical scrolls and all that 
		'&.classification': {
			'& .division': {
		        display: 'flex',
		        flexDirection: 'column',
		        flexWrap: 'nowrap',
		        // overflow: 'hidden',
		        position: 'absolute',
		        width: '100%',
		        height: '100%',
				'& #header': {
					flex: replacePXinStringWithEM('0 0 auto'), //100px
					// height: 'auto'
				},
				'& #body': {
					flex: '1',
					overflow: 'hidden',
					'& .wrapper': {
						height: '100%'
					},
					'& .taxonomyeditor': {
						'& .taxonomywrapper': {
							display: 'flex',
						    flexDirection: 'column',
						    flexWrap: 'nowrap',
						    height: '100%',
						    '& .tree': { zIndex: '1' },
						    '& .fade': { zIndex: '2', flex: replacePXinStringWithEM('0 0 120px'), height: 'auto' },
						    '& .taxonomyeditorcontrols': { background: '#fff', marginTop: '0', paddingTop: pxToEm(20), zIndex: '3' },
						}
					},
					'& #main': {
						position: 'relative',
                		width: '100%',
                		overflow: 'auto'
		            },
		            '& > .page': {
	                    height: '100%'
	                }
				}
			}
		},
		'&.mockupgenerator': {
			'& .division': {
		        display: 'flex',
		        flexDirection: 'column',
		        flexWrap: 'nowrap',
		        // overflow: 'hidden',
		        position: 'absolute',
		        width: '100%',
		        height: '100%',
				'& #header': {
					flex: replacePXinStringWithEM('0 0 auto'), //100px
					// height: 'auto'
				},
				'& #body': {
					flex: '1',
					overflow: 'hidden',
					'& #main': {
						position: 'relative',
                		width: '100%',
                		height: '100%',
                		overflow: 'auto'
		            },
		            '& .page': {
	                    height: '100%'
	                }
				}
			}
		}
	}
});