import React from 'react';
//material
import { Group } from "@insticator/insticator-ui";

//styles
import styles from 'apps/sspadstxt/pages/sspmanager/sspdetail/sspadstxt/sspadstxt_styles';
import injectSheet from "react-jss";
import AdsTxtColumn from "../../../../material/adstxtcolumn/adstxtcolumn";





const S2SAdsTxt = props => {
    const updateS2SAdsTxtFiles = (event, adType, fileLength) => props.updateS2SAdsTxtValues(event.target.value, adType, fileLength);

    return (<Group klass={`sspadstxt ${props.classes.sspadstxt}`} name={'adstxt'} >
                <div className={'full labeltitle'} >S2S Ads.txt</div>
                <Group name={'adstxtcolumns'}>
                    <AdsTxtColumn fileType={'display'} icons={['image']} label={'Display Ads.txt'} updateShortFile={e=>updateS2SAdsTxtFiles(e, 'display', 'short')} shortFile={props.s2SAdsTxtValues.short} longFile={props.s2SAdsTxtValues.long} updateLongFile={e=>updateS2SAdsTxtFiles(e, 'display', 'long')} editing={true}/>
                </Group>
            </Group>)

};

/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default injectSheet(styles)(S2SAdsTxt);
