/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
// libraries
import React from 'react';
// material
import { Button, TextAreaField, SaveButton, DiscardButton } from '@insticator/insticator-ui'; // iui-material
//styles
import injectSheet from 'react-jss';
import styles from 'apps/classification/pages/editor/pagecontrols/pagecontrols_styles';



/* ========== ~~~~~~~~~~ PAGECONTROLS : CHILD ~~~~~~~~~~ ========== */
// PRESENTATIONAL COMPONENT
// receives app state & actions as props from parent "_container" component
// this child component handles local state and uses props passed from parent to read/write application state when necessary
const PageControls = (props) => {
    const renderNotes = () => (
        <div className={'notecontainer'}>
            <div className={'notestitle'}>
                Notes:
            </div>
            <TextAreaField type={`textarea`}
                value={props.notes}
                handleChange={(event) => {props.updateQuestionNote(event.target.value)}} />
        </div>
    );
    const transpileDataForSavingQuestion = (data) => ({
        questionID: data.questionID,
        notes: !!data.notes ? data.notes : null,
        classified: data.classified,
        adminUUID: props.adminUUID,
        answers: transpileAnswers(data.answers)
    });
    const transpileAnswers = answers => answers.map(answer => ({
        classification: answer.classification.filter(id => !!props.taxonomyTree[id]),
        flagged: answer.flagged,
        answerID: answer.answerID
    }));
    const handleSave = () => props.saveQuestion(transpileDataForSavingQuestion(props.activeQuestion));
    const handleDiscard = () => props.discardChanges();
    const backNavigationDisabled = () => (props.questionChangeDetected || (props.questionIndex - 1 < 0));
    const nextNavigationDisabled = () => (props.questionChangeDetected || (props.questionIndex + 1 === props.totalQuestions));
    const handleNextQuestion = (navigationType) => {
        // nextActiveQuestion action will be dispatched with resetTaxonomyTreeState action to clear taxonomy tree state.
        // Please see component's container.
        navigationType === 'back' ? props.nextActiveQuestion(props.questionIndex-1) : props.nextActiveQuestion(props.questionIndex+1)
    };
    const renderPageNavigation = () => (
        <div className={'pagenavigation'}>
            <div className={'editstate'}>
                {props.questionChangeDetected && <DiscardButton klass={'discardbutton'} discardChanges={handleDiscard} />}
                {props.questionChangeDetected && <SaveButton klass={'savebutton'} savingData={props.savingActiveQuestion} removeIcon handleSave={handleSave} />}
                {(props.questionIsSaved && !props.questionChangeDetected) && <span className={'changessavedtext'}>Changes Saved</span>}
            </div>
            <div className={'pagechange'}>
                <Button
                    type={`action`}
                    action={()=>handleNextQuestion('back')}
                    variant={`primary`}
                    name={`back`}
                    text={`Back`}
                    klass={'pagenavbutton'}
                    icon={`angle-left`}
                    disabled={backNavigationDisabled()}
                />
                <Button
                    type={`action`}
                    action={()=>handleNextQuestion('next')}
                    variant={`primary`}
                    name={`next`}
                    text={`Next`}
                    klass={'pagenavbutton'}
                    icon={`angle-right`}
                    iconRight
                    disabled={nextNavigationDisabled()}
                />
            </div>
        </div>
    );
    return (
        <div className={`pagecontrols ${props.classes.pagecontrols}`}>
            {renderNotes()}
            {renderPageNavigation()}
        </div>
    );
};



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
// will be utilized in parent component after being infused with app state & actions
export default injectSheet(styles)(PageControls);
