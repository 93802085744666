/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { createStore, applyMiddleware, compose } from 'redux';
import { routerMiddleware } from 'react-router-redux';
import { enableBatching } from 'redux-batched-actions';
import { history } from '@insticator/insticator-ui'; // iui-utils
import thunk from 'redux-thunk';
import rootReducer from 'state/reducers/root';

// .......... STORE HELPERS .......... //

// ------------ WITH REDUX DEV TOOLS --------------------- //
// const composeEnhancers =
//     typeof window === 'object' &&
//     window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
//         window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
//             // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
//         }) : compose;
// const enhancers = [];
// const middleware = [
//     thunk, // used for asynchronous actions
//     routerMiddleware(history) // this will sync up internal routing with history object so we have traditional browser capabilities
// ];
// const composedEnhancers = composeEnhancers(
//     applyMiddleware(...middleware),
//     ...enhancers
// );

// ------------ WITHOUT REDUX DEV TOOLS --------------------- //
const enhancers = [];
const middleware = [
    thunk, // used for asynchronous actions
    routerMiddleware(history) // this will sync up internal routing with history object so we have traditional browser capabilities
];

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);


// /* ========== ~~~~~~~~~~ STORE (application state) ~~~~~~~~~~ ========== */
// STORE
// configure our store of application state
// index.js file will utilize this via "provider" functionality from redux to be passed down and accessible via child components
const store = createStore(
    enableBatching(rootReducer),
    // initialState,
    composedEnhancers,
);



/* ++++++++++ --------------- EXPORTS --------------- ++++++++++ */
export default store;



