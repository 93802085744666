/* ++++++++++ --------------- IMPORTS --------------- ++++++++++ */
import { pxToEm, pxToRem, replacePXinStringWithEM } from '@insticator/insticator-ui'; // iui-utils



/* ++++++++++ --------------- CREATE MOCKUP : STYLES --------------- ++++++++++ */
export default theme => ({
    createmockup: {
        '& .ctas': {
            justifyContent: 'flex-start',
            '& .field': { flex: '1', marginRight: pxToRem(7) },
            '& .button': { width: pxToEm(140) }
        },
        '& .name-visual': {
            '& .illustration': {
                fontSize: '15% !important'
            }
        }
    }
});