import axios from 'axios';
//
import { url } from 'utils/urls';
const headers = { "Content-Type": "application/json" };

export const asyncFetchClassificationQuestions = data => axios.post(url.cms.classification.questions, data, { headers, withCredentials: true });

export const asyncDownloadQuestionCSVFile = data => axios.post(url.cms.classification.questions, data, { headers, withCredentials: true });

export const asyncSetQuestionsLiveOnDMP = data => axios.post(url.cms.classification.setQuestionsLiveForDmp, data, { headers, withCredentials: true });

export const asyncSaveQuestion = data => axios.post(url.cms.classification.updateQuestion, data, { headers, withCredentials: true });
